var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./metabase.util.malli.registry.js");
'use strict';var sfa,tfa,ufa,vfa,wfa,xfa,yfa,zfa,Afa;sfa=function(a,b){a.sort(b||$CLJS.Da)};tfa=function(a,b){const c=Array(a.length);for(let e=0;e<a.length;e++)c[e]={index:e,value:a[e]};const d=b||$CLJS.Da;sfa(c,function(e,f){return d(e.value,f.value)||e.index-f.index});for(b=0;b<a.length;b++)a[b]=c[b].value};
$CLJS.gH=function(a){return $CLJS.E.g(a,$CLJS.Jd)?$CLJS.Jd:function(b,c){var d=a.g?a.g(b,c):a.call(null,b,c);return"number"===typeof d?d:$CLJS.n(d)?-1:$CLJS.n(a.g?a.g(c,b):a.call(null,c,b))?1:0}};$CLJS.hH=function(a,b){if($CLJS.y(b)){var c=$CLJS.we.h?$CLJS.we.h(b):$CLJS.we.call(null,b);tfa(c,$CLJS.gH(a));return $CLJS.od($CLJS.y(c),$CLJS.pd(b))}return $CLJS.Kc};$CLJS.iH=new $CLJS.M(null,"second","second",-444702010);$CLJS.jH=new $CLJS.M(null,"second-of-minute","second-of-minute",222734326);
$CLJS.kH=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time","metabase.lib.schema.temporal-bucketing/unit.date-time",1924841408);$CLJS.lH=new $CLJS.M(null,"millisecond","millisecond",-540123566);$CLJS.mH=new $CLJS.M(null,"year-of-era","year-of-era",682445876);ufa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.extract","metabase.lib.schema.temporal-bucketing/unit.date-time.extract",1230935001);
vfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time","metabase.lib.schema.temporal-bucketing/unit.time",713582593);$CLJS.nH=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.truncate","metabase.lib.schema.temporal-bucketing/unit.date-time.truncate",686665771);$CLJS.oH=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date","metabase.lib.schema.temporal-bucketing/unit.date",1876937510);
wfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.extract","metabase.lib.schema.temporal-bucketing/unit.time.extract",-456342719);xfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.truncate","metabase.lib.schema.temporal-bucketing/unit.date.truncate",1199064479);yfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.extract","metabase.lib.schema.temporal-bucketing/unit.date.extract",1595055425);
zfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","option","metabase.lib.schema.temporal-bucketing/option",-1756789401);$CLJS.pH=new $CLJS.M("option","temporal-bucketing","option/temporal-bucketing",-108914562);$CLJS.qH=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit","metabase.lib.schema.temporal-bucketing/unit",1808010880);$CLJS.rH=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.interval","metabase.lib.schema.temporal-bucketing/unit.date.interval",-1463811525);
Afa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.truncate","metabase.lib.schema.temporal-bucketing/unit.time.truncate",-948820335);var sH=new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.Fi,$CLJS.Ej,$CLJS.Dj,$CLJS.pi,$CLJS.kk,$CLJS.zi,$CLJS.ji,$CLJS.mH],null),tH=$CLJS.fh(sH),Bfa,Cfa,Dfa;$CLJS.X(yfa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lk,new $CLJS.h(null,1,[$CLJS.yt,"Valid date extraction unit"],null)],null),tH));var uH=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.gu,$CLJS.nj,$CLJS.wj,$CLJS.oi,$CLJS.ji],null),vH=$CLJS.fh(uH);
$CLJS.X(xfa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lk,new $CLJS.h(null,1,[$CLJS.yt,"Valid date truncation unit"],null)],null),vH));$CLJS.wH=$CLJS.eg.j($CLJS.Cf,$CLJS.jl.o(),$CLJS.gf.g(uH,sH));Bfa=$CLJS.fh($CLJS.wH);$CLJS.X($CLJS.oH,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lk,new $CLJS.h(null,1,[$CLJS.yt,"Valid date bucketing unit"],null)],null),Bfa));var xH=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jH,$CLJS.hj,$CLJS.vi],null),yH=$CLJS.fh(xH);
$CLJS.X(wfa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lk,new $CLJS.h(null,1,[$CLJS.yt,"Valid time extraction unit"],null)],null),yH));var zH=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.lH,$CLJS.iH,$CLJS.Ti,$CLJS.qk],null),AH=$CLJS.fh(zH);$CLJS.X(Afa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lk,new $CLJS.h(null,1,[$CLJS.yt,"Valid time truncation unit"],null)],null),AH));$CLJS.BH=$CLJS.eg.j($CLJS.Cf,$CLJS.jl.o(),$CLJS.gf.g(zH,xH));Cfa=$CLJS.fh($CLJS.BH);
$CLJS.X(vfa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lk,new $CLJS.h(null,1,[$CLJS.yt,"Valid time bucketing unit"],null)],null),Cfa));$CLJS.CH=$CLJS.eg.j($CLJS.Cf,$CLJS.jl.o(),$CLJS.gf.l(zH,uH,$CLJS.H([xH,sH])));Dfa=$CLJS.fh($CLJS.CH);$CLJS.X($CLJS.kH,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lk,new $CLJS.h(null,1,[$CLJS.yt,"Valid datetime bucketing unit"],null)],null),$CLJS.CH));var Efa=$CLJS.be.g(Dfa,$CLJS.ci);
$CLJS.X($CLJS.qH,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lk,new $CLJS.h(null,1,[$CLJS.yt,"Valid temporal bucketing unit"],null)],null),Efa));$CLJS.DH=$CLJS.hu.g(vH,AH);$CLJS.X($CLJS.nH,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lk,new $CLJS.h(null,1,[$CLJS.yt,"Valid datetime truncation unit"],null)],null),$CLJS.DH));$CLJS.EH=$CLJS.hu.g(tH,yH);$CLJS.X(ufa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lk,new $CLJS.h(null,1,[$CLJS.yt,"Valid datetime extraction unit"],null)],null),$CLJS.EH));
var FH=$CLJS.be.g(vH,$CLJS.ji);$CLJS.X($CLJS.rH,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lk,new $CLJS.h(null,1,[$CLJS.yt,"Valid date interval unit"],null)],null),FH));$CLJS.X($CLJS.oE,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lk,new $CLJS.h(null,1,[$CLJS.yt,"Valid time interval unit"],null)],null),AH));var Ffa=$CLJS.hu.g(FH,AH);$CLJS.X($CLJS.pE,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lk,new $CLJS.h(null,1,[$CLJS.yt,"Valid datetime interval unit"],null)],null),Ffa));
$CLJS.X(zfa,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tk,$CLJS.pH],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ri,$CLJS.qH],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ci,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.Os],null)],null));