var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");
'use strict';var su,uu,vu,wu,xu,yu,zu,Gu,Hu,Iu,Ju,Ku,yba,Nu,zba,Qu,Ru,Tu,Uu,Vu,Wu,Xu,Yu,cv,fv,hv,kv,Aba,Bba,pv,Cba,rv,sv,tv,zv,Av,Iv,Mv,Sv,Uv,Zv,cw,hw,jw,kw,ew,mw,nw,pw,ow,qw,Gba,vw,Ew,Fw,yw,Iba,Jw,Mw,Ow,Pw,Kba,Lba,Mba,Tw,Xw,Yw,Zw,$w,Nba,ex,hx,Oba,Pba,Qba,Rba,Sba,Tba,Uba,Vba,Wba,Yba,mx,Xba,qx,Zba,rx,$ba,aca,sx,bca,tx,cca,dca,eca,gca,hca,jca,kca,mca,oca,yx,Bx,qca,pca,rca,dw,gw,Ix,Jx,Kx,Lx,Ox,Px,uca,vca,wca,Sx,xca,Tx,Rx,Ux,av,Vx,ov,gx,yca,Wv,zca,Aca,dv,mv,Mx,Bca,Kv,uw,uv,Cca,Dca,Hv,Eca,zx,yv,tw,Fv,
ix,Fca,nv,Gca,px,wx,Wx,Hca,Qw,Yx,Sw,Jv,sw,$u,vx,Fba,Vv,Dv,gv,Ica,lw,Jca,Bw,Rw,Dx,Kca,Fx,Eba,Lca,Mca,Nca,$v,Oca,nx,Yv,Pca,Cw,Aw,Qca,iv,Rca,tca,Gx,vv,$x,Vw,ay,by,Gw,Sca,xv,Tca,sca,Uca,bw,lx,rw,Vca,Ww,Nx,Wca,kx,Xca,zw,lv,Yca,Lv,dy,Uw,Zca,ev,ux,Xv,Dba,xx,jv,ax,Ax,fx,bx,dx,ox,Hw,ey,fy,$ca,Su,ada,Pu,Zu,bda,wv,cda;su=function(a){return 1==a.length&&" "<=a&&"~">=a||""<=a&&"�">=a};$CLJS.tu=function(){};
uu=function(a){if(null!=a&&null!=a.rc)a=a.rc(a);else{var b=uu[$CLJS.wa(null==a?null:a)];if(null!=b)a=b.h?b.h(a):b.call(null,a);else if(b=uu._,null!=b)a=b.h?b.h(a):b.call(null,a);else throw $CLJS.bb("IWriter.-flush",a);}return a};vu=function(){};wu=function(a){if(null!=a&&null!=a.Lc)a=a.Lc(a);else{var b=wu[$CLJS.wa(null==a?null:a)];if(null!=b)a=b.h?b.h(a):b.call(null,a);else if(b=wu._,null!=b)a=b.h?b.h(a):b.call(null,a);else throw $CLJS.bb("IPending.-realized?",a);}return a};
xu=function(a){if("number"===typeof a)return String.fromCharCode(a);if("string"===typeof a&&1===a.length)return a;throw Error("Argument to char must be a character or number");};yu=function(a,b){return a-b*$CLJS.Vd(a,b)};zu=function(a,b,c){if(null!=a&&null!=a.m)a.m(a,b,c);else{var d=zu[$CLJS.wa(null==a?null:a)];if(null!=d)d.j?d.j(a,b,c):d.call(null,a,b,c);else if(d=zu._,null!=d)d.j?d.j(a,b,c):d.call(null,a,b,c);else throw $CLJS.bb("IMultiFn.-add-method",a);}};
$CLJS.Au=function(a,b){var c=$CLJS.ec($CLJS.N);a=$CLJS.y(a);for(b=$CLJS.y(b);;)if(a&&b)c=$CLJS.hg.j(c,$CLJS.A(a),$CLJS.A(b)),a=$CLJS.B(a),b=$CLJS.B(b);else return $CLJS.gc(c)};$CLJS.Bu=function(a,b){return $CLJS.lh(a,b)};$CLJS.Cu=function(a,b){return new $CLJS.ne(null,function(){var c=$CLJS.y(b);if(c){var d=$CLJS.A(c);d=a.h?a.h(d):a.call(null,d);c=$CLJS.n(d)?$CLJS.ee($CLJS.A(c),$CLJS.Cu(a,$CLJS.Lc(c))):null}else c=null;return c},null,null)};
$CLJS.Du=function(a,b){return $CLJS.hf.j(function(c){return c},b,$CLJS.bf(a,b))};Gu=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;$CLJS.Se.g(Eu,0<b.length?new $CLJS.w(b.slice(0),0,null):null);$CLJS.ac($CLJS.Fu,"\n")};Hu=function(a){if("number"===typeof a)return a;if("string"===typeof a&&1===a.length)return a.charCodeAt(0);throw Error("Argument to char must be a character or number");};
Iu=function(a,b,c){var d=c;for(c=$CLJS.Cf;;){if($CLJS.td(d))return new $CLJS.P(null,2,5,$CLJS.Q,[c,b],null);var e=$CLJS.A(d);d=$CLJS.B(d);e=$CLJS.Se.g(a,new $CLJS.P(null,2,5,$CLJS.Q,[e,b],null));b=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);c=$CLJS.be.g(c,b);b=e}};Ju=function(a,b){var c=b;for(b=$CLJS.Cf;;){var d=$CLJS.Se.g(a,new $CLJS.P(null,1,5,$CLJS.Q,[c],null));c=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);if($CLJS.Va(c))return new $CLJS.P(null,2,5,$CLJS.Q,[b,d],null);b=$CLJS.be.g(b,c);c=d}};
Ku=function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eg.g($CLJS.N,function(){return function d(c){return new $CLJS.ne(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.qe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);var v=$CLJS.I(t,0,null);$CLJS.I(t,1,null);l.add(new $CLJS.P(null,2,5,$CLJS.Q,[u,v],null));m+=1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.mc(e))):$CLJS.te($CLJS.ve(l),null)}f=
$CLJS.A(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);k=$CLJS.I(f,0,null);$CLJS.I(f,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[l,k],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}()),$CLJS.eg.g($CLJS.N,function(){return function d(c){return new $CLJS.ne(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.qe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);l.add(new $CLJS.P(null,
2,5,$CLJS.Q,[u,t],null));m+=1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.mc(e))):$CLJS.te($CLJS.ve(l),null)}f=$CLJS.A(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[l,f],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())],null)};
yba=function(a,b){return $CLJS.eg.g($CLJS.N,function(){return function e(d){return new $CLJS.ne(null,function(){for(;;){var f=$CLJS.y(d);if(f){if($CLJS.Ad(f)){var k=$CLJS.lc(f),l=$CLJS.D(k),m=$CLJS.qe(l);a:for(var t=0;;)if(t<l){var u=$CLJS.kd(k,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);m.add(new $CLJS.P(null,2,5,$CLJS.Q,[v,new $CLJS.P(null,2,5,$CLJS.Q,[u,b],null)],null));t+=1}else{k=!0;break a}return k?$CLJS.te($CLJS.ve(m),e($CLJS.mc(f))):$CLJS.te($CLJS.ve(m),null)}k=$CLJS.A(f);m=$CLJS.I(k,0,null);
k=$CLJS.I(k,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[m,new $CLJS.P(null,2,5,$CLJS.Q,[k,b],null)],null),e($CLJS.Lc(f)))}return null}},null,null)}(a)}())};$CLJS.Lu=function(){var a=$CLJS.Fu;if(null!=a&&null!=a.uf)a.uf(a);else{var b=$CLJS.Lu[$CLJS.wa(null==a?null:a)];if(null!=b)b.h?b.h(a):b.call(null,a);else if(b=$CLJS.Lu._,null!=b)b.h?b.h(a):b.call(null,a);else throw $CLJS.bb("IPrettyFlush.-ppflush",a);}};$CLJS.Mu=function(a,b){a=$CLJS.q($CLJS.q(a));return b.h?b.h(a):b.call(null,a)};
Nu=function(a,b,c){$CLJS.Oh.v($CLJS.q(a),$CLJS.R,b,c)};zba=function(a,b){$CLJS.E.g(b,"\n")?(Nu(a,$CLJS.Ou,0),Nu(a,$CLJS.mj,$CLJS.Mu(a,$CLJS.mj)+1)):Nu(a,$CLJS.Ou,$CLJS.Mu(a,$CLJS.Ou)+1);return $CLJS.ac($CLJS.Mu(a,Pu),b)};Qu=function(a,b,c,d){this.Ca=a;this.Nf=b;this.Yc=c;this.bi=d;this.C=1074167808;this.I=0};
Ru=function(a,b,c,d,e,f,k,l,m,t,u,v,x){this.parent=a;this.Cb=b;this.tb=c;this.sb=d;this.vb=e;this.wb=f;this.prefix=k;this.Ab=l;this.Db=m;this.xb=t;this.S=u;this.G=v;this.J=x;this.C=2230716170;this.I=139264};Tu=function(a,b){for(b=Su.h(b);;){if(null==b)return!1;if(a===b)return!0;b=Su.h(b)}};Uu=function(a,b,c,d,e,f,k,l){this.ca=a;this.data=b;this.ac=c;this.ba=d;this.aa=e;this.S=f;this.G=k;this.J=l;this.C=2230716170;this.I=139264};
Vu=function(a,b,c,d,e,f,k,l){this.ca=a;this.type=b;this.ea=c;this.ba=d;this.aa=e;this.S=f;this.G=k;this.J=l;this.C=2230716170;this.I=139264};Wu=function(a,b,c,d,e,f,k){this.ca=a;this.ea=b;this.ba=c;this.aa=d;this.S=e;this.G=f;this.J=k;this.C=2230716170;this.I=139264};Xu=function(a,b,c,d,e,f,k){this.ca=a;this.ea=b;this.ba=c;this.aa=d;this.S=e;this.G=f;this.J=k;this.C=2230716170;this.I=139264};
Yu=function(a,b,c,d,e,f,k,l,m){this.ca=a;this.ea=b;this.Wb=c;this.offset=d;this.ba=e;this.aa=f;this.S=k;this.G=l;this.J=m;this.C=2230716170;this.I=139264};
cv=function(a,b,c){b=$CLJS.y(b);for(var d=null,e=0,f=0;;)if(f<e){var k=d.X(null,f);if(!$CLJS.E.g(Zu.h(k),$u)){var l=av.h($CLJS.q($CLJS.q(a)));$CLJS.n(l)&&$CLJS.ac(Pu.h($CLJS.q($CLJS.q(a))),l)}bv.g(a,k);$CLJS.Oh.v($CLJS.q(a),$CLJS.R,av,av.h(k));k=av.h($CLJS.q($CLJS.q(a)));$CLJS.n($CLJS.n(c)?k:c)&&($CLJS.ac(Pu.h($CLJS.q($CLJS.q(a))),k),$CLJS.Oh.v($CLJS.q(a),$CLJS.R,av,null));f+=1}else if(b=$CLJS.y(b))$CLJS.Ad(b)?(d=$CLJS.lc(b),b=$CLJS.mc(b),k=d,e=$CLJS.D(d),d=k):(k=$CLJS.A(b),$CLJS.E.g(Zu.h(k),$u)||
(d=av.h($CLJS.q($CLJS.q(a))),$CLJS.n(d)&&$CLJS.ac(Pu.h($CLJS.q($CLJS.q(a))),d)),bv.g(a,k),$CLJS.Oh.v($CLJS.q(a),$CLJS.R,av,av.h(k)),k=av.h($CLJS.q($CLJS.q(a))),$CLJS.n($CLJS.n(c)?k:c)&&($CLJS.ac(Pu.h($CLJS.q($CLJS.q(a))),k),$CLJS.Oh.v($CLJS.q(a),$CLJS.R,av,null)),b=$CLJS.B(b),d=null,e=0),f=0;else break};fv=function(a,b){var c=$CLJS.Mu(Pu.h($CLJS.q($CLJS.q(a))),$CLJS.hk),d;(d=null==c)||(a=$CLJS.Mu(Pu.h($CLJS.q($CLJS.q(a))),$CLJS.Ou),b=(b=$CLJS.y(b))?dv.h($CLJS.id(b))-ev.h($CLJS.A(b)):0,d=a+b<c);return d};
hv=function(a,b,c){b=$CLJS.q(gv.h(b));return $CLJS.n(b)?b:!fv(a,c)};kv=function(a,b,c){var d=iv.h($CLJS.q($CLJS.q(a))),e=$CLJS.Mu(Pu.h($CLJS.q($CLJS.q(a))),$CLJS.hk);return $CLJS.n(d)?$CLJS.n(e)?(d=$CLJS.q(jv.h(b))>=e-d)?hv(a,b,c):d:e:d};Aba=function(a){var b=$CLJS.A(a),c=lv.h(b);b=$CLJS.y($CLJS.Cu(function(d){return!($CLJS.E.g(Zu.h(d),$u)&&Tu(lv.h(d),c))},$CLJS.B(a)));return new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.y($CLJS.bf($CLJS.D(b)+1,a))],null)};
Bba=function(a){var b=$CLJS.A(a),c=lv.h(b);return $CLJS.y($CLJS.Cu(function(d){var e=lv.h(d);return!($CLJS.E.g(Zu.h(d),$u)&&($CLJS.E.g(e,c)||Tu(e,c)))},$CLJS.B(a)))};
pv=function(a,b){$CLJS.ac(Pu.h($CLJS.q($CLJS.q(a))),"\n");$CLJS.Oh.v($CLJS.q(a),$CLJS.R,av,null);b=lv.h(b);var c=mv.h(b);$CLJS.n(c)&&$CLJS.ac(Pu.h($CLJS.q($CLJS.q(a))),c);c=$CLJS.Se.g($CLJS.p,$CLJS.df($CLJS.q(nv.h(b))-$CLJS.D(c)," "));$CLJS.ac(Pu.h($CLJS.q($CLJS.q(a))),c);a:for($CLJS.Ye(ov.h(b),!0),$CLJS.Ye(gv.h(b),!0),a=Su.h(b);;)if($CLJS.n(a))$CLJS.Ye(gv.h(a),!0),$CLJS.Ye(ov.h(a),!0),a=Su.h(a);else break a;return null};
Cba=function(a){var b=$CLJS.y($CLJS.Cu(function(c){return!$CLJS.E.g(Zu.h(c),$u)},a));return new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.y($CLJS.bf($CLJS.D(b),a))],null)};rv=function(a){for(var b=$CLJS.Mt.h($CLJS.q($CLJS.q(a)));;){$CLJS.Oh.v($CLJS.q(a),$CLJS.R,$CLJS.Mt,$CLJS.eg.g($CLJS.Cf,b));if(fv(a,b))return null;var c=qv(a,b);if(b!==c)b=c;else return null}};
sv=function(a,b){$CLJS.Oh.v($CLJS.q(a),$CLJS.R,$CLJS.Mt,$CLJS.be.g($CLJS.Mt.h($CLJS.q($CLJS.q(a))),b));return fv(a,$CLJS.Mt.h($CLJS.q($CLJS.q(a))))?null:rv(a)};tv=function(a){var b=av.h($CLJS.q($CLJS.q(a)));return $CLJS.n(b)?($CLJS.ac(Pu.h($CLJS.q($CLJS.q(a))),b),$CLJS.Oh.v($CLJS.q(a),$CLJS.R,av,null)):null};
zv=function(a,b){var c=$CLJS.Kt(b,"\n",-1);if($CLJS.E.g($CLJS.D(c),1))return b;b=mv.h($CLJS.A(uv.h($CLJS.q($CLJS.q(a)))));var d=$CLJS.A(c);if($CLJS.E.g(vv,$CLJS.xi.h($CLJS.q($CLJS.q(a))))){var e=wv.h($CLJS.q($CLJS.q(a))),f=e+$CLJS.D(d);$CLJS.Oh.v($CLJS.q(a),$CLJS.R,wv,f);sv(a,new Uu(xv,d,null,e,f,null,null,null));rv(a);d=$CLJS.Mt.h($CLJS.q($CLJS.q(a)));$CLJS.n(d)&&(cv(a,d,!0),$CLJS.Oh.v($CLJS.q(a),$CLJS.R,$CLJS.Mt,$CLJS.Cf))}else tv(a),$CLJS.ac(Pu.h($CLJS.q($CLJS.q(a))),d);$CLJS.ac(Pu.h($CLJS.q($CLJS.q(a))),
"\n");d=$CLJS.y($CLJS.B($CLJS.Gt(c)));e=null;for(var k=f=0;;)if(k<f){var l=e.X(null,k);$CLJS.ac(Pu.h($CLJS.q($CLJS.q(a))),l);$CLJS.ac(Pu.h($CLJS.q($CLJS.q(a))),"\n");$CLJS.n(b)&&$CLJS.ac(Pu.h($CLJS.q($CLJS.q(a))),b);k+=1}else if(d=$CLJS.y(d))e=d,$CLJS.Ad(e)?(d=$CLJS.lc(e),k=$CLJS.mc(e),e=d,f=$CLJS.D(d),d=k):(d=$CLJS.A(e),$CLJS.ac(Pu.h($CLJS.q($CLJS.q(a))),d),$CLJS.ac(Pu.h($CLJS.q($CLJS.q(a))),"\n"),$CLJS.n(b)&&$CLJS.ac(Pu.h($CLJS.q($CLJS.q(a))),b),d=$CLJS.B(e),e=null,f=0),k=0;else break;$CLJS.Oh.v($CLJS.q(a),
$CLJS.R,vv,yv);return $CLJS.id(c)};Av=function(a,b,c,d,e,f){this.Ca=a;this.Nf=b;this.li=c;this.mh=d;this.Yc=e;this.ci=f;this.C=1074167808;this.I=0};
$CLJS.Ev=function(a){var b=$CLJS.Bv,c=Cv,d=new Ru(null,null,$CLJS.Xe(0),$CLJS.Xe(0),$CLJS.Xe(!1),$CLJS.Xe(!1),null,null,null,null,null,null,null),e=$CLJS.Xe($CLJS.Ig([uv,iv,Dba,Dv,Eba,$CLJS.xi,wv,av,Pu,Fba,$CLJS.Mt],[d,c,d,!0,null,yv,0,null,new Qu(a,b,$CLJS.Xe(new $CLJS.h(null,4,[$CLJS.hk,b,$CLJS.Ou,0,$CLJS.mj,0,Pu,a],null)),$CLJS.N),1,$CLJS.Cf]));return new Av(a,b,c,d,e,$CLJS.N)};
Iv=function(a,b){var c=$CLJS.Fu;b=new Ru(uv.h($CLJS.q($CLJS.q(c))),null,$CLJS.Xe(0),$CLJS.Xe(0),$CLJS.Xe(!1),$CLJS.Xe(!1),a,null,b,null,null,null,null);$CLJS.Oh.v($CLJS.q(c),$CLJS.R,uv,b);if($CLJS.E.g($CLJS.xi.h($CLJS.q($CLJS.q(c))),yv)){tv(c);var d=Fv.h($CLJS.q($CLJS.q(c)));$CLJS.n(d)&&(d.h?d.h($CLJS.Gv):d.call(null,$CLJS.Gv));$CLJS.n(a)&&$CLJS.ac(Pu.h($CLJS.q($CLJS.q(c))),a);c=$CLJS.Mu(Pu.h($CLJS.q($CLJS.q(c))),$CLJS.Ou);$CLJS.Ye(b.tb,c);$CLJS.Ye(b.sb,c)}else d=wv.h($CLJS.q($CLJS.q(c))),a=d+($CLJS.n(a)?
$CLJS.D(a):0),$CLJS.Oh.v($CLJS.q(c),$CLJS.R,wv,a),sv(c,new Wu(Hv,b,d,a,null,null,null))};
Mv=function(){var a=$CLJS.Fu,b=uv.h($CLJS.q($CLJS.q(a))),c=Jv.h(b);if($CLJS.E.g($CLJS.xi.h($CLJS.q($CLJS.q(a))),yv)){tv(a);$CLJS.n(c)&&$CLJS.ac(Pu.h($CLJS.q($CLJS.q(a))),c);var d=Fv.h($CLJS.q($CLJS.q(a)));$CLJS.n(d)&&(d.h?d.h(Kv):d.call(null,Kv))}else d=wv.h($CLJS.q($CLJS.q(a))),c=d+($CLJS.n(c)?$CLJS.D(c):0),$CLJS.Oh.v($CLJS.q(a),$CLJS.R,wv,c),sv(a,new Xu(Lv,b,d,c,null,null,null));$CLJS.Oh.v($CLJS.q(a),$CLJS.R,uv,Su.h(b))};
$CLJS.Nv=function(a){var b=null!=a?a.C&32768||$CLJS.Bc===a.ag?!0:a.C?!1:$CLJS.$a($CLJS.tu,a):$CLJS.$a($CLJS.tu,a);return b?Dv.h($CLJS.q($CLJS.q(a))):b};$CLJS.Rv=function(a){var b=Ov;$CLJS.n(b)&&(b=$CLJS.Ma,b=$CLJS.n(b)?Ov>=$CLJS.Ma:b);$CLJS.Va($CLJS.Pv)?Eu.call(null,a):$CLJS.n(b)?$CLJS.ac($CLJS.Fu,"..."):($CLJS.n(Ov)&&(Ov+=1),Qv.call(null,a));return b};
Sv=function(a,b){if($CLJS.Va(b.h?b.h(a):b.call(null,a)))throw Error(["Bad argument: ",$CLJS.p.h(a),". It must be one of ",$CLJS.p.h(b)].join(""));};Uv=function(){var a=$CLJS.qh;return $CLJS.n(a)?Tv>=$CLJS.qh:a};Zv=function(a){Sv(a,new $CLJS.ah(null,new $CLJS.h(null,4,[Vv,null,Wv,null,Xv,null,Yv,null],null),null));var b=$CLJS.Fu;$CLJS.Oh.v($CLJS.q(b),$CLJS.R,$CLJS.xi,vv);var c=wv.h($CLJS.q($CLJS.q(b))),d=uv.h($CLJS.q($CLJS.q(b)));sv(b,new Vu($u,a,d,c,c,null,null,null))};
cw=function(a,b){Sv(a,new $CLJS.ah(null,new $CLJS.h(null,2,[$v,null,$CLJS.aw,null],null),null));var c=$CLJS.Fu,d=uv.h($CLJS.q($CLJS.q(c)));if($CLJS.E.g($CLJS.xi.h($CLJS.q($CLJS.q(c))),yv)){tv(c);var e=nv.h(d);if($CLJS.n($CLJS.E.g?$CLJS.E.g($v,a):$CLJS.E.call(null,$v,a)))a=$CLJS.q(jv.h(d));else if($CLJS.n($CLJS.E.g?$CLJS.E.g($CLJS.aw,a):$CLJS.E.call(null,$CLJS.aw,a)))a=$CLJS.Mu(Pu.h($CLJS.q($CLJS.q(c))),$CLJS.Ou);else throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));$CLJS.Ye(e,b+a)}else e=
wv.h($CLJS.q($CLJS.q(c))),sv(c,new Yu(bw,d,a,b,e,e,null,null,null))};hw=function(a,b,c){b="string"===typeof b?dw(b):b;c=ew(c);a:{var d=new $CLJS.Ea,e=$CLJS.Va(a)||!0===a?new $CLJS.sc(d):a,f=fw(b)&&$CLJS.Va($CLJS.Nv(e))?$CLJS.n($CLJS.Nv(e))?e:$CLJS.Ev(e):e,k=$CLJS.Fu;$CLJS.Fu=f;try{try{gw(b,c)}finally{e!==f&&uu(f)}var l=$CLJS.Va(a)?$CLJS.p.h(d):!0===a?$CLJS.uh($CLJS.p.h(d)):null;break a}finally{$CLJS.Fu=k}l=void 0}return l};
jw=function(a,b){a=[$CLJS.p.h(a),"\n",$CLJS.p.h(iw),"\n",$CLJS.p.h($CLJS.Se.g($CLJS.p,$CLJS.df(b," "))),"^\n"].join("");throw Error(a);};kw=function(a,b,c,d,e,f){this.uc=a;this.pb=b;this.$b=c;this.S=d;this.G=e;this.J=f;this.C=2230716170;this.I=139264};ew=function(a){a=$CLJS.y(a);return new kw(a,a,0,null,null,null)};
mw=function(a){var b=$CLJS.Oi.h(a);if($CLJS.n(b))return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.A(b),new kw(lw.h(a),$CLJS.B(b),wv.h(a)+1,null,null,null)],null);throw Error("Not enough arguments for format definition");};nw=function(a){var b=mw(a);a=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);a="string"===typeof a?dw(a):a;return new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null)};pw=function(a,b){return b>=wv.h(a)?(b=wv.h(a)-b,ow.g?ow.g(a,b):ow.call(null,a,b)):new kw(lw.h(a),$CLJS.bf(b,lw.h(a)),b,null,null,null)};
ow=function(a,b){var c=wv.h(a)+b;return 0>b?pw(a,c):new kw(lw.h(a),$CLJS.bf(b,$CLJS.Oi.h(a)),c,null,null,null)};qw=function(a,b,c,d,e,f,k){this.dc=a;this.cc=b;this.kc=c;this.offset=d;this.S=e;this.G=f;this.J=k;this.C=2230716170;this.I=139264};
Gba=function(a,b){var c=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);var d=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);d=$CLJS.Id(new $CLJS.ah(null,new $CLJS.h(null,2,[rw,null,sw,null],null),null),c)?new $CLJS.P(null,2,5,$CLJS.Q,[d,b],null):$CLJS.E.g(d,tw)?mw(b):$CLJS.E.g(d,uw)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.D($CLJS.Oi.h(b)),b],null):new $CLJS.P(null,2,5,$CLJS.Q,[d,b],null);b=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[c,new $CLJS.P(null,2,5,$CLJS.Q,
[b,a],null)],null),d],null)};vw=function(a,b){b=Iu(Gba,b,a);a=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eg.g($CLJS.N,a),b],null)};
Ew=function(a,b,c){c=mw(c);var d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);if($CLJS.Fd(d))if($CLJS.E.g(ww,10))var e=[$CLJS.p.h(d),$CLJS.n(xw)?".":null].join("");else{e=$CLJS.p;var f=e.h;if($CLJS.n(xw)){var k=$CLJS.J.g(Hba,ww);k=$CLJS.n(k)?k:["#",$CLJS.p.h(ww),"r"].join("")}else k=null;e=[f.call(e,k),$CLJS.p.h(yw(ww,d))].join("")}else e=null;a=$CLJS.n(e)?e:a.h?a.h(d):a.call(null,d);d=a.length;e=d+zw.h(b);e=e>=Aw.h(b)?e:e+($CLJS.Vd(Aw.h(b)-e-1,Bw.h(b))+1)*Bw.h(b);d=$CLJS.Se.g($CLJS.p,$CLJS.df(e-d,Cw.h(b)));
$CLJS.n(sw.h(b))?Dw.l($CLJS.H([[$CLJS.p.h(d),$CLJS.p.h(a)].join("")])):Dw.l($CLJS.H([[$CLJS.p.h(a),$CLJS.p.h(d)].join("")]));return c};Fw=function(a,b){return $CLJS.ce($CLJS.A(Ju(function(c){return 0<c?new $CLJS.P(null,2,5,$CLJS.Q,[yu(c,a),$CLJS.Vd(c,a)],null):new $CLJS.P(null,2,5,$CLJS.Q,[null,null],null)},b)))};yw=function(a,b){return 0===b?"0":$CLJS.Se.g($CLJS.p,$CLJS.hf.g(function(c){return 10>c?xu(Hu("0")+c):xu(Hu("a")+(c-10))},Fw(a,b)))};
Iba=function(a,b){return $CLJS.ce($CLJS.A(Ju(function(c){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.y($CLJS.ce($CLJS.af(a,c))),$CLJS.y($CLJS.bf(a,c))],null)},$CLJS.ce(b))))};
Jw=function(a,b,c){c=mw(c);var d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);if($CLJS.Fd(d)||"number"===typeof d&&!isNaN(d)&&Infinity!==d&&parseFloat(d)!==parseInt(d,10)&&$CLJS.E.g(d,Math.floor(d))){var e=0>d,f=yw(a,e?-d:d);a=$CLJS.n(rw.h(b))?function(){var k=$CLJS.hf.g(function(m){return $CLJS.Se.g($CLJS.p,m)},Iba(Gw.h(b),f)),l=$CLJS.df($CLJS.D(k),Hw.h(b));return $CLJS.Se.g($CLJS.p,$CLJS.B($CLJS.ef.g(l,k)))}():f;a=e?["-",$CLJS.p.h(a)].join(""):$CLJS.n(sw.h(b))?["+",$CLJS.p.h(a)].join(""):a;a=a.length<
Aw.h(b)?[$CLJS.p.h($CLJS.Se.g($CLJS.p,$CLJS.df(Aw.h(b)-a.length,Cw.h(b)))),$CLJS.p.h(a)].join(""):a;Dw.l($CLJS.H([a]))}else Ew($CLJS.Iw,new $CLJS.h(null,5,[Aw,Aw.h(b),Bw,1,zw,0,Cw,Cw.h(b),sw,!0],null),ew(new $CLJS.P(null,1,5,$CLJS.Q,[d],null)));return c};
Mw=function(a){var b=$CLJS.Vd(a,100);a=yu(a,100);var c=0<b?[$CLJS.p.h($CLJS.F(Kw,b))," hundred"].join(""):null,d=$CLJS.p,e=d.h;if(0<a)if(20>a)var f=$CLJS.F(Kw,a);else{f=$CLJS.Vd(a,10);var k=yu(a,10);f=[$CLJS.p.h(0<f?$CLJS.F(Lw,f):null),0<f&&0<k?"-":null,$CLJS.p.h(0<k?$CLJS.F(Kw,k):null)].join("")}else f=null;return[c,0<b&&0<a?" ":null,e.call(d,f)].join("")};
Ow=function(a,b){var c=$CLJS.D(a),d=$CLJS.Cf;--c;var e=$CLJS.A(a);for(a=$CLJS.B(a);;){if(null==a)return[$CLJS.p.h($CLJS.Se.g($CLJS.p,$CLJS.ff(", ",d))),$CLJS.td(e)||$CLJS.td(d)?null:", ",$CLJS.p.h(e),!$CLJS.td(e)&&0<c+b?[" ",$CLJS.p.h($CLJS.F(Nw,c+b))].join(""):null].join("");d=$CLJS.td(e)?d:$CLJS.be.g(d,[$CLJS.p.h(e)," ",$CLJS.p.h($CLJS.F(Nw,c+b))].join(""));--c;e=$CLJS.A(a);a=$CLJS.B(a)}};
Pw=function(a,b){b=mw(b);var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);if("number"===typeof c&&0<c&&4E3>c){var d=Fw(10,c);c=$CLJS.Cf;for(var e=$CLJS.D(d)-1;;)if($CLJS.td(d)){Dw.l($CLJS.H([$CLJS.Se.g($CLJS.p,c)]));break}else{var f=$CLJS.A(d);c=$CLJS.E.g(0,f)?c:$CLJS.be.g(c,$CLJS.F($CLJS.F(a,e),f-1));--e;d=$CLJS.B(d)}}else Jw(10,new $CLJS.h(null,5,[Aw,0,Cw," ",Hw,",",Gw,3,rw,!0],null),ew(new $CLJS.P(null,1,5,$CLJS.Q,[c],null)));return b};
Kba=function(a,b){a=mw(b);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);var c=Hu(b);b=c&127;c&=128;var d=$CLJS.J.g(Jba,b);0<c&&Dw.l($CLJS.H(["Meta-"]));Dw.l($CLJS.H([$CLJS.n(d)?d:32>b?["Control-",$CLJS.p.h(xu(b+64))].join(""):$CLJS.E.g(b,127)?"Control-?":xu(b)]));return a};
Lba=function(a,b){var c=mw(b);b=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);a=Qw.h(a);if($CLJS.n($CLJS.E.g?$CLJS.E.g("o",a):$CLJS.E.call(null,"o",a)))hw(!0,"\\o~3,'0o",$CLJS.H([Hu(b)]));else if($CLJS.n($CLJS.E.g?$CLJS.E.g("u",a):$CLJS.E.call(null,"u",a)))hw(!0,"\\u~4,'0x",$CLJS.H([Hu(b)]));else if($CLJS.n($CLJS.E.g?$CLJS.E.g(null,a):$CLJS.E.call(null,null,a)))$CLJS.ac($CLJS.Fu,$CLJS.n($CLJS.E.g?$CLJS.E.g("\b",b):$CLJS.E.call(null,"\b",b))?"\\backspace":$CLJS.n($CLJS.E.g?$CLJS.E.g(" ",b):$CLJS.E.call(null,
" ",b))?"\\space":$CLJS.n($CLJS.E.g?$CLJS.E.g("\t",b):$CLJS.E.call(null,"\t",b))?"\\tab":$CLJS.n($CLJS.E.g?$CLJS.E.g("\n",b):$CLJS.E.call(null,"\n",b))?"\\newline":$CLJS.n($CLJS.E.g?$CLJS.E.g("\f",b):$CLJS.E.call(null,"\f",b))?"\\formfeed":$CLJS.n($CLJS.E.g?$CLJS.E.g("\r",b):$CLJS.E.call(null,"\r",b))?"\\return":$CLJS.n($CLJS.E.g?$CLJS.E.g('"',b):$CLJS.E.call(null,'"',b))?'\\"':$CLJS.n($CLJS.E.g?$CLJS.E.g("\\",b):$CLJS.E.call(null,"\\",b))?"\\\\":["\\",$CLJS.p.h(b)].join(""));else throw Error(["No matching clause: ",
$CLJS.p.h(a)].join(""));return c};Mba=function(a,b){b=mw(b);a=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);Dw.l($CLJS.H([a]));return b};Tw=function(a){a=$CLJS.A(a);return $CLJS.E.g(Rw,a)||$CLJS.E.g(Sw,a)};
Xw=function(a,b,c){return $CLJS.hd(Iu(function(d,e){if(Tw(e))return new $CLJS.P(null,2,5,$CLJS.Q,[null,e],null);e=vw(Uw.h(d),e);var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);var k=Ku(f);f=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);f=$CLJS.R.j(f,Vw,c);return new $CLJS.P(null,2,5,$CLJS.Q,[null,$CLJS.Se.g(Ww.h(d),new $CLJS.P(null,3,5,$CLJS.Q,[f,e,k],null))],null)},b,a))};
Yw=function(a){a=$CLJS.p.h(a).toLowerCase();var b=a.indexOf("e"),c=a.indexOf(".");a=0>b?0>c?new $CLJS.P(null,2,5,$CLJS.Q,[a,$CLJS.p.h(a.length-1)],null):new $CLJS.P(null,2,5,$CLJS.Q,[[a.substring(0,c),a.substring(c+1)].join(""),$CLJS.p.h(c-1)],null):0>c?new $CLJS.P(null,2,5,$CLJS.Q,[a.substring(0,b),a.substring(b+1)],null):new $CLJS.P(null,2,5,$CLJS.Q,[[a.substring(0,1),a.substring(2,b)].join(""),a.substring(b+1)],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);a:if(c=$CLJS.D(b),0<c&&$CLJS.E.g($CLJS.F(b,
$CLJS.D(b)-1),"0"))for(--c;;){if(0>c){b="";break a}if($CLJS.E.g($CLJS.F(b,c),"0"))--c;else{b=b.substring(0,c+1);break a}}a:{c=b;var d=$CLJS.D(c);if(0<d&&$CLJS.E.g($CLJS.F(c,0),"0"))for(var e=0;;){if($CLJS.E.g(e,d)||!$CLJS.E.g($CLJS.F(c,e),"0")){c=c.substring(e);break a}e+=1}}b=$CLJS.D(b)-$CLJS.D(c);a=0<$CLJS.D(a)&&$CLJS.E.g($CLJS.F(a,0),"+")?a.substring(1):a;return $CLJS.td(c)?new $CLJS.P(null,2,5,$CLJS.Q,["0",0],null):new $CLJS.P(null,2,5,$CLJS.Q,[c,parseInt(a,10)-b],null)};
Zw=function(a,b,c,d){if($CLJS.n($CLJS.n(c)?c:d)){var e=$CLJS.D(a);d=$CLJS.n(d)?2>d?2:d:0;$CLJS.n(c)?c=b+c+1:0<=b?(c=b+1,--d,c=c>d?c:d):c=d+b;var f=$CLJS.E.g(c,0)?new $CLJS.P(null,4,5,$CLJS.Q,[["0",$CLJS.p.h(a)].join(""),b+1,1,e+1],null):new $CLJS.P(null,4,5,$CLJS.Q,[a,b,c,e],null);c=$CLJS.I(f,0,null);e=$CLJS.I(f,1,null);d=$CLJS.I(f,2,null);f=$CLJS.I(f,3,null);if($CLJS.n(d)){if(0>d)return new $CLJS.P(null,3,5,$CLJS.Q,["0",0,!1],null);if(f>d){b=$CLJS.F(c,d);a=c.substring(0,d);if(Hu(b)>=Hu("5")){a:for(b=
$CLJS.D(a)-1,c=b|0;;){if(0>c){b=$CLJS.Se.j($CLJS.p,"1",$CLJS.df(b+1,"0"));break a}if($CLJS.E.g("9",a.charAt(c)))--c;else{b=$CLJS.Se.v($CLJS.p,a.substring(0,c),xu(Hu(a.charAt(c))+1),$CLJS.df(b-c,"0"));break a}}a=$CLJS.D(b)>a.length;c=$CLJS.Q;a&&(d=$CLJS.D(b)-1,b=b.substring(0,d));return new $CLJS.P(null,3,5,c,[b,e,a],null)}return new $CLJS.P(null,3,5,$CLJS.Q,[a,e,!1],null)}}}return new $CLJS.P(null,3,5,$CLJS.Q,[a,b,!1],null)};
$w=function(a,b,c){var d=0>b?new $CLJS.P(null,2,5,$CLJS.Q,[[$CLJS.p.h($CLJS.Se.g($CLJS.p,$CLJS.df(-b-1,"0"))),$CLJS.p.h(a)].join(""),-1],null):new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null);a=$CLJS.I(d,0,null);var e=$CLJS.I(d,1,null);d=$CLJS.D(a);c=$CLJS.n(c)?e+c+1:e+1;c=d<c?[$CLJS.p.h(a),$CLJS.p.h($CLJS.Se.g($CLJS.p,$CLJS.df(c-d,"0")))].join(""):a;0>b?b=[".",$CLJS.p.h(c)].join(""):(b+=1,b=[c.substring(0,b),".",c.substring(b)].join(""));return b};
Nba=function(a,b){return 0>b?[".",$CLJS.p.h(a)].join(""):[a.substring(0,b),".",a.substring(b)].join("")};
ex=function(a,b){var c=ax.h(a),d=bx.h(a);b=mw(b);var e=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);var f=0>e?new $CLJS.P(null,2,5,$CLJS.Q,["-",-e],null):new $CLJS.P(null,2,5,$CLJS.Q,["+",e],null),k=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);f=Yw(f);var l=$CLJS.I(f,0,null),m=$CLJS.I(f,1,null)+$CLJS.cx.h(a);f=function(){var x=sw.h(a);return $CLJS.n(x)?x:0>e}();var t=$CLJS.Va(d)&&$CLJS.D(l)-1<=m,u=Zw(l,m,d,$CLJS.n(c)?c-($CLJS.n(f)?1:0):null);l=$CLJS.I(u,0,null);m=$CLJS.I(u,1,null);u=$CLJS.I(u,2,null);l=$w(l,$CLJS.n(u)?
m+1:m,d);d=$CLJS.n($CLJS.n(c)?$CLJS.n(d)?1<=d&&$CLJS.E.g(l.charAt(0),"0")&&$CLJS.E.g(l.charAt(1),".")&&l.length>c-($CLJS.n(f)?1:0):d:c)?l.substring(1):l;m=$CLJS.E.g($CLJS.A(d),".");if($CLJS.n(c)){l=d.length;l=$CLJS.n(f)?l+1:l;m=m&&!(l>=c);t=t&&!(l>=c);var v=m||t?l+1:l;$CLJS.n(function(){var x=v>c;return x?dx.h(a):x}())?Dw.l($CLJS.H([$CLJS.Se.g($CLJS.p,$CLJS.df(c,dx.h(a)))])):Dw.l($CLJS.H([[$CLJS.p.h($CLJS.Se.g($CLJS.p,$CLJS.df(c-v,Cw.h(a)))),$CLJS.p.h($CLJS.n(f)?k:null),m?"0":null,d,t?"0":null].join("")]))}else Dw.l($CLJS.H([[$CLJS.p.h($CLJS.n(f)?
k:null),m?"0":null,d,t?"0":null].join("")]));return b};
hx=function(a,b){b=mw(b);var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);var d=Yw(0>c?-c:c);$CLJS.I(d,0,null);for($CLJS.I(d,1,null);;){var e=$CLJS.I(d,0,null),f=$CLJS.I(d,1,null),k=ax.h(a),l=bx.h(a),m=fx.h(a),t=$CLJS.cx.h(a),u=function(){var K=gx.h(a);return $CLJS.n(K)?K:"E"}();d=function(){var K=sw.h(a);return $CLJS.n(K)?K:0>c}();var v=0>=t,x=f-(t-1),z=$CLJS.p.h(Math.abs(x));u=[$CLJS.p.h(u),0>x?"-":"+",$CLJS.p.h($CLJS.n(m)?$CLJS.Se.g($CLJS.p,$CLJS.df(m-z.length,"0")):null),z].join("");var C=u.length;
x=$CLJS.D(e);e=[$CLJS.p.h($CLJS.Se.g($CLJS.p,$CLJS.df(-t,"0"))),$CLJS.p.h(e),$CLJS.p.h($CLJS.n(l)?$CLJS.Se.g($CLJS.p,$CLJS.df(l-(x-1)-(0>t?-t:0),"0")):null)].join("");x=$CLJS.n(k)?k-C:null;e=Zw(e,0,$CLJS.E.g(t,0)?l-1:0<t?l:0>t?l-1:null,$CLJS.n(x)?x-($CLJS.n(d)?1:0):null);x=$CLJS.I(e,0,null);$CLJS.I(e,1,null);z=$CLJS.I(e,2,null);e=Nba(x,t);l=$CLJS.E.g(t,$CLJS.D(x))&&null==l;if($CLJS.Va(z)){if($CLJS.n(k)){f=e.length+C;f=$CLJS.n(d)?f+1:f;var G=(v=v&&!$CLJS.E.g(f,k))?f+1:f;f=l&&G<k;$CLJS.n(function(){var K=
G>k;K||(K=m,K=$CLJS.n(K)?C-2>m:K);return $CLJS.n(K)?dx.h(a):K}())?Dw.l($CLJS.H([$CLJS.Se.g($CLJS.p,$CLJS.df(k,dx.h(a)))])):Dw.l($CLJS.H([[$CLJS.p.h($CLJS.Se.g($CLJS.p,$CLJS.df(k-G-(f?1:0),Cw.h(a)))),$CLJS.n(d)?0>c?"-":"+":null,v?"0":null,e,f?"0":null,u].join("")]))}else Dw.l($CLJS.H([[$CLJS.n(d)?0>c?"-":"+":null,v?"0":null,e,l?"0":null,u].join("")]));break}else d=new $CLJS.P(null,2,5,$CLJS.Q,[x,f+1],null)}return b};
Oba=function(a,b,c){var d=mw(b),e=$CLJS.I(d,0,null);$CLJS.I(d,1,null);d=Yw(0>e?-e:e);var f=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);var k=ax.h(a),l=bx.h(a),m=fx.h(a);d=$CLJS.E.g(e,0)?0:d+1;e=$CLJS.n(m)?m+2:4;k=$CLJS.n(k)?k-e:null;$CLJS.n(l)?f=l:(f=$CLJS.D(f),l=7>d?d:7,f=f>l?f:l);d=f-d;return 0<=d&&d<=f?(a=ex(new $CLJS.h(null,6,[ax,k,bx,d,$CLJS.cx,0,dx,dx.h(a),Cw,Cw.h(a),sw,sw.h(a)],null),b,c),Dw.l($CLJS.H([$CLJS.Se.g($CLJS.p,$CLJS.df(e," "))])),a):hx(a,b,c)};
Pba=function(a,b){b=mw(b);var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);var d=Yw(Math.abs(c)),e=$CLJS.I(d,0,null),f=$CLJS.I(d,1,null),k=bx.h(a),l=$CLJS.rk.h(a);d=ax.h(a);var m=function(){var u=sw.h(a);return $CLJS.n(u)?u:0>c}(),t=Zw(e,f,k,null);e=$CLJS.I(t,0,null);f=$CLJS.I(t,1,null);t=$CLJS.I(t,2,null);k=$w(e,$CLJS.n(t)?f+1:f,k);l=[$CLJS.p.h($CLJS.Se.g($CLJS.p,$CLJS.df(l-k.indexOf("."),"0"))),k].join("");k=l.length+($CLJS.n(m)?1:0);Dw.l($CLJS.H([[$CLJS.n(function(){var u=rw.h(a);return $CLJS.n(u)?
m:u}())?0>c?"-":"+":null,$CLJS.p.h($CLJS.Se.g($CLJS.p,$CLJS.df(d-k,Cw.h(a)))),$CLJS.n(function(){var u=$CLJS.Va(rw.h(a));return u?m:u}())?0>c?"-":"+":null,l].join("")]));return b};Qba=function(a,b){var c=ix.h(a);c=$CLJS.n(c)?new $CLJS.P(null,2,5,$CLJS.Q,[c,b],null):mw(b);b=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);var d=$CLJS.jx.h(a);b=0>b||b>=$CLJS.D(d)?$CLJS.A($CLJS.Nt.h(a)):$CLJS.F(d,b);return $CLJS.n(b)?Xw(b,c,Vw.h(a)):c};
Rba=function(a,b){var c=mw(b);b=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);var d=$CLJS.jx.h(a);b=$CLJS.n(b)?$CLJS.hd(d):$CLJS.A(d);return $CLJS.n(b)?Xw(b,c,Vw.h(a)):c};Sba=function(a,b){var c=mw(b),d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);var e=$CLJS.jx.h(a);e=$CLJS.n(d)?$CLJS.A(e):null;return $CLJS.n(d)?$CLJS.n(e)?Xw(e,b,Vw.h(a)):b:c};
Tba=function(a,b){var c=kx.h(a),d=$CLJS.A($CLJS.jx.h(a));d=$CLJS.td(d)?nw(b):new $CLJS.P(null,2,5,$CLJS.Q,[d,b],null);b=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);d=mw(d);var e=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);var f=0;e=ew(e);for(var k=-1;;){if($CLJS.Va(c)&&$CLJS.E.g(wv.h(e),k)&&1<f)throw Error("%{ construct not consuming any arguments: Infinite loop!");k=$CLJS.td($CLJS.Oi.h(e))&&($CLJS.Va(rw.h(lx.h(a)))||0<f);if($CLJS.n(k?k:$CLJS.n(c)?f>=c:c))return d;k=Xw(b,e,Vw.h(a));if($CLJS.E.g(Rw,$CLJS.A(k)))return d;
f+=1;var l=wv.h(e);e=k;k=l}};Uba=function(a,b){var c=kx.h(a),d=$CLJS.A($CLJS.jx.h(a));d=$CLJS.td(d)?nw(b):new $CLJS.P(null,2,5,$CLJS.Q,[d,b],null);b=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);d=mw(d);var e=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);for(var f=0;;){var k=$CLJS.td(e)&&($CLJS.Va(rw.h(lx.h(a)))||0<f);if($CLJS.n(k?k:$CLJS.n(c)?f>=c:c))return d;k=Xw(b,ew($CLJS.A(e)),ew($CLJS.B(e)));if($CLJS.E.g(Sw,$CLJS.A(k)))return d;f+=1;e=$CLJS.B(e)}};
Vba=function(a,b){var c=kx.h(a),d=$CLJS.A($CLJS.jx.h(a)),e=$CLJS.td(d)?nw(b):new $CLJS.P(null,2,5,$CLJS.Q,[d,b],null);b=$CLJS.I(e,0,null);d=0;e=$CLJS.I(e,1,null);for(var f=-1;;){if($CLJS.Va(c)&&$CLJS.E.g(wv.h(e),f)&&1<d)throw Error("%@{ construct not consuming any arguments: Infinite loop!");f=$CLJS.td($CLJS.Oi.h(e))&&($CLJS.Va(rw.h(lx.h(a)))||0<d);if($CLJS.n(f?f:$CLJS.n(c)?d>=c:c))return e;f=Xw(b,e,Vw.h(a));if($CLJS.E.g(Rw,$CLJS.A(f)))return $CLJS.hd(f);d+=1;var k=wv.h(e);e=f;f=k}};
Wba=function(a,b){var c=kx.h(a),d=$CLJS.A($CLJS.jx.h(a)),e=$CLJS.td(d)?nw(b):new $CLJS.P(null,2,5,$CLJS.Q,[d,b],null);b=$CLJS.I(e,0,null);d=0;for(e=$CLJS.I(e,1,null);;){var f=$CLJS.td($CLJS.Oi.h(e))&&($CLJS.Va(rw.h(lx.h(a)))||0<d);if($CLJS.n(f?f:$CLJS.n(c)?d>=c:c))return e;f=$CLJS.Oi.h(e);f=$CLJS.n(f)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.A(f),new kw(lw.h(e),$CLJS.B(f),wv.h(e)+1,null,null,null)],null):new $CLJS.P(null,2,5,$CLJS.Q,[null,e],null);e=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);e=Xw(b,ew(e),f);
if($CLJS.E.g(Sw,$CLJS.A(e)))return f;e=f;d+=1}};Yba=function(a,b){if($CLJS.n(rw.h(lx.h(a)))){var c=$CLJS.jx.h(a),d=$CLJS.D(c),e=1<d?$CLJS.Xj.h(Uw.h($CLJS.A($CLJS.A(c)))):$CLJS.n(rw.h(a))?"(":null,f=$CLJS.F(c,1<d?1:0);c=2<d?$CLJS.Xj.h(Uw.h($CLJS.A($CLJS.F(c,2)))):$CLJS.n(rw.h(a))?")":null;d=mw(b);b=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);if($CLJS.n(Uv()))$CLJS.ac($CLJS.Fu,"#");else{var k=Tv,l=Ov;Tv+=1;Ov=0;try{Iv(e,c),Xw(f,ew(b),Vw.h(a)),Mv()}finally{Ov=l,Tv=k}}a=d}else a=Xba(a,b);return a};
mx=function(a,b,c){for(var d=$CLJS.Cf;;){if($CLJS.td(a))return new $CLJS.P(null,2,5,$CLJS.Q,[d,b],null);var e=$CLJS.A(a);a:{var f=new $CLJS.Ea,k=$CLJS.Fu;$CLJS.Fu=new $CLJS.sc(f);try{var l=new $CLJS.P(null,2,5,$CLJS.Q,[Xw(e,b,c),$CLJS.p.h(f)],null);break a}finally{$CLJS.Fu=k}l=void 0}b=$CLJS.I(l,0,null);e=$CLJS.I(l,1,null);if($CLJS.E.g(Rw,$CLJS.A(b)))return new $CLJS.P(null,2,5,$CLJS.Q,[d,$CLJS.hd(b)],null);a=$CLJS.B(a);d=$CLJS.be.g(d,e)}};
Xba=function(a,b){var c=function(){var V=$CLJS.Nt.h(a);return $CLJS.n(V)?mx(V,b,Vw.h(a)):null}(),d=$CLJS.I(c,0,null);d=$CLJS.I(d,0,null);c=$CLJS.I(c,1,null);var e=$CLJS.n(c)?c:b;c=function(){var V=nx.h(a);return $CLJS.n(V)?vw(V,e):null}();var f=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);var k=$CLJS.n(c)?c:e;c=function(){var V=$CLJS.A(ox.h(f));return $CLJS.n(V)?V:0}();var l=function(){var V=$CLJS.A(px.h(f));return $CLJS.n(V)?V:$CLJS.Mu($CLJS.Fu,$CLJS.hk)}(),m=$CLJS.jx.h(a);k=mx(m,k,Vw.h(a));var t=$CLJS.I(k,
0,null);k=$CLJS.I(k,1,null);var u=function(){var V=$CLJS.D(t)-1+($CLJS.n(rw.h(a))?1:0)+($CLJS.n(sw.h(a))?1:0);return 1>V?1:V}();m=$CLJS.Qd($CLJS.Kk,$CLJS.hf.g($CLJS.D,t));var v=Aw.h(a),x=zw.h(a),z=Bw.h(a),C=m+u*x;v=C<=v?v:v+z*(1+$CLJS.Vd(C-v-1,z));var G=v-m;m=function(){var V=$CLJS.Vd(G,u);return x>V?x:V}();z=G-m*u;m=$CLJS.Se.g($CLJS.p,$CLJS.df(m,Cw.h(a)));$CLJS.n($CLJS.n(d)?$CLJS.Mu(Pu.h($CLJS.q($CLJS.q($CLJS.Fu))),$CLJS.Ou)+c+v>l:d)&&Dw.l($CLJS.H([d]));c=z;for(var K=t,S=function(){var V=rw.h(a);
return $CLJS.n(V)?V:$CLJS.E.g($CLJS.D(K),1)&&$CLJS.Va(sw.h(a))}();;)if($CLJS.y(K))Dw.l($CLJS.H([[$CLJS.p.h($CLJS.Va(S)?$CLJS.A(K):null),$CLJS.p.h($CLJS.n(function(){var V=S;return $CLJS.n(V)?V:(V=$CLJS.B(K))?V:sw.h(a)}())?m:null),$CLJS.p.h(0<c?Cw.h(a):null)].join("")])),--c,K=d=$CLJS.n(S)?K:$CLJS.B(K),S=!1;else break;return k};qx=function(a,b){this.Ca=a;this.di=b;this.C=1074135040;this.I=0};Zba=function(a){return new qx(a,$CLJS.N)};rx=function(a,b){this.Ca=a;this.ei=b;this.C=1074135040;this.I=0};
$ba=function(a){return new rx(a,$CLJS.N)};
aca=function(a,b){var c=$CLJS.A(a);return $CLJS.Se.g($CLJS.p,$CLJS.A(Ju(function(d){if($CLJS.td(d))return new $CLJS.P(null,2,5,$CLJS.Q,[null,null],null);var e=RegExp("\\W\\w","g").exec(d);e=$CLJS.n(e)?e.index+1:e;return $CLJS.n(e)?new $CLJS.P(null,2,5,$CLJS.Q,[[d.substring(0,e),$CLJS.F(d,e).toUpperCase()].join(""),d.substring(e+1)],null):new $CLJS.P(null,2,5,$CLJS.Q,[d,null],null)},$CLJS.n($CLJS.n(b)?$CLJS.n(c)?su(c):c:b)?[c.toUpperCase(),a.substring(1)].join(""):a)))};
sx=function(a,b,c){this.Ca=a;this.Jd=b;this.fi=c;this.C=1074135040;this.I=0};bca=function(a){return new sx(a,$CLJS.Xe(!0),$CLJS.N)};tx=function(a,b,c){this.Ca=a;this.vd=b;this.gi=c;this.C=1074135040;this.I=0};cca=function(a){return new tx(a,$CLJS.Xe(!1),$CLJS.N)};dca=function(a,b){var c=$CLJS.n(rw.h(a))?$CLJS.aw:$v;cw(c,$CLJS.rk.h(a));return b};eca=function(a,b){a=$CLJS.n(rw.h(a))?$CLJS.n(sw.h(a))?Vv:Xv:$CLJS.n(sw.h(a))?Wv:Yv;Zv(a);return b};
gca=function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null),d=$CLJS.I(a,2,null);a=new RegExp(fca.source,"g");var e=a.exec(b);return $CLJS.n(e)?(d=$CLJS.A(e),b=b.substring(a.lastIndex),a=c+a.lastIndex,$CLJS.E.g(",",$CLJS.F(b,0))?new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[d,c],null),new $CLJS.P(null,3,5,$CLJS.Q,[b.substring(1),a+1,!0],null)],null):new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[d,c],null),new $CLJS.P(null,3,5,$CLJS.Q,[b,a,!1],null)],null)):$CLJS.n(d)?jw("Badly formed parameters in format directive",
c):new $CLJS.P(null,2,5,$CLJS.Q,[null,new $CLJS.P(null,2,5,$CLJS.Q,[b,c],null)],null)};hca=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.E.g(b.length,0)?null:$CLJS.E.g(b.length,1)&&$CLJS.Id(new $CLJS.ah(null,new $CLJS.h(null,2,["V",null,"v",null],null),null),$CLJS.F(b,0))?tw:$CLJS.E.g(b.length,1)&&$CLJS.E.g("#",$CLJS.F(b,0))?uw:$CLJS.E.g(b.length,2)&&$CLJS.E.g("'",$CLJS.F(b,0))?$CLJS.F(b,1):parseInt(b,10),a],null)};
jca=function(a,b){return Ju(function(c){var d=$CLJS.I(c,0,null),e=$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);if($CLJS.td(d))return new $CLJS.P(null,2,5,$CLJS.Q,[null,new $CLJS.P(null,3,5,$CLJS.Q,[d,e,c],null)],null);var f=$CLJS.J.g(ica,$CLJS.A(d));return $CLJS.n(f)?$CLJS.Id(c,f)?jw(['Flag "',$CLJS.p.h($CLJS.A(d)),'" appears more than once in a directive'].join(""),e):new $CLJS.P(null,2,5,$CLJS.Q,[!0,new $CLJS.P(null,3,5,$CLJS.Q,[d.substring(1),e+1,$CLJS.R.j(c,f,new $CLJS.P(null,2,5,$CLJS.Q,[!0,e],null))],
null)],null):new $CLJS.P(null,2,5,$CLJS.Q,[null,new $CLJS.P(null,3,5,$CLJS.Q,[d,e,c],null)],null)},new $CLJS.P(null,3,5,$CLJS.Q,[a,b,$CLJS.N],null))};
kca=function(a,b){var c=ux.h(a);$CLJS.n(function(){var d=$CLJS.Va(sw.h(c));return d?sw.h(b):d}())&&jw(['"@" is an illegal flag for format directive "',$CLJS.p.h(vx.h(a)),'"'].join(""),$CLJS.F(sw.h(b),1));$CLJS.n(function(){var d=$CLJS.Va(rw.h(c));return d?rw.h(b):d}())&&jw(['":" is an illegal flag for format directive "',$CLJS.p.h(vx.h(a)),'"'].join(""),$CLJS.F(rw.h(b),1));$CLJS.n(function(){var d=$CLJS.Va(wx.h(c));return d?(d=sw.h(b),$CLJS.n(d)?rw.h(b):d):d}())&&jw(['Cannot combine "@" and ":" flags for format directive "',
$CLJS.p.h(vx.h(a)),'"'].join(""),function(){var d=$CLJS.F(rw.h(b),1),e=$CLJS.F(sw.h(b),1);return d<e?d:e}())};
mca=function(a,b,c,d){kca(a,c);$CLJS.D(b)>$CLJS.D(Uw.h(a))&&jw(hw(null,'Too many parameters for directive "~C": ~D~:* ~[were~;was~:;were~] specified but only ~D~:* ~[are~;is~:;are~] allowed',$CLJS.H([vx.h(a),$CLJS.D(b),$CLJS.D(Uw.h(a))])),$CLJS.hd($CLJS.A(b)));$CLJS.mh($CLJS.hf.j(function(e,f){var k=$CLJS.A(e);return null==k||$CLJS.Id(lca,k)||$CLJS.E.g($CLJS.hd($CLJS.hd(f)),$CLJS.ab(k))?null:jw(["Parameter ",$CLJS.gh($CLJS.A(f)),' has bad type in directive "',$CLJS.p.h(vx.h(a)),'": ',$CLJS.p.h($CLJS.ab(k))].join(""),
$CLJS.hd(e))},b,Uw.h(a)));return $CLJS.hl.l($CLJS.H([$CLJS.eg.g($CLJS.N,$CLJS.ce(function(){return function k(f){return new $CLJS.ne(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.Ad(l)){var m=$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.qe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v),z=$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.I(x,0,null);u.add(new $CLJS.P(null,2,5,$CLJS.Q,[z,new $CLJS.P(null,2,5,$CLJS.Q,[x,d],null)],null));v+=1}else{m=!0;break a}return m?$CLJS.te($CLJS.ve(u),k($CLJS.mc(l))):
$CLJS.te($CLJS.ve(u),null)}m=$CLJS.A(l);u=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);m=$CLJS.I(m,0,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[u,new $CLJS.P(null,2,5,$CLJS.Q,[m,d],null)],null),k($CLJS.Lc(l)))}return null}},null,null)}(Uw.h(a))}())),$CLJS.eb(function(e,f){return $CLJS.Se.j($CLJS.R,e,f)},$CLJS.N,$CLJS.lf(function(e){return $CLJS.A($CLJS.F(e,1))},$CLJS.Au($CLJS.Xg(Uw.h(a)),b))),c]))};
oca=function(a,b){b=Ju(gca,new $CLJS.P(null,3,5,$CLJS.Q,[a,b,!1],null));a=$CLJS.I(b,0,null);var c=$CLJS.I(b,1,null);b=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);b=jca(b,c);$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);var d=$CLJS.I(b,0,null),e=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);c=$CLJS.A(d);var f=$CLJS.J.g(nca,c.toUpperCase()),k=$CLJS.n(f)?mca(f,$CLJS.hf.g(hca,a),b,e):null;$CLJS.Va(c)&&jw("Format string ended in the middle of a directive",e);$CLJS.Va(f)&&jw(['Directive "',$CLJS.p.h(c),'" is undefined'].join(""),
e);return new $CLJS.P(null,2,5,$CLJS.Q,[new qw(function(){var l=xx.h(f);return l.g?l.g(k,e):l.call(null,k,e)}(),f,k,e,null,null,null),function(){var l=d.substring(1),m=e+1;if($CLJS.E.g("\n",vx.h(f))&&$CLJS.Va(rw.h(k)))a:{var t=new $CLJS.P(null,2,5,$CLJS.Q,[" ","\t"],null);t=$CLJS.ud(t)?$CLJS.fh(t):$CLJS.ch([t]);for(var u=0;;){var v;(v=$CLJS.E.g(u,$CLJS.D(l)))||(v=$CLJS.F(l,u),v=t.h?t.h(v):t.call(null,v),v=$CLJS.Va(v));if(v){t=u;break a}u+=1}}else t=0;return new $CLJS.P(null,2,5,$CLJS.Q,[l.substring(t),
m+t],null)}()],null)};yx=function(a,b){return new qw(function(c,d){Dw.l($CLJS.H([a]));return d},null,new $CLJS.h(null,1,[$CLJS.Xj,a],null),b,null,null,null)};Bx=function(a,b){var c=pca(zx.h(Ax.h(a)),$CLJS.Ot.h(a),b);b=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[new qw(Ww.h(a),Ax.h(a),$CLJS.hl.l($CLJS.H([Uw.h(a),yba(b,$CLJS.Ot.h(a))])),$CLJS.Ot.h(a),null,null,null),c],null)};
qca=function(a,b,c){return Ju(function(d){if($CLJS.td(d))return jw("No closing bracket found.",b);var e=$CLJS.A(d);d=$CLJS.B(d);if($CLJS.n($CLJS.Cx.h(zx.h(Ax.h(e)))))e=Bx(e,d);else if($CLJS.E.g($CLJS.Cx.h(a),vx.h(Ax.h(e))))e=new $CLJS.P(null,2,5,$CLJS.Q,[null,new $CLJS.P(null,4,5,$CLJS.Q,[Dx,Uw.h(e),null,d],null)],null);else{var f=$CLJS.Pt.h(zx.h(Ax.h(e)));f=$CLJS.n(f)?rw.h(Uw.h(e)):f;e=$CLJS.n(f)?new $CLJS.P(null,2,5,$CLJS.Q,[null,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Nt,null,Uw.h(e),d],null)],null):
$CLJS.n($CLJS.Pt.h(zx.h(Ax.h(e))))?new $CLJS.P(null,2,5,$CLJS.Q,[null,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Pt,null,null,d],null)],null):new $CLJS.P(null,2,5,$CLJS.Q,[e,d],null)}return e},c)};
pca=function(a,b,c){return $CLJS.hd(Ju(function(d){var e=$CLJS.I(d,0,null),f=$CLJS.I(d,1,null);d=$CLJS.I(d,2,null);var k=qca(a,b,d);d=$CLJS.I(k,0,null);var l=$CLJS.I(k,1,null);k=$CLJS.I(l,0,null);var m=$CLJS.I(l,1,null),t=$CLJS.I(l,2,null);l=$CLJS.I(l,3,null);return $CLJS.E.g(k,Dx)?new $CLJS.P(null,2,5,$CLJS.Q,[null,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ex.l($CLJS.gf,$CLJS.H([e,$CLJS.Ee([$CLJS.n(f)?$CLJS.Nt:$CLJS.jx,new $CLJS.P(null,1,5,$CLJS.Q,[d],null),lx,m])])),l],null)],null):$CLJS.E.g(k,$CLJS.Nt)?
$CLJS.n($CLJS.Nt.h(e))?jw('Two else clauses ("~:;") inside bracket construction.',b):$CLJS.Va($CLJS.Nt.h(a))?jw('An else clause ("~:;") is in a bracket type that doesn\'t support it.',b):$CLJS.E.g(Fx,$CLJS.Nt.h(a))&&$CLJS.y($CLJS.jx.h(e))?jw('The else clause ("~:;") is only allowed in the first position for this directive.',b):$CLJS.E.g(Fx,$CLJS.Nt.h(a))?new $CLJS.P(null,2,5,$CLJS.Q,[!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ex.l($CLJS.gf,$CLJS.H([e,new $CLJS.h(null,2,[$CLJS.Nt,new $CLJS.P(null,1,5,
$CLJS.Q,[d],null),nx,t],null)])),!1,l],null)],null):new $CLJS.P(null,2,5,$CLJS.Q,[!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ex.l($CLJS.gf,$CLJS.H([e,new $CLJS.h(null,1,[$CLJS.jx,new $CLJS.P(null,1,5,$CLJS.Q,[d],null)],null)])),!0,l],null)],null):$CLJS.E.g(k,$CLJS.Pt)?$CLJS.n(f)?jw('A plain clause (with "~;") follows an else clause ("~:;") inside bracket construction.',b):$CLJS.Va(Gx.h(a))?jw('A separator ("~;") is in a bracket type that doesn\'t support it.',b):new $CLJS.P(null,2,5,$CLJS.Q,[!0,new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.Ex.l($CLJS.gf,$CLJS.H([e,new $CLJS.h(null,1,[$CLJS.jx,new $CLJS.P(null,1,5,$CLJS.Q,[d],null)],null)])),!1,l],null)],null):null},new $CLJS.P(null,3,5,$CLJS.Q,[new $CLJS.h(null,1,[$CLJS.jx,$CLJS.Cf],null),!1,c],null)))};rca=function(a){return $CLJS.A(Ju(function(b){var c=$CLJS.A(b);b=$CLJS.B(b);var d=zx.h(Ax.h(c));return $CLJS.n($CLJS.Cx.h(d))?Bx(c,b):new $CLJS.P(null,2,5,$CLJS.Q,[c,b],null)},a))};
dw=function(a){var b=iw;iw=a;try{return rca($CLJS.A(Ju(function(c){var d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);if($CLJS.td(d))return new $CLJS.P(null,2,5,$CLJS.Q,[null,d],null);var e=d.indexOf("~");return 0>e?new $CLJS.P(null,2,5,$CLJS.Q,[yx(d,c),new $CLJS.P(null,2,5,$CLJS.Q,["",c+d.length],null)],null):0===e?oca(d.substring(1),c+1):new $CLJS.P(null,2,5,$CLJS.Q,[yx(d.substring(0,e),c),new $CLJS.P(null,2,5,$CLJS.Q,[d.substring(e),e+c],null)],null)},new $CLJS.P(null,2,5,$CLJS.Q,[a,0],null))))}finally{iw=
b}};gw=function(a,b){Iu(function(c,d){if(Tw(d))return new $CLJS.P(null,2,5,$CLJS.Q,[null,d],null);d=vw(Uw.h(c),d);var e=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);var f=Ku(e);e=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);e=$CLJS.R.j(e,Vw,d);return new $CLJS.P(null,2,5,$CLJS.Q,[null,$CLJS.Se.g(Ww.h(c),new $CLJS.P(null,3,5,$CLJS.Q,[e,d,f],null))],null)},b,a);return null};
Ix=function(a){var b=$CLJS.A(a);b=Hx.h?Hx.h(b):Hx.call(null,b);return $CLJS.n($CLJS.n(b)?$CLJS.E.g(2,$CLJS.D(a)):b)?($CLJS.ac($CLJS.Fu,b),$CLJS.Rv($CLJS.hd(a)),!0):null};
Jx=function(a){if($CLJS.n(Uv()))$CLJS.ac($CLJS.Fu,"#");else{var b=Tv,c=Ov;Tv+=1;Ov=0;try{Iv("[","]");for(var d=0,e=$CLJS.y(a);;){if($CLJS.Va($CLJS.Ma)||d<$CLJS.Ma){if(e&&($CLJS.Rv($CLJS.A(e)),$CLJS.B(e))){$CLJS.ac($CLJS.Fu," ");Zv(Yv);a=d+1;var f=$CLJS.B(e);d=a;e=f;continue}}else $CLJS.ac($CLJS.Fu,"...");break}Mv()}finally{Ov=c,Tv=b}}return null};
Kx=function(a){var b=$CLJS.yd(a)?null:function(){var m=new $CLJS.Gc(function(){return $CLJS.Hh},sca,$CLJS.Ig([$CLJS.xk,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,$CLJS.Si,$CLJS.mj,$CLJS.zk,$CLJS.ti,$CLJS.ik,$CLJS.ok],[!0,$CLJS.li,tca,"cljs/core.cljs",15,1,10543,10543,$CLJS.U(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.dj],null)),"Returns [lifted-ns lifted-map] or nil if m can't be lifted.",$CLJS.n($CLJS.Hh)?$CLJS.Hh.H:null]));return m.h?m.h(a):m.call(null,a)}(),c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);var d=$CLJS.n(b)?
b:a,e=$CLJS.n(c)?["#:",$CLJS.p.h(c),"{"].join(""):"{";if($CLJS.n(Uv()))$CLJS.ac($CLJS.Fu,"#");else{c=Tv;b=Ov;Tv+=1;Ov=0;try{Iv(e,"}");e=0;for(var f=$CLJS.y(d);;){if($CLJS.Va($CLJS.Ma)||e<$CLJS.Ma){if(f){if($CLJS.n(Uv()))$CLJS.ac($CLJS.Fu,"#");else{d=Tv;var k=Ov;Tv+=1;Ov=0;try{Iv(null,null),$CLJS.Rv($CLJS.A($CLJS.A(f))),$CLJS.ac($CLJS.Fu," "),Zv(Yv),Ov=0,$CLJS.Rv($CLJS.A($CLJS.B($CLJS.A(f)))),Mv()}finally{Ov=k,Tv=d}}if($CLJS.B(f)){$CLJS.ac($CLJS.Fu,", ");Zv(Yv);d=e+1;var l=$CLJS.B(f);e=d;f=l;continue}}}else $CLJS.ac($CLJS.Fu,
"...");break}Mv()}finally{Ov=b,Tv=c}}return null};Lx=function(a){return $CLJS.ac($CLJS.Fu,$CLJS.Mh.l($CLJS.H([a])))};Ox=function(a){return a instanceof $CLJS.Rf?Mx:(null!=a?a.C&32768||$CLJS.Bc===a.ag||(a.C?0:$CLJS.$a($CLJS.tu,a)):$CLJS.$a($CLJS.tu,a))?Nx:a instanceof $CLJS.r?$CLJS.Gi:$CLJS.Dd(a)?$CLJS.Bj:$CLJS.xd(a)?$CLJS.gk:$CLJS.zd(a)?$CLJS.Ij:$CLJS.vd(a)?$CLJS.Zi:null==a?null:$CLJS.ci};
Px=function(a){return $CLJS.zd(a)?new $CLJS.P(null,2,5,$CLJS.Q,["[","]"],null):new $CLJS.P(null,2,5,$CLJS.Q,["(",")"],null)};
uca=function(a){if($CLJS.wd(a)){var b=Px(a),c=$CLJS.I(b,0,null),d=$CLJS.I(b,1,null),e=$CLJS.y(a),f=$CLJS.A(e),k=$CLJS.B(e);if($CLJS.n(Uv()))$CLJS.ac($CLJS.Fu,"#");else{var l=Tv,m=Ov,t=Tv+1;Tv=t;Ov=0;try{Iv(c,d);(function(){var Na=Qx("~w~:i");return function(){function zb(Za){var fb=null;if(0<arguments.length){fb=0;for(var Sa=Array(arguments.length-0);fb<Sa.length;)Sa[fb]=arguments[fb+0],++fb;fb=new $CLJS.w(Sa,0,null)}return Pa.call(this,fb)}function Pa(Za){Za=ew(Za);return gw(Na,Za)}zb.A=0;zb.B=function(Za){Za=
$CLJS.y(Za);return Pa(Za)};zb.l=Pa;return zb}()})()(f);for(var u=k;;)if($CLJS.y(u)){(function(){var Na=Qx(" ");return function(zb,Pa,Za){return function(){function fb(mb){var Ja=null;if(0<arguments.length){Ja=0;for(var bc=Array(arguments.length-0);Ja<bc.length;)bc[Ja]=arguments[Ja+0],++Ja;Ja=new $CLJS.w(bc,0,null)}return Sa.call(this,Ja)}function Sa(mb){mb=ew(mb);return gw(Za,mb)}fb.A=0;fb.B=function(mb){mb=$CLJS.y(mb);return Sa(mb)};fb.l=Sa;return fb}()}(u," ",Na,l,m,t,0,b,c,d,a,e,f,k,f,k)})()();
var v=$CLJS.A(u);if($CLJS.wd(v)){var x=Px(v),z=$CLJS.I(x,0,null),C=$CLJS.I(x,1,null);if($CLJS.n(Uv()))$CLJS.ac($CLJS.Fu,"#");else{var G=Tv,K=Ov,S=Tv+1;Tv=S;Ov=0;try{Iv(z,C);if($CLJS.E.g($CLJS.D(v),3)&&$CLJS.hd(v)instanceof $CLJS.M){var V=v,Z=$CLJS.I(V,0,null),ha=$CLJS.I(V,1,null),ra=$CLJS.I(V,2,null);(function(){var Na=Qx("~w ~w ");return function(zb,Pa,Za){return function(){function fb(mb){var Ja=null;if(0<arguments.length){Ja=0;for(var bc=Array(arguments.length-0);Ja<bc.length;)bc[Ja]=arguments[Ja+
0],++Ja;Ja=new $CLJS.w(bc,0,null)}return Sa.call(this,Ja)}function Sa(mb){mb=ew(mb);return gw(Za,mb)}fb.A=0;fb.B=function(mb){mb=$CLJS.y(mb);return Sa(mb)};fb.l=Sa;return fb}()}(u,"~w ~w ",Na,V,Z,ha,ra,G,K,S,0,x,z,C,v,l,m,t,0,b,c,d,a,e,f,k,f,k)})()(Z,ha);$CLJS.wd(ra)?function(){var Na=$CLJS.zd(ra)?"~\x3c[~;~@{~w~^ ~:_~}~;]~:\x3e":"~\x3c(~;~@{~w~^ ~:_~}~;)~:\x3e",zb="string"===typeof Na?Qx(Na):Na;return function(Pa,Za,fb){return function(){function Sa(Ja){var bc=null;if(0<arguments.length){bc=0;for(var $d=
Array(arguments.length-0);bc<$d.length;)$d[bc]=arguments[bc+0],++bc;bc=new $CLJS.w($d,0,null)}return mb.call(this,bc)}function mb(Ja){Ja=ew(Ja);return gw(fb,Ja)}Sa.A=0;Sa.B=function(Ja){Ja=$CLJS.y(Ja);return mb(Ja)};Sa.l=mb;return Sa}()}(u,Na,zb,V,Z,ha,ra,G,K,S,0,x,z,C,v,l,m,t,0,b,c,d,a,e,f,k,f,k)}()(ra):$CLJS.Rv(ra)}else $CLJS.Se.g(function(){var Na=Qx("~w ~:i~@{~w~^ ~:_~}");return function(zb,Pa,Za){return function(){function fb(mb){var Ja=null;if(0<arguments.length){Ja=0;for(var bc=Array(arguments.length-
0);Ja<bc.length;)bc[Ja]=arguments[Ja+0],++Ja;Ja=new $CLJS.w(bc,0,null)}return Sa.call(this,Ja)}function Sa(mb){mb=ew(mb);return gw(Za,mb)}fb.A=0;fb.B=function(mb){mb=$CLJS.y(mb);return Sa(mb)};fb.l=Sa;return fb}()}(u,"~w ~:i~@{~w~^ ~:_~}",Na,G,K,S,0,x,z,C,v,l,m,t,0,b,c,d,a,e,f,k,f,k)}(),v);Mv()}finally{Ov=K,Tv=G}}$CLJS.B(u)&&function(){var Na=Qx("~_");return function(zb,Pa,Za){return function(){function fb(mb){var Ja=null;if(0<arguments.length){Ja=0;for(var bc=Array(arguments.length-0);Ja<bc.length;)bc[Ja]=
arguments[Ja+0],++Ja;Ja=new $CLJS.w(bc,0,null)}return Sa.call(this,Ja)}function Sa(mb){mb=ew(mb);return gw(Za,mb)}fb.A=0;fb.B=function(mb){mb=$CLJS.y(mb);return Sa(mb)};fb.l=Sa;return fb}()}(u,"~_",Na,x,z,C,v,l,m,t,0,b,c,d,a,e,f,k,f,k)}()()}else $CLJS.Rv(v),$CLJS.B(u)&&function(){var Na=Qx("~:_");return function(zb,Pa,Za){return function(){function fb(mb){var Ja=null;if(0<arguments.length){Ja=0;for(var bc=Array(arguments.length-0);Ja<bc.length;)bc[Ja]=arguments[Ja+0],++Ja;Ja=new $CLJS.w(bc,0,null)}return Sa.call(this,
Ja)}function Sa(mb){mb=ew(mb);return gw(Za,mb)}fb.A=0;fb.B=function(mb){mb=$CLJS.y(mb);return Sa(mb)};fb.l=Sa;return fb}()}(u,"~:_",Na,v,l,m,t,0,b,c,d,a,e,f,k,f,k)}()();u=$CLJS.B(u)}else break;Mv()}finally{Ov=m,Tv=l}}}else $CLJS.Rv(a)};
vca=function(a,b){$CLJS.y(a)&&($CLJS.n(b)?function(){var c=Qx(" ~_");return function(){function d(f){var k=null;if(0<arguments.length){k=0;for(var l=Array(arguments.length-0);k<l.length;)l[k]=arguments[k+0],++k;k=new $CLJS.w(l,0,null)}return e.call(this,k)}function e(f){f=ew(f);return gw(c,f)}d.A=0;d.B=function(f){f=$CLJS.y(f);return e(f)};d.l=e;return d}()}()():function(){var c=Qx(" ~@_");return function(){function d(f){var k=null;if(0<arguments.length){k=0;for(var l=Array(arguments.length-0);k<
l.length;)l[k]=arguments[k+0],++k;k=new $CLJS.w(l,0,null)}return e.call(this,k)}function e(f){f=ew(f);return gw(c,f)}d.A=0;d.B=function(f){f=$CLJS.y(f);return e(f)};d.l=e;return d}()}()(),function(){var c=Qx("~{~w~^ ~_~}");return function(){function d(f){var k=null;if(0<arguments.length){k=0;for(var l=Array(arguments.length-0);k<l.length;)l[k]=arguments[k+0],++k;k=new $CLJS.w(l,0,null)}return e.call(this,k)}function e(f){f=ew(f);return gw(c,f)}d.A=0;d.B=function(f){f=$CLJS.y(f);return e(f)};d.l=e;
return d}()}()(a))};wca=function(a){$CLJS.y(a)&&function(){var b=Qx(" ~_~{~w~^ ~_~}");return function(){function c(e){var f=null;if(0<arguments.length){f=0;for(var k=Array(arguments.length-0);f<k.length;)k[f]=arguments[f+0],++f;f=new $CLJS.w(k,0,null)}return d.call(this,f)}function d(e){e=ew(e);return gw(b,e)}c.A=0;c.B=function(e){e=$CLJS.y(e);return d(e)};c.l=d;return c}()}()(a)};
Sx=function(a){if($CLJS.B(a)){var b=$CLJS.y(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);var d="string"===typeof $CLJS.A(c)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.A(c),$CLJS.B(c)],null):new $CLJS.P(null,2,5,$CLJS.Q,[null,c],null);c=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);var e=$CLJS.xd($CLJS.A(d))?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.A(d),$CLJS.B(d)],null):new $CLJS.P(null,2,5,$CLJS.Q,[null,d],null);d=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);if($CLJS.n(Uv()))$CLJS.ac($CLJS.Fu,"#");else{var f=
Tv,k=Ov;Tv+=1;Ov=0;try{Iv("(",")"),function(){var l=Qx("~w ~1I~@_~w");return function(){function m(u){var v=null;if(0<arguments.length){v=0;for(var x=Array(arguments.length-0);v<x.length;)x[v]=arguments[v+0],++v;v=new $CLJS.w(x,0,null)}return t.call(this,v)}function t(u){u=ew(u);return gw(l,u)}m.A=0;m.B=function(u){u=$CLJS.y(u);return t(u)};m.l=t;return m}()}()(a,b),$CLJS.n(c)&&function(){var l=Qx(" ~_~w");return function(){function m(u){var v=null;if(0<arguments.length){v=0;for(var x=Array(arguments.length-
0);v<x.length;)x[v]=arguments[v+0],++v;v=new $CLJS.w(x,0,null)}return t.call(this,v)}function t(u){u=ew(u);return gw(l,u)}m.A=0;m.B=function(u){u=$CLJS.y(u);return t(u)};m.l=t;return m}()}()(c),$CLJS.n(d)&&function(){var l=Qx(" ~_~w");return function(){function m(u){var v=null;if(0<arguments.length){v=0;for(var x=Array(arguments.length-0);v<x.length;)x[v]=arguments[v+0],++v;v=new $CLJS.w(x,0,null)}return t.call(this,v)}function t(u){u=ew(u);return gw(l,u)}m.A=0;m.B=function(u){u=$CLJS.y(u);return t(u)};
m.l=t;return m}()}()(d),$CLJS.zd($CLJS.A(e))?vca(e,$CLJS.n(c)?c:d):wca(e),Mv()}finally{Ov=k,Tv=f}}return null}return Rx(a)};
xca=function(a){if($CLJS.n(Uv()))$CLJS.ac($CLJS.Fu,"#");else{var b=Tv,c=Ov;Tv+=1;Ov=0;try{Iv("[","]");for(var d=0;;){if($CLJS.Va($CLJS.Ma)||d<$CLJS.Ma){if($CLJS.y(a)){if($CLJS.n(Uv()))$CLJS.ac($CLJS.Fu,"#");else{var e=Tv,f=Ov;Tv+=1;Ov=0;try{Iv(null,null),$CLJS.Rv($CLJS.A(a)),$CLJS.B(a)&&($CLJS.ac($CLJS.Fu," "),Zv(Wv),$CLJS.Rv($CLJS.hd(a))),Mv()}finally{Ov=f,Tv=e}}if($CLJS.B($CLJS.Lc(a))){$CLJS.ac($CLJS.Fu," ");Zv(Yv);e=d+1;var k=$CLJS.B($CLJS.Lc(a));d=e;a=k;continue}}}else $CLJS.ac($CLJS.Fu,"...");
break}Mv()}finally{Ov=c,Tv=b}}};
Tx=function(a){var b=$CLJS.A(a);if($CLJS.n(Uv()))$CLJS.ac($CLJS.Fu,"#");else{var c=Tv,d=Ov;Tv+=1;Ov=0;try{Iv("(",")"),$CLJS.B(a)&&$CLJS.zd($CLJS.hd(a))?(function(){var e=Qx("~w ~1I~@_");return function(){function f(l){var m=null;if(0<arguments.length){m=0;for(var t=Array(arguments.length-0);m<t.length;)t[m]=arguments[m+0],++m;m=new $CLJS.w(t,0,null)}return k.call(this,m)}function k(l){l=ew(l);return gw(e,l)}f.A=0;f.B=function(l){l=$CLJS.y(l);return k(l)};f.l=k;return f}()}()(b),xca($CLJS.hd(a)),function(){var e=
Qx(" ~_~{~w~^ ~_~}");return function(){function f(l){var m=null;if(0<arguments.length){m=0;for(var t=Array(arguments.length-0);m<t.length;)t[m]=arguments[m+0],++m;m=new $CLJS.w(t,0,null)}return k.call(this,m)}function k(l){l=ew(l);return gw(e,l)}f.A=0;f.B=function(l){l=$CLJS.y(l);return k(l)};f.l=k;return f}()}()($CLJS.B($CLJS.Lc(a)))):Rx(a),Mv()}finally{Ov=d,Tv=c}}return null};
Rx=function(a){if($CLJS.n(Uv()))$CLJS.ac($CLJS.Fu,"#");else{var b=Tv,c=Ov;Tv+=1;Ov=0;try{Iv("(",")");cw($v,1);for(var d=0,e=$CLJS.y(a);;){if($CLJS.Va($CLJS.Ma)||d<$CLJS.Ma){if(e&&($CLJS.Rv($CLJS.A(e)),$CLJS.B(e))){$CLJS.ac($CLJS.Fu," ");Zv(Yv);a=d+1;var f=$CLJS.B(e);d=a;e=f;continue}}else $CLJS.ac($CLJS.Fu,"...");break}Mv()}finally{Ov=c,Tv=b}}return null};$CLJS.ne.prototype.Lc=$CLJS.va(2,function(){return $CLJS.Va(this.Rb)});$CLJS.cf.prototype.Lc=$CLJS.va(1,function(){return!1});
$CLJS.Jh.prototype.Lc=$CLJS.va(0,function(){return $CLJS.Va(this.f)});$CLJS.Fu=null;Ux=function Ux(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return Ux.l(0<c.length?new $CLJS.w(c.slice(0),0,null):null)};Ux.l=function(a){a=$CLJS.y(a);for(var b=$CLJS.ec($CLJS.gg);;)if(a){var c=$CLJS.B(a);if(null==c)throw Error(["No value supplied for key: ",$CLJS.p.h($CLJS.A(a))].join(""));var d=$CLJS.B(c);b=$CLJS.hg.j(b,$CLJS.A(a),$CLJS.A(c));a=d}else return $CLJS.gc(b)};
Ux.A=0;Ux.B=function(a){return this.l($CLJS.y(a))};$CLJS.Ex=function Ex(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return Ex.l(arguments[0],1<c.length?new $CLJS.w(c.slice(1),0,null):null)};
$CLJS.Ex.l=function(a,b){if($CLJS.n($CLJS.Re($CLJS.Td,b))){var c=function(d,e){var f=$CLJS.Eb(e),k=$CLJS.Fb(e);if($CLJS.Id(d,f)){e=$CLJS.R.j;var l=$CLJS.J.g(d,f);k=a.g?a.g(l,k):a.call(null,l,k);d=e.call($CLJS.R,d,f,k)}else d=$CLJS.R.j(d,f,k);return d};return $CLJS.Qd(function(d,e){return $CLJS.eb(c,$CLJS.n(d)?d:$CLJS.N,$CLJS.y(e))},b)}return null};$CLJS.Ex.A=1;$CLJS.Ex.B=function(a){var b=$CLJS.A(a);a=$CLJS.B(a);return this.l(b,a)};
$CLJS.Iw=function Iw(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return Iw.l(0<c.length?new $CLJS.w(c.slice(0),0,null):null)};$CLJS.Iw.l=function(a){return $CLJS.Eh(a,$CLJS.R.j($CLJS.Oa(),$CLJS.Ga,!1))};$CLJS.Iw.A=0;$CLJS.Iw.B=function(a){return this.l($CLJS.y(a))};av=new $CLJS.M(null,"trailing-white-space","trailing-white-space",1496006996);Vx=new $CLJS.M(null,"relative-to","relative-to",-470100051);ov=new $CLJS.M(null,"intra-block-nl","intra-block-nl",1808826875);
gx=new $CLJS.M(null,"exponentchar","exponentchar",1986664222);yca=new $CLJS.r(null,"when-let","when-let",-1383043480,null);Wv=new $CLJS.M(null,"miser","miser",-556060186);$CLJS.aw=new $CLJS.M(null,"current","current",-1088038603);zca=new $CLJS.r(null,"-\x3e","-\x3e",-2139605430,null);Aca=new $CLJS.r(null,"..","..",-300507420,null);dv=new $CLJS.M(null,"end-pos","end-pos",-1643883926);mv=new $CLJS.M(null,"per-line-prefix","per-line-prefix",846941813);Mx=new $CLJS.M(null,"queue","queue",1455835879);
Bca=new $CLJS.r(null,"if-let","if-let",1803593690,null);Kv=new $CLJS.M(null,"end","end",-268185958);uw=new $CLJS.M(null,"remaining-arg-count","remaining-arg-count",-1216589335);uv=new $CLJS.M(null,"logical-blocks","logical-blocks",-1466339776);Cca=new $CLJS.r(null,"defmacro","defmacro",2054157304,null);Dca=new $CLJS.r(null,"when-first","when-first",821699168,null);Hv=new $CLJS.M(null,"start-block-t","start-block-t",-373430594);Eca=new $CLJS.r(null,"binding","binding",-2114503176,null);
zx=new $CLJS.M(null,"bracket-info","bracket-info",-1600092774);yv=new $CLJS.M(null,"writing","writing",-1486865108);tw=new $CLJS.M(null,"parameter-from-args","parameter-from-args",-758446196);Fv=new $CLJS.M(null,"logical-block-callback","logical-block-callback",1612691194);ix=new $CLJS.M(null,"selector","selector",762528866);Fca=new $CLJS.r(null,"struct","struct",325972931,null);nv=new $CLJS.M(null,"indent","indent",-148200125);Gca=new $CLJS.r(null,"loop","loop",1244978678,null);
px=new $CLJS.M(null,"max-columns","max-columns",1742323262);wx=new $CLJS.M(null,"both","both",-393648840);Wx=new $CLJS.M(null,"colnum","colnum",2023796854);Hca=new $CLJS.r(null,"doseq","doseq",221164135,null);$CLJS.Xx=new $CLJS.M(null,"length","length",588987862);Qw=new $CLJS.M(null,"char-format","char-format",-1016499218);Yx=new $CLJS.M(null,"prefix","prefix",-265908465);Sw=new $CLJS.M(null,"colon-up-arrow","colon-up-arrow",244853007);Jv=new $CLJS.M(null,"suffix","suffix",367373057);
sw=new $CLJS.M(null,"at","at",1476951349);$u=new $CLJS.M(null,"nl-t","nl-t",-1608382114);vx=new $CLJS.M(null,"directive","directive",793559132);Fba=new $CLJS.M(null,"buffer-level","buffer-level",928864731);Vv=new $CLJS.M(null,"mandatory","mandatory",542802336);Dv=new $CLJS.M(null,"pretty-writer","pretty-writer",-1222834267);gv=new $CLJS.M(null,"done-nl","done-nl",-381024340);Ica=new $CLJS.r(null,"condp","condp",1054325175,null);lw=new $CLJS.M(null,"seq","seq",-1817803783);
Jca=new $CLJS.r(null,"defn","defn",-126010802,null);Bw=new $CLJS.M(null,"colinc","colinc",-584873385);Rw=new $CLJS.M(null,"up-arrow","up-arrow",1705310333);Dx=new $CLJS.M(null,"right-bracket","right-bracket",951856080);Kca=new $CLJS.M(null,"radix","radix",857016463);Fx=new $CLJS.M(null,"first","first",-644103046);Eba=new $CLJS.M(null,"sections","sections",-886710106);$CLJS.Gv=new $CLJS.M(null,"start","start",-355208981);Lca=new $CLJS.r("cljs.core","unquote","cljs.core/unquote",1013085760,null);
Mca=new $CLJS.r(null,"defn-","defn-",1097765044,null);Nca=new $CLJS.r("cljs.core","deref","cljs.core/deref",1901963335,null);$v=new $CLJS.M(null,"block","block",664686210);Oca=new $CLJS.r(null,"when","when",1064114221,null);nx=new $CLJS.M(null,"else-params","else-params",-832171646);$CLJS.Zx=new $CLJS.M(null,"count","count",2139924085);$CLJS.Cx=new $CLJS.M(null,"right","right",-452581833);Yv=new $CLJS.M(null,"linear","linear",872268697);Pca=new $CLJS.r(null,"when-not","when-not",-1223136340,null);
Cw=new $CLJS.M(null,"padchar","padchar",2018584530);Aw=new $CLJS.M(null,"mincol","mincol",1230695445);Qca=new $CLJS.M(null,"not-delivered","not-delivered",1599158697);iv=new $CLJS.M(null,"miser-width","miser-width",-1310049437);Rca=new $CLJS.r(null,"with-local-vars","with-local-vars",837642072,null);tca=new $CLJS.r(null,"lift-ns","lift-ns",602311926,null);Gx=new $CLJS.M(null,"allows-separator","allows-separator",-818967742);vv=new $CLJS.M(null,"buffering","buffering",-876713613);
$x=new $CLJS.M(null,"arg1","arg1",951899358);Vw=new $CLJS.M(null,"base-args","base-args",-1268706822);ay=new $CLJS.M(null,"arg3","arg3",-1486822496);by=new $CLJS.M(null,"arg2","arg2",1729550917);Gw=new $CLJS.M(null,"commainterval","commainterval",-1980061083);Sca=new $CLJS.M(null,"right-margin","right-margin",-810413306);xv=new $CLJS.M(null,"buffer-blob","buffer-blob",-1830112173);Tca=new $CLJS.r(null,"with-open","with-open",172119667,null);
sca=new $CLJS.r("cljs.core","lift-ns","cljs.core/lift-ns",463499081,null);Uca=new $CLJS.M(null,"lines","lines",-700165781);bw=new $CLJS.M(null,"indent-t","indent-t",528318969);lx=new $CLJS.M(null,"right-params","right-params",-1790676237);rw=new $CLJS.M(null,"colon","colon",-965200945);Vca=new $CLJS.r(null,"if-not","if-not",-265415609,null);Ww=new $CLJS.M(null,"func","func",-238706040);$CLJS.cy=new $CLJS.M(null,"last","last",1105735132);Nx=new $CLJS.M(null,"deref","deref",-145586795);
Wca=new $CLJS.r(null,"dotimes","dotimes",-818708397,null);kx=new $CLJS.M(null,"max-iterations","max-iterations",2021275563);Xca=new $CLJS.r(null,"cond","cond",1606708055,null);zw=new $CLJS.M(null,"minpad","minpad",323570901);lv=new $CLJS.M(null,"logical-block","logical-block",-581022564);Yca=new $CLJS.r(null,"struct-map","struct-map",-1387540878,null);Lv=new $CLJS.M(null,"end-block-t","end-block-t",1544648735);dy=new $CLJS.M(null,"stream","stream",1534941648);
Uw=new $CLJS.M(null,"params","params",710516235);Zca=new $CLJS.M(null,"circle","circle",1903212362);ev=new $CLJS.M(null,"start-pos","start-pos",668789086);ux=new $CLJS.M(null,"flags","flags",1775418075);Xv=new $CLJS.M(null,"fill","fill",883462889);Dba=new $CLJS.M(null,"buffer-block","buffer-block",-10937307);xx=new $CLJS.M(null,"generator-fn","generator-fn",811851656);jv=new $CLJS.M(null,"start-col","start-col",668080143);ax=new $CLJS.M(null,"w","w",354169001);Ax=new $CLJS.M(null,"def","def",-1043430536);
$CLJS.cx=new $CLJS.M(null,"k","k",-2146297393);fx=new $CLJS.M(null,"e","e",1381269198);bx=new $CLJS.M(null,"d","d",1972142424);dx=new $CLJS.M(null,"overflowchar","overflowchar",-1620088106);ox=new $CLJS.M(null,"min-remaining","min-remaining",962687677);Hw=new $CLJS.M(null,"commachar","commachar",652859327);ey=new $CLJS.M(null,"section","section",-300141526);fy=new $CLJS.M(null,"pretty","pretty",-1916372486);$ca=new $CLJS.r(null,"let","let",358118826,null);Su=new $CLJS.M(null,"parent","parent",-878878779);
$CLJS.jx=new $CLJS.M(null,"clauses","clauses",1454841241);ada=new $CLJS.r(null,"defonce","defonce",-1681484013,null);Pu=new $CLJS.M(null,"base","base",185279322);Zu=new $CLJS.M(null,"type-tag","type-tag",-1873863267);bda=new $CLJS.r(null,"locking","locking",1542862874,null);wv=new $CLJS.M(null,"pos","pos",-864607220);cda=new $CLJS.M(null,"suppress-namespaces","suppress-namespaces",2130686956);$CLJS.Ou=new $CLJS.M(null,"cur","cur",1153190599);var Dw=function Dw(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return Dw.l(0<c.length?new $CLJS.w(c.slice(0),0,null):null)},Qv,Cv,hy,iy,jy,xw,ww,Tv,Ov,ky;Dw.l=function(a){return $CLJS.ac($CLJS.Fu,$CLJS.Se.g($CLJS.Iw,a))};Dw.A=0;Dw.B=function(a){return this.l($CLJS.y(a))};var Eu=function Eu(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return Eu.l(0<c.length?new $CLJS.w(c.slice(0),0,null):null)};
Eu.l=function(a){return $CLJS.ac($CLJS.Fu,$CLJS.Se.g($CLJS.Mh,a))};Eu.A=0;Eu.B=function(a){return this.l($CLJS.y(a))};$CLJS.g=Qu.prototype;$CLJS.g.P=function(a,b){return new Qu(this.Ca,this.Nf,this.Yc,b)};$CLJS.g.O=function(){return this.bi};$CLJS.g.Qb=function(){return this.Yc};$CLJS.g.rc=function(){return uu(this.Ca)};
$CLJS.g.Mc=function(a,b){a=$CLJS.ab(b);if($CLJS.n($CLJS.E.g?$CLJS.E.g(String,a):$CLJS.E.call(null,String,a)))return a=b.lastIndexOf("\n"),0>a?Nu(this,$CLJS.Ou,$CLJS.Mu(this,$CLJS.Ou)+$CLJS.D(b)):(Nu(this,$CLJS.Ou,$CLJS.D(b)-a-1),Nu(this,$CLJS.mj,$CLJS.Mu(this,$CLJS.mj)+$CLJS.D($CLJS.lf(function(c){return $CLJS.E.g(c,"\n")},b)))),$CLJS.ac($CLJS.Mu(this,Pu),b);if($CLJS.n($CLJS.E.g?$CLJS.E.g(Number,a):$CLJS.E.call(null,Number,a)))return zba(this,b);throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));
};$CLJS.g=Ru.prototype;$CLJS.g.na=function(a,b){return this.$(null,b,null)};
$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "parent":return this.parent;case "section":return this.Cb;case "start-col":return this.tb;case "indent":return this.sb;case "done-nl":return this.vb;case "intra-block-nl":return this.wb;case "prefix":return this.prefix;case "per-line-prefix":return this.Ab;case "suffix":return this.Db;case "logical-block-callback":return this.xb;default:return $CLJS.J.j(this.G,b,c)}};
$CLJS.g.Fb=function(a,b,c){return $CLJS.eb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.sh(b,function(d){return $CLJS.sh(b,$CLJS.yh,""," ","",c,d)},"#cljs.pprint.logical-block{",", ","}",c,$CLJS.gf.g(new $CLJS.P(null,10,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[Su,this.parent],null),new $CLJS.P(null,2,5,$CLJS.Q,[ey,this.Cb],null),new $CLJS.P(null,2,5,$CLJS.Q,[jv,this.tb],null),new $CLJS.P(null,2,5,$CLJS.Q,[nv,this.sb],null),new $CLJS.P(null,2,5,$CLJS.Q,[gv,this.vb],null),new $CLJS.P(null,2,5,$CLJS.Q,[ov,this.wb],null),new $CLJS.P(null,2,5,$CLJS.Q,
[Yx,this.prefix],null),new $CLJS.P(null,2,5,$CLJS.Q,[mv,this.Ab],null),new $CLJS.P(null,2,5,$CLJS.Q,[Jv,this.Db],null),new $CLJS.P(null,2,5,$CLJS.Q,[Fv,this.xb],null)],null),this.G))};$CLJS.g.Da=function(){return new $CLJS.Ht(this,10,new $CLJS.P(null,10,5,$CLJS.Q,[Su,ey,jv,nv,gv,ov,Yx,mv,Jv,Fv],null),$CLJS.n(this.G)?$CLJS.rc(this.G):$CLJS.He())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 10+$CLJS.D(this.G)};
$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=1977012399^$CLJS.Qc(this)};$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.g(this.parent,b.parent)&&$CLJS.E.g(this.Cb,b.Cb)&&$CLJS.E.g(this.tb,b.tb)&&$CLJS.E.g(this.sb,b.sb)&&$CLJS.E.g(this.vb,b.vb)&&$CLJS.E.g(this.wb,b.wb)&&$CLJS.E.g(this.prefix,b.prefix)&&$CLJS.E.g(this.Ab,b.Ab)&&$CLJS.E.g(this.Db,b.Db)&&$CLJS.E.g(this.xb,b.xb)&&$CLJS.E.g(this.G,b.G)};
$CLJS.g.Gb=function(a,b){return $CLJS.Id(new $CLJS.ah(null,new $CLJS.h(null,10,[Jv,null,nv,null,Su,null,ey,null,gv,null,jv,null,Yx,null,mv,null,Fv,null,ov,null],null),null),b)?$CLJS.Ik.g($CLJS.Ob($CLJS.eg.g($CLJS.N,this),this.S),b):new Ru(this.parent,this.Cb,this.tb,this.sb,this.vb,this.wb,this.prefix,this.Ab,this.Db,this.xb,this.S,$CLJS.Fe($CLJS.Ik.g(this.G,b)),null)};
$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "parent":case "section":case "start-col":case "indent":case "done-nl":case "intra-block-nl":case "prefix":case "per-line-prefix":case "suffix":case "logical-block-callback":return!0;default:return $CLJS.Id(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.he.g?$CLJS.he.g(Su,b):$CLJS.he.call(null,Su,b))?new Ru(c,this.Cb,this.tb,this.sb,this.vb,this.wb,this.prefix,this.Ab,this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(ey,b):$CLJS.he.call(null,ey,b))?new Ru(this.parent,c,this.tb,this.sb,this.vb,this.wb,this.prefix,this.Ab,this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(jv,b):$CLJS.he.call(null,jv,b))?new Ru(this.parent,this.Cb,c,this.sb,this.vb,this.wb,this.prefix,this.Ab,
this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(nv,b):$CLJS.he.call(null,nv,b))?new Ru(this.parent,this.Cb,this.tb,c,this.vb,this.wb,this.prefix,this.Ab,this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(gv,b):$CLJS.he.call(null,gv,b))?new Ru(this.parent,this.Cb,this.tb,this.sb,c,this.wb,this.prefix,this.Ab,this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(ov,b):$CLJS.he.call(null,ov,b))?new Ru(this.parent,this.Cb,this.tb,this.sb,this.vb,c,this.prefix,
this.Ab,this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(Yx,b):$CLJS.he.call(null,Yx,b))?new Ru(this.parent,this.Cb,this.tb,this.sb,this.vb,this.wb,c,this.Ab,this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(mv,b):$CLJS.he.call(null,mv,b))?new Ru(this.parent,this.Cb,this.tb,this.sb,this.vb,this.wb,this.prefix,c,this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(Jv,b):$CLJS.he.call(null,Jv,b))?new Ru(this.parent,this.Cb,this.tb,this.sb,this.vb,this.wb,
this.prefix,this.Ab,c,this.xb,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(Fv,b):$CLJS.he.call(null,Fv,b))?new Ru(this.parent,this.Cb,this.tb,this.sb,this.vb,this.wb,this.prefix,this.Ab,this.Db,c,this.S,this.G,null):new Ru(this.parent,this.Cb,this.tb,this.sb,this.vb,this.wb,this.prefix,this.Ab,this.Db,this.xb,this.S,$CLJS.R.j(this.G,b,c),null)};
$CLJS.g.ga=function(){return $CLJS.y($CLJS.gf.g(new $CLJS.P(null,10,5,$CLJS.Q,[new $CLJS.Yf(Su,this.parent),new $CLJS.Yf(ey,this.Cb),new $CLJS.Yf(jv,this.tb),new $CLJS.Yf(nv,this.sb),new $CLJS.Yf(gv,this.vb),new $CLJS.Yf(ov,this.wb),new $CLJS.Yf(Yx,this.prefix),new $CLJS.Yf(mv,this.Ab),new $CLJS.Yf(Jv,this.Db),new $CLJS.Yf(Fv,this.xb)],null),this.G))};$CLJS.g.P=function(a,b){return new Ru(this.parent,this.Cb,this.tb,this.sb,this.vb,this.wb,this.prefix,this.Ab,this.Db,this.xb,b,this.G,this.J)};
$CLJS.g.ja=function(a,b){return $CLJS.zd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.eb($CLJS.pb,this,b)};$CLJS.g=Uu.prototype;$CLJS.g.na=function(a,b){return this.$(null,b,null)};$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":return this.ca;case "data":return this.data;case "trailing-white-space":return this.ac;case "start-pos":return this.ba;case "end-pos":return this.aa;default:return $CLJS.J.j(this.G,b,c)}};
$CLJS.g.Fb=function(a,b,c){return $CLJS.eb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.sh(b,function(d){return $CLJS.sh(b,$CLJS.yh,""," ","",c,d)},"#cljs.pprint.buffer-blob{",", ","}",c,$CLJS.gf.g(new $CLJS.P(null,5,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[Zu,this.ca],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qj,this.data],null),new $CLJS.P(null,2,5,$CLJS.Q,[av,this.ac],null),new $CLJS.P(null,2,5,$CLJS.Q,[ev,this.ba],null),new $CLJS.P(null,2,5,$CLJS.Q,[dv,this.aa],null)],null),this.G))};
$CLJS.g.Da=function(){return new $CLJS.Ht(this,5,new $CLJS.P(null,5,5,$CLJS.Q,[Zu,$CLJS.qj,av,ev,dv],null),$CLJS.n(this.G)?$CLJS.rc(this.G):$CLJS.He())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 5+$CLJS.D(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=1809113693^$CLJS.Qc(this)};
$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.g(this.ca,b.ca)&&$CLJS.E.g(this.data,b.data)&&$CLJS.E.g(this.ac,b.ac)&&$CLJS.E.g(this.ba,b.ba)&&$CLJS.E.g(this.aa,b.aa)&&$CLJS.E.g(this.G,b.G)};
$CLJS.g.Gb=function(a,b){return $CLJS.Id(new $CLJS.ah(null,new $CLJS.h(null,5,[dv,null,av,null,Zu,null,ev,null,$CLJS.qj,null],null),null),b)?$CLJS.Ik.g($CLJS.Ob($CLJS.eg.g($CLJS.N,this),this.S),b):new Uu(this.ca,this.data,this.ac,this.ba,this.aa,this.S,$CLJS.Fe($CLJS.Ik.g(this.G,b)),null)};$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":case "data":case "trailing-white-space":case "start-pos":case "end-pos":return!0;default:return $CLJS.Id(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.he.g?$CLJS.he.g(Zu,b):$CLJS.he.call(null,Zu,b))?new Uu(c,this.data,this.ac,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g($CLJS.qj,b):$CLJS.he.call(null,$CLJS.qj,b))?new Uu(this.ca,c,this.ac,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(av,b):$CLJS.he.call(null,av,b))?new Uu(this.ca,this.data,c,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(ev,b):$CLJS.he.call(null,ev,b))?new Uu(this.ca,this.data,
this.ac,c,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(dv,b):$CLJS.he.call(null,dv,b))?new Uu(this.ca,this.data,this.ac,this.ba,c,this.S,this.G,null):new Uu(this.ca,this.data,this.ac,this.ba,this.aa,this.S,$CLJS.R.j(this.G,b,c),null)};$CLJS.g.ga=function(){return $CLJS.y($CLJS.gf.g(new $CLJS.P(null,5,5,$CLJS.Q,[new $CLJS.Yf(Zu,this.ca),new $CLJS.Yf($CLJS.qj,this.data),new $CLJS.Yf(av,this.ac),new $CLJS.Yf(ev,this.ba),new $CLJS.Yf(dv,this.aa)],null),this.G))};
$CLJS.g.P=function(a,b){return new Uu(this.ca,this.data,this.ac,this.ba,this.aa,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.zd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.eb($CLJS.pb,this,b)};$CLJS.g=Vu.prototype;$CLJS.g.na=function(a,b){return this.$(null,b,null)};
$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":return this.ca;case "type":return this.type;case "logical-block":return this.ea;case "start-pos":return this.ba;case "end-pos":return this.aa;default:return $CLJS.J.j(this.G,b,c)}};$CLJS.g.Fb=function(a,b,c){return $CLJS.eb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.sh(b,function(d){return $CLJS.sh(b,$CLJS.yh,""," ","",c,d)},"#cljs.pprint.nl-t{",", ","}",c,$CLJS.gf.g(new $CLJS.P(null,5,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[Zu,this.ca],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj,this.type],null),new $CLJS.P(null,2,5,$CLJS.Q,[lv,this.ea],null),new $CLJS.P(null,2,5,$CLJS.Q,[ev,this.ba],null),new $CLJS.P(null,2,5,$CLJS.Q,[dv,this.aa],null)],null),this.G))};
$CLJS.g.Da=function(){return new $CLJS.Ht(this,5,new $CLJS.P(null,5,5,$CLJS.Q,[Zu,$CLJS.lj,lv,ev,dv],null),$CLJS.n(this.G)?$CLJS.rc(this.G):$CLJS.He())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 5+$CLJS.D(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=-1640656800^$CLJS.Qc(this)};
$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.g(this.ca,b.ca)&&$CLJS.E.g(this.type,b.type)&&$CLJS.E.g(this.ea,b.ea)&&$CLJS.E.g(this.ba,b.ba)&&$CLJS.E.g(this.aa,b.aa)&&$CLJS.E.g(this.G,b.G)};
$CLJS.g.Gb=function(a,b){return $CLJS.Id(new $CLJS.ah(null,new $CLJS.h(null,5,[dv,null,$CLJS.lj,null,lv,null,Zu,null,ev,null],null),null),b)?$CLJS.Ik.g($CLJS.Ob($CLJS.eg.g($CLJS.N,this),this.S),b):new Vu(this.ca,this.type,this.ea,this.ba,this.aa,this.S,$CLJS.Fe($CLJS.Ik.g(this.G,b)),null)};$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":case "type":case "logical-block":case "start-pos":case "end-pos":return!0;default:return $CLJS.Id(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.he.g?$CLJS.he.g(Zu,b):$CLJS.he.call(null,Zu,b))?new Vu(c,this.type,this.ea,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g($CLJS.lj,b):$CLJS.he.call(null,$CLJS.lj,b))?new Vu(this.ca,c,this.ea,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(lv,b):$CLJS.he.call(null,lv,b))?new Vu(this.ca,this.type,c,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(ev,b):$CLJS.he.call(null,ev,b))?new Vu(this.ca,this.type,
this.ea,c,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(dv,b):$CLJS.he.call(null,dv,b))?new Vu(this.ca,this.type,this.ea,this.ba,c,this.S,this.G,null):new Vu(this.ca,this.type,this.ea,this.ba,this.aa,this.S,$CLJS.R.j(this.G,b,c),null)};$CLJS.g.ga=function(){return $CLJS.y($CLJS.gf.g(new $CLJS.P(null,5,5,$CLJS.Q,[new $CLJS.Yf(Zu,this.ca),new $CLJS.Yf($CLJS.lj,this.type),new $CLJS.Yf(lv,this.ea),new $CLJS.Yf(ev,this.ba),new $CLJS.Yf(dv,this.aa)],null),this.G))};
$CLJS.g.P=function(a,b){return new Vu(this.ca,this.type,this.ea,this.ba,this.aa,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.zd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.eb($CLJS.pb,this,b)};$CLJS.g=Wu.prototype;$CLJS.g.na=function(a,b){return this.$(null,b,null)};
$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":return this.ca;case "logical-block":return this.ea;case "start-pos":return this.ba;case "end-pos":return this.aa;default:return $CLJS.J.j(this.G,b,c)}};$CLJS.g.Fb=function(a,b,c){return $CLJS.eb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.sh(b,function(d){return $CLJS.sh(b,$CLJS.yh,""," ","",c,d)},"#cljs.pprint.start-block-t{",", ","}",c,$CLJS.gf.g(new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[Zu,this.ca],null),new $CLJS.P(null,2,5,$CLJS.Q,[lv,this.ea],null),new $CLJS.P(null,2,5,$CLJS.Q,[ev,this.ba],null),new $CLJS.P(null,2,5,$CLJS.Q,[dv,this.aa],null)],null),this.G))};
$CLJS.g.Da=function(){return new $CLJS.Ht(this,4,new $CLJS.P(null,4,5,$CLJS.Q,[Zu,lv,ev,dv],null),$CLJS.n(this.G)?$CLJS.rc(this.G):$CLJS.He())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 4+$CLJS.D(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=-414877272^$CLJS.Qc(this)};
$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.g(this.ca,b.ca)&&$CLJS.E.g(this.ea,b.ea)&&$CLJS.E.g(this.ba,b.ba)&&$CLJS.E.g(this.aa,b.aa)&&$CLJS.E.g(this.G,b.G)};$CLJS.g.Gb=function(a,b){return $CLJS.Id(new $CLJS.ah(null,new $CLJS.h(null,4,[dv,null,lv,null,Zu,null,ev,null],null),null),b)?$CLJS.Ik.g($CLJS.Ob($CLJS.eg.g($CLJS.N,this),this.S),b):new Wu(this.ca,this.ea,this.ba,this.aa,this.S,$CLJS.Fe($CLJS.Ik.g(this.G,b)),null)};
$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":case "logical-block":case "start-pos":case "end-pos":return!0;default:return $CLJS.Id(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.he.g?$CLJS.he.g(Zu,b):$CLJS.he.call(null,Zu,b))?new Wu(c,this.ea,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(lv,b):$CLJS.he.call(null,lv,b))?new Wu(this.ca,c,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(ev,b):$CLJS.he.call(null,ev,b))?new Wu(this.ca,this.ea,c,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(dv,b):$CLJS.he.call(null,dv,b))?new Wu(this.ca,this.ea,this.ba,c,this.S,this.G,null):new Wu(this.ca,
this.ea,this.ba,this.aa,this.S,$CLJS.R.j(this.G,b,c),null)};$CLJS.g.ga=function(){return $CLJS.y($CLJS.gf.g(new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.Yf(Zu,this.ca),new $CLJS.Yf(lv,this.ea),new $CLJS.Yf(ev,this.ba),new $CLJS.Yf(dv,this.aa)],null),this.G))};$CLJS.g.P=function(a,b){return new Wu(this.ca,this.ea,this.ba,this.aa,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.zd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.eb($CLJS.pb,this,b)};$CLJS.g=Xu.prototype;
$CLJS.g.na=function(a,b){return this.$(null,b,null)};$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":return this.ca;case "logical-block":return this.ea;case "start-pos":return this.ba;case "end-pos":return this.aa;default:return $CLJS.J.j(this.G,b,c)}};$CLJS.g.Fb=function(a,b,c){return $CLJS.eb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.sh(b,function(d){return $CLJS.sh(b,$CLJS.yh,""," ","",c,d)},"#cljs.pprint.end-block-t{",", ","}",c,$CLJS.gf.g(new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[Zu,this.ca],null),new $CLJS.P(null,2,5,$CLJS.Q,[lv,this.ea],null),new $CLJS.P(null,2,5,$CLJS.Q,[ev,this.ba],null),new $CLJS.P(null,2,5,$CLJS.Q,[dv,this.aa],null)],null),this.G))};
$CLJS.g.Da=function(){return new $CLJS.Ht(this,4,new $CLJS.P(null,4,5,$CLJS.Q,[Zu,lv,ev,dv],null),$CLJS.n(this.G)?$CLJS.rc(this.G):$CLJS.He())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 4+$CLJS.D(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=1365867980^$CLJS.Qc(this)};
$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.g(this.ca,b.ca)&&$CLJS.E.g(this.ea,b.ea)&&$CLJS.E.g(this.ba,b.ba)&&$CLJS.E.g(this.aa,b.aa)&&$CLJS.E.g(this.G,b.G)};$CLJS.g.Gb=function(a,b){return $CLJS.Id(new $CLJS.ah(null,new $CLJS.h(null,4,[dv,null,lv,null,Zu,null,ev,null],null),null),b)?$CLJS.Ik.g($CLJS.Ob($CLJS.eg.g($CLJS.N,this),this.S),b):new Xu(this.ca,this.ea,this.ba,this.aa,this.S,$CLJS.Fe($CLJS.Ik.g(this.G,b)),null)};
$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":case "logical-block":case "start-pos":case "end-pos":return!0;default:return $CLJS.Id(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.he.g?$CLJS.he.g(Zu,b):$CLJS.he.call(null,Zu,b))?new Xu(c,this.ea,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(lv,b):$CLJS.he.call(null,lv,b))?new Xu(this.ca,c,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(ev,b):$CLJS.he.call(null,ev,b))?new Xu(this.ca,this.ea,c,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(dv,b):$CLJS.he.call(null,dv,b))?new Xu(this.ca,this.ea,this.ba,c,this.S,this.G,null):new Xu(this.ca,
this.ea,this.ba,this.aa,this.S,$CLJS.R.j(this.G,b,c),null)};$CLJS.g.ga=function(){return $CLJS.y($CLJS.gf.g(new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.Yf(Zu,this.ca),new $CLJS.Yf(lv,this.ea),new $CLJS.Yf(ev,this.ba),new $CLJS.Yf(dv,this.aa)],null),this.G))};$CLJS.g.P=function(a,b){return new Xu(this.ca,this.ea,this.ba,this.aa,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.zd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.eb($CLJS.pb,this,b)};$CLJS.g=Yu.prototype;
$CLJS.g.na=function(a,b){return this.$(null,b,null)};$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":return this.ca;case "logical-block":return this.ea;case "relative-to":return this.Wb;case "offset":return this.offset;case "start-pos":return this.ba;case "end-pos":return this.aa;default:return $CLJS.J.j(this.G,b,c)}};$CLJS.g.Fb=function(a,b,c){return $CLJS.eb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.sh(b,function(d){return $CLJS.sh(b,$CLJS.yh,""," ","",c,d)},"#cljs.pprint.indent-t{",", ","}",c,$CLJS.gf.g(new $CLJS.P(null,6,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[Zu,this.ca],null),new $CLJS.P(null,2,5,$CLJS.Q,[lv,this.ea],null),new $CLJS.P(null,2,5,$CLJS.Q,[Vx,this.Wb],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ot,this.offset],null),new $CLJS.P(null,2,5,$CLJS.Q,[ev,this.ba],null),new $CLJS.P(null,2,5,$CLJS.Q,[dv,this.aa],null)],null),this.G))};
$CLJS.g.Da=function(){return new $CLJS.Ht(this,6,new $CLJS.P(null,6,5,$CLJS.Q,[Zu,lv,Vx,$CLJS.Ot,ev,dv],null),$CLJS.n(this.G)?$CLJS.rc(this.G):$CLJS.He())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 6+$CLJS.D(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=-1602780238^$CLJS.Qc(this)};
$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.g(this.ca,b.ca)&&$CLJS.E.g(this.ea,b.ea)&&$CLJS.E.g(this.Wb,b.Wb)&&$CLJS.E.g(this.offset,b.offset)&&$CLJS.E.g(this.ba,b.ba)&&$CLJS.E.g(this.aa,b.aa)&&$CLJS.E.g(this.G,b.G)};
$CLJS.g.Gb=function(a,b){return $CLJS.Id(new $CLJS.ah(null,new $CLJS.h(null,6,[$CLJS.Ot,null,dv,null,Vx,null,lv,null,Zu,null,ev,null],null),null),b)?$CLJS.Ik.g($CLJS.Ob($CLJS.eg.g($CLJS.N,this),this.S),b):new Yu(this.ca,this.ea,this.Wb,this.offset,this.ba,this.aa,this.S,$CLJS.Fe($CLJS.Ik.g(this.G,b)),null)};
$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":case "logical-block":case "relative-to":case "offset":case "start-pos":case "end-pos":return!0;default:return $CLJS.Id(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.he.g?$CLJS.he.g(Zu,b):$CLJS.he.call(null,Zu,b))?new Yu(c,this.ea,this.Wb,this.offset,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(lv,b):$CLJS.he.call(null,lv,b))?new Yu(this.ca,c,this.Wb,this.offset,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(Vx,b):$CLJS.he.call(null,Vx,b))?new Yu(this.ca,this.ea,c,this.offset,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g($CLJS.Ot,b):$CLJS.he.call(null,$CLJS.Ot,
b))?new Yu(this.ca,this.ea,this.Wb,c,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(ev,b):$CLJS.he.call(null,ev,b))?new Yu(this.ca,this.ea,this.Wb,this.offset,c,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(dv,b):$CLJS.he.call(null,dv,b))?new Yu(this.ca,this.ea,this.Wb,this.offset,this.ba,c,this.S,this.G,null):new Yu(this.ca,this.ea,this.Wb,this.offset,this.ba,this.aa,this.S,$CLJS.R.j(this.G,b,c),null)};
$CLJS.g.ga=function(){return $CLJS.y($CLJS.gf.g(new $CLJS.P(null,6,5,$CLJS.Q,[new $CLJS.Yf(Zu,this.ca),new $CLJS.Yf(lv,this.ea),new $CLJS.Yf(Vx,this.Wb),new $CLJS.Yf($CLJS.Ot,this.offset),new $CLJS.Yf(ev,this.ba),new $CLJS.Yf(dv,this.aa)],null),this.G))};$CLJS.g.P=function(a,b){return new Yu(this.ca,this.ea,this.Wb,this.offset,this.ba,this.aa,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.zd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.eb($CLJS.pb,this,b)};
var bv=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.gj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("cljs.pprint","write-token"),function(f,k){return Zu.h(k)},e,a,b,c,d)}();
bv.m(null,Hv,function(a,b){var c=Fv.h($CLJS.q($CLJS.q(a)));$CLJS.n(c)&&(c.h?c.h($CLJS.Gv):c.call(null,$CLJS.Gv));b=lv.h(b);c=Yx.h(b);$CLJS.n(c)&&$CLJS.ac(Pu.h($CLJS.q($CLJS.q(a))),c);a=$CLJS.Mu(Pu.h($CLJS.q($CLJS.q(a))),$CLJS.Ou);$CLJS.Ye(jv.h(b),a);return $CLJS.Ye(nv.h(b),a)});bv.m(null,Lv,function(a,b){var c=Fv.h($CLJS.q($CLJS.q(a)));$CLJS.n(c)&&(c.h?c.h(Kv):c.call(null,Kv));b=Jv.h(lv.h(b));return $CLJS.n(b)?$CLJS.ac(Pu.h($CLJS.q($CLJS.q(a))),b):null});
bv.m(null,bw,function(a,b){var c=lv.h(b),d=nv.h(c),e=$CLJS.Ot.h(b);b=Vx.h(b);if($CLJS.n($CLJS.E.g?$CLJS.E.g($v,b):$CLJS.E.call(null,$v,b)))a=$CLJS.q(jv.h(c));else if($CLJS.n($CLJS.E.g?$CLJS.E.g($CLJS.aw,b):$CLJS.E.call(null,$CLJS.aw,b)))a=$CLJS.Mu(Pu.h($CLJS.q($CLJS.q(a))),$CLJS.Ou);else throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));return $CLJS.Ye(d,e+a)});bv.m(null,xv,function(a,b){return $CLJS.ac(Pu.h($CLJS.q($CLJS.q(a))),$CLJS.qj.h(b))});
bv.m(null,$u,function(a,b){var c=$CLJS.E.g($CLJS.lj.h(b),Vv);c||(c=(c=!$CLJS.E.g($CLJS.lj.h(b),Xv))?$CLJS.q(gv.h(lv.h(b))):c);$CLJS.n(c)?pv.g?pv.g(a,b):pv.call(null,a,b):(b=av.h($CLJS.q($CLJS.q(a))),$CLJS.n(b)&&$CLJS.ac(Pu.h($CLJS.q($CLJS.q(a))),b));return $CLJS.Oh.v($CLJS.q(a),$CLJS.R,av,null)});
var gy=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.gj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("cljs.pprint","emit-nl?"),function(f){return $CLJS.lj.h(f)},e,a,b,c,d)}();gy.m(null,Yv,function(a,b,c){a=lv.h(a);return hv(b,a,c)});gy.m(null,Wv,function(a,b,c){a=lv.h(a);return kv(b,a,c)});
gy.m(null,Xv,function(a,b,c,d){a=lv.h(a);var e=$CLJS.q(ov.h(a));return $CLJS.n(e)?e:(d=!fv(b,d))?d:kv(b,a,c)});gy.m(null,Vv,function(){return!0});
var qv=function qv(a,b){var d=Cba(b);b=$CLJS.I(d,0,null);var e=$CLJS.I(d,1,null);$CLJS.n(b)&&cv(a,b,!1);if($CLJS.n(e)){d=Aba(e);b=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);var f=$CLJS.A(e),k=gy.v(f,a,b,Bba(e));$CLJS.n(k)&&(pv(a,f),e=$CLJS.B(e));fv(a,e)?a=e:(e=qv.g?qv.g(a,b):qv.call(null,a,b),$CLJS.E.g(e,b)?(cv(a,b,!1),a=d):a=$CLJS.eg.g($CLJS.Cf,$CLJS.gf.g(e,d)));return a}return null};$CLJS.g=Av.prototype;$CLJS.g.P=function(a,b){return new Av(this.Ca,this.Nf,this.li,this.mh,this.Yc,b)};$CLJS.g.O=function(){return this.ci};
$CLJS.g.Qb=function(){return this.Yc};
$CLJS.g.Mc=function(a,b){a=$CLJS.ab(b);if($CLJS.n($CLJS.E.g?$CLJS.E.g(String,a):$CLJS.E.call(null,String,a))){var c=zv(this,b);b=c.replace(/\s+$/,"");a=c.substring(b.length);var d=$CLJS.xi.h($CLJS.q($CLJS.q(this)));if($CLJS.E.g(d,yv))return tv(this),$CLJS.ac(Pu.h($CLJS.q($CLJS.q(this))),b),$CLJS.Oh.v($CLJS.q(this),$CLJS.R,av,a);d=wv.h($CLJS.q($CLJS.q(this)));c=d+$CLJS.D(c);$CLJS.Oh.v($CLJS.q(this),$CLJS.R,wv,c);return sv(this,new Uu(xv,b,a,d,c,null,null,null))}if($CLJS.n($CLJS.E.g?$CLJS.E.g(Number,
a):$CLJS.E.call(null,Number,a)))return $CLJS.E.g($CLJS.xi.h($CLJS.q($CLJS.q(this))),yv)?(tv(this),b=$CLJS.ac(Pu.h($CLJS.q($CLJS.q(this))),b)):$CLJS.E.g(b,"\n")?b=zv(this,"\n"):(a=wv.h($CLJS.q($CLJS.q(this))),c=a+1,$CLJS.Oh.v($CLJS.q(this),$CLJS.R,wv,c),b=xu(b),b=sv(this,new Uu(xv,b,null,a,c,null,null,null))),b;throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));};$CLJS.g.rc=function(){this.uf(null);return uu(Pu.h($CLJS.q($CLJS.q(this))))};
$CLJS.g.uf=function(){return $CLJS.E.g($CLJS.xi.h($CLJS.q($CLJS.q(this))),vv)?(cv(this,$CLJS.Mt.h($CLJS.q($CLJS.q(this))),!0),$CLJS.Oh.v($CLJS.q(this),$CLJS.R,$CLJS.Mt,$CLJS.Cf)):tv(this)};$CLJS.Pv=!0;Qv=null;$CLJS.Bv=72;Cv=40;hy=null;iy=null;jy=null;xw=null;ww=10;Tv=0;Ov=null;ky=function ky(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return ky.l(arguments[0],1<c.length?new $CLJS.w(c.slice(1),0,null):null)};
ky.l=function(a,b){var c=$CLJS.hl.l($CLJS.H([new $CLJS.h(null,1,[dy,!0],null),$CLJS.Se.g(Ux,b)]));b=ww;var d=iy,e=$CLJS.Ma,f=$CLJS.qh,k=hy,l=Cv,m=Qv,t=$CLJS.Pv,u=xw,v=$CLJS.Ha,x=$CLJS.Bv,z=jy,C=Pu.g(c,ww),G=Zca.g(c,iy),K=$CLJS.Xx.g(c,$CLJS.Ma),S=$CLJS.Qt.g(c,$CLJS.qh),V=Uca.g(c,hy),Z=iv.g(c,Cv),ha=$CLJS.Ni.g(c,Qv),ra=fy.g(c,$CLJS.Pv),Na=Kca.g(c,xw),zb=$CLJS.Ga.g(c,$CLJS.Ha),Pa=Sca.g(c,$CLJS.Bv),Za=cda.g(c,jy);ww=C;iy=G;$CLJS.Ma=K;$CLJS.qh=S;hy=V;Cv=Z;Qv=ha;$CLJS.Pv=ra;xw=Na;$CLJS.Ha=zb;$CLJS.Bv=Pa;
jy=Za;try{var fb=new $CLJS.Ea,Sa=$CLJS.Id(c,dy)?dy.h(c):!0,mb=!0===Sa||null==Sa?new $CLJS.sc(fb):Sa;if($CLJS.n($CLJS.Pv)){var Ja=$CLJS.Va($CLJS.Nv(mb));c=$CLJS.Fu;$CLJS.Fu=Ja?$CLJS.Ev(mb):mb;try{$CLJS.Rv(a),$CLJS.Lu()}finally{$CLJS.Fu=c}}else{Ja=$CLJS.Fu;$CLJS.Fu=mb;try{Eu.call(null,a)}finally{$CLJS.Fu=Ja}}!0===Sa&&$CLJS.uh($CLJS.p.h(fb));return null==Sa?$CLJS.p.h(fb):null}finally{jy=z,$CLJS.Bv=x,$CLJS.Ha=v,xw=u,$CLJS.Pv=t,Qv=m,Cv=l,hy=k,$CLJS.qh=f,$CLJS.Ma=e,iy=d,ww=b}};ky.A=1;
ky.B=function(a){var b=$CLJS.A(a);a=$CLJS.B(a);return this.l(b,a)};var iw=null;$CLJS.g=kw.prototype;$CLJS.g.na=function(a,b){return this.$(null,b,null)};$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "seq":return this.uc;case "rest":return this.pb;case "pos":return this.$b;default:return $CLJS.J.j(this.G,b,c)}};$CLJS.g.Fb=function(a,b,c){return $CLJS.eb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.sh(b,function(d){return $CLJS.sh(b,$CLJS.yh,""," ","",c,d)},"#cljs.pprint.arg-navigator{",", ","}",c,$CLJS.gf.g(new $CLJS.P(null,3,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[lw,this.uc],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Oi,this.pb],null),new $CLJS.P(null,2,5,$CLJS.Q,[wv,this.$b],null)],null),this.G))};$CLJS.g.Da=function(){return new $CLJS.Ht(this,3,new $CLJS.P(null,3,5,$CLJS.Q,[lw,$CLJS.Oi,wv],null),$CLJS.n(this.G)?$CLJS.rc(this.G):$CLJS.He())};
$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 3+$CLJS.D(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=-402038447^$CLJS.Qc(this)};$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.g(this.uc,b.uc)&&$CLJS.E.g(this.pb,b.pb)&&$CLJS.E.g(this.$b,b.$b)&&$CLJS.E.g(this.G,b.G)};
$CLJS.g.Gb=function(a,b){return $CLJS.Id(new $CLJS.ah(null,new $CLJS.h(null,3,[wv,null,lw,null,$CLJS.Oi,null],null),null),b)?$CLJS.Ik.g($CLJS.Ob($CLJS.eg.g($CLJS.N,this),this.S),b):new kw(this.uc,this.pb,this.$b,this.S,$CLJS.Fe($CLJS.Ik.g(this.G,b)),null)};$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "seq":case "rest":case "pos":return!0;default:return $CLJS.Id(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.he.g?$CLJS.he.g(lw,b):$CLJS.he.call(null,lw,b))?new kw(c,this.pb,this.$b,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g($CLJS.Oi,b):$CLJS.he.call(null,$CLJS.Oi,b))?new kw(this.uc,c,this.$b,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(wv,b):$CLJS.he.call(null,wv,b))?new kw(this.uc,this.pb,c,this.S,this.G,null):new kw(this.uc,this.pb,this.$b,this.S,$CLJS.R.j(this.G,b,c),null)};
$CLJS.g.ga=function(){return $CLJS.y($CLJS.gf.g(new $CLJS.P(null,3,5,$CLJS.Q,[new $CLJS.Yf(lw,this.uc),new $CLJS.Yf($CLJS.Oi,this.pb),new $CLJS.Yf(wv,this.$b)],null),this.G))};$CLJS.g.P=function(a,b){return new kw(this.uc,this.pb,this.$b,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.zd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.eb($CLJS.pb,this,b)};$CLJS.g=qw.prototype;$CLJS.g.na=function(a,b){return this.$(null,b,null)};
$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "func":return this.dc;case "def":return this.cc;case "params":return this.kc;case "offset":return this.offset;default:return $CLJS.J.j(this.G,b,c)}};$CLJS.g.Fb=function(a,b,c){return $CLJS.eb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.sh(b,function(d){return $CLJS.sh(b,$CLJS.yh,""," ","",c,d)},"#cljs.pprint.compiled-directive{",", ","}",c,$CLJS.gf.g(new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[Ww,this.dc],null),new $CLJS.P(null,2,5,$CLJS.Q,[Ax,this.cc],null),new $CLJS.P(null,2,5,$CLJS.Q,[Uw,this.kc],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ot,this.offset],null)],null),this.G))};
$CLJS.g.Da=function(){return new $CLJS.Ht(this,4,new $CLJS.P(null,4,5,$CLJS.Q,[Ww,Ax,Uw,$CLJS.Ot],null),$CLJS.n(this.G)?$CLJS.rc(this.G):$CLJS.He())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 4+$CLJS.D(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=-829256337^$CLJS.Qc(this)};
$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.g(this.dc,b.dc)&&$CLJS.E.g(this.cc,b.cc)&&$CLJS.E.g(this.kc,b.kc)&&$CLJS.E.g(this.offset,b.offset)&&$CLJS.E.g(this.G,b.G)};$CLJS.g.Gb=function(a,b){return $CLJS.Id(new $CLJS.ah(null,new $CLJS.h(null,4,[$CLJS.Ot,null,Ww,null,Uw,null,Ax,null],null),null),b)?$CLJS.Ik.g($CLJS.Ob($CLJS.eg.g($CLJS.N,this),this.S),b):new qw(this.dc,this.cc,this.kc,this.offset,this.S,$CLJS.Fe($CLJS.Ik.g(this.G,b)),null)};
$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "func":case "def":case "params":case "offset":return!0;default:return $CLJS.Id(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.he.g?$CLJS.he.g(Ww,b):$CLJS.he.call(null,Ww,b))?new qw(c,this.cc,this.kc,this.offset,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(Ax,b):$CLJS.he.call(null,Ax,b))?new qw(this.dc,c,this.kc,this.offset,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(Uw,b):$CLJS.he.call(null,Uw,b))?new qw(this.dc,this.cc,c,this.offset,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g($CLJS.Ot,b):$CLJS.he.call(null,$CLJS.Ot,b))?new qw(this.dc,this.cc,this.kc,c,this.S,
this.G,null):new qw(this.dc,this.cc,this.kc,this.offset,this.S,$CLJS.R.j(this.G,b,c),null)};$CLJS.g.ga=function(){return $CLJS.y($CLJS.gf.g(new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.Yf(Ww,this.dc),new $CLJS.Yf(Ax,this.cc),new $CLJS.Yf(Uw,this.kc),new $CLJS.Yf($CLJS.Ot,this.offset)],null),this.G))};$CLJS.g.P=function(a,b){return new qw(this.dc,this.cc,this.kc,this.offset,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.zd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.eb($CLJS.pb,this,b)};
var Hba=new $CLJS.h(null,3,[2,"#b",8,"#o",16,"#x"],null),Kw=new $CLJS.P(null,20,5,$CLJS.Q,"zero one two three four five six seven eight nine ten eleven twelve thirteen fourteen fifteen sixteen seventeen eighteen nineteen".split(" "),null),ly=new $CLJS.P(null,20,5,$CLJS.Q,"zeroth first second third fourth fifth sixth seventh eighth ninth tenth eleventh twelfth thirteenth fourteenth fifteenth sixteenth seventeenth eighteenth nineteenth".split(" "),null),Lw=new $CLJS.P(null,10,5,$CLJS.Q,"  twenty thirty forty fifty sixty seventy eighty ninety".split(" "),
null),dda=new $CLJS.P(null,10,5,$CLJS.Q,"  twentieth thirtieth fortieth fiftieth sixtieth seventieth eightieth ninetieth".split(" "),null),Nw=new $CLJS.P(null,22,5,$CLJS.Q," thousand million billion trillion quadrillion quintillion sextillion septillion octillion nonillion decillion undecillion duodecillion tredecillion quattuordecillion quindecillion sexdecillion septendecillion octodecillion novemdecillion vigintillion".split(" "),null),eda=new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.P(null,9,5,$CLJS.Q,
"I II III IIII V VI VII VIII VIIII".split(" "),null),new $CLJS.P(null,9,5,$CLJS.Q,"X XX XXX XXXX L LX LXX LXXX LXXXX".split(" "),null),new $CLJS.P(null,9,5,$CLJS.Q,"C CC CCC CCCC D DC DCC DCCC DCCCC".split(" "),null),new $CLJS.P(null,3,5,$CLJS.Q,["M","MM","MMM"],null)],null),fda=new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.P(null,9,5,$CLJS.Q,"I II III IV V VI VII VIII IX".split(" "),null),new $CLJS.P(null,9,5,$CLJS.Q,"X XX XXX XL L LX LXX LXXX XC".split(" "),null),new $CLJS.P(null,9,5,$CLJS.Q,"C CC CCC CD D DC DCC DCCC CM".split(" "),
null),new $CLJS.P(null,3,5,$CLJS.Q,["M","MM","MMM"],null)],null),Jba=new $CLJS.h(null,5,[8,"Backspace",9,"Tab",10,"Newline",13,"Return",32,"Space"],null);qx.prototype.P=function(a,b){return new qx(this.Ca,b)};qx.prototype.O=function(){return this.di};qx.prototype.rc=function(){return uu(this.Ca)};
qx.prototype.Mc=function(a,b){a=$CLJS.ab(b);if($CLJS.n($CLJS.E.g?$CLJS.E.g(String,a):$CLJS.E.call(null,String,a)))return $CLJS.ac(this.Ca,b.toLowerCase());if($CLJS.n($CLJS.E.g?$CLJS.E.g(Number,a):$CLJS.E.call(null,Number,a)))return $CLJS.ac(this.Ca,xu(b).toLowerCase());throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));};rx.prototype.P=function(a,b){return new rx(this.Ca,b)};rx.prototype.O=function(){return this.ei};rx.prototype.rc=function(){return uu(this.Ca)};
rx.prototype.Mc=function(a,b){a=$CLJS.ab(b);if($CLJS.n($CLJS.E.g?$CLJS.E.g(String,a):$CLJS.E.call(null,String,a)))return $CLJS.ac(this.Ca,b.toUpperCase());if($CLJS.n($CLJS.E.g?$CLJS.E.g(Number,a):$CLJS.E.call(null,Number,a)))return $CLJS.ac(this.Ca,xu(b).toUpperCase());throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));};sx.prototype.P=function(a,b){return new sx(this.Ca,this.Jd,b)};sx.prototype.O=function(){return this.fi};sx.prototype.rc=function(){return uu(this.Ca)};
sx.prototype.Mc=function(a,b){a=$CLJS.ab(b);if($CLJS.n($CLJS.E.g?$CLJS.E.g(String,a):$CLJS.E.call(null,String,a)))return $CLJS.ac(this.Ca,aca(b.toLowerCase(),$CLJS.q(this.Jd))),0<b.length?$CLJS.Ye(this.Jd,$CLJS.Aa($CLJS.F(b,$CLJS.D(b)-1))):null;if($CLJS.n($CLJS.E.g?$CLJS.E.g(Number,a):$CLJS.E.call(null,Number,a)))return b=xu(b),a=$CLJS.n($CLJS.q(this.Jd))?b.toUpperCase():b,$CLJS.ac(this.Ca,a),$CLJS.Ye(this.Jd,$CLJS.Aa(b));throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));};
tx.prototype.P=function(a,b){return new tx(this.Ca,this.vd,b)};tx.prototype.O=function(){return this.gi};tx.prototype.rc=function(){return uu(this.Ca)};
tx.prototype.Mc=function(a,b){a=$CLJS.ab(b);if($CLJS.n($CLJS.E.g?$CLJS.E.g(String,a):$CLJS.E.call(null,String,a)))return b=b.toLowerCase(),$CLJS.Va($CLJS.q(this.vd))?(a=RegExp("\\S","g").exec(b),a=$CLJS.n(a)?a.index:a,$CLJS.n(a)?($CLJS.ac(this.Ca,[b.substring(0,a),$CLJS.F(b,a).toUpperCase(),b.substring(a+1).toLowerCase()].join("")),$CLJS.Ye(this.vd,!0)):$CLJS.ac(this.Ca,b)):$CLJS.ac(this.Ca,b.toLowerCase());if($CLJS.n($CLJS.E.g?$CLJS.E.g(Number,a):$CLJS.E.call(null,Number,a)))return b=xu(b),a=$CLJS.Va($CLJS.q(this.vd)),
$CLJS.n(a?su(b):a)?($CLJS.Ye(this.vd,!0),$CLJS.ac(this.Ca,b.toUpperCase())):$CLJS.ac(this.Ca,b.toLowerCase());throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));};
var nca=$CLJS.Ig("ASDBOXRPCFEG$%\x26|~\nT*?()[;]{}\x3c\x3e^W_I".split(""),[new $CLJS.h(null,5,[vx,"A",Uw,new $CLJS.h(null,4,[Aw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),Bw,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null),zw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),Cw,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null)],null),ux,new $CLJS.ah(null,new $CLJS.h(null,3,[rw,null,sw,null,wx,null],null),null),zx,$CLJS.N,xx,function(){return function(a,b){return Ew($CLJS.Iw,a,b)}}],null),new $CLJS.h(null,
5,[vx,"S",Uw,new $CLJS.h(null,4,[Aw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),Bw,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null),zw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),Cw,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null)],null),ux,new $CLJS.ah(null,new $CLJS.h(null,3,[rw,null,sw,null,wx,null],null),null),zx,$CLJS.N,xx,function(){return function(a,b){return Ew($CLJS.Mh,a,b)}}],null),new $CLJS.h(null,5,[vx,"D",Uw,new $CLJS.h(null,4,[Aw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),Cw,new $CLJS.P(null,
2,5,$CLJS.Q,[" ",String],null),Hw,new $CLJS.P(null,2,5,$CLJS.Q,[",",String],null),Gw,new $CLJS.P(null,2,5,$CLJS.Q,[3,Number],null)],null),ux,new $CLJS.ah(null,new $CLJS.h(null,3,[rw,null,sw,null,wx,null],null),null),zx,$CLJS.N,xx,function(){return function(a,b){return Jw(10,a,b)}}],null),new $CLJS.h(null,5,[vx,"B",Uw,new $CLJS.h(null,4,[Aw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),Cw,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null),Hw,new $CLJS.P(null,2,5,$CLJS.Q,[",",String],null),Gw,new $CLJS.P(null,
2,5,$CLJS.Q,[3,Number],null)],null),ux,new $CLJS.ah(null,new $CLJS.h(null,3,[rw,null,sw,null,wx,null],null),null),zx,$CLJS.N,xx,function(){return function(a,b){return Jw(2,a,b)}}],null),new $CLJS.h(null,5,[vx,"O",Uw,new $CLJS.h(null,4,[Aw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),Cw,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null),Hw,new $CLJS.P(null,2,5,$CLJS.Q,[",",String],null),Gw,new $CLJS.P(null,2,5,$CLJS.Q,[3,Number],null)],null),ux,new $CLJS.ah(null,new $CLJS.h(null,3,[rw,null,sw,null,wx,
null],null),null),zx,$CLJS.N,xx,function(){return function(a,b){return Jw(8,a,b)}}],null),new $CLJS.h(null,5,[vx,"X",Uw,new $CLJS.h(null,4,[Aw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),Cw,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null),Hw,new $CLJS.P(null,2,5,$CLJS.Q,[",",String],null),Gw,new $CLJS.P(null,2,5,$CLJS.Q,[3,Number],null)],null),ux,new $CLJS.ah(null,new $CLJS.h(null,3,[rw,null,sw,null,wx,null],null),null),zx,$CLJS.N,xx,function(){return function(a,b){return Jw(16,a,b)}}],null),new $CLJS.h(null,
5,[vx,"R",Uw,new $CLJS.h(null,5,[Pu,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),Aw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),Cw,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null),Hw,new $CLJS.P(null,2,5,$CLJS.Q,[",",String],null),Gw,new $CLJS.P(null,2,5,$CLJS.Q,[3,Number],null)],null),ux,new $CLJS.ah(null,new $CLJS.h(null,3,[rw,null,sw,null,wx,null],null),null),zx,$CLJS.N,xx,function(a){return $CLJS.n($CLJS.A(Pu.h(a)))?function(b,c){return Jw(Pu.h(b),b,c)}:$CLJS.n(function(){var b=sw.h(a);return $CLJS.n(b)?
rw.h(a):b}())?function(b,c){return Pw(eda,c)}:$CLJS.n(sw.h(a))?function(b,c){return Pw(fda,c)}:$CLJS.n(rw.h(a))?function(b,c){b=mw(c);c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);if($CLJS.E.g(0,c))Dw.l($CLJS.H(["zeroth"]));else{var d=Fw(1E3,0>c?-c:c);if($CLJS.D(d)<=$CLJS.D(Nw)){var e=$CLJS.hf.g(Mw,$CLJS.Du(1,d));e=Ow(e,1);var f=$CLJS.id(d);d=$CLJS.Vd(f,100);f=yu(f,100);var k=0<d?[$CLJS.p.h($CLJS.F(Kw,d))," hundred"].join(""):null,l=$CLJS.p,m=l.h;if(0<f)if(20>f)var t=$CLJS.F(ly,f);else{t=$CLJS.Vd(f,10);
var u=yu(f,10);t=0<t&&!(0<u)?$CLJS.F(dda,t):[$CLJS.p.h(0<t?$CLJS.F(Lw,t):null),0<t&&0<u?"-":null,$CLJS.p.h(0<u?$CLJS.F(ly,u):null)].join("")}else t=0<d?"th":null;d=[k,0<d&&0<f?" ":null,m.call(l,t)].join("");Dw.l($CLJS.H([[0>c?"minus ":null,$CLJS.td(e)||$CLJS.td(d)?$CLJS.td(e)?d:[e,"th"].join(""):[e,", ",d].join("")].join("")]))}else Jw(10,new $CLJS.h(null,5,[Aw,0,Cw," ",Hw,",",Gw,3,rw,!0],null),ew(new $CLJS.P(null,1,5,$CLJS.Q,[c],null))),e=yu(c,100),c=11<e||19>e,e=yu(e,10),Dw.l($CLJS.H([1===e&&c?
"st":2===e&&c?"nd":3===e&&c?"rd":"th"]))}return b}:function(b,c){c=mw(c);b=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);if($CLJS.E.g(0,b))Dw.l($CLJS.H(["zero"]));else{var d=Fw(1E3,0>b?-b:b);$CLJS.D(d)<=$CLJS.D(Nw)?(d=$CLJS.hf.g(Mw,d),d=Ow(d,0),Dw.l($CLJS.H([[0>b?"minus ":null,d].join("")]))):Jw(10,new $CLJS.h(null,5,[Aw,0,Cw," ",Hw,",",Gw,3,rw,!0],null),ew(new $CLJS.P(null,1,5,$CLJS.Q,[b],null)))}return c}}],null),new $CLJS.h(null,5,[vx,"P",Uw,$CLJS.N,ux,new $CLJS.ah(null,new $CLJS.h(null,3,[rw,null,sw,
null,wx,null],null),null),zx,$CLJS.N,xx,function(){return function(a,b){b=$CLJS.n(rw.h(a))?ow(b,-1):b;a=$CLJS.n(sw.h(a))?new $CLJS.P(null,2,5,$CLJS.Q,["y","ies"],null):new $CLJS.P(null,2,5,$CLJS.Q,["","s"],null);var c=mw(b);b=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);Dw.l($CLJS.H([$CLJS.E.g(b,1)?$CLJS.A(a):$CLJS.hd(a)]));return c}}],null),new $CLJS.h(null,5,[vx,"C",Uw,new $CLJS.h(null,1,[Qw,new $CLJS.P(null,2,5,$CLJS.Q,[null,String],null)],null),ux,new $CLJS.ah(null,new $CLJS.h(null,3,[rw,null,sw,null,
wx,null],null),null),zx,$CLJS.N,xx,function(a){return $CLJS.n(rw.h(a))?Kba:$CLJS.n(sw.h(a))?Lba:Mba}],null),new $CLJS.h(null,5,[vx,"F",Uw,new $CLJS.h(null,5,[ax,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),bx,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),$CLJS.cx,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),dx,new $CLJS.P(null,2,5,$CLJS.Q,[null,String],null),Cw,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null)],null),ux,new $CLJS.ah(null,new $CLJS.h(null,1,[sw,null],null),null),zx,$CLJS.N,xx,
function(){return ex}],null),new $CLJS.h(null,5,[vx,"E",Uw,new $CLJS.h(null,7,[ax,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),bx,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),fx,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),$CLJS.cx,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null),dx,new $CLJS.P(null,2,5,$CLJS.Q,[null,String],null),Cw,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null),gx,new $CLJS.P(null,2,5,$CLJS.Q,[null,String],null)],null),ux,new $CLJS.ah(null,new $CLJS.h(null,1,[sw,null],
null),null),zx,$CLJS.N,xx,function(){return hx}],null),new $CLJS.h(null,5,[vx,"G",Uw,new $CLJS.h(null,7,[ax,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),bx,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),fx,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),$CLJS.cx,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null),dx,new $CLJS.P(null,2,5,$CLJS.Q,[null,String],null),Cw,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null),gx,new $CLJS.P(null,2,5,$CLJS.Q,[null,String],null)],null),ux,new $CLJS.ah(null,new $CLJS.h(null,
1,[sw,null],null),null),zx,$CLJS.N,xx,function(){return Oba}],null),new $CLJS.h(null,5,[vx,"$",Uw,new $CLJS.h(null,4,[bx,new $CLJS.P(null,2,5,$CLJS.Q,[2,Number],null),$CLJS.rk,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null),ax,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),Cw,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null)],null),ux,new $CLJS.ah(null,new $CLJS.h(null,3,[rw,null,sw,null,wx,null],null),null),zx,$CLJS.N,xx,function(){return Pba}],null),new $CLJS.h(null,5,[vx,"%",Uw,new $CLJS.h(null,1,[$CLJS.Zx,
new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null)],null),ux,$CLJS.bh,zx,$CLJS.N,xx,function(){return function(a,b){a=$CLJS.Zx.h(a);for(var c=0;;)if(c<a)Gu(),c+=1;else break;return b}}],null),new $CLJS.h(null,5,[vx,"\x26",Uw,new $CLJS.h(null,1,[$CLJS.Zx,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null)],null),ux,new $CLJS.ah(null,new $CLJS.h(null,1,[fy,null],null),null),zx,$CLJS.N,xx,function(){return function(a,b){a=$CLJS.Zx.h(a);0<a&&((null!=$CLJS.Fu?$CLJS.Fu.C&32768||$CLJS.Bc===$CLJS.Fu.ag||($CLJS.Fu.C?
0:$CLJS.$a($CLJS.tu,$CLJS.Fu)):$CLJS.$a($CLJS.tu,$CLJS.Fu))?$CLJS.E.g(0,$CLJS.Mu(Pu.h($CLJS.q($CLJS.q($CLJS.Fu))),$CLJS.Ou))||Gu():Gu());--a;for(var c=0;;)if(c<a)Gu(),c+=1;else break;return b}}],null),new $CLJS.h(null,5,[vx,"|",Uw,new $CLJS.h(null,1,[$CLJS.Zx,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null)],null),ux,$CLJS.bh,zx,$CLJS.N,xx,function(){return function(a,b){a=$CLJS.Zx.h(a);for(var c=0;;)if(c<a)Dw.l($CLJS.H(["\f"])),c+=1;else break;return b}}],null),new $CLJS.h(null,5,[vx,"~",Uw,new $CLJS.h(null,
1,[$CLJS.rk,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null)],null),ux,$CLJS.bh,zx,$CLJS.N,xx,function(){return function(a,b){a=$CLJS.rk.h(a);Dw.l($CLJS.H([$CLJS.Se.g($CLJS.p,$CLJS.df(a,"~"))]));return b}}],null),new $CLJS.h(null,5,[vx,"\n",Uw,$CLJS.N,ux,new $CLJS.ah(null,new $CLJS.h(null,2,[rw,null,sw,null],null),null),zx,$CLJS.N,xx,function(){return function(a,b){$CLJS.n(sw.h(a))&&Gu();return b}}],null),new $CLJS.h(null,5,[vx,"T",Uw,new $CLJS.h(null,2,[Wx,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null),
Bw,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null)],null),ux,new $CLJS.ah(null,new $CLJS.h(null,2,[sw,null,fy,null],null),null),zx,$CLJS.N,xx,function(a){return $CLJS.n(sw.h(a))?function(b,c){var d=Wx.h(b);b=Bw.h(b);var e=d+$CLJS.Mu(Pu.h($CLJS.q($CLJS.q($CLJS.Fu))),$CLJS.Ou);e=0<b?yu(e,b):0;d+=$CLJS.E.g(0,e)?0:b-e;Dw.l($CLJS.H([$CLJS.Se.g($CLJS.p,$CLJS.df(d," "))]));return c}:function(b,c){var d=Wx.h(b);b=Bw.h(b);var e=$CLJS.Mu(Pu.h($CLJS.q($CLJS.q($CLJS.Fu))),$CLJS.Ou);d=e<d?d-e:$CLJS.E.g(b,0)?0:b-
yu(e-d,b);Dw.l($CLJS.H([$CLJS.Se.g($CLJS.p,$CLJS.df(d," "))]));return c}}],null),new $CLJS.h(null,5,[vx,"*",Uw,new $CLJS.h(null,1,[$CLJS.rk,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null)],null),ux,new $CLJS.ah(null,new $CLJS.h(null,2,[rw,null,sw,null],null),null),zx,$CLJS.N,xx,function(){return function(a,b){var c=$CLJS.rk.h(a);return $CLJS.n(sw.h(a))?pw(b,c):ow(b,$CLJS.n(rw.h(a))?-c:c)}}],null),new $CLJS.h(null,5,[vx,"?",Uw,$CLJS.N,ux,new $CLJS.ah(null,new $CLJS.h(null,1,[sw,null],null),null),zx,
$CLJS.N,xx,function(a){return $CLJS.n(sw.h(a))?function(b,c){var d=nw(c);c=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);return Xw(c,d,Vw.h(b))}:function(b,c){var d=nw(c);c=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);var e=mw(d);d=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);d=ew(d);Xw(c,d,Vw.h(b));return e}}],null),new $CLJS.h(null,5,[vx,"(",Uw,$CLJS.N,ux,new $CLJS.ah(null,new $CLJS.h(null,3,[rw,null,sw,null,wx,null],null),null),zx,new $CLJS.h(null,3,[$CLJS.Cx,")",Gx,null,$CLJS.Nt,null],null),xx,function(a){var b=$CLJS.n(function(){var c=
sw.h(a);return $CLJS.n(c)?rw.h(a):c}())?$ba:$CLJS.n(rw.h(a))?bca:$CLJS.n(sw.h(a))?cca:Zba;return function(c,d){a:{var e=$CLJS.A($CLJS.jx.h(c)),f=$CLJS.Fu;$CLJS.Fu=b.h?b.h($CLJS.Fu):b.call(null,$CLJS.Fu);try{var k=Xw(e,d,Vw.h(c));break a}finally{$CLJS.Fu=f}k=void 0}return k}}],null),new $CLJS.h(null,5,[vx,")",Uw,$CLJS.N,ux,$CLJS.bh,zx,$CLJS.N,xx,function(){return null}],null),new $CLJS.h(null,5,[vx,"[",Uw,new $CLJS.h(null,1,[ix,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null)],null),ux,new $CLJS.ah(null,
new $CLJS.h(null,2,[rw,null,sw,null],null),null),zx,new $CLJS.h(null,3,[$CLJS.Cx,"]",Gx,!0,$CLJS.Nt,$CLJS.cy],null),xx,function(a){return $CLJS.n(rw.h(a))?Rba:$CLJS.n(sw.h(a))?Sba:Qba}],null),new $CLJS.h(null,5,[vx,";",Uw,new $CLJS.h(null,2,[ox,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),px,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null)],null),ux,new $CLJS.ah(null,new $CLJS.h(null,1,[rw,null],null),null),zx,new $CLJS.h(null,1,[$CLJS.Pt,!0],null),xx,function(){return null}],null),new $CLJS.h(null,
5,[vx,"]",Uw,$CLJS.N,ux,$CLJS.bh,zx,$CLJS.N,xx,function(){return null}],null),new $CLJS.h(null,5,[vx,"{",Uw,new $CLJS.h(null,1,[kx,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null)],null),ux,new $CLJS.ah(null,new $CLJS.h(null,3,[rw,null,sw,null,wx,null],null),null),zx,new $CLJS.h(null,2,[$CLJS.Cx,"}",Gx,!1],null),xx,function(a){var b=sw.h(a);b=$CLJS.n(b)?rw.h(a):b;return $CLJS.n(b)?Wba:$CLJS.n(rw.h(a))?Uba:$CLJS.n(sw.h(a))?Vba:Tba}],null),new $CLJS.h(null,5,[vx,"}",Uw,$CLJS.N,ux,new $CLJS.ah(null,
new $CLJS.h(null,1,[rw,null],null),null),zx,$CLJS.N,xx,function(){return null}],null),new $CLJS.h(null,5,[vx,"\x3c",Uw,new $CLJS.h(null,4,[Aw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),Bw,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null),zw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),Cw,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null)],null),ux,new $CLJS.ah(null,new $CLJS.h(null,4,[rw,null,sw,null,wx,null,fy,null],null),null),zx,new $CLJS.h(null,3,[$CLJS.Cx,"\x3e",Gx,!0,$CLJS.Nt,Fx],null),xx,function(){return Yba}],
null),new $CLJS.h(null,5,[vx,"\x3e",Uw,$CLJS.N,ux,new $CLJS.ah(null,new $CLJS.h(null,1,[rw,null],null),null),zx,$CLJS.N,xx,function(){return null}],null),new $CLJS.h(null,5,[vx,"^",Uw,new $CLJS.h(null,3,[$x,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),by,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),ay,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null)],null),ux,new $CLJS.ah(null,new $CLJS.h(null,1,[rw,null],null),null),zx,$CLJS.N,xx,function(){return function(a,b){var c=$x.h(a),d=by.h(a),e=
ay.h(a),f=$CLJS.n(rw.h(a))?Sw:Rw;return $CLJS.n($CLJS.n(c)?$CLJS.n(d)?e:d:c)?c<=d&&d<=e?new $CLJS.P(null,2,5,$CLJS.Q,[f,b],null):b:$CLJS.n($CLJS.n(c)?d:c)?$CLJS.E.g(c,d)?new $CLJS.P(null,2,5,$CLJS.Q,[f,b],null):b:$CLJS.n(c)?$CLJS.E.g(c,0)?new $CLJS.P(null,2,5,$CLJS.Q,[f,b],null):b:($CLJS.n(rw.h(a))?$CLJS.td($CLJS.Oi.h(Vw.h(a))):$CLJS.td($CLJS.Oi.h(b)))?new $CLJS.P(null,2,5,$CLJS.Q,[f,b],null):b}}],null),new $CLJS.h(null,5,[vx,"W",Uw,$CLJS.N,ux,new $CLJS.ah(null,new $CLJS.h(null,4,[rw,null,sw,null,
wx,null,fy,null],null),null),zx,$CLJS.N,xx,function(a){if($CLJS.n(function(){var c=sw.h(a);return $CLJS.n(c)?c:rw.h(a)}())){var b=$CLJS.gf.g($CLJS.n(sw.h(a))?new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Qt,null,$CLJS.Xx,null],null):$CLJS.Cf,$CLJS.n(rw.h(a))?new $CLJS.P(null,2,5,$CLJS.Q,[fy,!0],null):$CLJS.Cf);return function(c,d){d=mw(d);c=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);return $CLJS.n($CLJS.Se.j(ky,c,b))?new $CLJS.P(null,2,5,$CLJS.Q,[Rw,d],null):d}}return function(c,d){d=mw(d);c=$CLJS.I(d,0,null);d=
$CLJS.I(d,1,null);return $CLJS.n($CLJS.Rv(c))?new $CLJS.P(null,2,5,$CLJS.Q,[Rw,d],null):d}}],null),new $CLJS.h(null,5,[vx,"_",Uw,$CLJS.N,ux,new $CLJS.ah(null,new $CLJS.h(null,3,[rw,null,sw,null,wx,null],null),null),zx,$CLJS.N,xx,function(){return eca}],null),new $CLJS.h(null,5,[vx,"I",Uw,new $CLJS.h(null,1,[$CLJS.rk,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null)],null),ux,new $CLJS.ah(null,new $CLJS.h(null,1,[rw,null],null),null),zx,$CLJS.N,xx,function(){return dca}],null)]),fca=/^([vV]|#|('.)|([+-]?\d+)|(?=,))/,
lca=new $CLJS.ah(null,new $CLJS.h(null,2,[uw,null,tw,null],null),null),ica=new $CLJS.h(null,2,[":",rw,"@",sw],null),fw=function fw(a){for(;;){if($CLJS.td(a))return!1;var c=fy.h(ux.h(Ax.h($CLJS.A(a))));$CLJS.n(c)||(c=$CLJS.Re(fw,$CLJS.A($CLJS.jx.h(Uw.h($CLJS.A(a))))),c=$CLJS.n(c)?c:$CLJS.Re(fw,$CLJS.A($CLJS.Nt.h(Uw.h($CLJS.A(a))))));if($CLJS.n(c))return!0;a=$CLJS.B(a)}},Qx=$CLJS.Ph(dw),Hx=new $CLJS.h(null,6,[$CLJS.Oj,"'",$CLJS.Sj,"#'",$CLJS.lba,"@",$CLJS.mba,"~",Nca,"@",Lca,"~"],null);
(function(){var a=Qx("~\x3c[~;~@{~w~^, ~:_~}~;]~:\x3e");return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.w(f,0,null)}return c.call(this,e)}function c(d){d=ew(d);return gw(a,d)}b.A=0;b.B=function(d){d=$CLJS.y(d);return c(d)};b.l=c;return b}()})();
var my=function(){var a=Qx("~\x3c#{~;~@{~w~^ ~:_~}~;}~:\x3e");return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.w(f,0,null)}return c.call(this,e)}function c(d){d=ew(d);return gw(a,d)}b.A=0;b.B=function(d){d=$CLJS.y(d);return c(d)};b.l=c;return b}()}(),ny=new $CLJS.h(null,2,["core$future_call","Future","core$promise","Promise"],null),gda=function(){var a=Qx("~\x3c\x3c-(~;~@{~w~^ ~_~}~;)-\x3c~:\x3e");
return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.w(f,0,null)}return c.call(this,e)}function c(d){d=ew(d);return gw(a,d)}b.A=0;b.B=function(d){d=$CLJS.y(d);return c(d)};b.l=c;return b}()}(),oy,hda=$CLJS.Xe($CLJS.N),ida=$CLJS.Xe($CLJS.N),jda=$CLJS.Xe($CLJS.N),kda=$CLJS.Xe($CLJS.N),lda=$CLJS.J.j($CLJS.N,$CLJS.gj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));
oy=new $CLJS.di($CLJS.Fh.g("cljs.pprint","simple-dispatch"),Ox,lda,hda,ida,jda,kda);zu(oy,$CLJS.Bj,function(a){if($CLJS.Va(Ix(a)))if($CLJS.n(Uv()))$CLJS.ac($CLJS.Fu,"#");else{var b=Tv,c=Ov;Tv+=1;Ov=0;try{Iv("(",")");for(var d=0,e=$CLJS.y(a);;){if($CLJS.Va($CLJS.Ma)||d<$CLJS.Ma){if(e&&($CLJS.Rv($CLJS.A(e)),$CLJS.B(e))){$CLJS.ac($CLJS.Fu," ");Zv(Yv);a=d+1;var f=$CLJS.B(e);d=a;e=f;continue}}else $CLJS.ac($CLJS.Fu,"...");break}Mv()}finally{Ov=c,Tv=b}}return null});zu(oy,$CLJS.Ij,Jx);zu(oy,$CLJS.gk,Kx);
zu(oy,$CLJS.Zi,my);zu(oy,null,function(){return $CLJS.ac($CLJS.Fu,$CLJS.Mh.l($CLJS.H([null])))});zu(oy,$CLJS.ci,Lx);Qv=oy;
var py=function(){var a=Qx("~:\x3c~w~^ ~@_~w~^ ~_~@{~w~^ ~_~}~:\x3e");return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.w(f,0,null)}return c.call(this,e)}function c(d){d=ew(d);return gw(a,d)}b.A=0;b.B=function(d){d=$CLJS.y(d);return c(d)};b.l=c;return b}()}(),qy=function(){var a=Qx("~:\x3c~1I~w~^ ~@_~w~@{ ~_~w~}~:\x3e");return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=
Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.w(f,0,null)}return c.call(this,e)}function c(d){d=ew(d);return gw(a,d)}b.A=0;b.B=function(d){d=$CLJS.y(d);return c(d)};b.l=c;return b}()}(),ry=$CLJS.N,mda=function(a){return $CLJS.eg.g($CLJS.N,$CLJS.jf($CLJS.Td,$CLJS.H([function(){return function d(c){return new $CLJS.ne(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.qe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m);t=new $CLJS.P(null,
2,5,$CLJS.Q,[t,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fh.h($CLJS.gh($CLJS.A(t))),$CLJS.hd(t)],null)],null);l.add(t);m+=1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.mc(e))):$CLJS.te($CLJS.ve(l),null)}l=$CLJS.A(e);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[l,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fh.h($CLJS.gh($CLJS.A(l))),$CLJS.hd(l)],null)],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}()])))}(function(a){return $CLJS.eg.g($CLJS.N,$CLJS.hf.g(function(b){var c=$CLJS.I(b,0,null),d=$CLJS.I(b,
1,null);var e=$CLJS.ie(c);e=$CLJS.n(e)?e:$CLJS.Id(new $CLJS.ah(null,new $CLJS.h(null,24,[$CLJS.$i,"null",$CLJS.pj,"null",$CLJS.sk,"null",$CLJS.vk,"null",$CLJS.kj,"null",$CLJS.Kj,"null",$CLJS.Gj,"null",$CLJS.Lj,"null",$CLJS.qi,"null",$CLJS.Vj,"null",$CLJS.Aj,"null",$CLJS.sj,"null",$CLJS.ck,"null",$CLJS.uk,"null",$CLJS.Ri,"null",$CLJS.Mi,"null",$CLJS.ij,"null",$CLJS.uj,"null",$CLJS.aj,"null",$CLJS.Sj,"null",$CLJS.Oj,"null",$CLJS.jk,"null",$CLJS.Ii,"null",$CLJS.fk,"null"],null),null),c);return $CLJS.Va(e)?
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fh.g("clojure.core",$CLJS.gh(c)),d],null):b},a))}($CLJS.Ig([$CLJS.aj,$CLJS.Mi,Dca,$CLJS.Vj,Ica,Aca,Cca,Jca,Gca,Fca,Hca,Vca,Pca,$CLJS.fk,Oca,Tca,Rca,ada,yca,$CLJS.sj,Wca,Xca,$ca,$CLJS.Pj,Mca,bda,zca,Bca,Eca,Yca],[py,function(a){var b=$CLJS.hd(a),c=$CLJS.A($CLJS.Lc($CLJS.Lc(a)));if($CLJS.zd(b)){a=ry;ry=$CLJS.E.g(1,$CLJS.D(b))?$CLJS.Ee([$CLJS.A(b),"%"]):$CLJS.eg.g($CLJS.N,$CLJS.hf.j(function(d,e){return new $CLJS.P(null,2,5,$CLJS.Q,[d,["%",$CLJS.p.h(e)].join("")],
null)},b,$CLJS.Bu(1,$CLJS.D(b)+1)));try{return function(){var d=Qx("~\x3c#(~;~@{~w~^ ~_~}~;)~:\x3e");return function(){function e(k){var l=null;if(0<arguments.length){l=0;for(var m=Array(arguments.length-0);l<m.length;)m[l]=arguments[l+0],++l;l=new $CLJS.w(m,0,null)}return f.call(this,l)}function f(k){k=ew(k);return gw(d,k)}e.A=0;e.B=function(k){k=$CLJS.y(k);return f(k)};e.l=f;return e}()}()(c)}finally{ry=a}}else return Rx(a)},Tx,qy,function(a){if(3<$CLJS.D(a)){if($CLJS.n(Uv()))$CLJS.ac($CLJS.Fu,
"#");else{var b=Tv,c=Ov;Tv+=1;Ov=0;try{Iv("(",")");cw($v,1);$CLJS.Se.g(function(){var l=Qx("~w ~@_~w ~@_~w ~_");return function(){function m(u){var v=null;if(0<arguments.length){v=0;for(var x=Array(arguments.length-0);v<x.length;)x[v]=arguments[v+0],++v;v=new $CLJS.w(x,0,null)}return t.call(this,v)}function t(u){u=ew(u);return gw(l,u)}m.A=0;m.B=function(u){u=$CLJS.y(u);return t(u)};m.l=t;return m}()}(),a);for(var d=0,e=$CLJS.y($CLJS.bf(3,a));;){if($CLJS.Va($CLJS.Ma)||d<$CLJS.Ma){if(e){if($CLJS.n(Uv()))$CLJS.ac($CLJS.Fu,
"#");else{a=Tv;var f=Ov;Tv+=1;Ov=0;try{Iv(null,null),$CLJS.Rv($CLJS.A(e)),$CLJS.B(e)&&($CLJS.ac($CLJS.Fu," "),Zv(Wv),$CLJS.Rv($CLJS.hd(e))),Mv()}finally{Ov=f,Tv=a}}if($CLJS.B($CLJS.Lc(e))){$CLJS.ac($CLJS.Fu," ");Zv(Yv);a=d+1;var k=$CLJS.B($CLJS.Lc(e));d=a;e=k;continue}}}else $CLJS.ac($CLJS.Fu,"...");break}Mv()}finally{Ov=c,Tv=b}}return null}return Rx(a)},py,Sx,Sx,Tx,py,Tx,qy,qy,py,qy,Tx,Tx,py,Tx,function(a){if($CLJS.B(a)){var b=$CLJS.y(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);var d=
"string"===typeof $CLJS.A(c)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.A(c),$CLJS.B(c)],null):new $CLJS.P(null,2,5,$CLJS.Q,[null,c],null);c=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);d=$CLJS.xd($CLJS.A(d))?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.A(d),$CLJS.B(d)],null):new $CLJS.P(null,2,5,$CLJS.Q,[null,d],null);var e=$CLJS.I(d,0,null),f=$CLJS.I(d,1,null);if($CLJS.n(Uv()))$CLJS.ac($CLJS.Fu,"#");else{d=Tv;var k=Ov;Tv+=1;Ov=0;try{Iv("(",")");(function(){var m=Qx("~w ~1I~@_~w");return function(){function t(v){var x=
null;if(0<arguments.length){x=0;for(var z=Array(arguments.length-0);x<z.length;)z[x]=arguments[x+0],++x;x=new $CLJS.w(z,0,null)}return u.call(this,x)}function u(v){v=ew(v);return gw(m,v)}t.A=0;t.B=function(v){v=$CLJS.y(v);return u(v)};t.l=u;return t}()})()(a,b);$CLJS.n($CLJS.n(c)?c:$CLJS.n(e)?e:$CLJS.y(f))&&function(){var m=Qx("~@:_");return function(){function t(v){var x=null;if(0<arguments.length){x=0;for(var z=Array(arguments.length-0);x<z.length;)z[x]=arguments[x+0],++x;x=new $CLJS.w(z,0,null)}return u.call(this,
x)}function u(v){v=ew(v);return gw(m,v)}t.A=0;t.B=function(v){v=$CLJS.y(v);return u(v)};t.l=u;return t}()}()();$CLJS.n(c)&&hw(!0,'"~a"~:[~;~:@_~]',$CLJS.H([c,$CLJS.n(e)?e:$CLJS.y(f)]));$CLJS.n(e)&&function(){var m=Qx("~w~:[~;~:@_~]");return function(){function t(v){var x=null;if(0<arguments.length){x=0;for(var z=Array(arguments.length-0);x<z.length;)z[x]=arguments[x+0],++x;x=new $CLJS.w(z,0,null)}return u.call(this,x)}function u(v){v=ew(v);return gw(m,v)}t.A=0;t.B=function(v){v=$CLJS.y(v);return u(v)};
t.l=u;return t}()}()(e,$CLJS.y(f));for(a=f;;){uca($CLJS.A(a));var l=$CLJS.B(a);if(l)b=l,Zv(Yv),a=b;else break}Mv()}finally{Ov=k,Tv=d}}return null}return $CLJS.Rv(a)},Tx,function(a){if($CLJS.n(Uv()))$CLJS.ac($CLJS.Fu,"#");else{var b=Tv,c=Ov;Tv+=1;Ov=0;try{Iv("(",")");cw($v,1);$CLJS.Rv($CLJS.A(a));if($CLJS.B(a)){$CLJS.ac($CLJS.Fu," ");Zv(Yv);for(var d=0,e=$CLJS.B(a);;){if($CLJS.Va($CLJS.Ma)||d<$CLJS.Ma){if(e){if($CLJS.n(Uv()))$CLJS.ac($CLJS.Fu,"#");else{a=Tv;var f=Ov;Tv+=1;Ov=0;try{Iv(null,null),$CLJS.Rv($CLJS.A(e)),
$CLJS.B(e)&&($CLJS.ac($CLJS.Fu," "),Zv(Wv),$CLJS.Rv($CLJS.hd(e))),Mv()}finally{Ov=f,Tv=a}}if($CLJS.B($CLJS.Lc(e))){$CLJS.ac($CLJS.Fu," ");Zv(Yv);a=d+1;var k=$CLJS.B($CLJS.Lc(e));d=a;e=k;continue}}}else $CLJS.ac($CLJS.Fu,"...");break}}Mv()}finally{Ov=c,Tv=b}}return null},Tx,Sx,Sx,py,py,Tx,Tx,py]))),sy,nda=$CLJS.Xe($CLJS.N),oda=$CLJS.Xe($CLJS.N),pda=$CLJS.Xe($CLJS.N),qda=$CLJS.Xe($CLJS.N),rda=$CLJS.J.j($CLJS.N,$CLJS.gj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));
sy=new $CLJS.di($CLJS.Fh.g("cljs.pprint","code-dispatch"),Ox,rda,nda,oda,pda,qda);zu(sy,$CLJS.Bj,function(a){if($CLJS.Va(Ix(a))){var b=mda.call(null,$CLJS.A(a));return $CLJS.n(b)?b.h?b.h(a):b.call(null,a):Rx(a)}return null});zu(sy,$CLJS.Gi,function(a){var b=a.h?a.h(ry):a.call(null,ry);return $CLJS.n(b)?Dw.l($CLJS.H([b])):$CLJS.n(jy)?Dw.l($CLJS.H([$CLJS.gh(a)])):Eu.call(null,a)});zu(sy,$CLJS.Ij,Jx);zu(sy,$CLJS.gk,Kx);zu(sy,$CLJS.Zi,my);zu(sy,Mx,gda);
zu(sy,Nx,function(a){var b=$CLJS.p,c=b.h,d=$CLJS.ab(a).name;var e=$CLJS.oh(/^[^$]+\$[^$]+/,d);e=$CLJS.n(e)?ny.h?ny.h(e):ny.call(null,e):null;b=["#\x3c",c.call(b,$CLJS.n(e)?e:d),"@",$CLJS.p.h($CLJS.ya(a)),": "].join("");if($CLJS.n(Uv()))$CLJS.ac($CLJS.Fu,"#");else{c=Tv;d=Ov;Tv+=1;Ov=0;try{Iv(b,"\x3e");cw($v,-(b.length-2));Zv(Yv);var f=null!=a?a.I&1||$CLJS.Bc===a.jj?!0:a.I?!1:$CLJS.$a(vu,a):$CLJS.$a(vu,a);var k=f?!wu(a):f;$CLJS.Rv(k?Qca:$CLJS.q(a));Mv()}finally{Ov=d,Tv=c}}return null});zu(sy,null,Eu);
zu(sy,$CLJS.ci,Lx);Qv=oy;