var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./medley.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var IH,JH,LH,MH,Gfa,Hfa,Ifa,NH,KH;$CLJS.GH=function(a,b){return $CLJS.gc($CLJS.eb(function(c,d){var e=a.h?a.h(d):a.call(null,d);return $CLJS.hg.j(c,e,$CLJS.be.g($CLJS.J.j(c,e,$CLJS.Cf),d))},$CLJS.ec($CLJS.N),b))};$CLJS.HH=function(a,b){return $CLJS.eb(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.Sc(d):null},null,b)};
IH=function(a,b){$CLJS.I(a,0,null);$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);b=$CLJS.AC(b,$CLJS.aD)?$CLJS.rH:$CLJS.AC(b,$CLJS.$D)?$CLJS.oE:$CLJS.AC(b,$CLJS.AD)?$CLJS.pE:null;return $CLJS.n(b)?$CLJS.CF(b,a):!0};
JH=function(a){a=$CLJS.y(a);$CLJS.A(a);a=$CLJS.B(a);$CLJS.A(a);a=$CLJS.B(a);a=$CLJS.GH(function(d){return $CLJS.AC($CLJS.$E(d),$CLJS.lD)},a);var b=$CLJS.O(a);a=$CLJS.J.g(b,!1);b=$CLJS.J.g(b,!0);if($CLJS.Ok.g($CLJS.D(a),1))return"Temporal arithmetic expression must contain exactly one non-interval value";if(1>$CLJS.D(b))return"Temporal arithmetic expression must contain at least one :interval";var c=$CLJS.$E($CLJS.A(a));return $CLJS.Re(function(d){$CLJS.I(d,0,null);$CLJS.I(d,1,null);$CLJS.I(d,2,null);
var e=$CLJS.I(d,3,null);return $CLJS.n(IH(d,c))?null:["Cannot add a ",$CLJS.p.h(e)," interval to a ",$CLJS.p.h(c)," expression"].join("")},b)};
LH=function(a){return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Fs,new $CLJS.h(null,1,[$CLJS.yt,[$CLJS.p.h(a)," clause with a temporal expression and one or more :interval clauses"].join("")],null),new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ns,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tk,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.JE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,KH],null)],
null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,KH],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.At,function(b){b=$CLJS.O(b);b=$CLJS.J.g(b,$CLJS.Cj);return["Invalid ",$CLJS.p.h(a)," clause: ",$CLJS.p.h(JH(b))].join("")}],null),$CLJS.Te(JH)],null)],null)};
MH=function(a){return new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ns,new $CLJS.h(null,1,[$CLJS.yt,[$CLJS.p.h(a)," clause with numeric args"].join("")],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tk,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.JE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ls,new $CLJS.h(null,1,[$CLJS.Zn,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.vF],null)],null)],null)],null)};
Gfa=function(a){return $CLJS.Qd($CLJS.FF,$CLJS.hf.g(function(b){var c=$CLJS.$E(b),d=$CLJS.AC(c,$CLJS.lF);b=d?$CLJS.CF($CLJS.zG,b):d;return $CLJS.n(b)?$CLJS.Nj:c},a))};Hfa=function(a){a=$CLJS.HH(function(b){return!$CLJS.AC(b,$CLJS.lD)},$CLJS.hf.g($CLJS.$E,a));return $CLJS.AC(a,$CLJS.lF)?$CLJS.Uj:a};
Ifa=function(a,b){return $CLJS.n($CLJS.Re(function(c){return $CLJS.AC($CLJS.$E(c),$CLJS.lD)},b))?Hfa(b):$CLJS.E.g(a,$CLJS.rt)&&$CLJS.E.g($CLJS.D(b),2)&&($CLJS.Qe(function(c){return $CLJS.AC($CLJS.$E(c),$CLJS.aD)},b)||$CLJS.Qe(function(c){return $CLJS.AC($CLJS.$E(c),$CLJS.AD)},b))?$CLJS.lD:Gfa(b)};NH=new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.numbers","metabase.lib.schema.expression.arithmetic/args.numbers",-179216621);
$CLJS.OH=new $CLJS.M("lib.type-of","type-is-type-of-arithmetic-args","lib.type-of/type-is-type-of-arithmetic-args",-626871615);KH=new $CLJS.M("mbql.clause","interval","mbql.clause/interval",-990446354);$CLJS.X(new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.temporal","metabase.lib.schema.expression.arithmetic/args.temporal",1338604923),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.M(null,"intervals","intervals",2096054013),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ds,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,KH],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.yt,"Temporal arithmetic expression with valid interval units for the expression type"],null),function(a){var b=$CLJS.y(a);a=$CLJS.A(b);b=$CLJS.B(b);var c=$CLJS.$E(a);return $CLJS.Qe(function(d){return IH(d,c)},b)}],null)],null));
$CLJS.X(NH,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ls,new $CLJS.h(null,1,[$CLJS.Zn,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.vF],null)],null)],null));
var Jfa=new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ns,new $CLJS.h(null,1,[$CLJS.yt,":- clause taking the difference of two temporal expressions"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tk,$CLJS.rt],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.JE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null)],null)],
null);$CLJS.WG.g($CLJS.ds,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ys,LH($CLJS.ds),MH($CLJS.ds)],null));$CLJS.WG.g($CLJS.rt,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ys,LH($CLJS.rt),Jfa,MH($CLJS.rt)],null));$CLJS.MF($CLJS.es,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dt,NH],null)]));$CLJS.MF($CLJS.RG,$CLJS.H([$CLJS.rt,$CLJS.XD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dt,NH],null)]));
for(var PH=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ds,$CLJS.rt,$CLJS.es],null)),QH=null,RH=0,SH=0;;)if(SH<RH){var Kfa=QH.X(null,SH);$CLJS.DF(Kfa,$CLJS.OH);SH+=1}else{var TH=$CLJS.y(PH);if(TH){var UH=TH;if($CLJS.Ad(UH)){var VH=$CLJS.lc(UH),Lfa=$CLJS.mc(UH),Mfa=VH,Nfa=$CLJS.D(VH);PH=Lfa;QH=Mfa;RH=Nfa}else{var Ofa=$CLJS.A(UH);$CLJS.DF(Ofa,$CLJS.OH);PH=$CLJS.B(UH);QH=null;RH=0}SH=0}else break}
$CLJS.ZE.m(null,$CLJS.OH,function(a){var b=$CLJS.y(a);a=$CLJS.A(b);b=$CLJS.B(b);$CLJS.A(b);b=$CLJS.B(b);return Ifa(a,b)});$CLJS.KF($CLJS.WF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.vF],null)],null)]));$CLJS.DF($CLJS.WF,$CLJS.pF);
for(var WH=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TG,$CLJS.NF,$CLJS.iG],null)),XH=null,YH=0,ZH=0;;)if(ZH<YH){var Pfa=XH.X(null,ZH);$CLJS.KF(Pfa,$CLJS.H([$CLJS.rt,$CLJS.XD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.vF],null)],null)]));ZH+=1}else{var $H=$CLJS.y(WH);if($H){var aI=$H;if($CLJS.Ad(aI)){var bI=$CLJS.lc(aI),Qfa=$CLJS.mc(aI),Rfa=bI,Sfa=$CLJS.D(bI);WH=Qfa;XH=Rfa;YH=Sfa}else{var Tfa=$CLJS.A(aI);$CLJS.KF(Tfa,$CLJS.H([$CLJS.rt,$CLJS.XD,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.vF],null)],null)]));WH=$CLJS.B(aI);XH=null;YH=0}ZH=0}else break}
for(var cI=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RF,$CLJS.tG,$CLJS.AG],null)),dI=null,eI=0,fI=0;;)if(fI<eI){var Ufa=dI.X(null,fI);$CLJS.KF(Ufa,$CLJS.H([$CLJS.rt,$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.vF],null)],null)]));fI+=1}else{var gI=$CLJS.y(cI);if(gI){var hI=gI;if($CLJS.Ad(hI)){var iI=$CLJS.lc(hI),Vfa=$CLJS.mc(hI),Wfa=iI,Xfa=$CLJS.D(iI);cI=Vfa;dI=Wfa;eI=Xfa}else{var Yfa=$CLJS.A(hI);$CLJS.KF(Yfa,$CLJS.H([$CLJS.rt,$CLJS.vj,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.vF],null)],null)]));cI=$CLJS.B(hI);dI=null;eI=0}fI=0}else break}$CLJS.KF($CLJS.TF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.vF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.vF],null)],null)]));
$CLJS.ZE.m(null,$CLJS.TF,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.AC($CLJS.$E(b),$CLJS.vj)&&$CLJS.AC($CLJS.$E(a),$CLJS.vj)?$CLJS.vj:$CLJS.XD});