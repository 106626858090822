var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.shared.util.internal.time_common.js");require("./metabase.util.malli.registry.js");require("./shadow.js.shim.module$moment.js");require("./shadow.js.shim.module$moment_timezone.js");
'use strict';var aJ,cJ,eJ,qJ,uJ,CJ;$CLJS.$I=new $CLJS.M(null,"get-month","get-month",-369374731);aJ=new $CLJS.M("metabase.lib.schema.expression.temporal","absolute-datetime.options","metabase.lib.schema.expression.temporal/absolute-datetime.options",-925669633);$CLJS.bJ=new $CLJS.M(null,"convert-timezone","convert-timezone",-124803001);cJ=new $CLJS.M("metabase.lib.schema.expression.temporal","temporal-extract.unit","metabase.lib.schema.expression.temporal/temporal-extract.unit",339336486);
$CLJS.dJ=new $CLJS.M(null,"iso","iso",-1366207543);eJ=new $CLJS.M("metabase.lib.schema.expression.temporal","absolute-datetime.base-type","metabase.lib.schema.expression.temporal/absolute-datetime.base-type",-904703365);$CLJS.fJ=new $CLJS.M(null,"get-second","get-second",-2065946318);$CLJS.gJ=new $CLJS.M(null,"get-year","get-year",-936011274);$CLJS.hJ=new $CLJS.M(null,"absolute-datetime","absolute-datetime",-560340465);
$CLJS.iJ=new $CLJS.M("lib.type-of","type-is-temporal-type-of-first-arg","lib.type-of/type-is-temporal-type-of-first-arg",76272405);$CLJS.jJ=new $CLJS.M(null,"week-of-year-iso","week-of-year-iso",-1827744293);$CLJS.kJ=new $CLJS.M(null,"datetime-diff","datetime-diff",1133112155);$CLJS.lJ=new $CLJS.M(null,"interval","interval",1708495417);$CLJS.mJ=new $CLJS.M(null,"us","us",746429226);$CLJS.nJ=new $CLJS.M(null,"relative-datetime","relative-datetime",1285169278);
$CLJS.oJ=new $CLJS.M(null,"datetime","datetime",494675702);$CLJS.pJ=new $CLJS.M(null,"now","now",-1650525531);qJ=new $CLJS.M("metabase.lib.schema.expression.temporal","temporal-extract.week-mode","metabase.lib.schema.expression.temporal/temporal-extract.week-mode",430590916);$CLJS.rJ=new $CLJS.M(null,"instance","instance",-2121349050);$CLJS.sJ=new $CLJS.M(null,"get-day","get-day",127568857);$CLJS.tJ=new $CLJS.M(null,"get-week","get-week",-888059349);
uJ=new $CLJS.M("metabase.lib.schema.expression.temporal","relative-datetime.amount","metabase.lib.schema.expression.temporal/relative-datetime.amount",7216442);$CLJS.vJ=new $CLJS.M(null,"datetime-add","datetime-add",209603411);$CLJS.wJ=new $CLJS.M(null,"get-minute","get-minute",1468086343);$CLJS.xJ=new $CLJS.M(null,"get-day-of-week","get-day-of-week",2010140752);$CLJS.yJ=new $CLJS.M("metabase.lib.schema.expression.temporal","timezone-id","metabase.lib.schema.expression.temporal/timezone-id",-1909635091);
$CLJS.zJ=new $CLJS.M(null,"temporal-extract","temporal-extract",1781686405);$CLJS.AJ=new $CLJS.M(null,"get-hour","get-hour",622714059);$CLJS.BJ=new $CLJS.M(null,"datetime-subtract","datetime-subtract",275307697);CJ=new $CLJS.M("metabase.lib.schema.expression.temporal","get-week-mode","metabase.lib.schema.expression.temporal/get-week-mode",1489967652);$CLJS.DJ=new $CLJS.M(null,"get-quarter","get-quarter",1327778593);$CLJS.EJ=new $CLJS.M(null,"week-of-year-us","week-of-year-us",849423511);
$CLJS.FJ=new $CLJS.M(null,"week-of-year-instance","week-of-year-instance",2047974624);$CLJS.GJ=new $CLJS.M(null,"target","target",253001721);$CLJS.KF($CLJS.lJ,$CLJS.H([$CLJS.rt,$CLJS.lD,$CLJS.Ks,$CLJS.pE]));$CLJS.ZE.m(null,$CLJS.iJ,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.$E(a);return $CLJS.vd(a)?(a=$CLJS.ou.g(a,new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.aD,null,$CLJS.AD,null],null),null)),$CLJS.E.g($CLJS.D(a),1)?$CLJS.A(a):a):a});$CLJS.DF($CLJS.iJ,$CLJS.pF);
for(var HJ=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vJ,$CLJS.BJ],null)),IJ=null,JJ=0,KJ=0;;)if(KJ<JJ){var LJ=IJ.X(null,KJ);$CLJS.KF(LJ,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null),$CLJS.Ks,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pE],null)]));$CLJS.DF(LJ,$CLJS.iJ);KJ+=1}else{var MJ=$CLJS.y(HJ);if(MJ){var NJ=MJ;if($CLJS.Ad(NJ)){var OJ=$CLJS.lc(NJ),Kga=$CLJS.mc(NJ),Lga=OJ,Mga=$CLJS.D(OJ);HJ=Kga;IJ=Lga;JJ=Mga}else{var PJ=$CLJS.A(NJ);$CLJS.KF(PJ,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,
[$CLJS.W,$CLJS.hF],null),$CLJS.Ks,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pE],null)]));$CLJS.DF(PJ,$CLJS.iJ);HJ=$CLJS.B(NJ);IJ=null;JJ=0}KJ=0}else break}
for(var QJ=$CLJS.y(new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.gJ,$CLJS.$I,$CLJS.sJ,$CLJS.AJ,$CLJS.wJ,$CLJS.fJ,$CLJS.DJ],null)),RJ=null,SJ=0,TJ=0;;)if(TJ<SJ){var Nga=RJ.X(null,TJ);$CLJS.KF(Nga,$CLJS.H([$CLJS.rt,$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null)],null)]));TJ+=1}else{var UJ=$CLJS.y(QJ);if(UJ){var VJ=UJ;if($CLJS.Ad(VJ)){var WJ=$CLJS.lc(VJ),Oga=$CLJS.mc(VJ),Pga=WJ,Qga=$CLJS.D(WJ);QJ=Oga;RJ=Pga;SJ=Qga}else{var Rga=$CLJS.A(VJ);$CLJS.KF(Rga,$CLJS.H([$CLJS.rt,
$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null)],null)]));QJ=$CLJS.B(VJ);RJ=null;SJ=0}TJ=0}else break}$CLJS.KF($CLJS.kJ,$CLJS.H([$CLJS.rt,$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.nH],null)]));
for(var XJ=$CLJS.y(new $CLJS.ah(null,new $CLJS.h(null,8,[$CLJS.DJ,null,$CLJS.wJ,null,$CLJS.AJ,null,$CLJS.xJ,null,$CLJS.fJ,null,$CLJS.$I,null,$CLJS.gJ,null,$CLJS.sJ,null],null),null)),YJ=null,ZJ=0,$J=0;;)if($J<ZJ){var Sga=YJ.X(null,$J);$CLJS.KF(Sga,$CLJS.H([$CLJS.rt,$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null)],null)]));$J+=1}else{var aK=$CLJS.y(XJ);if(aK){var bK=aK;if($CLJS.Ad(bK)){var cK=$CLJS.lc(bK),Tga=$CLJS.mc(bK),Uga=cK,Vga=$CLJS.D(cK);
XJ=Tga;YJ=Uga;ZJ=Vga}else{var Wga=$CLJS.A(bK);$CLJS.KF(Wga,$CLJS.H([$CLJS.rt,$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null)],null)]));XJ=$CLJS.B(bK);YJ=null;ZJ=0}$J=0}else break}$CLJS.X(CJ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.lk,$CLJS.dJ,$CLJS.mJ,$CLJS.rJ],null));
$CLJS.MF($CLJS.tJ,$CLJS.H([$CLJS.rt,$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,CJ],null)],null)],null)],null)]));
$CLJS.X($CLJS.yJ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,$CLJS.EE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ys,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lk,new $CLJS.h(null,2,[$CLJS.yt,"valid timezone ID",$CLJS.At,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.Cj);return["invalid timezone ID: ",$CLJS.Mh.l($CLJS.H([a]))].join("")}],null)],null),$CLJS.hH($CLJS.Jd,$CLJS.ZI.tz.names())),$CLJS.QI],null)],null));
$CLJS.MF($CLJS.bJ,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.GJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.yJ],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hA,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.yJ],null)],null)],null)],
null)]));$CLJS.DF($CLJS.bJ,$CLJS.iJ);$CLJS.KF($CLJS.pJ,$CLJS.H([$CLJS.rt,$CLJS.dD]));$CLJS.X(eJ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.OE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.yt,":absolute-datetime base-type must derive from :type/Date or :type/DateTime"],null),function(a){return $CLJS.Re(function(b){return $CLJS.AC(a,b)},new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aD,$CLJS.AD],null))}],null)],null));
$CLJS.X(aJ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.JE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zB,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,eJ],null)],null)],null)],null));
$CLJS.WG.g($CLJS.hJ,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ns,new $CLJS.h(null,1,[$CLJS.yt,"valid :absolute-datetime clause"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tk,$CLJS.hJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,aJ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ps,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ns,new $CLJS.h(null,1,[$CLJS.yt,":absolute-datetime literal and unit for :type/Date"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,
4,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UI],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.WI],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.OI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tk,$CLJS.ci],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.oH],null)],null)],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ns,new $CLJS.h(null,1,[$CLJS.yt,":absolute-datetime literal and unit for :type/DateTime"],
null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tk,$CLJS.aw],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.YI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tk,$CLJS.ci],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kH],null)],null)],null)],null)],null)],null));
$CLJS.ZE.m(null,$CLJS.hJ,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);a=$CLJS.E.g(b,$CLJS.aw)?$CLJS.E.g(a,$CLJS.ci)?$CLJS.AD:$CLJS.n($CLJS.CF($CLJS.oH,a))?$CLJS.aD:$CLJS.AD:null;if($CLJS.n(a))return a;a="string"===typeof b?$CLJS.n($CLJS.nh($CLJS.LI,b))?$CLJS.aD:$CLJS.n($CLJS.nh($CLJS.MI,b))?$CLJS.aD:null:null;if($CLJS.n(a))return a;b=$CLJS.$E(b);b=$CLJS.vd(b)?$CLJS.eg.j($CLJS.bh,$CLJS.kf(function(c){return $CLJS.AC(c,$CLJS.Uj)}),b):b;return $CLJS.vd(b)&&
$CLJS.E.g($CLJS.D(b),1)?$CLJS.A(b):b});$CLJS.X(uJ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tk,$CLJS.aw],null),$CLJS.Ks],null));
$CLJS.MF($CLJS.nJ,$CLJS.H([$CLJS.rt,$CLJS.AD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uJ],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ri,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pE],null)],null)],null)],null)]));
$CLJS.KF($CLJS.Ny,$CLJS.H([$CLJS.rt,$CLJS.$D,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.oE],null)]));$CLJS.X(cJ,new $CLJS.P(null,12,5,$CLJS.Q,[$CLJS.lk,$CLJS.mH,$CLJS.zi,$CLJS.kk,$CLJS.jJ,$CLJS.EJ,$CLJS.FJ,$CLJS.Ej,$CLJS.Fi,$CLJS.vi,$CLJS.hj,$CLJS.jH],null));$CLJS.X(qJ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.lk,$CLJS.dJ,$CLJS.mJ,$CLJS.rJ],null));
$CLJS.MF($CLJS.zJ,$CLJS.H([$CLJS.rt,$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ri,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,cJ],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qJ],null)],null)],
null)],null)]));