var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.mbql.schema.js");require("./metabase.util.malli.registry.js");
'use strict';var nV,oV,pV,rV,sV,tV,uV,vV,wV,xV;nV=new $CLJS.M("metabase.lib.schema.template-tag","widget-type","metabase.lib.schema.template-tag/widget-type",2021141560);oV=new $CLJS.M("metabase.lib.schema.template-tag","raw-value","metabase.lib.schema.template-tag/raw-value",-530604923);pV=new $CLJS.M("metabase.lib.schema.template-tag","template-tag","metabase.lib.schema.template-tag/template-tag",-1178540449);
$CLJS.qV=new $CLJS.M("metabase.lib.schema.template-tag","template-tag-map","metabase.lib.schema.template-tag/template-tag-map",1471181299);rV=new $CLJS.M("metabase.lib.schema.template-tag","type","metabase.lib.schema.template-tag/type",1926679295);sV=new $CLJS.M("metabase.lib.schema.template-tag","field-filter","metabase.lib.schema.template-tag/field-filter",1376344463);tV=new $CLJS.M("metabase.lib.schema.template-tag","snippet","metabase.lib.schema.template-tag/snippet",190689821);
uV=new $CLJS.M("metabase.lib.schema.template-tag","common","metabase.lib.schema.template-tag/common",1713198790);vV=new $CLJS.M("metabase.lib.schema.template-tag","value.common","metabase.lib.schema.template-tag/value.common",-974864959);wV=new $CLJS.M("metabase.lib.schema.template-tag","raw-value.type","metabase.lib.schema.template-tag/raw-value.type",-805651412);xV=new $CLJS.M("metabase.lib.schema.template-tag","source-query","metabase.lib.schema.template-tag/source-query",479361081);$CLJS.X(nV,$CLJS.eg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lk,new $CLJS.h(null,1,[$CLJS.yt,"Valid template tag :widget-type"],null),$CLJS.mA],null),$CLJS.Xg($CLJS.iV)));$CLJS.X(rV,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.lk,$CLJS.RM,$CLJS.GN,$CLJS.PF,$CLJS.$t,$CLJS.DB,$CLJS.MP],null));
$CLJS.X(uV,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.EE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zE,$CLJS.EE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Yi,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ys,$CLJS.EE,$CLJS.Ns],null)],null)],null));
$CLJS.X(vV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ci,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.Dl],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hO,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.Os],null)],null)],null));
$CLJS.X(sV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vV],null),new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tk,$CLJS.PF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.PF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.mF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aO,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nV],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,new $CLJS.h(null,1,[$CLJS.Ts,!0],
null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,$CLJS.gk],null)],null)],null)],null));
$CLJS.X(tV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uV],null),new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tk,$CLJS.RM],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.DM,$CLJS.EE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dQ,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.qL],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eQ,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.iL],null)],null)],null));
$CLJS.X(xV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tk,$CLJS.GN],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nR,$CLJS.hL],null)],null)],null));$CLJS.X(wV,$CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.lk],null),$CLJS.XU));
$CLJS.X(oV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vV],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,wV],null)],null)],null)],null));
$CLJS.X(pV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rV],null)],null)],null),new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,1,[$CLJS.Ni,$CLJS.lj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.PF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sV],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.RM,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tV],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.GN,
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,xV],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$o,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oV],null)],null)],null)],null));
$CLJS.X($CLJS.qV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ds,$CLJS.EE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pV],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.yt,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Qe(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.E.g(c,$CLJS.T.h(b))},a)}],null)],null));