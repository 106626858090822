var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var LL,NL,PL,SL,XL;$CLJS.HL=function(a){return $CLJS.Wh($CLJS.q($CLJS.oC),a,$CLJS.zG)};$CLJS.IL=new $CLJS.M(null,"many-pks?","many-pks?",-459675021);$CLJS.JL=new $CLJS.M(null,"row","row",-570139521);$CLJS.KL=new $CLJS.M(null,"object-id","object-id",-754527291);LL=new $CLJS.M("metabase.lib.schema.ref","aggregation-options","metabase.lib.schema.ref/aggregation-options",-15566487);$CLJS.ML=new $CLJS.M(null,"operators","operators",-2064102509);
NL=new $CLJS.M("metabase.lib.schema.ref","field.options","metabase.lib.schema.ref/field.options",408316031);$CLJS.OL=new $CLJS.M(null,"stage-number","stage-number",-1752729638);PL=new $CLJS.M("metabase.lib.schema.ref","field.literal","metabase.lib.schema.ref/field.literal",-1228060748);$CLJS.QL=new $CLJS.M(null,"min-value","min-value",-1119123315);$CLJS.RL=new $CLJS.M(null,"dimensions","dimensions",-254818097);
SL=new $CLJS.M("metabase.lib.schema.ref","field.id","metabase.lib.schema.ref/field.id",-956546909);$CLJS.TL=new $CLJS.M("drill-thru","pk","drill-thru/pk",1721977789);$CLJS.UL=new $CLJS.M("drill-thru","fk-details","drill-thru/fk-details",-687193600);$CLJS.VL=new $CLJS.M(null,"max-value","max-value",687805168);$CLJS.WL=new $CLJS.M("metabase.lib.schema","query","metabase.lib.schema/query",-1207387140);
XL=new $CLJS.M("metabase.lib.schema.ref","field.literal.options","metabase.lib.schema.ref/field.literal.options",-860446668);$CLJS.YL=new $CLJS.M("metabase.lib.drill-thru","drill-thru","metabase.lib.drill-thru/drill-thru",-67424984);$CLJS.X(NL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xt,$CLJS.JE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BG,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.qH],null)],null)],null));$CLJS.X(XL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xt,NL,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zB,$CLJS.OE],null)],null)],null));$CLJS.X(PL,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Bs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tk,$CLJS.vG],null),XL,$CLJS.EE],null));
$CLJS.X(SL,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Bs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tk,$CLJS.vG],null),NL,$CLJS.NE],null));
$CLJS.WG.g($CLJS.vG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Bs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tk,$CLJS.vG],null),NL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ys,$CLJS.NE,$CLJS.EE],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,2,[$CLJS.Ni,function(a){return $CLJS.wd(a)?($CLJS.I(a,0,null),$CLJS.I(a,1,null),a=$CLJS.I(a,2,null),$CLJS.mC(a)):null},$CLJS.yt,"Invalid :field clause ID or name: must be a string or integer"],null),new $CLJS.P(null,2,
5,$CLJS.Q,[$CLJS.qB,SL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pB,PL],null)],null)],null));$CLJS.DF($CLJS.vG,$CLJS.zG);$CLJS.ZE.m(null,$CLJS.vG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.HA($CLJS.Ei,$CLJS.zB)(b);return $CLJS.n(a)?a:$CLJS.lF});$CLJS.KF($CLJS.wB,$CLJS.H([$CLJS.EE]));$CLJS.ZE.m(null,$CLJS.wB,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.HA($CLJS.Ei,$CLJS.zB)(b);return $CLJS.n(a)?a:$CLJS.lF});
$CLJS.DF($CLJS.wB,$CLJS.zG);$CLJS.X(LL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xt,$CLJS.JE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.EE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zE,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.EE],null)],null)],null));$CLJS.WG.g($CLJS.kG,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Bs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tk,$CLJS.kG],null),LL,$CLJS.Xj],null));
$CLJS.ZE.m(null,$CLJS.kG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.HA($CLJS.Ei,$CLJS.zB)(b);return $CLJS.n(a)?a:$CLJS.lF});$CLJS.DF($CLJS.kG,$CLJS.zG);$CLJS.KF($CLJS.UF,$CLJS.H([$CLJS.rt,$CLJS.Jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.wE],null)],null)]));$CLJS.DF($CLJS.UF,$CLJS.zG);
$CLJS.KF($CLJS.qG,$CLJS.H([$CLJS.rt,$CLJS.lF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.uE],null)],null)]));$CLJS.DF($CLJS.qG,$CLJS.zG);
$CLJS.X($CLJS.zG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,$CLJS.aF,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.At,function(){return["Valid reference, must be one of these clauses: ",$CLJS.Jt(", ",$CLJS.hH($CLJS.Jd,$CLJS.ll.g($CLJS.q($CLJS.oC),$CLJS.zG)))].join("")}],null),function(a){a=$CLJS.I(a,0,null);return $CLJS.HL(a)}],null)],null));