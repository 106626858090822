var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.drill_thru.column_filter.js");require("./metabase.lib.drill_thru.common.js");require("./metabase.lib.drill_thru.distribution.js");require("./metabase.lib.drill_thru.fk_details.js");require("./metabase.lib.drill_thru.fk_filter.js");require("./metabase.lib.drill_thru.object_details.js");require("./metabase.lib.drill_thru.pivot.js");require("./metabase.lib.drill_thru.pk.js");require("./metabase.lib.drill_thru.quick_filter.js");require("./metabase.lib.drill_thru.sort.js");require("./metabase.lib.drill_thru.summarize_column.js");require("./metabase.lib.drill_thru.summarize_column_by_time.js");require("./metabase.lib.drill_thru.underlying_records.js");require("./metabase.lib.drill_thru.zoom.js");require("./metabase.lib.drill_thru.zoom_in_bins.js");require("./metabase.lib.drill_thru.zoom_in_geographic.js");require("./metabase.lib.drill_thru.zoom_in_timeseries.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.drill_thru.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var d8,f8,g8,h8,i8,k8,l8,Kra,m8,n8,Lra,o8,Mra,Nra,p8,q8,s8,t8,u8,Ora,v8,Pra,Qra,Rra,Sra,w8,Tra,Ura,Vra,x8,Wra,j8,Xra,Yra,Zra,$ra,asa,bsa,y8,csa,dsa,esa,fsa,gsa,hsa,isa,z8,jsa,ksa,lsa,msa,nsa,osa,psa,qsa,rsa,ssa,tsa,usa,vsa,wsa,xsa,ysa,A8,B8,zsa,Asa,Bsa,C8,Csa,Dsa,Esa,Fsa,Gsa,Hsa,Isa,D8,Jsa,Ksa,Lsa,Msa;$CLJS.c8=function(a){return $CLJS.gc($CLJS.eb(function(b,c){return $CLJS.hg.j(b,c,$CLJS.J.j(b,c,0)+1)},$CLJS.ec($CLJS.N),a))};
d8=function(a){var b=$CLJS.X_(a);return $CLJS.n(b)?$CLJS.lf($CLJS.L_,$CLJS.d0(a,b)):$CLJS.Cf};$CLJS.e8=function(a){return $CLJS.Tk.v(a,$CLJS.zL,$CLJS.be,new $CLJS.h(null,1,[$CLJS.fu,$CLJS.eW],null))};
f8=function(a,b,c){var d=$CLJS.G2(a,$CLJS.OW(a,b)),e=$CLJS.Ok.g($CLJS.$K.h(c),$CLJS.sL)?new $CLJS.h(null,2,[$CLJS.SF,a,$CLJS.OL,b],null):$CLJS.n(d)?new $CLJS.h(null,2,[$CLJS.SF,a,$CLJS.OL,d],null):new $CLJS.h(null,2,[$CLJS.SF,$CLJS.e8(a),$CLJS.OL,-1],null),f=$CLJS.D3.g($CLJS.SF.h(e),$CLJS.OL.h(e));a=function(){var k=$CLJS.t2.v($CLJS.SF.h(e),$CLJS.OL.h(e),$CLJS.AW(c),f);if($CLJS.n(k))return k;k=$CLJS.l1.h(c);return $CLJS.n(k)?$CLJS.HH(function(l){return $CLJS.E.g($CLJS.l1.h(l),$CLJS.l1.h(c))},f):k}();
return $CLJS.R.j(e,$CLJS.Si,a)};g8=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.Si);d=$CLJS.J.g(d,$CLJS.Cj);var e=$CLJS.$5(a,b);d=e?$CLJS.n(c)?null==d&&!$CLJS.M_(c):c:e;return $CLJS.n(d)?(d=$CLJS.F_(c)?null:$CLJS.R.j($CLJS.A($CLJS.S2(c)),$CLJS.fu,$CLJS.dK),$CLJS.hl.l($CLJS.H([new $CLJS.h(null,3,[$CLJS.fu,$CLJS.YL,$CLJS.lj,$CLJS.w6,$CLJS.u6,d],null),f8(a,b,c)]))):null};
h8=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.Si);d=$CLJS.J.g(d,$CLJS.Cj);var e=$CLJS.$5(a,b);a=e?$CLJS.n(c)?null==d&&$CLJS.Ok.g($CLJS.$K.h(c),$CLJS.sL)&&!$CLJS.L_(c)&&!$CLJS.M_(c)&&!$CLJS.U_(c)&&!$CLJS.J_(c)&&$CLJS.Va($CLJS.y($CLJS.Y5.j(a,b,c))):c:e;return $CLJS.n(a)?new $CLJS.h(null,3,[$CLJS.fu,$CLJS.YL,$CLJS.lj,$CLJS.T6,$CLJS.Si,c],null):null};
i8=function(a,b,c){b=$CLJS.O(c);a=$CLJS.J.g(b,$CLJS.Si);b=$CLJS.J.g(b,$CLJS.Cj);return $CLJS.n($CLJS.n(a)?null!=b&&$CLJS.Ok.g(b,$CLJS.X2)&&!$CLJS.L_(a)&&$CLJS.K_(a):a)?new $CLJS.h(null,3,[$CLJS.fu,$CLJS.YL,$CLJS.lj,$CLJS.A6,$CLJS.$F,$CLJS.zW(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.tk,$CLJS.N,$CLJS.AW(a),b],null))],null):null};k8=function(a,b,c){c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.Si),e=$CLJS.J.g(c,$CLJS.Cj);return $CLJS.n($CLJS.n(d)?null!=e:d)?j8(a,b,c,1<$CLJS.D(d8(a))):null};
l8=function(a,b,c,d){var e=$CLJS.O(c);c=$CLJS.J.g(e,$CLJS.Si);e=$CLJS.J.g(e,$CLJS.Cj);var f=$CLJS.$5(a,b);c=f?$CLJS.n(c)?null!=e&&$CLJS.E.g($CLJS.$K.h(c),$CLJS.sL):c:f;return $CLJS.n(c)?$CLJS.lf(d,$CLJS.X5.g(a,b)):null};
Kra=function(a,b){var c=$CLJS.c8($CLJS.hf.g(function(d){d=$CLJS.h0.j(a,b,d);return $CLJS.F_(d)?$CLJS.MP:$CLJS.O_(d)?$CLJS.G7:$CLJS.I_(d)?$CLJS.PG:null},$CLJS.V5.g(a,b)));return $CLJS.E.g(new $CLJS.h(null,1,[$CLJS.MP,1],null),c)?new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.PG,null,$CLJS.q6,null],null),null):$CLJS.E.g(new $CLJS.h(null,2,[$CLJS.MP,1,$CLJS.PG,1],null),c)?new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.PG,null,$CLJS.q6,null],null),null):$CLJS.E.g(new $CLJS.h(null,1,[$CLJS.G7,1],null),c)?new $CLJS.ah(null,
new $CLJS.h(null,2,[$CLJS.PG,null,$CLJS.Ny,null],null),null):$CLJS.E.g($CLJS.N,c)?new $CLJS.ah(null,new $CLJS.h(null,3,[$CLJS.PG,null,$CLJS.Ny,null,$CLJS.q6,null],null),null):$CLJS.E.g(new $CLJS.h(null,1,[$CLJS.PG,1],null),c)?new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.PG,null,$CLJS.Ny,null],null),null):$CLJS.E.g(new $CLJS.h(null,1,[$CLJS.PG,2],null),c)?new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.PG,null,$CLJS.Ny,null],null),null):$CLJS.bh};
m8=function(a,b,c){var d=$CLJS.O(c),e=$CLJS.J.g(d,$CLJS.Si);c=$CLJS.J.g(d,$CLJS.RL);var f=$CLJS.J.g(d,$CLJS.Cj);if($CLJS.n(function(){var m=$CLJS.$5(a,b);return m?$CLJS.n(e)?null!=f&&$CLJS.E.g($CLJS.$K.h(e),$CLJS.sL)&&0<$CLJS.D($CLJS.H4.g(a,b)):e:m}())){var k=Kra(a,b),l=$CLJS.eg.g($CLJS.N,function(){return function u(t){return new $CLJS.ne(null,function(){for(var v=t;;)if(v=$CLJS.y(v)){if($CLJS.Ad(v)){var x=$CLJS.lc(v),z=$CLJS.D(x),C=$CLJS.qe(z);a:for(var G=0;;)if(G<z){var K=$CLJS.kd(x,G),S=$CLJS.J.g($CLJS.H7,
K);S=l8(a,b,d,S);$CLJS.n($CLJS.Fe(S))&&C.add(new $CLJS.P(null,2,5,$CLJS.Q,[K,S],null));G+=1}else{x=!0;break a}return x?$CLJS.te($CLJS.ve(C),u($CLJS.mc(v))):$CLJS.te($CLJS.ve(C),null)}C=$CLJS.A(v);x=$CLJS.J.g($CLJS.H7,C);x=l8(a,b,d,x);if($CLJS.n($CLJS.Fe(x)))return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[C,x],null),u($CLJS.Lc(v)));v=$CLJS.Lc(v)}else return null},null,null)}(k)}());return $CLJS.td(l)?null:new $CLJS.h(null,4,[$CLJS.fu,$CLJS.YL,$CLJS.lj,$CLJS.b7,$CLJS.RL,c,$CLJS.m6,l],null)}return null};
n8=function(a,b){return $CLJS.zW($CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[a,$CLJS.N],null),b))};
Lra=function(a,b){var c=$CLJS.AW(a);if($CLJS.M_(a))return $CLJS.Cf;if($CLJS.E.g(b,$CLJS.X2))return new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.T,"\x3d",$CLJS.$F,n8($CLJS.XF,$CLJS.H([c]))],null),new $CLJS.h(null,2,[$CLJS.T,"≠",$CLJS.$F,n8($CLJS.hG,$CLJS.H([c]))],null)],null);if($CLJS.G_(a)||$CLJS.F_(a))return a=function f(e){return new $CLJS.ne(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m);a:for(var u=0;;)if(u<m){var v=$CLJS.kd(l,
u),x=$CLJS.I(v,0,null);v=$CLJS.I(v,1,null);x=new $CLJS.h(null,2,[$CLJS.T,v,$CLJS.$F,n8(x,$CLJS.H([c,b]))],null);t.add(x);u+=1}else{l=!0;break a}return l?$CLJS.te($CLJS.ve(t),f($CLJS.mc(k))):$CLJS.te($CLJS.ve(t),null)}l=$CLJS.A(k);t=$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);return $CLJS.ee(new $CLJS.h(null,2,[$CLJS.T,l,$CLJS.$F,n8(t,$CLJS.H([c,b]))],null),f($CLJS.Lc(k)))}return null}},null,null)},a(new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,"\x3c"],null),new $CLJS.P(null,2,5,
$CLJS.Q,[$CLJS.Wr,"\x3e"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tk,"\x3d"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.NG,"≠"],null)],null));a=function f(e){return new $CLJS.ne(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m);a:for(var u=0;;)if(u<m){var v=$CLJS.kd(l,u),x=$CLJS.I(v,0,null);v=$CLJS.I(v,1,null);x=new $CLJS.h(null,2,[$CLJS.T,v,$CLJS.$F,n8(x,$CLJS.H([c,b]))],null);t.add(x);u+=1}else{l=!0;break a}return l?$CLJS.te($CLJS.ve(t),
f($CLJS.mc(k))):$CLJS.te($CLJS.ve(t),null)}l=$CLJS.A(k);t=$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);return $CLJS.ee(new $CLJS.h(null,2,[$CLJS.T,l,$CLJS.$F,n8(t,$CLJS.H([c,b]))],null),f($CLJS.Lc(k)))}return null}},null,null)};return a(new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tk,"\x3d"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.NG,"≠"],null)],null))};
o8=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.Si);d=$CLJS.J.g(d,$CLJS.Cj);var e=(e=$CLJS.$5(a,b))?$CLJS.n(c)?null!=d&&!$CLJS.L_(c)&&!$CLJS.K_(c):c:e;return $CLJS.n(e)?(a=f8(a,b,c),$CLJS.hl.l($CLJS.H([new $CLJS.h(null,4,[$CLJS.fu,$CLJS.YL,$CLJS.lj,$CLJS.g6,$CLJS.ML,Lra($CLJS.Si.h(a),d),$CLJS.Cj,d],null),a]))):null};Mra=function(a,b,c){return $CLJS.t2.v(a,b,$CLJS.AW(c),$CLJS.N7.g(a,b))};
Nra=function(a,b,c){a=$CLJS.P7(a,b,c);return $CLJS.n(a)?(b=$CLJS.I(a,0,null),$CLJS.I(a,1,null),$CLJS.I(a,2,null),b):null};
p8=function(a,b,c){c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.Si),e=$CLJS.J.g(c,$CLJS.Cj);if($CLJS.n(function(){var k=$CLJS.$5(a,b);return k?$CLJS.n(d)?null==e&&!$CLJS.M_(d):d:k}())&&$CLJS.n(Mra(a,b,d))){var f=Nra(a,b,d);return new $CLJS.h(null,4,[$CLJS.fu,$CLJS.YL,$CLJS.lj,$CLJS.G6,$CLJS.Si,d,$CLJS.J6,function(){switch(f instanceof $CLJS.M?f.T:null){case "asc":return new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.lG],null);case "desc":return new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.sG],null);default:return new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.sG,$CLJS.lG],null)}}()],null)}return null};
q8=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.Si);d=$CLJS.J.g(d,$CLJS.Cj);var e=$CLJS.$5(a,b);a=e?$CLJS.n(c)?null==d&&!$CLJS.M_(c)&&$CLJS.Ok.g($CLJS.$K.h(c),$CLJS.sL)&&$CLJS.Va($CLJS.y($CLJS.Y5.j(a,b,c))):c:e;return $CLJS.n(a)?(a=$CLJS.gf.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.aG],null),$CLJS.H_(c)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.IG,$CLJS.pG],null):null),new $CLJS.h(null,4,[$CLJS.fu,$CLJS.YL,$CLJS.lj,$CLJS.H6,$CLJS.Si,c,$CLJS.L6,a],null)):null};
s8=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.Si);d=$CLJS.J.g(d,$CLJS.Cj);var e=$CLJS.$5(a,b);d=e?$CLJS.n(c)?null==d&&!$CLJS.M_(c)&&$CLJS.H_(c)&&$CLJS.Ok.g($CLJS.$K.h(c),$CLJS.sL):c:e;if($CLJS.n(d)&&(d=$CLJS.HH($CLJS.F_,$CLJS.X5.g(a,b)),$CLJS.n(d)&&(e=$CLJS.HH($CLJS.ci,$CLJS.f1.j(a,b,d)),$CLJS.n(e)))){var f=$CLJS.G3(d,e);return $CLJS.n($CLJS.TV($CLJS.hf.g($CLJS.AW,$CLJS.ee(f,$CLJS.V5.g(a,b)))))?new $CLJS.h(null,5,[$CLJS.fu,$CLJS.YL,$CLJS.lj,$CLJS.Z6,$CLJS.Si,c,$CLJS.jG,d,$CLJS.ri,$CLJS.c1.h(e)],
null):null}return null};
t8=function(a,b,c){c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.Si),e=$CLJS.J.g(c,$CLJS.h6),f=$CLJS.J.g(c,$CLJS.RL),k=$CLJS.J.g(c,$CLJS.Cj);return $CLJS.n(function(){var l=$CLJS.$5(a,b);return l?$CLJS.n(d)?(l=null!=k)?(l=$CLJS.Fe(f),$CLJS.n(l)?!$CLJS.M_(d):l):l:d:l}())?new $CLJS.h(null,6,[$CLJS.fu,$CLJS.YL,$CLJS.lj,$CLJS.V6,$CLJS.Q6,"number"===typeof k?k:2,$CLJS.VZ,function(){var l=$CLJS.X_(a);l=null==l?null:$CLJS.c0(a,l);return null==l?null:$CLJS.i0.j(a,b,l)}(),$CLJS.RL,f,$CLJS.h6,e],null):null};
u8=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.Si);d=$CLJS.J.g(d,$CLJS.Cj);if($CLJS.n($CLJS.n(c)?d:c))if(a=$CLJS.A($CLJS.Y5.j(a,b,c)),$CLJS.n(a))if(a=$CLJS.m7(a),$CLJS.n(a))if(b=$CLJS.S7(c,d),$CLJS.n(b)){var e=$CLJS.O(b);b=$CLJS.J.g(e,$CLJS.QL);var f=$CLJS.J.g(e,$CLJS.VL);e=$CLJS.J.g(e,$CLJS.PE);var k=$CLJS.QE.h(a);k=k instanceof $CLJS.M?k.T:null;switch(k){case "num-bins":return new $CLJS.h(null,6,[$CLJS.fu,$CLJS.YL,$CLJS.lj,$CLJS.K6,$CLJS.Si,c,$CLJS.QL,d,$CLJS.VL,d+e,$CLJS.D6,new $CLJS.h(null,
1,[$CLJS.QE,$CLJS.ci],null)],null);case "bin-width":return new $CLJS.h(null,6,[$CLJS.fu,$CLJS.YL,$CLJS.lj,$CLJS.K6,$CLJS.Si,c,$CLJS.QL,b,$CLJS.VL,f,$CLJS.D6,$CLJS.Tk.j(a,$CLJS.PE,function(l){return l/10})],null);case "default":return null;default:throw Error(["No matching clause: ",$CLJS.p.h(k)].join(""));}}else return null;else return null;else return null;else return null};
Ora=function(a,b,c){c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.JL);b=$CLJS.T0.j(a,b,$CLJS.PW(a,b));a=$CLJS.HH($CLJS.S_,b);if($CLJS.n(a)&&(b=$CLJS.HH($CLJS.T_,b),$CLJS.n(b))){var e=function(f){return $CLJS.Re(function(k){var l=$CLJS.Si.h(k);l=$CLJS.n($CLJS.Yi.h(f))?$CLJS.E.g($CLJS.Yi.h(f),$CLJS.Yi.h(l)):$CLJS.E.g($CLJS.TZ.h(f),$CLJS.TZ.h(l));return $CLJS.n(l)?$CLJS.Cj.h(k):null},d)};return $CLJS.R.l(c,$CLJS.Y7,a,$CLJS.H([$CLJS.Z7,b,$CLJS.$7,e(a),$CLJS.X7,e(b)]))}return null};
v8=function(a,b){var c=$CLJS.O(a);a=$CLJS.J.g(c,$CLJS.Si);var d=$CLJS.J.g(c,$CLJS.Cj),e=$CLJS.J.g(c,$CLJS.Y7);c=$CLJS.J.g(c,$CLJS.Z7);return $CLJS.n(d)?new $CLJS.h(null,7,[$CLJS.fu,$CLJS.YL,$CLJS.lj,$CLJS.f7,$CLJS.l6,$CLJS.y6,$CLJS.Si,a,$CLJS.Cj,d,$CLJS.B6,new $CLJS.h(null,2,[$CLJS.Si,e,$CLJS.PE,b],null),$CLJS.z6,new $CLJS.h(null,2,[$CLJS.Si,c,$CLJS.PE,b],null)],null):null};Pra=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.Si);return $CLJS.n(null==b?null:$CLJS.R_(b))?v8(a,10):null};
Qra=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.Si);return $CLJS.n(null==b?null:$CLJS.Q_(b))?v8(a,1):null};Rra=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.Si);return $CLJS.n(null==b?null:$CLJS.P_(b))?v8(a,.1):null};
Sra=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.Y7);var c=$CLJS.J.g(b,$CLJS.Z7),d=$CLJS.J.g(b,$CLJS.$7);b=$CLJS.J.g(b,$CLJS.X7);if($CLJS.n($CLJS.n(d)?b:d)){var e=$CLJS.PE.h($CLJS.m7(a));if($CLJS.n(e)){var f=$CLJS.PE.h($CLJS.m7(c));if($CLJS.n(f)){var k=20<=e&&20<=f?new $CLJS.P(null,2,5,$CLJS.Q,[10,10],null):new $CLJS.P(null,2,5,$CLJS.Q,[e/10,f/10],null),l=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);return new $CLJS.h(null,5,[$CLJS.fu,$CLJS.YL,$CLJS.lj,$CLJS.f7,$CLJS.l6,$CLJS.e6,$CLJS.B6,new $CLJS.h(null,
4,[$CLJS.Si,a,$CLJS.PE,l,$CLJS.Zn,d,$CLJS.hk,d+e],null),$CLJS.z6,new $CLJS.h(null,4,[$CLJS.Si,c,$CLJS.PE,k,$CLJS.Zn,b,$CLJS.hk,b+f],null)],null)}}}return null};w8=function(a,b,c){c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.Cj);if($CLJS.n(d)){var e=Ora(a,b,c);return $CLJS.n(e)?$CLJS.Re(function(f){return f.h?f.h(e):f.call(null,e)},new $CLJS.P(null,4,5,$CLJS.Q,[Pra,Qra,Rra,Sra],null)):null}return null};
Tra=function(a,b,c){return $CLJS.A(function(){return function f(e){return new $CLJS.ne(null,function(){for(var k=e;;){var l=$CLJS.y(k);if(l){var m=l,t=$CLJS.A(m);if($CLJS.n(function(){var u=$CLJS.QY(t,$CLJS.vG);return u?$CLJS.O2(t):u}())&&(l=$CLJS.y(function(u,v,x,z){return function K(G){return new $CLJS.ne(null,function(S,V){return function(){for(var Z=G;;)if(Z=$CLJS.y(Z)){if($CLJS.Ad(Z)){var ha=$CLJS.lc(Z),ra=$CLJS.D(ha),Na=$CLJS.qe(ra);return function(){for(var fb=0;;)if(fb<ra){var Sa=$CLJS.kd(ha,
fb),mb=$CLJS.O(Sa);Sa=mb;mb=$CLJS.J.g(mb,$CLJS.Si);var Ja=$CLJS.t2.g(V,new $CLJS.P(null,1,5,$CLJS.Q,[mb],null));mb=$CLJS.n(Ja)?$CLJS.E.g($CLJS.O2(V),$CLJS.O2(mb)):Ja;$CLJS.n(mb)&&(Sa=$CLJS.R.j(Sa,$CLJS.h6,V),Na.add(Sa));fb+=1}else return!0}()?$CLJS.te($CLJS.ve(Na),K($CLJS.mc(Z))):$CLJS.te($CLJS.ve(Na),null)}var zb=$CLJS.A(Z),Pa=zb=$CLJS.O(zb),Za=$CLJS.J.g(zb,$CLJS.Si);if($CLJS.n(function(){var fb=$CLJS.t2.g(V,new $CLJS.P(null,1,5,$CLJS.Q,[Za],null));return $CLJS.n(fb)?$CLJS.E.g($CLJS.O2(V),$CLJS.O2(Za)):
fb}()))return $CLJS.ee($CLJS.R.j(Pa,$CLJS.h6,V),K($CLJS.Lc(Z)));Z=$CLJS.Lc(Z)}else return null}}(u,v,x,z),null,null)}}(k,t,m,l)(c))))return $CLJS.gf.g(l,f($CLJS.Lc(k)));k=$CLJS.Lc(k)}else return null}},null,null)}($CLJS.V5.g(a,b))}())};Ura=function(a){a=$CLJS.c1.h(a);return $CLJS.n(a)?$CLJS.Id($CLJS.fh($CLJS.a8),a)?$CLJS.b8.h?$CLJS.b8.h(a):$CLJS.b8.call(null,a):null:null};
Vra=function(a){a=a instanceof $CLJS.M?a.T:null;switch(a){case "quarter":return $CLJS.XE("See this year by quarter");case "month":return $CLJS.XE("See this quarter by month");case "week":return $CLJS.XE("See this month by week");case "day":return $CLJS.XE("See this week by day");case "hour":return $CLJS.XE("See this day by hour");case "minute":return $CLJS.XE("See this hour by minute");default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}};
x8=function(a,b,c){c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.Si),e=$CLJS.J.g(c,$CLJS.RL),f=$CLJS.J.g(c,$CLJS.Cj);if($CLJS.n(function(){var l=$CLJS.$5(a,b);return l?$CLJS.n(d)?(l=$CLJS.Fe(e),$CLJS.n(l)?null!=f:l):d:l}())&&(c=Tra(a,b,e),$CLJS.n(c))){var k=Ura($CLJS.Si.h(c));return $CLJS.n(k)?new $CLJS.h(null,5,[$CLJS.fu,$CLJS.YL,$CLJS.zE,Vra(k),$CLJS.lj,$CLJS.d7,$CLJS.PF,c,$CLJS.Y6,k],null):null}return null};
Wra=function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.RL);return $CLJS.Fe(function(){return function f(e){return new $CLJS.ne(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m);a:for(var u=0;;)if(u<m){var v=$CLJS.kd(l,u);v=$CLJS.hl.l($CLJS.H([b,v]));t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.te($CLJS.ve(t),f($CLJS.mc(k))):$CLJS.te($CLJS.ve(t),null)}t=$CLJS.A(k);return $CLJS.ee($CLJS.hl.l($CLJS.H([b,t])),f($CLJS.Lc(k)))}return null}},null,
null)}(c)}())};
j8=function j8(a,b,c,d){c=$CLJS.O(c);var f=$CLJS.J.g(c,$CLJS.Si),k=$CLJS.J.g(c,$CLJS.JL),l=$CLJS.J.g(c,$CLJS.Cj);l=new $CLJS.h(null,5,[$CLJS.fu,$CLJS.YL,$CLJS.lj,$CLJS.TL,$CLJS.Si,f,$CLJS.KL,l,$CLJS.IL,d],null);var m=$CLJS.$5(a,b);if($CLJS.n(function(){var u=$CLJS.L_(f);return u?$CLJS.n(d)?m:d:u}()))return $CLJS.R.j(l,$CLJS.lj,$CLJS.TL);if($CLJS.L_(f))return $CLJS.R.j(l,$CLJS.lj,$CLJS.j7);if($CLJS.K_(f))return $CLJS.R.j(l,$CLJS.lj,$CLJS.UL);if($CLJS.n(function(){var u=$CLJS.Va(d);return u?(u=$CLJS.Fe(k),
$CLJS.n(u)?$CLJS.td($CLJS.H4.g(a,b)):u):u}())){l=d8(a);var t=$CLJS.I(l,0,null);l=$CLJS.Cj.h($CLJS.HH(function(u){return $CLJS.E.g($CLJS.T.h($CLJS.Si.h(u)),$CLJS.T.h(t))},k));if($CLJS.n($CLJS.n(l)?$CLJS.Ok.g($CLJS.T.h(f),$CLJS.T.h(t)):l))return c=$CLJS.R.l(c,$CLJS.Si,t,$CLJS.H([$CLJS.Cj,l])),j8.v?j8.v(a,b,c,d):j8.call(null,a,b,c,d)}return null};Xra=new $CLJS.r(null,"_query","_query",1604043437,null);Yra=new $CLJS.r(null,"metabase.lib.drill-thru.pivot","metabase.lib.drill-thru.pivot",109208756,null);
Zra=new $CLJS.r(null,"_stage-number","_stage-number",1726062348,null);$ra=new $CLJS.r(null,"metabase.lib.drill-thru.zoom-in-bins","metabase.lib.drill-thru.zoom-in-bins",477390405,null);asa=new $CLJS.r("metabase.lib.drill-thru.zoom-in-bins","zoom-in-binning-drill","metabase.lib.drill-thru.zoom-in-bins/zoom-in-binning-drill",-862934837,null);bsa=new $CLJS.r(null,"metabase.lib.drill-thru.summarize-column-by-time","metabase.lib.drill-thru.summarize-column-by-time",-369504986,null);
y8=new $CLJS.r(null,"stage-number","stage-number",-112198111,null);csa=new $CLJS.r(null,"underlying-records-drill","underlying-records-drill",713740052,null);dsa=new $CLJS.r("metabase.lib.drill-thru.zoom-in-timeseries","zoom-in-timeseries-drill","metabase.lib.drill-thru.zoom-in-timeseries/zoom-in-timeseries-drill",313862423,null);esa=new $CLJS.r(null,"zoom-in-timeseries-drill","zoom-in-timeseries-drill",-1304664195,null);fsa=new $CLJS.r(null,"distribution-drill","distribution-drill",-898205041,null);
gsa=new $CLJS.r(null,"sort-drill","sort-drill",600387798,null);hsa=new $CLJS.M(null,"drill","drill",-1704624793);isa=new $CLJS.r("metabase.lib.drill-thru.quick-filter","quick-filter-drill","metabase.lib.drill-thru.quick-filter/quick-filter-drill",457872114,null);z8=new $CLJS.r(null,"_context","_context",-1238791322,null);jsa=new $CLJS.r(null,"metabase.lib.drill-thru.distribution","metabase.lib.drill-thru.distribution",-514507852,null);
ksa=new $CLJS.r(null,"zoom-in-binning-drill","zoom-in-binning-drill",1550598377,null);lsa=new $CLJS.r(null,"metabase.lib.drill-thru.object-details","metabase.lib.drill-thru.object-details",1788168194,null);msa=new $CLJS.r("metabase.lib.drill-thru.zoom-in-geographic","zoom-in-geographic-drill","metabase.lib.drill-thru.zoom-in-geographic/zoom-in-geographic-drill",754461586,null);
nsa=new $CLJS.r("metabase.lib.drill-thru.distribution","distribution-drill","metabase.lib.drill-thru.distribution/distribution-drill",-2103693273,null);osa=new $CLJS.r(null,"fk-filter-drill","fk-filter-drill",-1258190784,null);psa=new $CLJS.r(null,"metabase.lib.drill-thru.summarize-column","metabase.lib.drill-thru.summarize-column",490384430,null);qsa=new $CLJS.r(null,"pivot-drill","pivot-drill",-1794856768,null);
rsa=new $CLJS.r(null,"metabase.lib.drill-thru.quick-filter","metabase.lib.drill-thru.quick-filter",671398986,null);ssa=new $CLJS.r(null,"metabase.lib.drill-thru.underlying-records","metabase.lib.drill-thru.underlying-records",-827008688,null);tsa=new $CLJS.r("metabase.lib.drill-thru.summarize-column-by-time","summarize-column-by-time-drill","metabase.lib.drill-thru.summarize-column-by-time/summarize-column-by-time-drill",391970943,null);
usa=new $CLJS.r(null,"summarize-column-drill","summarize-column-drill",-1961542613,null);vsa=new $CLJS.r(null,"column-filter-drill","column-filter-drill",-1088669153,null);wsa=new $CLJS.r("metabase.lib.drill-thru.underlying-records","underlying-records-drill","metabase.lib.drill-thru.underlying-records/underlying-records-drill",1176519262,null);xsa=new $CLJS.r(null,"metabase.lib.drill-thru.sort","metabase.lib.drill-thru.sort",961185366,null);
ysa=new $CLJS.r(null,"metabase.lib.drill-thru.zoom-in-geographic","metabase.lib.drill-thru.zoom-in-geographic",-1562146002,null);A8=new $CLJS.r(null,"dimensions","dimensions",1385713430,null);B8=new $CLJS.M(null,"return-drills-for-dimensions?","return-drills-for-dimensions?",-1759804348);zsa=new $CLJS.r("metabase.lib.drill-thru.column-filter","column-filter-drill","metabase.lib.drill-thru.column-filter/column-filter-drill",-1130871894,null);
Asa=new $CLJS.r(null,"object-detail-drill","object-detail-drill",-613919053,null);Bsa=new $CLJS.r(null,"metabase.lib.drill-thru.column-filter","metabase.lib.drill-thru.column-filter",23504270,null);C8=new $CLJS.r(null,"context","context",810340414,null);Csa=new $CLJS.r(null,"zoom-in-geographic-drill","zoom-in-geographic-drill",312914971,null);Dsa=new $CLJS.r(null,"summarize-column-by-time-drill","summarize-column-by-time-drill",1117784387,null);
Esa=new $CLJS.r("metabase.lib.drill-thru.object-details","object-detail-drill","metabase.lib.drill-thru.object-details/object-detail-drill",-1376576725,null);Fsa=new $CLJS.r("metabase.lib.drill-thru.pivot","pivot-drill","metabase.lib.drill-thru.pivot/pivot-drill",1004065694,null);Gsa=new $CLJS.r(null,"column-ref","column-ref",-636247393,null);Hsa=new $CLJS.r(null,"metabase.lib.drill-thru.fk-filter","metabase.lib.drill-thru.fk-filter",-1939155669,null);
Isa=new $CLJS.r("metabase.lib.drill-thru.summarize-column","summarize-column-drill","metabase.lib.drill-thru.summarize-column/summarize-column-drill",-894275347,null);D8=new $CLJS.r(null,"query","query",352022017,null);Jsa=new $CLJS.r(null,"metabase.lib.drill-thru.zoom-in-timeseries","metabase.lib.drill-thru.zoom-in-timeseries",105846904,null);Ksa=new $CLJS.r("metabase.lib.drill-thru.fk-filter","fk-filter-drill","metabase.lib.drill-thru.fk-filter/fk-filter-drill",-716335012,null);
Lsa=new $CLJS.r("metabase.lib.drill-thru.sort","sort-drill","metabase.lib.drill-thru.sort/sort-drill",2019212116,null);Msa=new $CLJS.r(null,"quick-filter-drill","quick-filter-drill",534264366,null);var Nsa;$CLJS.P0.m(null,$CLJS.YL,function(a,b,c){return $CLJS.c6.j(a,b,c)});
Nsa=new $CLJS.P(null,13,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.jt,new $CLJS.Gc(function(){return h8},nsa,$CLJS.Ig([$CLJS.mk,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,$CLJS.Si,$CLJS.mj,$CLJS.zk,$CLJS.ti,$CLJS.ik,$CLJS.ok],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.go,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ns,$CLJS.WL,$CLJS.Ks,$CLJS.p6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,$CLJS.k6],null)],null),jsa,fsa,"metabase/lib/drill_thru/distribution.cljc",28,1,16,16,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[D8,y8,new $CLJS.h(null,
1,[$CLJS.op,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gA,$CLJS.Ui],null)],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value]} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.distribution]\n          \n\n  Select a column and see a histogram of how many rows fall into an automatic set of bins/buckets.\n  - For dates, breaks out by month by default.\n  - For numeric values, by an auto-selected set of bins\n  - For strings, by each distinct value (which might be \x3d the number of rows)",
$CLJS.n(h8)?h8.H:null])),B8,!0],null),new $CLJS.h(null,2,[$CLJS.jt,new $CLJS.Gc(function(){return g8},zsa,$CLJS.Ig([$CLJS.mk,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,$CLJS.Si,$CLJS.mj,$CLJS.zk,$CLJS.ti,$CLJS.ik,$CLJS.ok],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.go,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ns,$CLJS.WL,$CLJS.Ks,$CLJS.p6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,$CLJS.s6],null)],null),Bsa,vsa,"metabase/lib/drill_thru/column_filter.cljc",29,1,56,56,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[D8,y8,new $CLJS.h(null,
1,[$CLJS.op,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gA,$CLJS.Ui],null)],null)],null)),'Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value]} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.column-filter]\n          \n\n  Filtering at the column level, based on its type. Displays a submenu of eg. "Today", "This Week", etc. for date\n  columns.\n\n  Note that if the clicked column is an aggregation, filtering by it will require a new stage. Therefore this drill\n  returns a possibly-updated `:query` and `:stage-number` along with a `:column` referencing that later stage.',
$CLJS.n(g8)?g8.H:null])),B8,!0],null),new $CLJS.h(null,2,[$CLJS.jt,new $CLJS.Gc(function(){return i8},Ksa,$CLJS.Ig([$CLJS.mk,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,$CLJS.Si,$CLJS.mj,$CLJS.zk,$CLJS.ti,$CLJS.ik,$CLJS.ok],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.go,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ns,$CLJS.WL,$CLJS.Ks,$CLJS.p6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,$CLJS.W6],null)],null),Hsa,osa,"metabase/lib/drill_thru/fk_filter.cljc",25,1,13,13,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[Xra,Zra,new $CLJS.h(null,
2,[$CLJS.op,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gA,$CLJS.Ui],null),$CLJS.pt,z8],null)],null)),"Inputs: [_query :- :metabase.lib.schema/query _stage-number :- :int {:keys [column value], :as _context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.fk-filter]\n          \n\n  When clicking on a foreign key value, filter this query by that column.\n\n  This has the same effect as the `\x3d` filter on a generic field (ie. not a key), but renders differently.\n\n  Contrast [[metabase.lib.drill-thru.object-details/object-detail-drill]], which shows the details of the foreign\n  object.",
$CLJS.n(i8)?i8.H:null])),B8,!1],null),new $CLJS.h(null,2,[$CLJS.jt,new $CLJS.Gc(function(){return k8},Esa,$CLJS.Ig([$CLJS.mk,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,$CLJS.Si,$CLJS.mj,$CLJS.zk,$CLJS.ti,$CLJS.ik,$CLJS.ok],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.go,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ns,$CLJS.WL,$CLJS.Ks,$CLJS.p6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ys,$CLJS.f6,$CLJS.X6,$CLJS.N6],null)],null)],null),lsa,Asa,"metabase/lib/drill_thru/object_details.cljc",
29,1,47,47,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[D8,y8,new $CLJS.h(null,2,[$CLJS.op,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gA,$CLJS.Ui],null),$CLJS.pt,C8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value], :as context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe\n           [:or\n            :metabase.lib.schema.drill-thru/drill-thru.pk\n            :metabase.lib.schema.drill-thru/drill-thru.zoom\n            :metabase.lib.schema.drill-thru/drill-thru.fk-details]]\n          \n\n  When clicking a foreign key or primary key value, drill through to the details for that specific object.\n\n  Contrast [[metabase.lib.drill-thru.fk-filter/fk-filter-drill]], which filters this query to only those rows with a\n  specific value for a FK column.",
$CLJS.n(k8)?k8.H:null])),B8,!1],null),new $CLJS.h(null,2,[$CLJS.jt,new $CLJS.Gc(function(){return m8},Fsa,$CLJS.Ig([$CLJS.mk,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,$CLJS.Si,$CLJS.mj,$CLJS.zk,$CLJS.ti,$CLJS.ik,$CLJS.ok],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.go,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ns,$CLJS.WL,$CLJS.Ks,$CLJS.p6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,$CLJS.n6],null)],null),Yra,qsa,"metabase/lib/drill_thru/pivot.cljc",21,1,76,76,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[D8,y8,new $CLJS.h(null,
2,[$CLJS.op,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gA,A8,$CLJS.Ui],null),$CLJS.pt,C8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column dimensions value], :as context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.pivot]\n          \n\n  Return all possible pivoting options on the given column and value.\n\n  See `:pivots` key, which holds a map `{t [breakouts...]}` where `t` is `:category`, `:location`, or `:time`.\n  If a key is missing, there are no breakouts of that kind.",
$CLJS.n(m8)?m8.H:null])),B8,!1],null),new $CLJS.h(null,2,[$CLJS.jt,new $CLJS.Gc(function(){return o8},isa,$CLJS.Ig([$CLJS.mk,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,$CLJS.Si,$CLJS.mj,$CLJS.zk,$CLJS.ti,$CLJS.ik,$CLJS.ok],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.go,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ns,$CLJS.WL,$CLJS.Ks,$CLJS.p6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,$CLJS.O6],null)],null),rsa,Msa,"metabase/lib/drill_thru/quick_filter.cljc",28,1,39,39,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[D8,y8,new $CLJS.h(null,
1,[$CLJS.op,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gA,$CLJS.Ui],null)],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value]} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.quick-filter]\n          \n\n  Filter the current query based on the value clicked.\n\n  The options vary depending on the type of the field:\n  - `:is-null` and `:not-null` for a `NULL` value;\n  - `:\x3d` and `:!\x3d` for everything else;\n  - plus `:\x3c` and `:\x3e` for numeric and date columns.\n\n  Note that this returns a single `::drill-thru` value with 1 or more `:operators`; these are rendered as a set of small\n  buttons in a single row of the drop-down.",
$CLJS.n(o8)?o8.H:null])),B8,!1],null),new $CLJS.h(null,2,[$CLJS.jt,new $CLJS.Gc(function(){return p8},Lsa,$CLJS.Ig([$CLJS.mk,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,$CLJS.Si,$CLJS.mj,$CLJS.zk,$CLJS.ti,$CLJS.ik,$CLJS.ok],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.go,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ns,$CLJS.WL,$CLJS.Ks,$CLJS.p6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,$CLJS.g7],null)],null),xsa,gsa,"metabase/lib/drill_thru/sort.cljc",20,1,34,34,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[D8,y8,new $CLJS.h(null,
2,[$CLJS.op,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gA,$CLJS.Ui],null),$CLJS.pt,z8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value], :as _context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.sort]\n          \n\n  Sorting on a clicked column.",$CLJS.n(p8)?p8.H:null])),B8,!0],null),new $CLJS.h(null,2,[$CLJS.jt,new $CLJS.Gc(function(){return q8},Isa,$CLJS.Ig([$CLJS.mk,$CLJS.Vi,$CLJS.T,$CLJS.pk,
$CLJS.ui,$CLJS.Si,$CLJS.mj,$CLJS.zk,$CLJS.ti,$CLJS.ik,$CLJS.ok],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.go,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ns,$CLJS.WL,$CLJS.Ks,$CLJS.p6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,$CLJS.v6],null)],null),psa,usa,"metabase/lib/drill_thru/summarize_column.cljc",32,1,11,11,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[D8,y8,new $CLJS.h(null,1,[$CLJS.op,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gA,$CLJS.Ui],null)],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value]} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.summarize-column]\n          \n\n  A set of possible aggregations that can summarize this column: distinct values, sum, average.\n  Separate from [[summarize-column-by-time-drill]] which breaks out a column over time.",
$CLJS.n(q8)?q8.H:null])),B8,!0],null),new $CLJS.h(null,2,[$CLJS.jt,new $CLJS.Gc(function(){return s8},tsa,$CLJS.Ig([$CLJS.mk,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,$CLJS.Si,$CLJS.mj,$CLJS.zk,$CLJS.ti,$CLJS.ik,$CLJS.ok],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.go,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ns,$CLJS.WL,$CLJS.Ks,$CLJS.p6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,$CLJS.t6],null)],null),bsa,Dsa,"metabase/lib/drill_thru/summarize_column_by_time.cljc",40,1,15,15,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,
[D8,y8,new $CLJS.h(null,1,[$CLJS.op,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gA,$CLJS.Ui],null)],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value]} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.summarize-column-by-time]\n          \n\n  A breakout summarizing a column over time.\n  Separate from single-value [[summarize-column-drill]] for sum, average, and distinct value count.",$CLJS.n(s8)?
s8.H:null])),B8,!0],null),new $CLJS.h(null,2,[$CLJS.jt,new $CLJS.Gc(function(){return t8},wsa,$CLJS.Ig([$CLJS.mk,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,$CLJS.Si,$CLJS.mj,$CLJS.zk,$CLJS.ti,$CLJS.ik,$CLJS.ok],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.go,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ns,$CLJS.WL,$CLJS.Ks,$CLJS.p6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,$CLJS.e7],null)],null),ssa,csa,"metabase/lib/drill_thru/underlying_records.cljc",34,1,19,19,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[D8,y8,new $CLJS.h(null,
1,[$CLJS.op,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.gA,Gsa,A8,$CLJS.Ui],null)],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column column-ref dimensions value]} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.underlying-records]\n          \n\n  When clicking on a particular broken-out group, offer a look at the details of all the rows that went into this\n  bucket. Eg. distribution of People by State, then click New York and see the table of all People filtered by\n  `STATE \x3d 'New York'`.",
$CLJS.n(t8)?t8.H:null])),B8,!1],null),new $CLJS.h(null,2,[$CLJS.jt,new $CLJS.Gc(function(){return x8},dsa,$CLJS.Ig([$CLJS.mk,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,$CLJS.Si,$CLJS.mj,$CLJS.zk,$CLJS.ti,$CLJS.ik,$CLJS.ok],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.go,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ns,$CLJS.WL,$CLJS.Ks,$CLJS.p6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,$CLJS.$6],null)],null),Jsa,esa,"metabase/lib/drill_thru/zoom_in_timeseries.cljc",34,1,56,56,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[D8,y8,
new $CLJS.h(null,1,[$CLJS.op,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gA,A8,$CLJS.Ui],null)],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column dimensions value]} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.zoom-in.timeseries]\n          \n\n  Zooms in on some window, showing it in finer detail.\n\n  For example: The month of a year, days or weeks of a quarter, smaller lat/long regions, etc.\n\n  This is different from the `:drill-thru/zoom` type, which is for showing the details of a single object.",
$CLJS.n(x8)?x8.H:null])),B8,!1],null),new $CLJS.h(null,2,[$CLJS.jt,new $CLJS.Gc(function(){return w8},msa,$CLJS.Ig([$CLJS.mk,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,$CLJS.Si,$CLJS.mj,$CLJS.zk,$CLJS.ti,$CLJS.ik,$CLJS.ok],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.go,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ns,$CLJS.WL,$CLJS.Ks,$CLJS.p6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,$CLJS.a7],null)],null),ysa,Csa,"metabase/lib/drill_thru/zoom_in_geographic.cljc",34,1,135,135,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[D8,
y8,new $CLJS.h(null,2,[$CLJS.op,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Ui],null),$CLJS.pt,C8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [value], :as context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.zoom-in.geographic]\n          \n\n  Return a `:drill-thru/zoom-in.geographic` drill if appropriate. See docstring\n  for [[metabase.lib.drill-thru.zoom-in-geographic]] for more information on what circumstances this is returned in\n  and what it means to apply this drill.",
$CLJS.n(w8)?w8.H:null])),B8,!1],null),new $CLJS.h(null,2,[$CLJS.jt,new $CLJS.Gc(function(){return u8},asa,$CLJS.Ig([$CLJS.mk,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,$CLJS.Si,$CLJS.mj,$CLJS.zk,$CLJS.ti,$CLJS.ik,$CLJS.ok],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.go,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ns,$CLJS.WL,$CLJS.Ks,$CLJS.p6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,$CLJS.c7],null)],null),$ra,ksa,"metabase/lib/drill_thru/zoom_in_bins.cljc",31,1,54,54,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[D8,y8,new $CLJS.h(null,
2,[$CLJS.op,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gA,$CLJS.Ui],null),$CLJS.pt,z8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value], :as _context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.zoom-in.binning]\n          \n\n  Return a drill thru that 'zooms in' on a breakout that uses `:binning` if applicable.\n  See [[metabase.lib.drill-thru.zoom-in-bins]] docstring for more information.",
$CLJS.n(u8)?u8.H:null])),B8,!1],null)],null);
$CLJS.Osa=function(){function a(d,e,f){try{var k=Wra(f);return $CLJS.eg.g($CLJS.Cf,function(){return function u(t){return new $CLJS.ne(null,function(){for(var v=t;;){var x=$CLJS.y(v);if(x){var z=x,C=$CLJS.A(z),G=$CLJS.O(C),K=$CLJS.J.g(G,$CLJS.jt),S=$CLJS.J.g(G,B8);if(x=$CLJS.y(function(V,Z,ha,ra,Na,zb,Pa,Za){return function mb(Sa){return new $CLJS.ne(null,function(Ja,bc,$d,Hd){return function(){for(var qd=Sa;;)if(qd=$CLJS.y(qd)){if($CLJS.Ad(qd)){var ol=$CLJS.lc(qd),r8=$CLJS.D(ol),np=$CLJS.qe(r8);
a:for(var iQ=0;;)if(iQ<r8){var sB=$CLJS.kd(ol,iQ);sB=Hd.j?Hd.j(d,e,sB):Hd.call(null,d,e,sB);$CLJS.n(sB)&&np.add(sB);iQ+=1}else{ol=!0;break a}return ol?$CLJS.te($CLJS.ve(np),mb($CLJS.mc(qd))):$CLJS.te($CLJS.ve(np),null)}np=$CLJS.A(qd);np=Hd.j?Hd.j(d,e,np):Hd.call(null,d,e,np);if($CLJS.n(np))return $CLJS.ee(np,mb($CLJS.Lc(qd)));qd=$CLJS.Lc(qd)}else return null}}(V,Z,ha,ra,Na,zb,Pa,Za),null,null)}}(v,C,G,K,S,z,x,k)($CLJS.n(function(){var V=S;return $CLJS.n(V)?k:V}())?k:new $CLJS.P(null,1,5,$CLJS.Q,[f],
null))))return $CLJS.gf.g(x,u($CLJS.Lc(v)));v=$CLJS.Lc(v)}else return null}},null,null)}(Nsa)}())}catch(m){var l=m;throw $CLJS.gi(["Error getting available drill thrus for query: ",$CLJS.p.h($CLJS.DV(l))].join(""),new $CLJS.h(null,3,[$CLJS.SF,d,$CLJS.OL,e,$CLJS.AN,f],null),l);}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);
};c.g=b;c.j=a;return c}();
$CLJS.Psa=function(){function a(d,e){return b.j?b.j(d,-1,e):b.call(null,d,-1,e)}var b=null,c=function(){function d(f,k,l,m){var t=null;if(3<arguments.length){t=0;for(var u=Array(arguments.length-3);t<u.length;)u[t]=arguments[t+3],++t;t=new $CLJS.w(u,0,null)}return e.call(this,f,k,l,t)}function e(f,k,l,m){var t=$CLJS.RA($CLJS.Oy);$CLJS.n($CLJS.QA("metabase.lib.drill-thru",t))&&$CLJS.PA("metabase.lib.drill-thru",t,$CLJS.EW("Applying drill thru: %s",$CLJS.H([$CLJS.FW(new $CLJS.h(null,4,[$CLJS.SF,f,$CLJS.OL,
k,hsa,l,$CLJS.dt,m],null))])),null);return $CLJS.Se.N($CLJS.b6,f,k,l,m)}d.A=3;d.B=function(f){var k=$CLJS.A(f);f=$CLJS.B(f);var l=$CLJS.A(f);f=$CLJS.B(f);var m=$CLJS.A(f);f=$CLJS.Lc(f);return e(k,l,m,f)};d.l=e;return d}();b=function(d,e,f,k){switch(arguments.length){case 2:return a.call(this,d,e);default:var l=null;if(3<arguments.length){l=0;for(var m=Array(arguments.length-3);l<m.length;)m[l]=arguments[l+3],++l;l=new $CLJS.w(m,0,null)}return c.l(d,e,f,l)}throw Error("Invalid arity: "+arguments.length);
};b.A=3;b.B=c.B;b.g=a;b.l=c.l;return b}();