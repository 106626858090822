var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./malli.error.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.temporal.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.literal.js");require("./metabase.mbql.schema.helpers.js");require("./metabase.mbql.schema.macros.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.registry.js");
'use strict';var bM,cM,dM,Aha,Bha,hM,Cha,kM,lM,mM,nM,Dha,oM,pM,rM,sM,eM,tM,Eha,uM,Fha,vM,wM,xM,Gha,yM,zM,Hha,AM,Iha,BM,CM,Jha,EM,Kha,Lha,Mha,GM,HM,IM,JM,KM,LM,MM,NM,Nha,OM,PM,QM,SM,TM,Oha,UM,VM,WM,XM,Pha,YM,ZM,$M,aN,Qha,bN,cN,dN,eN,Rha,fN,Sha,gN,hN,iN,Tha,jN,kN,lN,mN,oN,pN,Uha,qN,rN,Vha,sN,tN,uN,vN,wN,Wha,xN,Xha,yN,zN,BN,Yha,CN,DN,EN,Zha,HN,IN,$ha,aia,JN,LN,MN,NN,bia,QN,RN,cia,dia,eia,SN,TN,UN,VN,WN,XN,fia,YN,gia,ZN,$N,bO,dO,eO,fO,gO,hia,iia,iO,jia,kia,jO,kO,lO,mO,nO,lia,oO,mia,nia,pO,qO,rO,sO,tO,
oia,pia,qia,uO,ria,vO,sia,xO,yO,tia,AO,BO,CO,DO,uia,GO,via,wia,HO,IO,JO,xia,KO,LO,MO,NO,yia,PO,QO,RO,SO,TO,zia,UO,VO,Aia,WO,XO,YO,ZO,$O,aP,cP,Bia,dP,Cia,eP,fP,gP,Dia,Eia,Fia,iP,jP,kP,Gia,lP,mP,nP,oP,Hia,Iia,qP,Jia,Kia,rP,sP,tP,uP,vP,Lia,wP,xP,yP,zP,Mia,AP,BP,CP,DP,FP,Nia,GP,HP,Oia,IP,JP,KP,Pia,LP,Qia,NP,Ria,OP,PP,QP,RP,SP,TP,UP,Sia,Tia,Uia,VP,Via,WP,XP,Wia,Xia,YP,ZP,$P,Yia,aQ,Zia,bQ,$ia,aja,cQ,bja,cja,dja,fQ,gQ,eja,hQ,jQ,lQ,fja,mQ,gja,nQ,oQ,pQ,hja,ija,qQ,rQ,sQ,tQ,jja,vQ,wQ,kja,xQ,yQ,zQ,AQ,BQ,CQ,DQ,
EQ,lja,FQ,GQ,mja,HQ,nja,IQ,JQ,KQ,oja,LQ,MQ,pja,qja,NQ,OQ,PQ,rja,sja,QQ,tja,uja,vja,SQ,wja,UQ,xja,yja,VQ,WQ,zja,Aja,Bja,XQ,YQ,ZQ,$Q,aR,bR,cR,Cja,dR,eR,Dja,fR,Eja,Fja,Gja,gR,hR,iR,Hja,jR,kR,Ija,lR,Jja,mR,Kja,Lja,oR,pR,qR,rR,sR,Mja,tR,uR,Nja,vR,wR,xR,yR,zR,AR,BR,CR,Oja,DR,ER,GR,HR,IR,JR,KR,LR,MR,NR,OR,PR,Pja,QR,RR,SR,TR,UR,Qja,VR,WR,YR,ZR,Rja,aS,Sja,Tja,cS,Uja,dS,Vja,eS,Wja,Xja,fS,Yja,gS,iS,jS,Zja,$ja,nS,oS,aka,bka,pS,qS,rS,sS,tS,cka,uS,vS,xS,yS,dka;
$CLJS.aM=function(a){var b=$CLJS.yo.g(a,null),c=$CLJS.zF(b,$CLJS.Us,function(d){return $CLJS.xn(d,$CLJS.Cf)});return function(){function d(k,l,m){return(l=$CLJS.y(c.j?c.j(k,l,m):c.call(null,k,l,m)))?new $CLJS.h(null,3,[$CLJS.mk,b,$CLJS.Cj,k,$CLJS.$s,l],null):null}function e(k){return f.j(k,$CLJS.Cf,$CLJS.Cf)}var f=null;f=function(k,l,m){switch(arguments.length){case 1:return e.call(this,k);case 3:return d.call(this,k,l,m)}throw Error("Invalid arity: "+arguments.length);};f.h=e;f.j=d;return f}()};
bM=function(a,b){return $CLJS.xd(a)?$CLJS.J.g(a,b):a};cM=function(a,b,c,d){d=$CLJS.n(d)?d:$CLJS.An($CLJS.yo.g($CLJS.mk.h(a),null));if($CLJS.n(b)){var e=bM($CLJS.At.h(b),c);$CLJS.n(e)?(e=$CLJS.Iq(e,d),a=e.g?e.g(a,d):e.call(null,a,d)):a=null;return $CLJS.n(a)?a:bM($CLJS.yt.h(b),c)}return null};dM=function(a,b){return $CLJS.vd(a)||$CLJS.Jl(a)?$CLJS.J.g(a,b):$CLJS.wd(a)?$CLJS.J.g($CLJS.Df(a),b):null};
Aha=function(a,b){a=$CLJS.O(a);var c=$CLJS.J.g(a,$CLJS.mk),d=$CLJS.J.g(a,$CLJS.lj);b=$CLJS.O(b);var e=$CLJS.J.j(b,$CLJS.$s,$CLJS.kea),f=$CLJS.J.j(b,$CLJS.fea,!0),k=$CLJS.J.g(b,$CLJS.lC),l=$CLJS.J.j(b,$CLJS.jC,$CLJS.hC),m=cM(a,$CLJS.Uq.h(c),k,b);if($CLJS.n(m))return m;m=cM(a,$CLJS.lt.h(c),k,b);if($CLJS.n(m))return m;m=cM(a,e.h?e.h(d):e.call(null,d),k,b);if($CLJS.n(m))return m;m=cM(a,function(){var t=$CLJS.fo.h(c);return e.h?e.h(t):e.call(null,t)}(),k,b);if($CLJS.n(m))return m;m=cM(a,$CLJS.Uq.h(c),
l,b);if($CLJS.n(m))return m;m=cM(a,$CLJS.lt.h(c),l,b);if($CLJS.n(m))return m;d=cM(a,e.h?e.h(d):e.call(null,d),l,b);if($CLJS.n(d))return d;d=cM(a,function(){var t=$CLJS.fo.h(c);return e.h?e.h(t):e.call(null,t)}(),l,b);if($CLJS.n(d))return d;k=$CLJS.n(f)?cM(a,e.h?e.h($CLJS.fC):e.call(null,$CLJS.fC),k,b):f;return $CLJS.n(k)?k:$CLJS.n(f)?cM(a,e.h?e.h($CLJS.fC):e.call(null,$CLJS.fC),l,b):f};
Bha=function(a,b,c){a=$CLJS.Q;var d=$CLJS.eg.g;var e=$CLJS.nl.h(b);var f=$CLJS.O(b);var k=$CLJS.J.g(f,$CLJS.mk),l=$CLJS.O(c);f=$CLJS.J.g(l,$CLJS.lC);l=$CLJS.J.j(l,$CLJS.jC,$CLJS.hC);k=$CLJS.Uq.h(k);f=bM($CLJS.iC.h(k),f);f=$CLJS.n(f)?f:bM($CLJS.iC.h(k),l);d=d.call($CLJS.eg,e,f);return new $CLJS.P(null,2,5,a,[d,Aha(b,c)],null)};
$CLJS.fM=function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.Cj);a=$CLJS.J.g(b,$CLJS.$s);var d=$CLJS.O(null),e=$CLJS.J.j(d,$CLJS.gea,$CLJS.Pi),f=$CLJS.J.j(d,$CLJS.iea,Bha);return $CLJS.n(a)?$CLJS.eb(function(k,l){var m=f.j?f.j(b,l,d):f.call(null,b,l,d),t=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);l=$CLJS.R.j(l,$CLJS.Pi,m);l=e.h?e.h(l):e.call(null,l);return eM(k,c,t,l)},null,a):null};
hM=function(a,b,c){var d=$CLJS.IA($CLJS.q($CLJS.kE),new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null));if($CLJS.n(d))return d;c=c.o?c.o():c.call(null);$CLJS.Oh.v($CLJS.kE,$CLJS.gM,new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null),c);return c};$CLJS.iM=function(a){return hM($CLJS.yk,a,function(){return $CLJS.AF.h(a)})};Cha=function(){var a=jM;return hM($CLJS.Us,a,function(){var b=$CLJS.AF.h(a),c=$CLJS.aM(a);return function(d){return $CLJS.n(b.h?b.h(d):b.call(null,d))?null:c.h?c.h(d):c.call(null,d)}})};
kM=function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,$CLJS.me(a)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,a],null):a],null)};lM=function(a){if($CLJS.zd(a)){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);switch(b instanceof $CLJS.M?b.T:null){case "optional":return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,kM(c)],null)],null);case "rest":return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.es,kM(c)],null);default:return kM(a)}}else return kM(a)};
mM=function(a,b){var c=$CLJS.zd(b);return c?(c=$CLJS.A(b)instanceof $CLJS.M)?$CLJS.ud(a)?(b=$CLJS.A(b),a=$CLJS.fh(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,$CLJS.A(b)):c:c};
nM=function(a,b){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.yt,["not a ",$CLJS.p.h(a)," clause"].join("")],null),$CLJS.Ve(mM,a)],null),$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,new $CLJS.P(null,2,5,$CLJS.Q,["tag",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tk,a],null)],null)],null),function(){return function e(d){return new $CLJS.ne(null,function(){for(;;){var f=$CLJS.y(d);if(f){if($CLJS.Ad(f)){var k=$CLJS.lc(f),l=$CLJS.D(k),m=$CLJS.qe(l);
a:for(var t=0;;)if(t<l){var u=$CLJS.kd(k,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);v=new $CLJS.P(null,2,5,$CLJS.Q,[v,lM(u)],null);m.add(v);t+=1}else{k=!0;break a}return k?$CLJS.te($CLJS.ve(m),e($CLJS.mc(f))):$CLJS.te($CLJS.ve(m),null)}k=$CLJS.A(f);m=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[m,lM(k)],null),e($CLJS.Lc(f)))}return null}},null,null)}($CLJS.ot(2,2,b))}())],null)};Dha=function(a){return $CLJS.zd(a)&&$CLJS.A(a)instanceof $CLJS.M?$CLJS.A(a):null};
oM=function(a){return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,2,[$CLJS.Ni,Dha,$CLJS.yt,["valid instance of one of these MBQL clauses: ",$CLJS.Jt(", ",$CLJS.hf.g($CLJS.A,a))].join("")],null)],null),function(){return function d(c){return new $CLJS.ne(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.qe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[u,
$CLJS.me(t)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,t],null):t],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.mc(e))):$CLJS.te($CLJS.ve(l),null)}f=$CLJS.A(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[l,$CLJS.me(f)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,f],null):f],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())};
pM=function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$L],null)],null)};$CLJS.qM={};rM={};sM={};$CLJS.gM=function gM(a,b,c){var e=$CLJS.y(b);b=$CLJS.A(e);var f=$CLJS.B(e);if(f){e=$CLJS.R.j;var k=$CLJS.J.g(a,b);c=gM.j?gM.j(k,f,c):gM.call(null,k,f,c);a=e.call($CLJS.R,a,b,c)}else a=$CLJS.R.j(a,b,c);return a};
eM=function eM(a,b,c,d){var f=$CLJS.y(c);c=$CLJS.A(f);var k=$CLJS.B(f),l=dM(b,c);f=$CLJS.n(a)?a:$CLJS.wd(b)?$CLJS.Cf:$CLJS.yd(b)?$CLJS.N:$CLJS.jd(b);return $CLJS.n($CLJS.n(c)?$CLJS.gC.h($CLJS.pd(f)):c)?a:$CLJS.n(c)?(b=dM(f,c),d=eM.v?eM.v(b,l,k,d):eM.call(null,b,l,k,d),$CLJS.Ol(c)&&$CLJS.wd(f)&&c>$CLJS.D(f)&&(b=$CLJS.df(c-$CLJS.D(f),null),b=$CLJS.gf.g(f,b),f=null==f||$CLJS.Dd(f)?b:$CLJS.eg.g($CLJS.jd(f),b)),null==f||$CLJS.Jl(f)?$CLJS.R.j(f,c,d):$CLJS.vd(f)?$CLJS.be.g(f,d):$CLJS.Se.g($CLJS.U,$CLJS.R.j($CLJS.Df(f),
c,d))):$CLJS.xd(a)?(c=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.hea],null),eM.v?eM.v(f,b,c,d):eM.call(null,f,b,c,d)):$CLJS.n($CLJS.gC.h($CLJS.pd(f)))?$CLJS.be.g(f,d):$CLJS.zd($CLJS.Fe(f))?f:$CLJS.od(new $CLJS.P(null,1,5,$CLJS.Q,[d],null),new $CLJS.h(null,1,[$CLJS.gC,!0],null))};tM=new $CLJS.M("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",-662824303);Eha=new $CLJS.r("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",-1242286173,null);
uM=new $CLJS.M("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",-345484364);Fha=new $CLJS.r("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",1275783375,null);vM=new $CLJS.M("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",1412149596);wM=new $CLJS.M("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",-985876836);xM=new $CLJS.M("metabase.mbql.schema","substring","metabase.mbql.schema/substring",188871083);
Gha=new $CLJS.r("metabase.mbql.schema","replace","metabase.mbql.schema/replace",1269362826,null);yM=new $CLJS.M("metabase.mbql.schema","TimeLiteral","metabase.mbql.schema/TimeLiteral",-822346498);zM=new $CLJS.M("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",549586740);Hha=new $CLJS.M(null,"disable-mbql-\x3enative?","disable-mbql-\x3enative?",1103196677);AM=new $CLJS.M("metabase.mbql.schema","StringExpressionArg","metabase.mbql.schema/StringExpressionArg",596241960);
Iha=new $CLJS.M(null,"from","from",1815293044);BM=new $CLJS.M("metabase.mbql.schema","and","metabase.mbql.schema/and",-86675456);CM=new $CLJS.r(null,"does-not-contain","does-not-contain",1365584674,null);Jha=new $CLJS.M(null,"add-default-userland-constraints?","add-default-userland-constraints?",2039156190);$CLJS.DM=new $CLJS.M(null,"snippet-name","snippet-name",819240328);EM=new $CLJS.M("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",560735368);
Kha=new $CLJS.r("metabase.mbql.schema","max","metabase.mbql.schema/max",-1397867614,null);Lha=new $CLJS.r("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",-1600012951,null);$CLJS.FM=new $CLJS.M(null,"aggregation-options","aggregation-options",-1904917550);Mha=new $CLJS.r("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",596526831,null);GM=new $CLJS.M("location","zip_code","location/zip_code",1641155222);
HM=new $CLJS.r(null,"ExpressionArg","ExpressionArg",1060743736,null);IM=new $CLJS.r(null,"get-hour","get-hour",-2031721710,null);JM=new $CLJS.M("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",-140332301);KM=new $CLJS.r(null,"ag:var","ag:var",-1804440286,null);LM=new $CLJS.M("metabase.mbql.schema","IntGreaterThanZeroOrNumericExpression","metabase.mbql.schema/IntGreaterThanZeroOrNumericExpression",1485789132);
MM=new $CLJS.M("metabase.mbql.schema","exp","metabase.mbql.schema/exp",-1188751039);NM=new $CLJS.M("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",-364748152);Nha=new $CLJS.M(null,"lon-max","lon-max",1590224717);OM=new $CLJS.M("metabase.mbql.schema","asc","metabase.mbql.schema/asc",-302489464);PM=new $CLJS.r(null,"stddev","stddev",775056588,null);QM=new $CLJS.M("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-1954451840);
$CLJS.RM=new $CLJS.M(null,"snippet","snippet",953581994);SM=new $CLJS.r("metabase.mbql.schema","case","metabase.mbql.schema/case",2100482976,null);TM=new $CLJS.M(null,"string-expression","string-expression",-1395337766);Oha=new $CLJS.M(null,"lat-field","lat-field",-830652957);UM=new $CLJS.M("metabase.mbql.schema","now","metabase.mbql.schema/now",-975392912);VM=new $CLJS.M("metabase.mbql.schema","avg","metabase.mbql.schema/avg",1123586863);
WM=new $CLJS.M("metabase.mbql.schema","not","metabase.mbql.schema/not",-1753911131);XM=new $CLJS.M("metabase.mbql.schema","StringExpression","metabase.mbql.schema/StringExpression",-670335423);Pha=new $CLJS.r("metabase.mbql.schema","min","metabase.mbql.schema/min",-1534510688,null);YM=new $CLJS.M("location","country","location/country",1666636202);ZM=new $CLJS.M("metabase.mbql.schema","trim","metabase.mbql.schema/trim",350521664);
$M=new $CLJS.M("metabase.mbql.schema","Reference","metabase.mbql.schema/Reference",-770092616);aN=new $CLJS.M("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",-1080110117);Qha=new $CLJS.r("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",-1224231786,null);bN=new $CLJS.M(null,"unary","unary",-989314568);cN=new $CLJS.M("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",297284410);
dN=new $CLJS.M("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",324961083);eN=new $CLJS.r(null,"ceil","ceil",-184398425,null);Rha=new $CLJS.M(null,"lon-min","lon-min",-787291357);fN=new $CLJS.r(null,"NonBlankString","NonBlankString",-719244809,null);Sha=new $CLJS.M(null,"match","match",1220059550);gN=new $CLJS.r(null,"count-where","count-where",2025939247,null);hN=new $CLJS.M("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",1844658706);
iN=new $CLJS.M(null,"max-results-bare-rows","max-results-bare-rows",2096475889);Tha=new $CLJS.M(null,"embedded-question","embedded-question",-2146473954);jN=new $CLJS.M("metabase.mbql.schema","WidgetType","metabase.mbql.schema/WidgetType",1831406784);kN=new $CLJS.r(null,"sum","sum",1777518341,null);lN=new $CLJS.M("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",197727284);mN=new $CLJS.r("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",-807630752,null);
$CLJS.nN=new $CLJS.M("date","range","date/range",1647265776);oN=new $CLJS.r(null,"between","between",-1523336493,null);pN=new $CLJS.M(null,"clause-form","clause-form",1820463737);Uha=new $CLJS.r("metabase.mbql.schema","log","metabase.mbql.schema/log",-899459908,null);qN=new $CLJS.M("metabase.mbql.schema","Query","metabase.mbql.schema/Query",1321874752);rN=new $CLJS.r(null,"field","field",338095027,null);Vha=new $CLJS.M(null,"segment-id","segment-id",1810133590);
sN=new $CLJS.M("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",94927598);tN=new $CLJS.r(null,"not-null","not-null",313812992,null);uN=new $CLJS.M("metabase.mbql.schema","Joins","metabase.mbql.schema/Joins",1376278278);vN=new $CLJS.M("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",-1587636578);wN=new $CLJS.M("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",556298105);Wha=new $CLJS.M(null,"template-tag","template-tag",310841038);
xN=new $CLJS.M(null,"invalid","invalid",412869516);Xha=new $CLJS.r("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",1838258811,null);yN=new $CLJS.M("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",1473793750);zN=new $CLJS.r(null,"regex-match-first","regex-match-first",983231809,null);$CLJS.AN=new $CLJS.M(null,"context","context",-830191113);BN=new $CLJS.r(null,"get-year","get-year",704520253,null);
Yha=new $CLJS.M(null,"format-rows?","format-rows?",992129486);CN=new $CLJS.M("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",336705322);DN=new $CLJS.M("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",829238608);EN=new $CLJS.M("metabase.mbql.schema","variable","metabase.mbql.schema/variable",672451083);$CLJS.FN=new $CLJS.M(null,"parameters","parameters",-1229919748);Zha=new $CLJS.M(null,"json-download","json-download",-971130133);
$CLJS.GN=new $CLJS.M(null,"card","card",-1430355152);HN=new $CLJS.M(null,"string-or-field","string-or-field",-1970678542);IN=new $CLJS.r(null,"!\x3d","!\x3d",-201205829,null);$ha=new $CLJS.r("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",1295047163,null);aia=new $CLJS.r("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",-1404628626,null);JN=new $CLJS.r(null,"expression","expression",1842843403,null);$CLJS.KN=new $CLJS.M("date","month-year","date/month-year",1948031290);
LN=new $CLJS.M("metabase.mbql.schema","BooleanExpression","metabase.mbql.schema/BooleanExpression",-2109835363);MN=new $CLJS.r(null,"ends-with","ends-with",1183740516,null);NN=new $CLJS.M("metabase.mbql.schema","time","metabase.mbql.schema/time",727162193);$CLJS.ON=new $CLJS.M(null,"joins","joins",1033962699);bia=new $CLJS.M(null,"slug","slug",2029314850);$CLJS.PN=new $CLJS.M(null,"source-field","source-field",933829534);QN=new $CLJS.r(null,"Field","Field",430385967,null);
RN=new $CLJS.r(null,"convert-timezone","convert-timezone",1515728526,null);cia=new $CLJS.r("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",-1180642019,null);dia=new $CLJS.M(null,"disable-max-results?","disable-max-results?",857693204);eia=new $CLJS.M(null,"items","items",1031954938);SN=new $CLJS.M(null,"datetime-expression","datetime-expression",391782195);TN=new $CLJS.M("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",1605865100);
UN=new $CLJS.M(null,"more","more",-2058821800);VN=new $CLJS.M(null,"first-clause","first-clause",-20953491);WN=new $CLJS.M("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",-671453147);XN=new $CLJS.r(null,"OrderComparable","OrderComparable",772072595,null);fia=new $CLJS.r("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",-2117292579,null);YN=new $CLJS.r(null,"contains","contains",-1977535957,null);
gia=new $CLJS.r("metabase.mbql.schema","floor","metabase.mbql.schema/floor",422926927,null);ZN=new $CLJS.r("metabase.mbql.schema","expression","metabase.mbql.schema/expression",370334320,null);$N=new $CLJS.M("number","\x3d","number/\x3d",-2094581309);$CLJS.aO=new $CLJS.M(null,"widget-type","widget-type",1836256899);bO=new $CLJS.M("metabase.mbql.schema","max","metabase.mbql.schema/max",1256568155);$CLJS.cO=new $CLJS.M(null,"fingerprint","fingerprint",598613022);
dO=new $CLJS.r(null,"is-null","is-null",-356519403,null);eO=new $CLJS.M(null,"other-clauses","other-clauses",1570511021);fO=new $CLJS.M("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",-1003477115);gO=new $CLJS.M("metabase.mbql.schema","upper","metabase.mbql.schema/upper",-646839893);hia=new $CLJS.M(null,"map-tiles","map-tiles",1961865797);$CLJS.hO=new $CLJS.M(null,"required","required",1807647006);iia=new $CLJS.M(null,"embedded-csv-download","embedded-csv-download",1858870014);
iO=new $CLJS.M(null,"unnamed-aggregation","unnamed-aggregation",-93854280);jia=new $CLJS.M(null,"datetime-x","datetime-x",1519265947);kia=new $CLJS.M(null,"datetime-y","datetime-y",-1666955771);jO=new $CLJS.r(null,"CaseClauses","CaseClauses",-1749071354,null);kO=new $CLJS.M(null,"date-arithmetics","date-arithmetics",-1832808309);lO=new $CLJS.M("string","contains","string/contains",1602423827);mO=new $CLJS.M("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",1430203983);
nO=new $CLJS.M("metabase.mbql.schema","or","metabase.mbql.schema/or",1154080514);lia=new $CLJS.r("metabase.mbql.schema","metric","metabase.mbql.schema/metric",1030004285,null);oO=new $CLJS.M("string","!\x3d","string/!\x3d",-1083772573);mia=new $CLJS.r(null,"IntGreaterThanZeroOrNumericExpression","IntGreaterThanZeroOrNumericExpression",2098230228,null);nia=new $CLJS.r("metabase.mbql.schema","count","metabase.mbql.schema/count",678066683,null);
pO=new $CLJS.M("metabase.mbql.schema","min","metabase.mbql.schema/min",1119925081);qO=new $CLJS.r("metabase.mbql.schema","field","metabase.mbql.schema/field",1566852856,null);rO=new $CLJS.M("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",-11600894);sO=new $CLJS.M("metabase.mbql.schema","Join","metabase.mbql.schema/Join",-2100258965);tO=new $CLJS.r(null,"share","share",1051097594,null);
oia=new $CLJS.r("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",-823840515,null);pia=new $CLJS.r("metabase.mbql.schema","round","metabase.mbql.schema/round",314817788,null);qia=new $CLJS.r("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",2007639089,null);uO=new $CLJS.M("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",1830595254);ria=new $CLJS.M(null,"xlsx-download","xlsx-download",-1622892009);
vO=new $CLJS.M("metabase.mbql.schema","contains","metabase.mbql.schema/contains",-241280695);sia=new $CLJS.r("metabase.mbql.schema","contains","metabase.mbql.schema/contains",1399250832,null);$CLJS.wO=new $CLJS.M(null,"collection","collection",-683361892);xO=new $CLJS.M("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",45949435);yO=new $CLJS.M("metabase.mbql.schema","median","metabase.mbql.schema/median",414562044);
$CLJS.zO=new $CLJS.M("metadata","dataset-metadata","metadata/dataset-metadata",-728376469);tia=new $CLJS.r("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",1686480962,null);AO=new $CLJS.M("number","\x3c\x3d","number/\x3c\x3d",-1499316353);BO=new $CLJS.r(null,"metric","metric",2049329604,null);CO=new $CLJS.r(null,"concat","concat",-467652465,null);DO=new $CLJS.M(null,"variable","variable",-281346492);$CLJS.EO=new $CLJS.M("date","quarter-year","date/quarter-year",-1453950150);
uia=new $CLJS.r(null,"TimeUnit","TimeUnit",1200517789,null);$CLJS.FO=new $CLJS.M("date","relative","date/relative",25987732);GO=new $CLJS.M("location","city","location/city",-1746973325);via=new $CLJS.M(null,"public-dashboard","public-dashboard",645968405);wia=new $CLJS.r("metabase.mbql.schema","desc","metabase.mbql.schema/desc",-2050113424,null);HO=new $CLJS.M("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",1054422818);IO=new $CLJS.M("number","\x3e\x3d","number/\x3e\x3d",-1670691032);
JO=new $CLJS.M("number","between","number/between",97700581);xia=new $CLJS.r("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",-2078342061,null);KO=new $CLJS.M("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",-576867936);LO=new $CLJS.r(null,"sqrt","sqrt",370479598,null);MO=new $CLJS.M("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",515738880);NO=new $CLJS.M("metabase.mbql.schema","expression","metabase.mbql.schema/expression",-1270197207);
$CLJS.OO=new $CLJS.M(null,"semantic_type","semantic_type",272485089);yia=new $CLJS.M(null,"metric-id","metric-id",-686486942);PO=new $CLJS.r(null,"*","*",345799209,null);QO=new $CLJS.r(null,"+","+",-740910886,null);RO=new $CLJS.r(null,"-","-",-471816912,null);SO=new $CLJS.r(null,"template-tag","template-tag",1951372565,null);TO=new $CLJS.M(null,"allowed-for","allowed-for",122724334);zia=new $CLJS.M(null,"question","question",-1411720117);UO=new $CLJS.r(null,"asc","asc",1997386096,null);
VO=new $CLJS.M("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",-391950078);Aia=new $CLJS.M(null,"pulse-id","pulse-id",1331432237);WO=new $CLJS.M("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",277590849);XO=new $CLJS.r(null,"\x3c\x3d","\x3c\x3d",1244895369,null);YO=new $CLJS.r(null,"\x3c","\x3c",993667236,null);ZO=new $CLJS.r(null,"\x3e","\x3e",1085014381,null);$O=new $CLJS.r(null,"\x3d","\x3d",-1501502141,null);
aP=new $CLJS.M("metabase.mbql.schema","OrderComparable","metabase.mbql.schema/OrderComparable",1970325905);$CLJS.bP=new $CLJS.M(null,"source-metadata","source-metadata",-477816085);cP=new $CLJS.M("string","ends-with","string/ends-with",302681156);Bia=new $CLJS.r("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-265030428,null);dP=new $CLJS.r(null,"RelativeDatetimeUnit","RelativeDatetimeUnit",-1157637501,null);Cia=new $CLJS.r(null,"MetricID","MetricID",-2128635641,null);
eP=new $CLJS.r(null,"and","and",668631710,null);fP=new $CLJS.r(null,"\x3e\x3d","\x3e\x3d",1016916022,null);gP=new $CLJS.r(null,"round","round",-645002441,null);Dia=new $CLJS.M(null,"to","to",192099007);$CLJS.hP=new $CLJS.M("date","single","date/single",1554682003);Eia=new $CLJS.M(null,"action-id","action-id",-1727958578);Fia=new $CLJS.r("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",1248581449,null);iP=new $CLJS.r(null,"exp","exp",1378825265,null);
jP=new $CLJS.r(null,"Filter","Filter",-424893332,null);kP=new $CLJS.r(null,"cum-count","cum-count",-323900016,null);Gia=new $CLJS.M(null,"dashboard-id","dashboard-id",1965414288);lP=new $CLJS.M("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-1731861963);mP=new $CLJS.M("metabase.mbql.schema","abs","metabase.mbql.schema/abs",152348622);nP=new $CLJS.M("metabase.mbql.schema","desc","metabase.mbql.schema/desc",604322345);
oP=new $CLJS.M(null,"numeric-expression","numeric-expression",-1661337235);$CLJS.pP=new $CLJS.M(null,"source-table","source-table",-225307692);Hia=new $CLJS.M(null,"embedded-dashboard","embedded-dashboard",-485078014);Iia=new $CLJS.r("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",-824662873,null);qP=new $CLJS.r(null,"floor","floor",-772394748,null);Jia=new $CLJS.M(null,"middleware","middleware",1462115504);
Kia=new $CLJS.r("metabase.mbql.schema","share","metabase.mbql.schema/share",1977664161,null);rP=new $CLJS.M(null,"requires-features","requires-features",-101116256);sP=new $CLJS.M("metabase.mbql.schema","power","metabase.mbql.schema/power",2136530448);tP=new $CLJS.M(null,"clause-name","clause-name",-996419059);uP=new $CLJS.M("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-1905561955);vP=new $CLJS.r(null,"now","now",-9994004,null);
Lia=new $CLJS.r("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",654654691,null);wP=new $CLJS.r(null,"not","not",1044554643,null);xP=new $CLJS.r(null,"avg","avg",1837937727,null);yP=new $CLJS.r(null,"sum-where","sum-where",-519087341,null);zP=new $CLJS.M(null,"max-results","max-results",-32858165);Mia=new $CLJS.r("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",560421410,null);AP=new $CLJS.r(null,"case","case",-1510733573,null);
BP=new $CLJS.r(null,"distinct","distinct",-148347594,null);CP=new $CLJS.r(null,"get-second","get-second",-425414791,null);DP=new $CLJS.M("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",537143190);$CLJS.EP=new $CLJS.M(null,"join-alias","join-alias",1454206794);FP=new $CLJS.r(null,"is-empty","is-empty",600228619,null);Nia=new $CLJS.r("metabase.mbql.schema","segment","metabase.mbql.schema/segment",-512121472,null);
GP=new $CLJS.r(null,"relative-datetime","relative-datetime",-1369266491,null);HP=new $CLJS.M("number","!\x3d","number/!\x3d",-673025509);Oia=new $CLJS.M(null,"original","original",-445386197);IP=new $CLJS.M("metabase.mbql.schema","interval","metabase.mbql.schema/interval",-1491935720);JP=new $CLJS.r(null,"abs","abs",1394505050,null);KP=new $CLJS.r(null,"datetime-diff","datetime-diff",-1521323614,null);Pia=new $CLJS.r("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",-809777063,null);
LP=new $CLJS.M("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",-1044004696);Qia=new $CLJS.r(null,"DatetimeDiffUnit","DatetimeDiffUnit",-1310225671,null);$CLJS.MP=new $CLJS.M(null,"date","date",-1463434462);NP=new $CLJS.M(null,"second-clause","second-clause",-461435645);Ria=new $CLJS.r("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",551389774,null);OP=new $CLJS.r(null,"rtrim","rtrim",979195078,null);
PP=new $CLJS.M("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",1829772896);QP=new $CLJS.M("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",-1089141753);RP=new $CLJS.r("metabase.mbql.schema","or","metabase.mbql.schema/or",-1500355255,null);SP=new $CLJS.M("metabase.mbql.schema","Filter","metabase.mbql.schema/Filter",1210108656);TP=new $CLJS.r(null,"CaseOptions","CaseOptions",1989286806,null);UP=new $CLJS.r(null,"or","or",1876275696,null);
Sia=new $CLJS.r("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",52894949,null);Tia=new $CLJS.M(null,"constraints","constraints",422775616);Uia=new $CLJS.r("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",1500199226,null);VP=new $CLJS.M("metabase.mbql.schema","Field","metabase.mbql.schema/Field",-551724627);Via=new $CLJS.M(null,"csv-download","csv-download",2141432084);WP=new $CLJS.M("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",367107562);
XP=new $CLJS.r(null,"datetime-add","datetime-add",1850134938,null);Wia=new $CLJS.r("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-91330436,null);Xia=new $CLJS.r("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",-2104849029,null);YP=new $CLJS.r("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",-1619862474,null);ZP=new $CLJS.r("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",1850085384,null);
$P=new $CLJS.r("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",-1057391587,null);Yia=new $CLJS.r("metabase.mbql.schema","/","metabase.mbql.schema//",2004134796,null);aQ=new $CLJS.r(null,"get-quarter","get-quarter",-1326657176,null);Zia=new $CLJS.r("metabase.mbql.schema","-","metabase.mbql.schema/-",-1440604423,null);bQ=new $CLJS.r("metabase.mbql.schema","+","metabase.mbql.schema/+",-1802115711,null);$ia=new $CLJS.r("metabase.mbql.schema","*","metabase.mbql.schema/*",1842546528,null);
aja=new $CLJS.r("metabase.mbql.schema","time","metabase.mbql.schema/time",-1927273576,null);cQ=new $CLJS.r(null,"temporal-extract","temporal-extract",-872749364,null);bja=new $CLJS.r("metabase.mbql.schema","sum","metabase.mbql.schema/sum",-1589890100,null);cja=new $CLJS.r("metabase.mbql.schema","between","metabase.mbql.schema/between",-302972630,null);dja=new $CLJS.M(null,"display_name","display_name",-1494335013);$CLJS.dQ=new $CLJS.M(null,"snippet-id","snippet-id",1987785841);
$CLJS.eQ=new $CLJS.M(null,"database","database",1849087575);fQ=new $CLJS.r(null,"NumericExpressionArgOrInterval","NumericExpressionArgOrInterval",-151709337,null);gQ=new $CLJS.r(null,"get-day-of-week","get-day-of-week",-644295017,null);eja=new $CLJS.r("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",1063663591,null);hQ=new $CLJS.M("metabase.mbql.schema","floor","metabase.mbql.schema/floor",-1217604600);
jQ=new $CLJS.M("metabase.mbql.schema","NumericExpressionArgOrInterval","metabase.mbql.schema/NumericExpressionArgOrInterval",-596692841);$CLJS.kQ=new $CLJS.M(null,"expressions","expressions",255689909);lQ=new $CLJS.M("metabase.mbql.schema","ExpressionArg","metabase.mbql.schema/ExpressionArg",372701340);fja=new $CLJS.M(null,"ad-hoc","ad-hoc",-2033634036);mQ=new $CLJS.M("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",912159158);
gja=new $CLJS.r("metabase.mbql.schema","concat","metabase.mbql.schema/concat",-580718778,null);nQ=new $CLJS.M("metabase.mbql.schema","aggregation-options","metabase.mbql.schema/aggregation-options",-936374135);oQ=new $CLJS.M("metabase.mbql.schema","log","metabase.mbql.schema/log",1754975861);pQ=new $CLJS.r(null,"absolute-datetime","absolute-datetime",1080191062,null);hja=new $CLJS.r(null,"TimeIntervalOptions","TimeIntervalOptions",1566864026,null);ija=new $CLJS.M(null,"action","action",-811238024);
qQ=new $CLJS.r(null,"get-day","get-day",1768100384,null);rQ=new $CLJS.M("metabase.mbql.schema","MBQLQuery","metabase.mbql.schema/MBQLQuery",323823775);sQ=new $CLJS.M("metabase.mbql.schema","between","metabase.mbql.schema/between",-1943504157);tQ=new $CLJS.r(null,"not-empty","not-empty",2029453590,null);$CLJS.uQ=new $CLJS.M(null,"native","native",-613060878);jja=new $CLJS.r("metabase.mbql.schema","variable","metabase.mbql.schema/variable",-1981984686,null);vQ=new $CLJS.M(null,"page","page",849072397);
wQ=new $CLJS.r(null,"length","length",-2065447907,null);kja=new $CLJS.M(null,"dashboard","dashboard",-631747508);xQ=new $CLJS.r(null,"get-week","get-week",752472178,null);yQ=new $CLJS.r(null,"get-month","get-month",1271156796,null);zQ=new $CLJS.r(null,"dimension","dimension",-2111181571,null);AQ=new $CLJS.M(null,"boolean-expression","boolean-expression",-394924008);BQ=new $CLJS.M("metabase.mbql.schema","length","metabase.mbql.schema/length",1810214269);
CQ=new $CLJS.r(null,"StringFilterOptions","StringFilterOptions",-847398229,null);DQ=new $CLJS.M("metabase.mbql.schema","segment","metabase.mbql.schema/segment",2142314297);EQ=new $CLJS.r("metabase.mbql.schema","not","metabase.mbql.schema/not",-113379604,null);lja=new $CLJS.r("metabase.mbql.schema","now","metabase.mbql.schema/now",665138615,null);FQ=new $CLJS.M("metabase.mbql.schema","case","metabase.mbql.schema/case",459951449);GQ=new $CLJS.r(null,"substring","substring",-1513569493,null);
mja=new $CLJS.r("metabase.mbql.schema","avg","metabase.mbql.schema/avg",-1530848906,null);HQ=new $CLJS.M(null,"internal","internal",-854870097);nja=new $CLJS.r("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",1918122376,null);IQ=new $CLJS.M("metabase.mbql.schema","DateTimeExpressionArg","metabase.mbql.schema/DateTimeExpressionArg",1033762257);JQ=new $CLJS.M("metabase.mbql.schema","TemplateTag","metabase.mbql.schema/TemplateTag",-1837740311);
KQ=new $CLJS.M("metabase.mbql.schema","EqualityComparable","metabase.mbql.schema/EqualityComparable",-72675242);oja=new $CLJS.r("metabase.mbql.schema","substring","metabase.mbql.schema/substring",1829402610,null);LQ=new $CLJS.M(null,"more-values-or-fields","more-values-or-fields",-886177554);MQ=new $CLJS.r(null,"ltrim","ltrim",-1000166486,null);pja=new $CLJS.r("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",1937815937,null);
qja=new $CLJS.r("metabase.mbql.schema","length","metabase.mbql.schema/length",-844221500,null);NQ=new $CLJS.M("metabase.mbql.schema","DatetimeExpression","metabase.mbql.schema/DatetimeExpression",1214423543);OQ=new $CLJS.M("metabase.mbql.schema","template-tag","metabase.mbql.schema/template-tag",1296341515);PQ=new $CLJS.r("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",-1048570669,null);
rja=new $CLJS.r("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",-2093700401,null);sja=new $CLJS.r("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-313920313,null);QQ=new $CLJS.r(null,"desc","desc",-560950005,null);$CLJS.RQ=new $CLJS.M(null,"template-tags","template-tags",1853115685);tja=new $CLJS.M(null,"public-question","public-question",629369976);uja=new $CLJS.r("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",-1825197161,null);
vja=new $CLJS.M(null,"binary","binary",-1802232288);SQ=new $CLJS.r(null,"time-interval","time-interval",-1949813754,null);wja=new $CLJS.M(null,"userland-query?","userland-query?",-123699383);$CLJS.TQ=new $CLJS.M(null,"source-query","source-query",198004422);UQ=new $CLJS.M("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-1932527842);xja=new $CLJS.r(null,"SegmentID","SegmentID",-1773652704,null);
yja=new $CLJS.r("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",-2138696889,null);VQ=new $CLJS.M("metabase.mbql.schema","UnnamedAggregation","metabase.mbql.schema/UnnamedAggregation",-196705529);WQ=new $CLJS.M("metabase.mbql.schema","share","metabase.mbql.schema/share",337132634);zja=new $CLJS.M(null,"executed-by","executed-by",-739811161);Aja=new $CLJS.r("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",977707224,null);
Bja=new $CLJS.r("metabase.mbql.schema","median","metabase.mbql.schema/median",2055093571,null);XQ=new $CLJS.M(null,"amount","amount",364489504);YQ=new $CLJS.r(null,"percentile","percentile",1039342775,null);ZQ=new $CLJS.M("metabase.mbql.schema","metric","metabase.mbql.schema/metric",-610527242);$Q=new $CLJS.r(null,"metabase.mbql.schema","metabase.mbql.schema",-408168902,null);aR=new $CLJS.M("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",1249807143);
bR=new $CLJS.r(null,"NumericExpressionArg","NumericExpressionArg",-1537445195,null);cR=new $CLJS.r(null,"trim","trim",-1880116002,null);Cja=new $CLJS.r(null,"RawTimeLiteral","RawTimeLiteral",-2137224789,null);dR=new $CLJS.r(null,"coalesce","coalesce",-1999813740,null);eR=new $CLJS.M("metabase.mbql.schema","sum","metabase.mbql.schema/sum",1064545669);Dja=new $CLJS.M("metabase.mbql.schema","DateTimeValue","metabase.mbql.schema/DateTimeValue",1983295553);
fR=new $CLJS.M("string","\x3d","string/\x3d",983744235);Eja=new $CLJS.M(null,"skip-results-metadata?","skip-results-metadata?",251010463);Fja=new $CLJS.r("metabase.mbql.schema","asc","metabase.mbql.schema/asc",1338042063,null);Gja=new $CLJS.r("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-291996315,null);gR=new $CLJS.r(null,"StringExpressionArg","StringExpressionArg",-1131357258,null);hR=new $CLJS.M(null,"value-or-field","value-or-field",-1387286309);
iR=new $CLJS.r(null,"FieldOrExpressionDef","FieldOrExpressionDef",-788269541,null);Hja=new $CLJS.M(null,"lat-min","lat-min",1630784161);jR=new $CLJS.r(null,"ArithmeticDateTimeUnit","ArithmeticDateTimeUnit",-838492507,null);kR=new $CLJS.r(null,"inside","inside",-681932758,null);Ija=new $CLJS.r("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",1735459125,null);lR=new $CLJS.M("metabase.mbql.schema","NumericExpression","metabase.mbql.schema/NumericExpression",1794540739);
Jja=new $CLJS.r("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",1628930633,null);mR=new $CLJS.r(null,"starts-with","starts-with",2007034536,null);Kja=new $CLJS.M(null,"process-viz-settings?","process-viz-settings?",-173592315);Lja=new $CLJS.r("metabase.mbql.schema","power","metabase.mbql.schema/power",-517905321,null);$CLJS.nR=new $CLJS.M(null,"card-id","card-id",-1770060179);oR=new $CLJS.M(null,"variadic","variadic",882626057);
pR=new $CLJS.r(null,"upper","upper",1886775433,null);qR=new $CLJS.M(null,"field-or-expression","field-or-expression",-1409494368);rR=new $CLJS.M("metabase.mbql.schema","Aggregation","metabase.mbql.schema/Aggregation",746170880);sR=new $CLJS.r(null,"optional","optional",-600484260,null);Mja=new $CLJS.r("metabase.mbql.schema","exp","metabase.mbql.schema/exp",451780488,null);tR=new $CLJS.M("metabase.mbql.schema","FieldOptions","metabase.mbql.schema/FieldOptions",-2115604229);
uR=new $CLJS.M(null,"sugar","sugar",-73788488);Nja=new $CLJS.M(null,"lat-max","lat-max",841568226);vR=new $CLJS.r(null,"power","power",702679448,null);wR=new $CLJS.r("metabase.mbql.schema","and","metabase.mbql.schema/and",1553856071,null);xR=new $CLJS.M("metabase.mbql.schema","replace","metabase.mbql.schema/replace",-371168701);yR=new $CLJS.M("metabase.mbql.schema","NumericExpressionArg","metabase.mbql.schema/NumericExpressionArg",-1965874261);
zR=new $CLJS.M("metabase.mbql.schema","Fields","metabase.mbql.schema/Fields",1872461942);AR=new $CLJS.r(null,"median","median",-2084869638,null);BR=new $CLJS.M("metabase.mbql.schema","ParameterType","metabase.mbql.schema/ParameterType",1268586893);CR=new $CLJS.r(null,"cum-sum","cum-sum",-589533420,null);Oja=new $CLJS.r("metabase.mbql.schema","trim","metabase.mbql.schema/trim",1991053191,null);DR=new $CLJS.M(null,"y","y",-1757859776);ER=new $CLJS.r(null,"aggregation","aggregation",-1056959073,null);
$CLJS.FR=new $CLJS.M(null,"binning","binning",1709835866);GR=new $CLJS.r(null,"TimezoneId","TimezoneId",-1406242397,null);HR=new $CLJS.M("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",1034573295);IR=new $CLJS.M("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",1597044182);JR=new $CLJS.M("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",209553857);KR=new $CLJS.r(null,"Reference","Reference",2024574086,null);LR=new $CLJS.M(null,"b","b",1482224470);
MR=new $CLJS.M(null,"a","a",-2123407586);NR=new $CLJS.M("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",1846805017);OR=new $CLJS.r(null,"replace","replace",853943757,null);PR=new $CLJS.M("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",576093708);Pja=new $CLJS.r("metabase.mbql.schema","upper","metabase.mbql.schema/upper",993691634,null);QR=new $CLJS.M("metabase.mbql.schema","/","metabase.mbql.schema//",363603269);
RR=new $CLJS.M("metabase.mbql.schema","-","metabase.mbql.schema/-",1213831346);SR=new $CLJS.M("metabase.mbql.schema","*","metabase.mbql.schema/*",202015001);TR=new $CLJS.M("metabase.mbql.schema","+","metabase.mbql.schema/+",852320058);UR=new $CLJS.r(null,"segment","segment",675610331,null);Qja=new $CLJS.r("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",1436319181,null);VR=new $CLJS.M("metabase.mbql.schema","field","metabase.mbql.schema/field",-73678671);
WR=new $CLJS.r("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",1977236849,null);YR=new $CLJS.M("metabase.mbql.schema","count","metabase.mbql.schema/count",-962464844);ZR=new $CLJS.M("string","starts-with","string/starts-with",1266861170);Rja=new $CLJS.r(null,"TemporalExtractUnit","TemporalExtractUnit",1691102108,null);$CLJS.$R=new $CLJS.M(null,"order-by","order-by",1527318070);aS=new $CLJS.M(null,"advanced-math-expressions","advanced-math-expressions",1076581757);
Sja=new $CLJS.r("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",637054412,null);Tja=new $CLJS.M("alias","escaped-\x3eoriginal","alias/escaped-\x3eoriginal",-1334024182);$CLJS.bS=new $CLJS.M(null,"condition","condition",1668437652);cS=new $CLJS.M("string","does-not-contain","string/does-not-contain",-1536178964);Uja=new $CLJS.M(null,"report-timezone","report-timezone",-2053796389);dS=new $CLJS.r("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",-2098137664,null);
Vja=new $CLJS.M(null,"card-name","card-name",-2035606807);eS=new $CLJS.M("metabase.mbql.schema","value","metabase.mbql.schema/value",772933314);Wja=new $CLJS.r("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",969078380,null);Xja=new $CLJS.r("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",-1742276611,null);fS=new $CLJS.r(null,"log","log",45015523,null);Yja=new $CLJS.r("metabase.mbql.schema","abs","metabase.mbql.schema/abs",1792880149,null);
gS=new $CLJS.r(null,"time","time",-1268547887,null);$CLJS.hS=new $CLJS.M(null,"database_type","database_type",-54700895);iS=new $CLJS.r(null,"EqualityComparable","EqualityComparable",-2076258858,null);jS=new $CLJS.r(null,"get-minute","get-minute",-1186349426,null);$CLJS.kS=new $CLJS.M(null,"clause","clause",1479668060);$CLJS.lS=new $CLJS.M(null,"expression-name","expression-name",-1798450709);$CLJS.mS=new $CLJS.M("date","all-options","date/all-options",549325958);
Zja=new $CLJS.r("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",1965492610,null);$ja=new $CLJS.M(null,"embedded-json-download","embedded-json-download",-1980927033);nS=new $CLJS.M("location","state","location/state",-114378652);oS=new $CLJS.r(null,"datetime-subtract","datetime-subtract",1915839224,null);aka=new $CLJS.M(null,"aggregation-clause-index","aggregation-clause-index",-1510292261);bka=new $CLJS.M(null,"lon-field","lon-field",517872067);
pS=new $CLJS.M("metabase.mbql.schema","TemporalLiteral","metabase.mbql.schema/TemporalLiteral",-904081026);qS=new $CLJS.r(null,"DateTimeExpressionArg","DateTimeExpressionArg",-1507653823,null);rS=new $CLJS.M(null,"numeric","numeric",-1495594714);sS=new $CLJS.r(null,"variable","variable",1359185035,null);tS=new $CLJS.r(null,"lower","lower",-1534114948,null);cka=new $CLJS.M(null,"embedded-xlsx-download","embedded-xlsx-download",-1105087722);
uS=new $CLJS.M("metabase.mbql.schema","concat","metabase.mbql.schema/concat",2073716991);vS=new $CLJS.M("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",-204212346);$CLJS.wS=new $CLJS.M(null,"limit","limit",-1355822363);xS=new $CLJS.M("metabase.mbql.schema","round","metabase.mbql.schema/round",-1325713739);yS=new $CLJS.r(null,"ExtractWeekMode","ExtractWeekMode",397218079,null);dka=new $CLJS.M(null,"pulse","pulse",-244494476);var zS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.EE],null),AS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.OE],null),BS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.AE],null),CS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.vE],null),DS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.IE],null),ES=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.NE],null),eka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hL],null),fka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bL],null),FS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,
$CLJS.UI],null),GS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.YI],null),HS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.PI],null),IS=new $CLJS.ah(null,new $CLJS.h(null,12,[$CLJS.oi,null,$CLJS.gu,null,$CLJS.nj,null,$CLJS.ci,null,$CLJS.Fi,null,$CLJS.wj,null,$CLJS.kk,null,$CLJS.Ej,null,$CLJS.ji,null,$CLJS.Dj,null,$CLJS.pi,null,$CLJS.zi,null],null),null),JS=new $CLJS.ah(null,new $CLJS.h(null,7,[$CLJS.qk,null,$CLJS.iH,null,$CLJS.ci,null,$CLJS.vi,null,$CLJS.lH,null,$CLJS.hj,null,$CLJS.Ti,null],null),null),
KS=$CLJS.hu.g(IS,JS),gka=$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lk,new $CLJS.h(null,1,[$CLJS.yt,"date bucketing unit"],null)],null),IS),hka=$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lk,new $CLJS.h(null,1,[$CLJS.yt,"time bucketing unit"],null)],null),JS),LS=$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lk,new $CLJS.h(null,1,[$CLJS.yt,"datetime bucketing unit"],null)],null),KS),MS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.yJ],null),ika=new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.lk,new $CLJS.h(null,
1,[$CLJS.yt,"temporal extract unit"],null),$CLJS.mH,$CLJS.zi,$CLJS.kk,$CLJS.jJ,$CLJS.EJ,$CLJS.FJ,$CLJS.Ej,$CLJS.Fi,$CLJS.vi,$CLJS.hj,$CLJS.jH],null),jka=new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.lk,new $CLJS.h(null,1,[$CLJS.yt,"datetime-diff unit"],null),$CLJS.iH,$CLJS.Ti,$CLJS.qk,$CLJS.gu,$CLJS.nj,$CLJS.wj,$CLJS.oi,$CLJS.ji],null),NS=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.lk,new $CLJS.h(null,1,[$CLJS.yt,"temporal-extract week extraction mode"],null),$CLJS.dJ,$CLJS.mJ,$CLJS.rJ],null),OS=new $CLJS.P(null,
10,5,$CLJS.Q,[$CLJS.lk,new $CLJS.h(null,1,[$CLJS.yt,"relative-datetime unit"],null),$CLJS.ci,$CLJS.Ti,$CLJS.qk,$CLJS.gu,$CLJS.nj,$CLJS.wj,$CLJS.oi,$CLJS.ji],null),VS,lka,cT,dT,eT,fT,gT,hT,iT,UU,nka,VU,oka,pka,WU,qka,rka,ska;$CLJS.X(dN,nM($CLJS.nJ,$CLJS.H(["n",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tk,$CLJS.aw],null),$CLJS.Ks],null),"unit",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ts,OS],null)])));var PS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dN],null);
$CLJS.X(IP,nM($CLJS.lJ,$CLJS.H(["n",$CLJS.Ks,"unit",OS])));var kka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,IP],null);
$CLJS.X(yN,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,2,[$CLJS.yt,"valid :absolute-datetime clause",$CLJS.Ni,function(a){if($CLJS.Va(mM($CLJS.hJ,a)))a=xN;else{a=$CLJS.hd(a);var b=$CLJS.iM(FS);a=b.h?b.h(a):b.call(null,a);a=$CLJS.n(a)?$CLJS.MP:$CLJS.oJ}return a}],null),new $CLJS.P(null,2,5,$CLJS.Q,[xN,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.yt,"not an :absolute-datetime clause"],null),$CLJS.Ue(!1)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.MP,nM($CLJS.hJ,
$CLJS.H(["date",FS,"unit",gka]))],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oJ,nM($CLJS.hJ,$CLJS.H(["datetime",GS,"unit",LS]))],null)],null));var QS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yN],null);$CLJS.X(NN,nM($CLJS.Ny,$CLJS.H(["time",HS,"unit",hka])));var RS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NN],null),SS=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ys,new $CLJS.h(null,1,[$CLJS.yt,"date or datetime literal"],null),QS,GS,FS],null);
$CLJS.X(yM,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ys,new $CLJS.h(null,1,[$CLJS.yt,"time literal"],null),RS,HS],null));$CLJS.X(pS,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ys,new $CLJS.h(null,1,[$CLJS.yt,"temporal literal"],null),SS,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yM],null)],null));var TS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pS],null);
$CLJS.X(Dja,oM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof QS?new $CLJS.Gc(function(){return QS},$CLJS.od(cia,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,HQ,$CLJS.mj,$CLJS.zk,$CLJS.ti,$CLJS.ik,$CLJS.ok],[$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),pQ,"metabase/mbql/schema.cljc",69,$CLJS.hJ,1,!0,168,168,$CLJS.Kc,"Schema for an `:absolute-datetime` clause.",$CLJS.n(QS)?QS.H:null])):null));return $CLJS.n(a)?a:pQ}(),QS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof PS?new $CLJS.Gc(function(){return PS},$CLJS.od(Zja,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,
$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),GP,"metabase/mbql/schema.cljc",29,$CLJS.nJ,1,131,131,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.nJ,$CLJS.rk,$CLJS.U($CLJS.Oj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tk,$CLJS.aw],null),$CLJS.Ks],null)),$CLJS.ri,$CLJS.U($CLJS.Oj,$CLJS.U(sR,dP))],null),$CLJS.Kc,"Schema for a valid relative-datetime clause.",
$CLJS.n(PS)?PS.H:null])):null));return $CLJS.n(a)?a:GP}(),PS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof RS?new $CLJS.Gc(function(){return RS},$CLJS.od(aja,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,HQ,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),gS,"metabase/mbql/schema.cljc",27,$CLJS.Ny,1,!0,175,175,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Ny,$CLJS.Ny,$CLJS.U($CLJS.Oj,Cja),$CLJS.ri,$CLJS.U($CLJS.Oj,uia)],null),$CLJS.Kc,"Schema for a valid time clause.",$CLJS.n(RS)?RS.H:null])):null));return $CLJS.n(a)?a:gS}(),RS],null)])));
$CLJS.X(eS,nM($CLJS.Cj,$CLJS.H(["value",$CLJS.Ps,"type-info",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hS,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,zS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nD,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,AS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.OO,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.xs,BS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ri,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,LS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,zS],null)],null)],null)],null)])));var US=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,eS],null);$CLJS.X(NO,nM($CLJS.wB,$CLJS.H(["expression-name",zS,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ts,$CLJS.gk],null)])));
VS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NO],null);
lka=function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.yt,"You must specify :num-bins when using the :num-bins strategy."],null),function(b){var c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.QE);c=$CLJS.J.g(c,$CLJS.xE);return $CLJS.E.g(b,$CLJS.xE)?c:!0}],null)],null)}(function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.yt,"You must specify :bin-width when using the :bin-width strategy."],
null),function(b){var c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.QE);c=$CLJS.J.g(c,$CLJS.PE);return $CLJS.E.g(b,$CLJS.PE)?c:!0}],null)],null)}(new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.gk,new $CLJS.h(null,1,[$CLJS.yt,"binning options"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.QE,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.lk,new $CLJS.h(null,1,[$CLJS.yt,"binning strategy"],null),$CLJS.xE,$CLJS.PE,$CLJS.ci],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xE,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),CS],null),new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.PE,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,$CLJS.Bl,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.yt,"bin width must be \x3e\x3d 0."],null),$CLJS.Te($CLJS.Wl)],null)],null)],null)],null)));$CLJS.WS=function WS(a){switch(arguments.length){case 1:return WS.h(arguments[0]);case 2:return WS.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};
$CLJS.WS.h=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.zB);b=$CLJS.J.g(b,$CLJS.BG);return $CLJS.WS.g(a,b)};$CLJS.WS.g=function(a,b){a=$CLJS.n($CLJS.n(b)?a:b)?$CLJS.AC(a,$CLJS.aD)?IS:$CLJS.AC(a,$CLJS.$D)?JS:$CLJS.AC(a,$CLJS.AD)?KS:null:null;return $CLJS.n(a)?$CLJS.Id(a,b):!0};$CLJS.WS.A=2;
$CLJS.X(tR,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.gk,new $CLJS.h(null,1,[$CLJS.yt,"field options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zB,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,AS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.PN,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,ES],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BG,new $CLJS.h(null,
1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,LS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EP,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,zS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FR,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,lka],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.yt,"Invalid :temporal-unit for the specified :base-type."],null),$CLJS.WS],null)],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.yt,"Found :binning keys at the top level of :field options. binning-related options belong under the :binning key."],null),$CLJS.Te($CLJS.QE)],null)],null));
$CLJS.X(VR,function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.yt,":field clauses using a string field name must specify :base-type."],null),function(b){$CLJS.I(b,0,null);var c=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);b=$CLJS.O(b);b=$CLJS.J.g(b,$CLJS.zB);return"string"===typeof c?b:!0}],null)],null)}(nM($CLJS.vG,$CLJS.H(["id-or-name",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ys,ES,zS],null),"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tR],null)],null)]))));var XS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,VR],null);
$CLJS.X(VP,oM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof VS?new $CLJS.Gc(function(){return VS},$CLJS.od(ZN,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([rP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.kQ,"null"],null),null),$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),JN,"metabase/mbql/schema.cljc",60,$CLJS.wB,1,249,249,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.wB,$CLJS.lS,$CLJS.U($CLJS.Oj,fN),$CLJS.ej,$CLJS.U($CLJS.Oj,$CLJS.U(sR,$CLJS.gk))],null),$CLJS.Kc,"Schema for a valid expression clause.",$CLJS.n(VS)?VS.H:null])):null));return $CLJS.n(a)?a:JN}(),VS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof XS?new $CLJS.Gc(function(){return XS},
$CLJS.od(qO,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.yr,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,$CLJS.ti,$CLJS.ik,$CLJS.ok],["0.39.0",$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rN,"metabase/mbql/schema.cljc",51,$CLJS.vG,1,382,382,$CLJS.Kc,"Schema for a `:field` clause.",$CLJS.n(XS)?XS.H:null])):null));return $CLJS.n(a)?a:rN}(),XS],null)])));
$CLJS.YS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,VP],null);$CLJS.X(tM,nM($CLJS.kG,$CLJS.H(["aggregation-clause-index",$CLJS.Ks,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ts,$CLJS.gk],null)])));var ZS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tM],null);
$CLJS.X($M,oM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof ZS?new $CLJS.Gc(function(){return ZS},$CLJS.od(Aja,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),ER,"metabase/mbql/schema.cljc",23,$CLJS.kG,1,418,418,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.kG,aka,$CLJS.U($CLJS.Oj,$CLJS.Ks),$CLJS.ej,$CLJS.U($CLJS.Oj,$CLJS.U(sR,$CLJS.gk))],null),$CLJS.Kc,"Schema for a valid aggregation clause.",$CLJS.n(ZS)?ZS.H:null])):null));return $CLJS.n(a)?a:ER}(),ZS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof VS?new $CLJS.Gc(function(){return VS},
$CLJS.od(ZN,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([rP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.kQ,"null"],null),null),$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),JN,"metabase/mbql/schema.cljc",60,$CLJS.wB,1,249,249,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.wB,$CLJS.lS,$CLJS.U($CLJS.Oj,fN),
$CLJS.ej,$CLJS.U($CLJS.Oj,$CLJS.U(sR,$CLJS.gk))],null),$CLJS.Kc,"Schema for a valid expression clause.",$CLJS.n(VS)?VS.H:null])):null));return $CLJS.n(a)?a:JN}(),VS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof XS?new $CLJS.Gc(function(){return XS},$CLJS.od(qO,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.yr,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,
$CLJS.ti,$CLJS.ik,$CLJS.ok],["0.39.0",$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rN,"metabase/mbql/schema.cljc",51,$CLJS.vG,1,382,382,$CLJS.Kc,"Schema for a `:field` clause.",$CLJS.n(XS)?XS.H:null])):null));return $CLJS.n(a)?a:rN}(),XS],null)])));
var $S=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$M],null),aT=new $CLJS.ah(null,new $CLJS.h(null,11,[$CLJS.Lt,null,$CLJS.OG,null,$CLJS.OF,null,$CLJS.UG,null,$CLJS.VF,null,$CLJS.yG,null,$CLJS.eG,null,$CLJS.Rt,null,$CLJS.MG,null,$CLJS.xG,null,$CLJS.VG,null],null),null),bT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,XM],null);
$CLJS.X(AM,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,1,[$CLJS.Ni,function(a){return"string"===typeof a?$CLJS.Xj:$CLJS.n(mM(aT,a))?TM:$CLJS.n(mM($CLJS.Cj,a))?$CLJS.Cj:$CLJS.Nt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xj,$CLJS.Xj],null),new $CLJS.P(null,2,5,$CLJS.Q,[TM,bT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cj,US],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Nt,$CLJS.YS],null)],null));cT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,AM],null);
dT=new $CLJS.ah(null,new $CLJS.h(null,26,[$CLJS.RF,null,$CLJS.DJ,null,$CLJS.es,null,$CLJS.zJ,null,$CLJS.wJ,null,$CLJS.iG,null,$CLJS.rt,null,$CLJS.NF,null,$CLJS.AJ,null,$CLJS.tJ,null,$CLJS.yG,null,$CLJS.RG,null,$CLJS.xJ,null,$CLJS.AG,null,$CLJS.TF,null,$CLJS.fJ,null,$CLJS.ds,null,$CLJS.WF,null,$CLJS.eG,null,$CLJS.$I,null,$CLJS.Xx,null,$CLJS.gJ,null,$CLJS.sJ,null,$CLJS.kJ,null,$CLJS.TG,null,$CLJS.tG,null],null),null);
eT=new $CLJS.ah(null,new $CLJS.h(null,9,[$CLJS.bs,null,$CLJS.Wr,null,$CLJS.ys,null,$CLJS.As,null,$CLJS.Yr,null,$CLJS.NG,null,$CLJS.Fs,null,$CLJS.tk,null,$CLJS.$r,null],null),null);fT=new $CLJS.ah(null,new $CLJS.h(null,17,[$CLJS.Zn,null,$CLJS.LG,null,$CLJS.EG,null,$CLJS.wG,null,$CLJS.QG,null,$CLJS.SG,null,$CLJS.aG,null,$CLJS.gG,null,$CLJS.FM,null,$CLJS.bG,null,$CLJS.JG,null,$CLJS.CG,null,$CLJS.hk,null,$CLJS.Zx,null,$CLJS.pG,null,$CLJS.qG,null,$CLJS.IG,null],null),null);
gT=new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.pJ,null,$CLJS.bJ,null,$CLJS.BJ,null,$CLJS.ds,null,$CLJS.vJ,null],null),null);hT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lR],null);iT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,LN],null);$CLJS.jT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NQ],null);$CLJS.kT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rR],null);
$CLJS.X(yR,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,2,[$CLJS.yt,"numeric expression argument",$CLJS.Ni,function(a){return"number"===typeof a?$CLJS.$t:$CLJS.n(mM(dT,a))?oP:$CLJS.n(mM(fT,a))?$CLJS.kG:$CLJS.n(mM($CLJS.Cj,a))?$CLJS.Cj:$CLJS.vG}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$t,$CLJS.Bl],null),new $CLJS.P(null,2,5,$CLJS.Q,[oP,hT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kG,$CLJS.kT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cj,US],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vG,
$CLJS.YS],null)],null));var lT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yR],null);
$CLJS.X(IQ,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,2,[$CLJS.yt,"datetime expression argument",$CLJS.Ni,function(a){return $CLJS.n(mM(fT,a))?$CLJS.kG:$CLJS.n(mM($CLJS.Cj,a))?$CLJS.Cj:$CLJS.n(mM(gT,a))?SN:$CLJS.Nt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kG,$CLJS.kT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cj,US],null),new $CLJS.P(null,2,5,$CLJS.Q,[SN,$CLJS.jT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Nt,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ys,SS,$CLJS.YS],null)],null)],null));
var mT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,IQ],null);
$CLJS.X(lQ,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,2,[$CLJS.yt,"expression argument",$CLJS.Ni,function(a){return"number"===typeof a?$CLJS.$t:$CLJS.Cd(a)?$CLJS.Os:$CLJS.n(mM(eT,a))?AQ:$CLJS.n(mM(dT,a))?oP:$CLJS.n(mM(gT,a))?SN:"string"===typeof a?$CLJS.Xj:$CLJS.n(mM(aT,a))?TM:$CLJS.n(mM($CLJS.Cj,a))?$CLJS.Cj:$CLJS.Nt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$t,$CLJS.Bl],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Os,$CLJS.Os],null),new $CLJS.P(null,2,5,$CLJS.Q,[AQ,iT],null),new $CLJS.P(null,
2,5,$CLJS.Q,[oP,hT],null),new $CLJS.P(null,2,5,$CLJS.Q,[SN,$CLJS.jT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xj,$CLJS.Xj],null),new $CLJS.P(null,2,5,$CLJS.Q,[TM,bT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cj,US],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Nt,$CLJS.YS],null)],null));var nT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lQ],null);$CLJS.X(jQ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ys,new $CLJS.h(null,1,[$CLJS.yt,"numeric expression arg or interval"],null),kka,lT],null));
var oT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jQ],null);$CLJS.X(LM,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,2,[$CLJS.yt,"int greater than zero or numeric expression",$CLJS.Ni,function(a){return"number"===typeof a?$CLJS.$t:$CLJS.Nt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$t,CS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Nt,hT],null)],null));var mka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,LM],null);
$CLJS.X(TN,nM($CLJS.yG,$CLJS.H(["a",nT,"b",nT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Oi,nT],null)])));var pT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,TN],null);$CLJS.X(xM,nM($CLJS.OF,$CLJS.H(["s",cT,"start",mka,"length",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ts,lT],null)])));var qT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,xM],null);$CLJS.X(BQ,nM($CLJS.Xx,$CLJS.H(["s",cT])));var rT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,BQ],null);$CLJS.X(ZM,nM($CLJS.MG,$CLJS.H(["s",cT])));
var sT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ZM],null);$CLJS.X(cN,nM($CLJS.VG,$CLJS.H(["s",cT])));var tT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,cN],null);$CLJS.X(vN,nM($CLJS.OG,$CLJS.H(["s",cT])));var uT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vN],null);$CLJS.X(gO,nM($CLJS.Lt,$CLJS.H(["s",cT])));var vT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,gO],null);$CLJS.X(QM,nM($CLJS.Rt,$CLJS.H(["s",cT])));var wT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QM],null);
$CLJS.X(xR,nM($CLJS.UG,$CLJS.H(["s",cT,"match",$CLJS.Xj,"replacement",$CLJS.Xj])));var xT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,xR],null);$CLJS.X(uS,nM($CLJS.VF,$CLJS.H(["a",cT,"b",cT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Oi,cT],null)])));var yT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uS],null);$CLJS.X(WO,nM($CLJS.xG,$CLJS.H(["s",cT,"pattern",$CLJS.Xj])));var zT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,WO],null);
$CLJS.X(TR,nM($CLJS.ds,$CLJS.H(["x",oT,"y",oT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Oi,oT],null)])));var AT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,TR],null);$CLJS.X(RR,nM($CLJS.rt,$CLJS.H(["x",lT,"y",oT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Oi,oT],null)])));var BT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RR],null);$CLJS.X(QR,nM($CLJS.RG,$CLJS.H(["x",lT,"y",lT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Oi,lT],null)])));var CT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QR],null);
$CLJS.X(SR,nM($CLJS.es,$CLJS.H(["x",lT,"y",lT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Oi,lT],null)])));var DT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SR],null);$CLJS.X(hQ,nM($CLJS.tG,$CLJS.H(["x",lT])));var ET=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hQ],null);$CLJS.X(aR,nM($CLJS.RF,$CLJS.H(["x",lT])));var FT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,aR],null);$CLJS.X(xS,nM($CLJS.AG,$CLJS.H(["x",lT])));var GT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,xS],null);$CLJS.X(mP,nM($CLJS.WF,$CLJS.H(["x",lT])));
var HT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mP],null);$CLJS.X(sP,nM($CLJS.TF,$CLJS.H(["x",lT,"y",lT])));var IT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sP],null);$CLJS.X(PP,nM($CLJS.iG,$CLJS.H(["x",lT])));var JT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PP],null);$CLJS.X(MM,nM($CLJS.NF,$CLJS.H(["x",lT])));var KT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,MM],null);$CLJS.X(oQ,nM($CLJS.TG,$CLJS.H(["x",lT])));var LT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oQ],null);
$CLJS.X(lN,nM($CLJS.kJ,$CLJS.H(["datetime-x",mT,"datetime-y",mT,"unit",jka])));var MT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lN],null);$CLJS.X(DN,nM($CLJS.zJ,$CLJS.H(["datetime",mT,"unit",ika,"mode",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ts,NS],null)])));var NT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,DN],null);$CLJS.X(uP,nM($CLJS.gJ,$CLJS.H(["date",mT])));var OT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uP],null);$CLJS.X(WP,nM($CLJS.DJ,$CLJS.H(["date",mT])));
var PT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,WP],null);$CLJS.X(zM,nM($CLJS.$I,$CLJS.H(["date",mT])));var QT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,zM],null);$CLJS.X(vS,nM($CLJS.tJ,$CLJS.H(["date",mT,"mode",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ts,NS],null)])));var RT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vS],null);$CLJS.X(HO,nM($CLJS.sJ,$CLJS.H(["date",mT])));var ST=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HO],null);$CLJS.X(QP,nM($CLJS.xJ,$CLJS.H(["date",mT])));
var TT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QP],null);$CLJS.X(hN,nM($CLJS.AJ,$CLJS.H(["datetime",mT])));var UT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hN],null);$CLJS.X(MO,nM($CLJS.wJ,$CLJS.H(["datetime",mT])));var VT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,MO],null);$CLJS.X(aN,nM($CLJS.fJ,$CLJS.H(["datetime",mT])));var WT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,aN],null);$CLJS.X(rO,nM($CLJS.bJ,$CLJS.H(["datetime",mT,"to",MS,"from",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ts,MS],null)])));
var XT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rO],null),YT=new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.lk,new $CLJS.h(null,1,[$CLJS.yt,"datetime arithmetic unit"],null),$CLJS.lH,$CLJS.iH,$CLJS.Ti,$CLJS.qk,$CLJS.gu,$CLJS.nj,$CLJS.wj,$CLJS.oi,$CLJS.ji],null);$CLJS.X(wM,nM($CLJS.vJ,$CLJS.H(["datetime",mT,"amount",lT,"unit",YT])));var ZT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,wM],null);
$CLJS.X(UM,function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return nM(arguments[0],1<b.length?new $CLJS.w(b.slice(1),0,null):null)}($CLJS.pJ));var $T=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,UM],null);$CLJS.X(vM,nM($CLJS.BJ,$CLJS.H(["datetime",mT,"amount",lT,"unit",YT])));var aU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vM],null);
$CLJS.X(NQ,oM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof AT?new $CLJS.Gc(function(){return AT},$CLJS.od(bQ,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([rP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.kQ,"null"],null),null),$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),QO,"metabase/mbql/schema.cljc",51,$CLJS.ds,1,612,612,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ds,$CLJS.ME,$CLJS.U($CLJS.Oj,fQ),DR,$CLJS.U($CLJS.Oj,fQ),UN,$CLJS.U($CLJS.Oj,$CLJS.U($CLJS.Li,fQ))],null),$CLJS.Kc,"Schema for a valid + clause.",$CLJS.n(AT)?AT.H:null])):null));return $CLJS.n(a)?a:QO}(),AT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof ZT?new $CLJS.Gc(function(){return ZT},
$CLJS.od(Lia,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([rP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[kO,"null"],null),null),$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),XP,"metabase/mbql/schema.cljc",67,$CLJS.vJ,1,703,703,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.vJ,$CLJS.oJ,$CLJS.U($CLJS.Oj,qS),XQ,$CLJS.U($CLJS.Oj,
bR),$CLJS.ri,$CLJS.U($CLJS.Oj,jR)],null),$CLJS.Kc,"Schema for a valid datetime-add clause.",$CLJS.n(ZT)?ZT.H:null])):null));return $CLJS.n(a)?a:XP}(),ZT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof aU?new $CLJS.Gc(function(){return aU},$CLJS.od(Eha,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([rP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,
$CLJS.ik,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[kO,"null"],null),null),$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oS,"metabase/mbql/schema.cljc",72,$CLJS.BJ,1,710,710,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.BJ,$CLJS.oJ,$CLJS.U($CLJS.Oj,qS),XQ,$CLJS.U($CLJS.Oj,bR),$CLJS.ri,$CLJS.U($CLJS.Oj,jR)],null),$CLJS.Kc,"Schema for a valid datetime-subtract clause.",$CLJS.n(aU)?aU.H:null])):null));
return $CLJS.n(a)?a:oS}(),aU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof XT?new $CLJS.Gc(function(){return XT},$CLJS.od(Jja,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([rP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.bJ,"null"],null),null),$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),RN,"metabase/mbql/schema.cljc",71,$CLJS.bJ,1,695,695,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.bJ,$CLJS.oJ,$CLJS.U($CLJS.Oj,qS),Dia,$CLJS.U($CLJS.Oj,GR),Iha,$CLJS.U($CLJS.Oj,$CLJS.U(sR,GR))],null),$CLJS.Kc,"Schema for a valid convert-timezone clause.",$CLJS.n(XT)?XT.H:null])):null));return $CLJS.n(a)?a:RN}(),XT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&
"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof $T?new $CLJS.Gc(function(){return $T},$CLJS.od(lja,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([rP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.pJ,"null"],null),null),$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),vP,"metabase/mbql/schema.cljc",
45,$CLJS.pJ,1,708,708,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.pJ],null),$CLJS.Kc,"Schema for a valid now clause.",$CLJS.n($T)?$T.H:null])):null));return $CLJS.n(a)?a:vP}(),$T],null)])));$CLJS.bU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SP],null);$CLJS.X(BM,nM($CLJS.Fs,$CLJS.H(["first-clause",$CLJS.bU,"second-clause",$CLJS.bU,"other-clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Oi,$CLJS.bU],null)])));var cU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,BM],null);
$CLJS.X(nO,nM($CLJS.ys,$CLJS.H(["first-clause",$CLJS.bU,"second-clause",$CLJS.bU,"other-clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Oi,$CLJS.bU],null)])));var dU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nO],null);$CLJS.X(WM,nM($CLJS.As,$CLJS.H(["clause",$CLJS.bU])));
var eU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,WM],null),fU=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,3,[$CLJS.yt,":field or :expression reference or :relative-datetime",$CLJS.At,$CLJS.Ue(":field or :expression reference or :relative-datetime"),$CLJS.Ni,function(a){return $CLJS.n(mM($CLJS.nJ,a))?$CLJS.nJ:$CLJS.Nt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nJ,PS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Nt,$CLJS.YS],null)],null);
$CLJS.X(KQ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,new $CLJS.h(null,1,[$CLJS.yt,"equality comparable"],null),new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.ys,$CLJS.Os,$CLJS.Bl,$CLJS.Xj,TS,fU,nT,US],null)],null));var gU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,KQ],null);
$CLJS.X(aP,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,2,[$CLJS.yt,"order comparable",$CLJS.Ni,function(a){return $CLJS.n(mM($CLJS.Cj,a))?$CLJS.Cj:$CLJS.Nt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cj,US],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Nt,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ys,$CLJS.Bl,$CLJS.Xj,TS,nT,fU],null)],null)],null));var hU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,aP],null);
$CLJS.X(JR,nM($CLJS.tk,$CLJS.H(["field",gU,"value-or-field",gU,"more-values-or-fields",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Oi,gU],null)])));var iU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JR],null);$CLJS.X(NR,nM($CLJS.NG,$CLJS.H(["field",gU,"value-or-field",gU,"more-values-or-fields",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Oi,gU],null)])));var jU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NR],null);$CLJS.X(IR,nM($CLJS.$r,$CLJS.H(["field",hU,"value-or-field",hU])));
var kU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,IR],null);$CLJS.X(HR,nM($CLJS.Wr,$CLJS.H(["field",hU,"value-or-field",hU])));var lU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HR],null);$CLJS.X(wN,nM($CLJS.bs,$CLJS.H(["field",hU,"value-or-field",hU])));var mU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,wN],null);$CLJS.X(CN,nM($CLJS.Yr,$CLJS.H(["field",hU,"value-or-field",hU])));var nU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,CN],null);$CLJS.X(sQ,nM($CLJS.YF,$CLJS.H(["field",hU,"min",hU,"max",hU])));
var oU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sQ],null);$CLJS.X(UQ,nM($CLJS.dG,$CLJS.H(["lat-field",hU,"lon-field",hU,"lat-max",hU,"lon-min",hU,"lat-min",hU,"lon-max",hU])));var pU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,UQ],null);$CLJS.X(fO,nM($CLJS.XF,$CLJS.H(["field",$CLJS.YS])));var qU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fO],null);$CLJS.X(VO,nM($CLJS.hG,$CLJS.H(["field",$CLJS.YS])));var rU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,VO],null);$CLJS.X(xO,nM($CLJS.ZF,$CLJS.H(["field",$CLJS.YS])));
var sU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,xO],null);$CLJS.X(EM,nM($CLJS.fG,$CLJS.H(["field",$CLJS.YS])));var tU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,EM],null),uU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DG,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.Os],null)],null);$CLJS.X(KO,nM($CLJS.mG,$CLJS.H(["field",cT,"string-or-field",cT,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ts,uU],null)])));var vU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,KO],null);
$CLJS.X(DP,nM($CLJS.GG,$CLJS.H(["field",cT,"string-or-field",cT,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ts,uU],null)])));var wU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,DP],null);$CLJS.X(vO,nM($CLJS.uG,$CLJS.H(["field",cT,"string-or-field",cT,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ts,uU],null)])));var xU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vO],null);$CLJS.X(mQ,nM($CLJS.nG,$CLJS.H(["field",cT,"string-or-field",cT,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ts,uU],null)])));
var yU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mQ],null);$CLJS.X(NM,nM($CLJS.HG,$CLJS.H(["field",$CLJS.YS,"n",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ys,$CLJS.Ks,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.lk,$CLJS.aw,$CLJS.cy,$CLJS.cG],null)],null),"unit",OS,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ts,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ek,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.Os],null)],null)],null)])));var zU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NM],null);
$CLJS.X(DQ,nM($CLJS.UF,$CLJS.H(["segment-id",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.wE],null),zS],null)])));var AU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,DQ],null);
$CLJS.X(LN,oM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof cU?new $CLJS.Gc(function(){return cU},$CLJS.od(wR,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),eP,"metabase/mbql/schema.cljc",15,$CLJS.Fs,1,724,724,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Fs,VN,$CLJS.U($CLJS.Oj,jP),NP,$CLJS.U($CLJS.Oj,jP),eO,$CLJS.U($CLJS.Oj,$CLJS.U($CLJS.Li,jP))],null),$CLJS.Kc,"Schema for a valid and clause.",$CLJS.n(cU)?cU.H:null])):null));return $CLJS.n(a)?a:eP}(),cU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof dU?new $CLJS.Gc(function(){return dU},
$CLJS.od(RP,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),UP,"metabase/mbql/schema.cljc",14,$CLJS.ys,1,729,729,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ys,VN,$CLJS.U($CLJS.Oj,jP),NP,$CLJS.U($CLJS.Oj,jP),eO,$CLJS.U($CLJS.Oj,$CLJS.U($CLJS.Li,jP))],null),$CLJS.Kc,
"Schema for a valid or clause.",$CLJS.n(dU)?dU.H:null])):null));return $CLJS.n(a)?a:UP}(),dU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof eU?new $CLJS.Gc(function(){return eU},$CLJS.od(EQ,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),wP,"metabase/mbql/schema.cljc",15,$CLJS.As,1,734,734,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.As,$CLJS.kS,$CLJS.U($CLJS.Oj,jP)],null),$CLJS.Kc,"Schema for a valid not clause.",$CLJS.n(eU)?eU.H:null])):null));return $CLJS.n(a)?a:wP}(),eU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==
typeof kU?new $CLJS.Gc(function(){return kU},$CLJS.od($P,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),YO,"metabase/mbql/schema.cljc",13,$CLJS.$r,1,798,798,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.$r,$CLJS.vG,$CLJS.U($CLJS.Oj,XN),hR,$CLJS.U($CLJS.Oj,XN)],
null),$CLJS.Kc,"Schema for a valid \x3c clause.",$CLJS.n(kU)?kU.H:null])):null));return $CLJS.n(a)?a:YO}(),kU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof mU?new $CLJS.Gc(function(){return mU},$CLJS.od(dS,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[$CLJS.od($Q,new $CLJS.h(null,
1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),XO,"metabase/mbql/schema.cljc",14,$CLJS.bs,1,800,800,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.bs,$CLJS.vG,$CLJS.U($CLJS.Oj,XN),hR,$CLJS.U($CLJS.Oj,XN)],null),$CLJS.Kc,"Schema for a valid \x3c\x3d clause.",$CLJS.n(mU)?mU.H:null])):null));return $CLJS.n(a)?a:XO}(),mU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&
"undefined"!==typeof sM&&"undefined"!==typeof lU?new $CLJS.Gc(function(){return lU},$CLJS.od(YP,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ZO,"metabase/mbql/schema.cljc",13,$CLJS.Wr,1,799,799,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Wr,$CLJS.vG,$CLJS.U($CLJS.Oj,
XN),hR,$CLJS.U($CLJS.Oj,XN)],null),$CLJS.Kc,"Schema for a valid \x3e clause.",$CLJS.n(lU)?lU.H:null])):null));return $CLJS.n(a)?a:ZO}(),lU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof nU?new $CLJS.Gc(function(){return nU},$CLJS.od(WR,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],
[$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),fP,"metabase/mbql/schema.cljc",14,$CLJS.Yr,1,801,801,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Yr,$CLJS.vG,$CLJS.U($CLJS.Oj,XN),hR,$CLJS.U($CLJS.Oj,XN)],null),$CLJS.Kc,"Schema for a valid \x3e\x3d clause.",$CLJS.n(nU)?nU.H:null])):null));return $CLJS.n(a)?a:fP}(),nU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&
"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof iU?new $CLJS.Gc(function(){return iU},$CLJS.od(ZP,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$O,"metabase/mbql/schema.cljc",13,$CLJS.tk,1,795,795,new $CLJS.P(null,7,5,$CLJS.Q,
[$CLJS.tk,$CLJS.vG,$CLJS.U($CLJS.Oj,iS),hR,$CLJS.U($CLJS.Oj,iS),LQ,$CLJS.U($CLJS.Oj,$CLJS.U($CLJS.Li,iS))],null),$CLJS.Kc,"Schema for a valid \x3d clause.",$CLJS.n(iU)?iU.H:null])):null));return $CLJS.n(a)?a:$O}(),iU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof jU?new $CLJS.Gc(function(){return jU},$CLJS.od(mN,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,
$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),IN,"metabase/mbql/schema.cljc",14,$CLJS.NG,1,796,796,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.NG,$CLJS.vG,$CLJS.U($CLJS.Oj,iS),hR,$CLJS.U($CLJS.Oj,iS),LQ,$CLJS.U($CLJS.Oj,$CLJS.U($CLJS.Li,iS))],null),$CLJS.Kc,"Schema for a valid !\x3d clause.",$CLJS.n(jU)?jU.H:null])):null));
return $CLJS.n(a)?a:IN}(),jU],null)])));
$CLJS.X(SP,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,2,[$CLJS.yt,"valid filter expression",$CLJS.Ni,function(a){return $CLJS.n(mM(gT,a))?$CLJS.oJ:$CLJS.n(mM(dT,a))?rS:$CLJS.n(mM(aT,a))?$CLJS.Xj:$CLJS.n(mM(eT,a))?$CLJS.Os:$CLJS.Nt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oJ,$CLJS.jT],null),new $CLJS.P(null,2,5,$CLJS.Q,[rS,hT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xj,bT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Os,iT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Nt,oM($CLJS.H([new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof cU?new $CLJS.Gc(function(){return cU},$CLJS.od(wR,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),eP,"metabase/mbql/schema.cljc",
15,$CLJS.Fs,1,724,724,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Fs,VN,$CLJS.U($CLJS.Oj,jP),NP,$CLJS.U($CLJS.Oj,jP),eO,$CLJS.U($CLJS.Oj,$CLJS.U($CLJS.Li,jP))],null),$CLJS.Kc,"Schema for a valid and clause.",$CLJS.n(cU)?cU.H:null])):null));return $CLJS.n(a)?a:eP}(),cU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof dU?new $CLJS.Gc(function(){return dU},$CLJS.od(RP,new $CLJS.h(null,1,[$CLJS.Zj,
!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),UP,"metabase/mbql/schema.cljc",14,$CLJS.ys,1,729,729,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ys,VN,$CLJS.U($CLJS.Oj,jP),NP,$CLJS.U($CLJS.Oj,jP),eO,$CLJS.U($CLJS.Oj,$CLJS.U($CLJS.Li,jP))],null),$CLJS.Kc,"Schema for a valid or clause.",$CLJS.n(dU)?
dU.H:null])):null));return $CLJS.n(a)?a:UP}(),dU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof eU?new $CLJS.Gc(function(){return eU},$CLJS.od(EQ,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),wP,"metabase/mbql/schema.cljc",15,$CLJS.As,1,734,734,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.As,$CLJS.kS,$CLJS.U($CLJS.Oj,jP)],null),$CLJS.Kc,"Schema for a valid not clause.",$CLJS.n(eU)?eU.H:null])):null));return $CLJS.n(a)?a:wP}(),eU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof iU?new $CLJS.Gc(function(){return iU},$CLJS.od(ZP,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,
$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$O,"metabase/mbql/schema.cljc",13,$CLJS.tk,1,795,795,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.tk,$CLJS.vG,$CLJS.U($CLJS.Oj,iS),hR,$CLJS.U($CLJS.Oj,iS),LQ,$CLJS.U($CLJS.Oj,$CLJS.U($CLJS.Li,iS))],null),$CLJS.Kc,"Schema for a valid \x3d clause.",$CLJS.n(iU)?iU.H:null])):
null));return $CLJS.n(a)?a:$O}(),iU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof jU?new $CLJS.Gc(function(){return jU},$CLJS.od(mN,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),IN,"metabase/mbql/schema.cljc",14,$CLJS.NG,1,796,796,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.NG,$CLJS.vG,$CLJS.U($CLJS.Oj,iS),hR,$CLJS.U($CLJS.Oj,iS),LQ,$CLJS.U($CLJS.Oj,$CLJS.U($CLJS.Li,iS))],null),$CLJS.Kc,"Schema for a valid !\x3d clause.",$CLJS.n(jU)?jU.H:null])):null));return $CLJS.n(a)?a:IN}(),jU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof kU?new $CLJS.Gc(function(){return kU},
$CLJS.od($P,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),YO,"metabase/mbql/schema.cljc",13,$CLJS.$r,1,798,798,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.$r,$CLJS.vG,$CLJS.U($CLJS.Oj,XN),hR,$CLJS.U($CLJS.Oj,XN)],null),$CLJS.Kc,"Schema for a valid \x3c clause.",
$CLJS.n(kU)?kU.H:null])):null));return $CLJS.n(a)?a:YO}(),kU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof lU?new $CLJS.Gc(function(){return lU},$CLJS.od(YP,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),ZO,"metabase/mbql/schema.cljc",13,$CLJS.Wr,1,799,799,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Wr,$CLJS.vG,$CLJS.U($CLJS.Oj,XN),hR,$CLJS.U($CLJS.Oj,XN)],null),$CLJS.Kc,"Schema for a valid \x3e clause.",$CLJS.n(lU)?lU.H:null])):null));return $CLJS.n(a)?a:ZO}(),lU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof mU?new $CLJS.Gc(function(){return mU},$CLJS.od(dS,new $CLJS.h(null,
1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),XO,"metabase/mbql/schema.cljc",14,$CLJS.bs,1,800,800,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.bs,$CLJS.vG,$CLJS.U($CLJS.Oj,XN),hR,$CLJS.U($CLJS.Oj,XN)],null),$CLJS.Kc,"Schema for a valid \x3c\x3d clause.",$CLJS.n(mU)?mU.H:null])):
null));return $CLJS.n(a)?a:XO}(),mU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof nU?new $CLJS.Gc(function(){return nU},$CLJS.od(WR,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),fP,"metabase/mbql/schema.cljc",14,$CLJS.Yr,1,801,801,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Yr,$CLJS.vG,$CLJS.U($CLJS.Oj,XN),hR,$CLJS.U($CLJS.Oj,XN)],null),$CLJS.Kc,"Schema for a valid \x3e\x3d clause.",$CLJS.n(nU)?nU.H:null])):null));return $CLJS.n(a)?a:fP}(),nU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof oU?new $CLJS.Gc(function(){return oU},$CLJS.od(cja,new $CLJS.h(null,
1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oN,"metabase/mbql/schema.cljc",19,$CLJS.YF,1,804,804,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.YF,$CLJS.vG,$CLJS.U($CLJS.Oj,XN),$CLJS.Zn,$CLJS.U($CLJS.Oj,XN),$CLJS.hk,$CLJS.U($CLJS.Oj,XN)],null),$CLJS.Kc,"Schema for a valid between clause.",
$CLJS.n(oU)?oU.H:null])):null));return $CLJS.n(a)?a:oN}(),oU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof vU?new $CLJS.Gc(function(){return vU},$CLJS.od(eja,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),mR,"metabase/mbql/schema.cljc",23,$CLJS.mG,1,829,829,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.mG,$CLJS.vG,$CLJS.U($CLJS.Oj,gR),HN,$CLJS.U($CLJS.Oj,gR),$CLJS.ej,$CLJS.U($CLJS.Oj,$CLJS.U(sR,CQ))],null),$CLJS.Kc,"Schema for a valid starts-with clause.",$CLJS.n(vU)?vU.H:null])):null));return $CLJS.n(a)?a:mR}(),vU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof wU?new $CLJS.Gc(function(){return wU},
$CLJS.od(fia,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),MN,"metabase/mbql/schema.cljc",21,$CLJS.GG,1,830,830,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.GG,$CLJS.vG,$CLJS.U($CLJS.Oj,gR),HN,$CLJS.U($CLJS.Oj,gR),$CLJS.ej,$CLJS.U($CLJS.Oj,$CLJS.U(sR,CQ))],null),
$CLJS.Kc,"Schema for a valid ends-with clause.",$CLJS.n(wU)?wU.H:null])):null));return $CLJS.n(a)?a:MN}(),wU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof xU?new $CLJS.Gc(function(){return xU},$CLJS.od(sia,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[$CLJS.od($Q,new $CLJS.h(null,
1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),YN,"metabase/mbql/schema.cljc",20,$CLJS.uG,1,831,831,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.uG,$CLJS.vG,$CLJS.U($CLJS.Oj,gR),HN,$CLJS.U($CLJS.Oj,gR),$CLJS.ej,$CLJS.U($CLJS.Oj,$CLJS.U(sR,CQ))],null),$CLJS.Kc,"Schema for a valid contains clause.",$CLJS.n(xU)?xU.H:null])):null));return $CLJS.n(a)?a:YN}(),xU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==
typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof yU?new $CLJS.Gc(function(){return yU},$CLJS.od(Xja,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,uR,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),CM,"metabase/mbql/schema.cljc",36,$CLJS.nG,1,834,834,!0,new $CLJS.P(null,
7,5,$CLJS.Q,[$CLJS.nG,$CLJS.vG,$CLJS.U($CLJS.Oj,gR),HN,$CLJS.U($CLJS.Oj,gR),$CLJS.ej,$CLJS.U($CLJS.Oj,$CLJS.U(sR,CQ))],null),$CLJS.Kc,"Schema for a valid does-not-contain clause.",$CLJS.n(yU)?yU.H:null])):null));return $CLJS.n(a)?a:CM}(),yU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof pU?new $CLJS.Gc(function(){return pU},$CLJS.od(Gja,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),
$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,uR,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),kR,"metabase/mbql/schema.cljc",26,$CLJS.dG,1,807,807,!0,new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.dG,Oha,$CLJS.U($CLJS.Oj,XN),bka,$CLJS.U($CLJS.Oj,XN),Nja,$CLJS.U($CLJS.Oj,XN),Rha,$CLJS.U($CLJS.Oj,XN),Hja,$CLJS.U($CLJS.Oj,XN),Nha,$CLJS.U($CLJS.Oj,
XN)],null),$CLJS.Kc,"Schema for a valid inside clause.",$CLJS.n(pU)?pU.H:null])):null));return $CLJS.n(a)?a:kR}(),pU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof sU?new $CLJS.Gc(function(){return sU},$CLJS.od(tia,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,uR,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[$CLJS.od($Q,new $CLJS.h(null,
1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),FP,"metabase/mbql/schema.cljc",28,$CLJS.ZF,1,821,821,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZF,$CLJS.vG,$CLJS.U($CLJS.Oj,QN)],null),$CLJS.Kc,"Schema for a valid is-empty clause.",$CLJS.n(sU)?sU.H:null])):null));return $CLJS.n(a)?a:FP}(),sU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&
"undefined"!==typeof tU?new $CLJS.Gc(function(){return tU},$CLJS.od(rja,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,uR,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),tQ,"metabase/mbql/schema.cljc",29,$CLJS.fG,1,822,822,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fG,$CLJS.vG,$CLJS.U($CLJS.Oj,QN)],null),
$CLJS.Kc,"Schema for a valid not-empty clause.",$CLJS.n(tU)?tU.H:null])):null));return $CLJS.n(a)?a:tQ}(),tU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof qU?new $CLJS.Gc(function(){return qU},$CLJS.od(Sja,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,uR,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[$CLJS.od($Q,new $CLJS.h(null,
1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),dO,"metabase/mbql/schema.cljc",27,$CLJS.XF,1,816,816,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XF,$CLJS.vG,$CLJS.U($CLJS.Oj,QN)],null),$CLJS.Kc,"Schema for a valid is-null clause.",$CLJS.n(qU)?qU.H:null])):null));return $CLJS.n(a)?a:dO}(),qU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&
"undefined"!==typeof rU?new $CLJS.Gc(function(){return rU},$CLJS.od(Fia,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,uR,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),tN,"metabase/mbql/schema.cljc",28,$CLJS.hG,1,817,817,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hG,$CLJS.vG,$CLJS.U($CLJS.Oj,QN)],null),
$CLJS.Kc,"Schema for a valid not-null clause.",$CLJS.n(rU)?rU.H:null])):null));return $CLJS.n(a)?a:tN}(),rU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof zU?new $CLJS.Gc(function(){return zU},$CLJS.od(Fha,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,uR,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[$CLJS.od($Q,new $CLJS.h(null,
1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),SQ,"metabase/mbql/schema.cljc",33,$CLJS.HG,1,856,856,!0,new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.HG,$CLJS.vG,$CLJS.U($CLJS.Oj,QN),$CLJS.rk,$CLJS.U($CLJS.Oj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ys,$CLJS.Ks,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.lk,$CLJS.aw,$CLJS.cy,$CLJS.cG],null)],null)),$CLJS.ri,$CLJS.U($CLJS.Oj,dP),$CLJS.ej,$CLJS.U($CLJS.Oj,$CLJS.U(sR,hja))],null),$CLJS.Kc,"Schema for a valid time-interval clause.",
$CLJS.n(zU)?zU.H:null])):null));return $CLJS.n(a)?a:SQ}(),zU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof AU?new $CLJS.Gc(function(){return AU},$CLJS.od(Nia,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,uR,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),UR,"metabase/mbql/schema.cljc",27,$CLJS.UF,1,872,872,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.UF,Vha,$CLJS.U($CLJS.Oj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ys,xja,fN],null))],null),$CLJS.Kc,"Schema for a valid segment clause.",$CLJS.n(AU)?AU.H:null])):null));return $CLJS.n(a)?a:UR}(),AU],null)]))],null)],null));
$CLJS.X(FQ,nM($CLJS.eG,$CLJS.H(["clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yj,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Bs,new $CLJS.h(null,1,[$CLJS.yt,":case subclause"],null),$CLJS.bU,nT],null)],null)],null),"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ts,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gk,new $CLJS.h(null,1,[$CLJS.yt,":case options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ci,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),nT],null)],null)],null)])));
var BU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,FQ],null);
$CLJS.X(lR,oM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof AT?new $CLJS.Gc(function(){return AT},$CLJS.od(bQ,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([rP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.kQ,"null"],null),null),$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),QO,"metabase/mbql/schema.cljc",51,$CLJS.ds,1,612,612,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ds,$CLJS.ME,$CLJS.U($CLJS.Oj,fQ),DR,$CLJS.U($CLJS.Oj,fQ),UN,$CLJS.U($CLJS.Oj,$CLJS.U($CLJS.Li,fQ))],null),$CLJS.Kc,"Schema for a valid + clause.",$CLJS.n(AT)?AT.H:null])):null));return $CLJS.n(a)?a:QO}(),AT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof BT?new $CLJS.Gc(function(){return BT},
$CLJS.od(Zia,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([rP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.kQ,"null"],null),null),$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),RO,"metabase/mbql/schema.cljc",51,$CLJS.rt,1,615,615,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.rt,$CLJS.ME,$CLJS.U($CLJS.Oj,bR),
DR,$CLJS.U($CLJS.Oj,fQ),UN,$CLJS.U($CLJS.Oj,$CLJS.U($CLJS.Li,fQ))],null),$CLJS.Kc,"Schema for a valid - clause.",$CLJS.n(BT)?BT.H:null])):null));return $CLJS.n(a)?a:RO}(),BT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM?new $CLJS.Gc(function(){return CT},$CLJS.od(Yia,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([rP,$CLJS.Vi,$CLJS.T,$CLJS.pk,tP,$CLJS.Si,$CLJS.mj,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],
[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.kQ,"null"],null),null),$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.lA,"metabase/mbql/schema.cljc",$CLJS.RG,1,618,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.RG,$CLJS.ME,$CLJS.U($CLJS.Oj,bR),DR,$CLJS.U($CLJS.Oj,bR),UN,$CLJS.U($CLJS.Oj,$CLJS.U($CLJS.Li,bR))],null),$CLJS.Kc,"Schema for a valid / clause.",$CLJS.n(CT)?CT.H:null])):null));return $CLJS.n(a)?
a:$CLJS.lA}(),CT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof DT?new $CLJS.Gc(function(){return DT},$CLJS.od($ia,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([rP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.kQ,"null"],null),null),$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),PO,"metabase/mbql/schema.cljc",51,$CLJS.es,1,620,620,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.es,$CLJS.ME,$CLJS.U($CLJS.Oj,bR),DR,$CLJS.U($CLJS.Oj,bR),UN,$CLJS.U($CLJS.Oj,$CLJS.U($CLJS.Li,bR))],null),$CLJS.Kc,"Schema for a valid * clause.",$CLJS.n(DT)?DT.H:null])):null));return $CLJS.n(a)?a:PO}(),DT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof pT?new $CLJS.Gc(function(){return pT},
$CLJS.od(PQ,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([rP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.kQ,"null"],null),null),$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),dR,"metabase/mbql/schema.cljc",58,$CLJS.yG,1,579,579,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.yG,MR,$CLJS.U($CLJS.Oj,HM),LR,$CLJS.U($CLJS.Oj,
HM),UN,$CLJS.U($CLJS.Oj,$CLJS.U($CLJS.Li,HM))],null),$CLJS.Kc,"Schema for a valid coalesce clause.",$CLJS.n(pT)?pT.H:null])):null));return $CLJS.n(a)?a:dR}(),pT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof rT?new $CLJS.Gc(function(){return rT},$CLJS.od(qja,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([rP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,
$CLJS.ti,$CLJS.ik,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.kQ,"null"],null),null),$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),wQ,"metabase/mbql/schema.cljc",56,$CLJS.Xx,1,585,585,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Xx,$CLJS.ty,$CLJS.U($CLJS.Oj,gR)],null),$CLJS.Kc,"Schema for a valid length clause.",$CLJS.n(rT)?rT.H:null])):null));return $CLJS.n(a)?a:wQ}(),rT],null),new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof ET?new $CLJS.Gc(function(){return ET},$CLJS.od(gia,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([rP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.kQ,"null"],null),null),$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),qP,"metabase/mbql/schema.cljc",55,$CLJS.tG,1,622,622,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tG,$CLJS.ME,$CLJS.U($CLJS.Oj,bR)],null),$CLJS.Kc,"Schema for a valid floor clause.",$CLJS.n(ET)?ET.H:null])):null));return $CLJS.n(a)?a:qP}(),ET],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof FT?new $CLJS.Gc(function(){return FT},$CLJS.od(aia,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),
$CLJS.Ig([rP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.kQ,"null"],null),null),$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),eN,"metabase/mbql/schema.cljc",54,$CLJS.RF,1,625,625,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RF,$CLJS.ME,$CLJS.U($CLJS.Oj,bR)],null),$CLJS.Kc,"Schema for a valid ceil clause.",$CLJS.n(FT)?
FT.H:null])):null));return $CLJS.n(a)?a:eN}(),FT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof GT?new $CLJS.Gc(function(){return GT},$CLJS.od(pia,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([rP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.kQ,"null"],null),null),$CLJS.od($Q,new $CLJS.h(null,
1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),gP,"metabase/mbql/schema.cljc",55,$CLJS.AG,1,628,628,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AG,$CLJS.ME,$CLJS.U($CLJS.Oj,bR)],null),$CLJS.Kc,"Schema for a valid round clause.",$CLJS.n(GT)?GT.H:null])):null));return $CLJS.n(a)?a:gP}(),GT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&
"undefined"!==typeof HT?new $CLJS.Gc(function(){return HT},$CLJS.od(Yja,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([rP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.kQ,"null"],null),null),$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),JP,"metabase/mbql/schema.cljc",53,$CLJS.WF,1,631,631,new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.WF,$CLJS.ME,$CLJS.U($CLJS.Oj,bR)],null),$CLJS.Kc,"Schema for a valid abs clause.",$CLJS.n(HT)?HT.H:null])):null));return $CLJS.n(a)?a:JP}(),HT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof IT?new $CLJS.Gc(function(){return IT},$CLJS.od(Lja,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([rP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,
pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[aS,"null"],null),null),$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),vR,"metabase/mbql/schema.cljc",69,$CLJS.TF,1,634,634,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.TF,$CLJS.ME,$CLJS.U($CLJS.Oj,bR),DR,$CLJS.U($CLJS.Oj,bR)],null),$CLJS.Kc,"Schema for a valid power clause.",$CLJS.n(IT)?IT.H:null])):null));return $CLJS.n(a)?a:vR}(),IT],
null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof JT?new $CLJS.Gc(function(){return JT},$CLJS.od(Iia,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([rP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[aS,"null"],null),null),$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),LO,"metabase/mbql/schema.cljc",68,$CLJS.iG,1,637,637,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iG,$CLJS.ME,$CLJS.U($CLJS.Oj,bR)],null),$CLJS.Kc,"Schema for a valid sqrt clause.",$CLJS.n(JT)?JT.H:null])):null));return $CLJS.n(a)?a:LO}(),JT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof KT?new $CLJS.Gc(function(){return KT},$CLJS.od(Mja,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),
$CLJS.Ig([rP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[aS,"null"],null),null),$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),iP,"metabase/mbql/schema.cljc",67,$CLJS.NF,1,640,640,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NF,$CLJS.ME,$CLJS.U($CLJS.Oj,bR)],null),$CLJS.Kc,"Schema for a valid exp clause.",$CLJS.n(KT)?
KT.H:null])):null));return $CLJS.n(a)?a:iP}(),KT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof LT?new $CLJS.Gc(function(){return LT},$CLJS.od(Uha,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([rP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[aS,"null"],null),null),$CLJS.od($Q,new $CLJS.h(null,
1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),fS,"metabase/mbql/schema.cljc",67,$CLJS.TG,1,643,643,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TG,$CLJS.ME,$CLJS.U($CLJS.Oj,bR)],null),$CLJS.Kc,"Schema for a valid log clause.",$CLJS.n(LT)?LT.H:null])):null));return $CLJS.n(a)?a:fS}(),LT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&
"undefined"!==typeof BU?new $CLJS.Gc(function(){return BU},$CLJS.od(SM,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([rP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.QF,"null"],null),null),$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),AP,"metabase/mbql/schema.cljc",61,$CLJS.eG,1,909,909,new $CLJS.P(null,
5,5,$CLJS.Q,[$CLJS.eG,$CLJS.jx,$CLJS.U($CLJS.Oj,jO),$CLJS.ej,$CLJS.U($CLJS.Oj,$CLJS.U(sR,TP))],null),$CLJS.Kc,"Schema for a valid case clause.",$CLJS.n(BU)?BU.H:null])):null));return $CLJS.n(a)?a:AP}(),BU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof MT?new $CLJS.Gc(function(){return MT},$CLJS.od(Xha,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([rP,$CLJS.Vi,$CLJS.T,$CLJS.pk,
$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.kJ,"null"],null),null),$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),KP,"metabase/mbql/schema.cljc",65,$CLJS.kJ,1,656,656,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.kJ,jia,$CLJS.U($CLJS.Oj,qS),kia,$CLJS.U($CLJS.Oj,qS),$CLJS.ri,$CLJS.U($CLJS.Oj,Qia)],null),$CLJS.Kc,"Schema for a valid datetime-diff clause.",
$CLJS.n(MT)?MT.H:null])):null));return $CLJS.n(a)?a:KP}(),MT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof NT?new $CLJS.Gc(function(){return NT},$CLJS.od(uja,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([rP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.zJ,"null"],null),null),$CLJS.od($Q,
new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),cQ,"metabase/mbql/schema.cljc",71,$CLJS.zJ,1,661,661,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.zJ,$CLJS.oJ,$CLJS.U($CLJS.Oj,qS),$CLJS.ri,$CLJS.U($CLJS.Oj,Rja),$CLJS.xi,$CLJS.U($CLJS.Oj,$CLJS.U(sR,yS))],null),$CLJS.Kc,"Schema for a valid temporal-extract clause.",$CLJS.n(NT)?NT.H:null])):null));return $CLJS.n(a)?a:cQ}(),NT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=
tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof OT?new $CLJS.Gc(function(){return OT},$CLJS.od(Bia,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([rP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,uR,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.zJ,"null"],null),null),$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),BN,"metabase/mbql/schema.cljc",71,$CLJS.gJ,1,667,667,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gJ,$CLJS.MP,$CLJS.U($CLJS.Oj,qS)],null),$CLJS.Kc,"Schema for a valid get-year clause.",$CLJS.n(OT)?OT.H:null])):null));return $CLJS.n(a)?a:BN}(),OT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof PT?new $CLJS.Gc(function(){return PT},$CLJS.od(qia,new $CLJS.h(null,1,[$CLJS.Zj,!0],
null)),$CLJS.Ig([rP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,uR,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.zJ,"null"],null),null),$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),aQ,"metabase/mbql/schema.cljc",74,$CLJS.DJ,1,670,670,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DJ,$CLJS.MP,$CLJS.U($CLJS.Oj,qS)],null),$CLJS.Kc,"Schema for a valid get-quarter clause.",
$CLJS.n(PT)?PT.H:null])):null));return $CLJS.n(a)?a:aQ}(),PT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof QT?new $CLJS.Gc(function(){return QT},$CLJS.od(Xia,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([rP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,uR,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.zJ,"null"],null),null),
$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),yQ,"metabase/mbql/schema.cljc",72,$CLJS.$I,1,673,673,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$I,$CLJS.MP,$CLJS.U($CLJS.Oj,qS)],null),$CLJS.Kc,"Schema for a valid get-month clause.",$CLJS.n(QT)?QT.H:null])):null));return $CLJS.n(a)?a:yQ}(),QT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==
typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof RT?new $CLJS.Gc(function(){return RT},$CLJS.od(Qja,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([rP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,uR,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.zJ,"null"],null),null),$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),xQ,"metabase/mbql/schema.cljc",
71,$CLJS.tJ,1,676,676,!0,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.tJ,$CLJS.MP,$CLJS.U($CLJS.Oj,qS),$CLJS.xi,$CLJS.U($CLJS.Oj,$CLJS.U(sR,yS))],null),$CLJS.Kc,"Schema for a valid get-week clause.",$CLJS.n(RT)?RT.H:null])):null));return $CLJS.n(a)?a:xQ}(),RT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof ST?new $CLJS.Gc(function(){return ST},$CLJS.od(Lha,new $CLJS.h(null,1,[$CLJS.Zj,
!0],null)),$CLJS.Ig([rP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,uR,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.zJ,"null"],null),null),$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qQ,"metabase/mbql/schema.cljc",70,$CLJS.sJ,1,680,680,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sJ,$CLJS.MP,$CLJS.U($CLJS.Oj,qS)],null),$CLJS.Kc,"Schema for a valid get-day clause.",
$CLJS.n(ST)?ST.H:null])):null));return $CLJS.n(a)?a:qQ}(),ST],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof TT?new $CLJS.Gc(function(){return TT},$CLJS.od(Ria,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([rP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,uR,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.zJ,"null"],null),null),
$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),gQ,"metabase/mbql/schema.cljc",78,$CLJS.xJ,1,683,683,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xJ,$CLJS.MP,$CLJS.U($CLJS.Oj,qS)],null),$CLJS.Kc,"Schema for a valid get-day-of-week clause.",$CLJS.n(TT)?TT.H:null])):null));return $CLJS.n(a)?a:gQ}(),TT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==
typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof UT?new $CLJS.Gc(function(){return UT},$CLJS.od(Pia,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([rP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,uR,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.zJ,"null"],null),null),$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),IM,"metabase/mbql/schema.cljc",
71,$CLJS.AJ,1,686,686,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AJ,$CLJS.oJ,$CLJS.U($CLJS.Oj,qS)],null),$CLJS.Kc,"Schema for a valid get-hour clause.",$CLJS.n(UT)?UT.H:null])):null));return $CLJS.n(a)?a:IM}(),UT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof VT?new $CLJS.Gc(function(){return VT},$CLJS.od(yja,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([rP,$CLJS.Vi,$CLJS.T,$CLJS.pk,
$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,uR,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.zJ,"null"],null),null),$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),jS,"metabase/mbql/schema.cljc",73,$CLJS.wJ,1,689,689,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wJ,$CLJS.oJ,$CLJS.U($CLJS.Oj,qS)],null),$CLJS.Kc,"Schema for a valid get-minute clause.",$CLJS.n(VT)?VT.H:null])):null));
return $CLJS.n(a)?a:jS}(),VT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof WT?new $CLJS.Gc(function(){return WT},$CLJS.od(Mia,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([rP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,uR,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.zJ,"null"],null),null),$CLJS.od($Q,new $CLJS.h(null,1,
[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),CP,"metabase/mbql/schema.cljc",73,$CLJS.fJ,1,692,692,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fJ,$CLJS.oJ,$CLJS.U($CLJS.Oj,qS)],null),$CLJS.Kc,"Schema for a valid get-second clause.",$CLJS.n(WT)?WT.H:null])):null));return $CLJS.n(a)?a:CP}(),WT],null)])));
$CLJS.X(XM,oM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof qT?new $CLJS.Gc(function(){return qT},$CLJS.od(oja,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([rP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.kQ,"null"],null),null),$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),GQ,"metabase/mbql/schema.cljc",59,$CLJS.OF,1,582,582,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.OF,$CLJS.ty,$CLJS.U($CLJS.Oj,gR),$CLJS.Gv,$CLJS.U($CLJS.Oj,mia),$CLJS.Xx,$CLJS.U($CLJS.Oj,$CLJS.U(sR,bR))],null),$CLJS.Kc,"Schema for a valid substring clause.",$CLJS.n(qT)?qT.H:null])):null));return $CLJS.n(a)?a:GQ}(),qT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof sT?new $CLJS.Gc(function(){return sT},
$CLJS.od(Oja,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([rP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.kQ,"null"],null),null),$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),cR,"metabase/mbql/schema.cljc",54,$CLJS.MG,1,588,588,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.MG,$CLJS.ty,$CLJS.U($CLJS.Oj,gR)],
null),$CLJS.Kc,"Schema for a valid trim clause.",$CLJS.n(sT)?sT.H:null])):null));return $CLJS.n(a)?a:cR}(),sT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof uT?new $CLJS.Gc(function(){return uT},$CLJS.od(Sia,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([rP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,
1,[$CLJS.kQ,"null"],null),null),$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),MQ,"metabase/mbql/schema.cljc",55,$CLJS.OG,1,594,594,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.OG,$CLJS.ty,$CLJS.U($CLJS.Oj,gR)],null),$CLJS.Kc,"Schema for a valid ltrim clause.",$CLJS.n(uT)?uT.H:null])):null));return $CLJS.n(a)?a:MQ}(),uT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&
"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof tT?new $CLJS.Gc(function(){return tT},$CLJS.od(pja,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([rP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.kQ,"null"],null),null),$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),OP,"metabase/mbql/schema.cljc",
55,$CLJS.VG,1,591,591,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VG,$CLJS.ty,$CLJS.U($CLJS.Oj,gR)],null),$CLJS.Kc,"Schema for a valid rtrim clause.",$CLJS.n(tT)?tT.H:null])):null));return $CLJS.n(a)?a:OP}(),tT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof xT?new $CLJS.Gc(function(){return xT},$CLJS.od(Gha,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([rP,$CLJS.Vi,$CLJS.T,$CLJS.pk,
$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.kQ,"null"],null),null),$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),OR,"metabase/mbql/schema.cljc",57,$CLJS.UG,1,603,603,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.UG,$CLJS.ty,$CLJS.U($CLJS.Oj,gR),Sha,$CLJS.U($CLJS.Oj,$CLJS.Xj),$CLJS.sA,$CLJS.U($CLJS.Oj,$CLJS.Xj)],null),$CLJS.Kc,"Schema for a valid replace clause.",
$CLJS.n(xT)?xT.H:null])):null));return $CLJS.n(a)?a:OR}(),xT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof wT?new $CLJS.Gc(function(){return wT},$CLJS.od(sja,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([rP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.kQ,"null"],null),null),$CLJS.od($Q,
new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),tS,"metabase/mbql/schema.cljc",55,$CLJS.Rt,1,600,600,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Rt,$CLJS.ty,$CLJS.U($CLJS.Oj,gR)],null),$CLJS.Kc,"Schema for a valid lower clause.",$CLJS.n(wT)?wT.H:null])):null));return $CLJS.n(a)?a:tS}(),wT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==
typeof sM&&"undefined"!==typeof vT?new $CLJS.Gc(function(){return vT},$CLJS.od(Pja,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([rP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.kQ,"null"],null),null),$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pR,"metabase/mbql/schema.cljc",55,$CLJS.Lt,1,597,597,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Lt,$CLJS.ty,$CLJS.U($CLJS.Oj,gR)],null),$CLJS.Kc,"Schema for a valid upper clause.",$CLJS.n(vT)?vT.H:null])):null));return $CLJS.n(a)?a:pR}(),vT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof yT?new $CLJS.Gc(function(){return yT},$CLJS.od(gja,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([rP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,
$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.kQ,"null"],null),null),$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),CO,"metabase/mbql/schema.cljc",56,$CLJS.VF,1,606,606,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.VF,MR,$CLJS.U($CLJS.Oj,gR),LR,$CLJS.U($CLJS.Oj,gR),UN,$CLJS.U($CLJS.Oj,$CLJS.U($CLJS.Li,gR))],null),$CLJS.Kc,"Schema for a valid concat clause.",
$CLJS.n(yT)?yT.H:null])):null));return $CLJS.n(a)?a:CO}(),yT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof zT?new $CLJS.Gc(function(){return zT},$CLJS.od(nja,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([rP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.kQ,"null",$CLJS.Mda,"null"],
null),null),$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),zN,"metabase/mbql/schema.cljc",74,$CLJS.xG,1,609,609,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.xG,$CLJS.ty,$CLJS.U($CLJS.Oj,gR),$CLJS.rA,$CLJS.U($CLJS.Oj,$CLJS.Xj)],null),$CLJS.Kc,"Schema for a valid regex-match-first clause.",$CLJS.n(zT)?zT.H:null])):null));return $CLJS.n(a)?a:zN}(),zT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==
typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof pT?new $CLJS.Gc(function(){return pT},$CLJS.od(PQ,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([rP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.kQ,"null"],null),null),$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
dR,"metabase/mbql/schema.cljc",58,$CLJS.yG,1,579,579,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.yG,MR,$CLJS.U($CLJS.Oj,HM),LR,$CLJS.U($CLJS.Oj,HM),UN,$CLJS.U($CLJS.Oj,$CLJS.U($CLJS.Li,HM))],null),$CLJS.Kc,"Schema for a valid coalesce clause.",$CLJS.n(pT)?pT.H:null])):null));return $CLJS.n(a)?a:dR}(),pT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof BU?new $CLJS.Gc(function(){return BU},
$CLJS.od(SM,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([rP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.QF,"null"],null),null),$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),AP,"metabase/mbql/schema.cljc",61,$CLJS.eG,1,909,909,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.eG,$CLJS.jx,$CLJS.U($CLJS.Oj,jO),
$CLJS.ej,$CLJS.U($CLJS.Oj,$CLJS.U(sR,TP))],null),$CLJS.Kc,"Schema for a valid case clause.",$CLJS.n(BU)?BU.H:null])):null));return $CLJS.n(a)?a:AP}(),BU],null)])));
$CLJS.CU=new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,2,[$CLJS.yt,":field or :expression reference or expression",$CLJS.Ni,function(a){return $CLJS.n(mM(dT,a))?rS:$CLJS.n(mM(aT,a))?$CLJS.Xj:$CLJS.n(mM(eT,a))?$CLJS.Os:$CLJS.n(mM(gT,a))?$CLJS.oJ:$CLJS.n(mM($CLJS.eG,a))?$CLJS.eG:$CLJS.Nt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[rS,hT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xj,bT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Os,iT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oJ,$CLJS.jT],null),
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eG,BU],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Nt,$CLJS.YS],null)],null);$CLJS.X(YR,nM($CLJS.Zx,$CLJS.H(["field",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ts,$CLJS.YS],null)])));var DU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YR],null);$CLJS.X(LP,nM($CLJS.wG,$CLJS.H(["field",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ts,$CLJS.YS],null)])));var EU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,LP],null);$CLJS.X(VM,nM($CLJS.pG,$CLJS.H(["field-or-expression",$CLJS.CU])));
var FU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,VM],null);$CLJS.X(PR,nM($CLJS.SG,$CLJS.H(["field-or-expression",$CLJS.CU])));var GU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PR],null);$CLJS.X(uO,nM($CLJS.aG,$CLJS.H(["field-or-expression",$CLJS.CU])));var HU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uO],null);$CLJS.X(eR,nM($CLJS.IG,$CLJS.H(["field-or-expression",$CLJS.CU])));var IU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,eR],null);$CLJS.X(pO,nM($CLJS.Zn,$CLJS.H(["field-or-expression",$CLJS.CU])));
var JU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pO],null);$CLJS.X(bO,nM($CLJS.hk,$CLJS.H(["field-or-expression",$CLJS.CU])));var KU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,bO],null);$CLJS.X(WN,nM($CLJS.QG,$CLJS.H(["field-or-expression",$CLJS.CU,"pred",$CLJS.bU])));var LU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,WN],null);$CLJS.X(mO,nM($CLJS.EG,$CLJS.H(["pred",$CLJS.bU])));var MU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mO],null);$CLJS.X(WQ,nM($CLJS.CG,$CLJS.H(["pred",$CLJS.bU])));
var NU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,WQ],null);$CLJS.X(sN,nM($CLJS.LG,$CLJS.H(["field-or-expression",$CLJS.CU])));var OU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sN],null);$CLJS.X(uM,nM($CLJS.bG,$CLJS.H(["field-or-expression",$CLJS.CU])));var PU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uM],null);$CLJS.X(yO,nM($CLJS.JG,$CLJS.H(["field-or-expression",$CLJS.CU])));var QU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yO],null);$CLJS.X(lP,nM($CLJS.gG,$CLJS.H(["field-or-expression",$CLJS.CU,"percentile",lT])));
var RU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lP],null);$CLJS.X(ZQ,nM($CLJS.qG,$CLJS.H(["metric-id",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.uE],null),zS],null)])));var SU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ZQ],null);
$CLJS.X(VQ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,2,[$CLJS.yt,"unnamed aggregation clause or numeric expression",$CLJS.Ni,function(a){return $CLJS.n(mM(dT,a))?oP:$CLJS.Nt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[oP,hT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Nt,oM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof FU?new $CLJS.Gc(function(){return FU},$CLJS.od(mja,
new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([rP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.QF,"null"],null),null),$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),xP,"metabase/mbql/schema.cljc",60,$CLJS.pG,1,957,957,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pG,qR,$CLJS.U($CLJS.Oj,iR)],null),$CLJS.Kc,"Schema for a valid avg clause.",
$CLJS.n(FU)?FU.H:null])):null));return $CLJS.n(a)?a:xP}(),FU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof GU?new $CLJS.Gc(function(){return GU},$CLJS.od(xia,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([rP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.QF,"null"],null),null),$CLJS.od($Q,
new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),CR,"metabase/mbql/schema.cljc",64,$CLJS.SG,1,958,958,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SG,qR,$CLJS.U($CLJS.Oj,iR)],null),$CLJS.Kc,"Schema for a valid cum-sum clause.",$CLJS.n(GU)?GU.H:null])):null));return $CLJS.n(a)?a:CR}(),GU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==
typeof sM&&"undefined"!==typeof HU?new $CLJS.Gc(function(){return HU},$CLJS.od(oia,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([rP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.QF,"null"],null),null),$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),BP,"metabase/mbql/schema.cljc",65,$CLJS.aG,1,959,959,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aG,qR,$CLJS.U($CLJS.Oj,iR)],null),$CLJS.Kc,"Schema for a valid distinct clause.",$CLJS.n(HU)?HU.H:null])):null));return $CLJS.n(a)?a:BP}(),HU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof OU?new $CLJS.Gc(function(){return OU},$CLJS.od(Ija,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([rP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,
$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.FG,"null"],null),null),$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),PM,"metabase/mbql/schema.cljc",76,$CLJS.LG,1,973,973,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LG,qR,$CLJS.U($CLJS.Oj,iR)],null),$CLJS.Kc,"Schema for a valid stddev clause.",$CLJS.n(OU)?OU.H:null])):null));return $CLJS.n(a)?a:PM}(),OU],null),new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof IU?new $CLJS.Gc(function(){return IU},$CLJS.od(bja,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([rP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.QF,"null"],null),null),$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),kN,"metabase/mbql/schema.cljc",60,$CLJS.IG,1,960,960,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IG,qR,$CLJS.U($CLJS.Oj,iR)],null),$CLJS.Kc,"Schema for a valid sum clause.",$CLJS.n(IU)?IU.H:null])):null));return $CLJS.n(a)?a:kN}(),IU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof JU?new $CLJS.Gc(function(){return JU},$CLJS.od(Pha,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([rP,
$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.QF,"null"],null),null),$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.et,"metabase/mbql/schema.cljc",60,$CLJS.Zn,1,961,961,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Zn,qR,$CLJS.U($CLJS.Oj,iR)],null),$CLJS.Kc,"Schema for a valid min clause.",$CLJS.n(JU)?JU.H:
null])):null));return $CLJS.n(a)?a:$CLJS.et}(),JU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof KU?new $CLJS.Gc(function(){return KU},$CLJS.od(Kha,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([rP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.QF,"null"],null),null),$CLJS.od($Q,new $CLJS.h(null,
1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.it,"metabase/mbql/schema.cljc",60,$CLJS.hk,1,962,962,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hk,qR,$CLJS.U($CLJS.Oj,iR)],null),$CLJS.Kc,"Schema for a valid max clause.",$CLJS.n(KU)?KU.H:null])):null));return $CLJS.n(a)?a:$CLJS.it}(),KU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&
"undefined"!==typeof SU?new $CLJS.Gc(function(){return SU},$CLJS.od(lia,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),BO,"metabase/mbql/schema.cljc",18,$CLJS.qG,1,994,994,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qG,yia,$CLJS.U($CLJS.Oj,new $CLJS.P(null,3,
5,$CLJS.Q,[$CLJS.ys,Cia,fN],null))],null),$CLJS.Kc,"Schema for a valid metric clause.",$CLJS.n(SU)?SU.H:null])):null));return $CLJS.n(a)?a:BO}(),SU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof NU?new $CLJS.Gc(function(){return NU},$CLJS.od(Kia,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([rP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,
$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.QF,"null"],null),null),$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),tO,"metabase/mbql/schema.cljc",62,$CLJS.CG,1,970,970,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CG,$CLJS.ro,$CLJS.U($CLJS.Oj,jP)],null),$CLJS.Kc,"Schema for a valid share clause.",$CLJS.n(NU)?NU.H:null])):null));return $CLJS.n(a)?a:tO}(),NU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=
tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof MU?new $CLJS.Gc(function(){return MU},$CLJS.od(Qha,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([rP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.QF,"null"],null),null),$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),gN,"metabase/mbql/schema.cljc",68,$CLJS.EG,1,967,967,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EG,$CLJS.ro,$CLJS.U($CLJS.Oj,jP)],null),$CLJS.Kc,"Schema for a valid count-where clause.",$CLJS.n(MU)?MU.H:null])):null));return $CLJS.n(a)?a:gN}(),MU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof LU?new $CLJS.Gc(function(){return LU},$CLJS.od(Wja,new $CLJS.h(null,1,[$CLJS.Zj,!0],
null)),$CLJS.Ig([rP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.QF,"null"],null),null),$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),yP,"metabase/mbql/schema.cljc",66,$CLJS.QG,1,964,964,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.QG,qR,$CLJS.U($CLJS.Oj,iR),$CLJS.ro,$CLJS.U($CLJS.Oj,jP)],null),$CLJS.Kc,"Schema for a valid sum-where clause.",
$CLJS.n(LU)?LU.H:null])):null));return $CLJS.n(a)?a:yP}(),LU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof BU?new $CLJS.Gc(function(){return BU},$CLJS.od(SM,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([rP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.QF,"null"],null),null),$CLJS.od($Q,
new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),AP,"metabase/mbql/schema.cljc",61,$CLJS.eG,1,909,909,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.eG,$CLJS.jx,$CLJS.U($CLJS.Oj,jO),$CLJS.ej,$CLJS.U($CLJS.Oj,$CLJS.U(sR,TP))],null),$CLJS.Kc,"Schema for a valid case clause.",$CLJS.n(BU)?BU.H:null])):null));return $CLJS.n(a)?a:AP}(),BU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&
"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof QU?new $CLJS.Gc(function(){return QU},$CLJS.od(Bja,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([rP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.rG,"null"],null),null),$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),AR,"metabase/mbql/schema.cljc",
68,$CLJS.JG,1,979,979,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.JG,qR,$CLJS.U($CLJS.Oj,iR)],null),$CLJS.Kc,"Schema for a valid median clause.",$CLJS.n(QU)?QU.H:null])):null));return $CLJS.n(a)?a:AR}(),QU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof RU?new $CLJS.Gc(function(){return RU},$CLJS.od(Wia,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([rP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,
tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.rG,"null"],null),null),$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),YQ,"metabase/mbql/schema.cljc",72,$CLJS.gG,1,982,982,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.gG,qR,$CLJS.U($CLJS.Oj,iR),$CLJS.gG,$CLJS.U($CLJS.Oj,bR)],null),$CLJS.Kc,"Schema for a valid percentile clause.",$CLJS.n(RU)?RU.H:null])):
null));return $CLJS.n(a)?a:YQ}(),RU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof PU?new $CLJS.Gc(function(){return PU},$CLJS.od($ha,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),KM,"metabase/mbql/schema.cljc",77,$CLJS.bG,1,976,976,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bG,qR,$CLJS.U($CLJS.Oj,iR)],null),$CLJS.Kc,"Schema for a valid var clause.",$CLJS.n(PU)?PU.H:null])):null));return $CLJS.n(a)?a:KM}(),PU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof EU?new $CLJS.Gc(function(){return EU},$CLJS.od(Mha,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([rP,
$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,uR,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.QF,"null"],null),null),$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),kP,"metabase/mbql/schema.cljc",74,$CLJS.wG,1,947,947,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wG,$CLJS.vG,$CLJS.U($CLJS.Oj,$CLJS.U(sR,QN))],null),$CLJS.Kc,"Schema for a valid cum-count clause.",
$CLJS.n(EU)?EU.H:null])):null));return $CLJS.n(a)?a:kP}(),EU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof DU?new $CLJS.Gc(function(){return DU},$CLJS.od(nia,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([rP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,uR,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.QF,"null"],null),null),
$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.wi,"metabase/mbql/schema.cljc",70,$CLJS.Zx,1,946,946,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Zx,$CLJS.vG,$CLJS.U($CLJS.Oj,$CLJS.U(sR,QN))],null),$CLJS.Kc,"Schema for a valid count clause.",$CLJS.n(DU)?DU.H:null])):null));return $CLJS.n(a)?a:$CLJS.wi}(),DU],null)]))],null)],null));
$CLJS.X(nQ,nM($CLJS.FM,$CLJS.H(["aggregation",VQ,"options",new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.gk,new $CLJS.h(null,1,[$CLJS.yt,":aggregation-options options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),zS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zE,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),zS],null)],null)])));
$CLJS.X(rR,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,2,[$CLJS.yt,"aggregation clause or numeric expression",$CLJS.Ni,function(a){return $CLJS.n(mM($CLJS.FM,a))?$CLJS.FM:iO}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.FM,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nQ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[iO,VQ],null)],null));$CLJS.X(OM,nM($CLJS.sG,$CLJS.H(["field",$S])));var TU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,OM],null);$CLJS.X(nP,nM($CLJS.lG,$CLJS.H(["field",$S])));
UU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nP],null);
nka=oM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof TU?new $CLJS.Gc(function(){return TU},$CLJS.od(Fja,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
UO,"metabase/mbql/schema.cljc",15,$CLJS.sG,1,1049,1049,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sG,$CLJS.vG,$CLJS.U($CLJS.Oj,KR)],null),$CLJS.Kc,"Schema for a valid asc clause.",$CLJS.n(TU)?TU.H:null])):null));return $CLJS.n(a)?a:UO}(),TU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof UU?new $CLJS.Gc(function(){return UU},$CLJS.od(wia,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,
$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),QQ,"metabase/mbql/schema.cljc",16,$CLJS.lG,1,1050,1050,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lG,$CLJS.vG,$CLJS.U($CLJS.Oj,KR)],null),$CLJS.Kc,"Schema for a valid desc clause.",$CLJS.n(UU)?UU.H:null])):null));return $CLJS.n(a)?a:QQ}(),UU],null)]));
VU=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.lk,$CLJS.RM,$CLJS.GN,$CLJS.PF,$CLJS.$t,$CLJS.DB,$CLJS.MP],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,zS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zE,zS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Yi,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),zS],null)],null);
oka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xt,VU,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tk,$CLJS.RM],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.DM,zS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dQ,CS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eQ,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),CS],null)],null)],null);
pka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xt,VU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tk,$CLJS.GN],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nR,CS],null)],null)],null);
WU=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xt,VU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ci,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.Ps],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hO,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.Os],null)],null)],null);qka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,BR],null);
rka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xt,WU,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tk,$CLJS.PF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.PF,XS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aO,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jN],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ds,$CLJS.Wi,$CLJS.Ps],
null)],null)],null)],null)],null);$CLJS.XU=new $CLJS.ah(null,new $CLJS.h(null,4,[$CLJS.MP,null,$CLJS.$t,null,$CLJS.Os,null,$CLJS.DB,null],null),null);ska=$CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.lk],null),$CLJS.XU);
$CLJS.X(JQ,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,1,[$CLJS.Ni,$CLJS.lj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.PF,rka],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.RM,oka],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.GN,pka],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$o,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xt,WU,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj,ska],null)],null)],null)],null)],null));
var YU=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RQ,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ds,zS,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JQ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.yt,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Qe(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.E.g(c,
$CLJS.T.h(b))},a)}],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wO,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,zS],null)],null)],null),tka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xt,YU,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.SF,$CLJS.Ps],null)],null)],null),ZU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rQ],null),$U=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,1,[$CLJS.Ni,function(a){return $CLJS.n($CLJS.qE($CLJS.xd,
$CLJS.uQ)(a))?$CLJS.uQ:$CLJS.yE}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uQ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xt,YU,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uQ,$CLJS.Ps],null)],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yE,ZU],null)],null),aV=new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,zS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nD,AS],null),new $CLJS.P(null,2,5,$CLJS.Q,[dja,zS],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.OO,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,BS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cO,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,$CLJS.gk],null)],null)],null),bV=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ys,fka,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zs,new $CLJS.h(null,1,[$CLJS.yt,"'card__\x3cid\x3e' string Table ID"],null),/^card__[1-9]\d*$/],null)],null),uka=$CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.lk],null),
new $CLJS.ah(null,new $CLJS.h(null,4,[$CLJS.uF,null,$CLJS.dF,null,$CLJS.nF,null,$CLJS.rF,null],null),null)),cV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,zR],null);
$CLJS.X(sO,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pP,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),bV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TQ,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$U],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bS,$CLJS.bU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QE,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),uka],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eF,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,
4,5,$CLJS.Q,[$CLJS.ys,new $CLJS.h(null,1,[$CLJS.yt,"Valid join `:fields`: `:all`, `:none`, or a sequence of `:field` clauses that have `:join-alias`."],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lk,$CLJS.Ey,$CLJS.mA],null),cV],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iF,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),zS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kL,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,ES],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bP,new $CLJS.h(null,
1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yj,aV],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.yt,"Joins must have either a `source-table` or `source-query`, but not both."],null),$CLJS.qE($CLJS.HA($CLJS.pP,$CLJS.TQ),$CLJS.Te($CLJS.qE($CLJS.pP,$CLJS.TQ)))],null)],null));var vka=$CLJS.Q,dV;var eV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sO],null)],null);
if($CLJS.wd(eV)&&$CLJS.E.g($CLJS.A(eV),$CLJS.yj)){var fV=$CLJS.y(eV);$CLJS.A(fV);var gV=$CLJS.B(fV),wka=$CLJS.xd($CLJS.A(gV))?gV:$CLJS.ee(null,gV),hV=$CLJS.y(wka),xka=$CLJS.A(hV),yka=$CLJS.B(hV);dV=$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yj,$CLJS.R.j(xka,$CLJS.Zn,1)],null),yka)}else dV=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,eV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.yt,"non-empty"],null),$CLJS.y],null)],null);
$CLJS.X(uN,new $CLJS.P(null,3,5,vka,[$CLJS.Fs,dV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.yt,"All join aliases must be unique."],null),function(a){return $CLJS.ZL($CLJS.lf($CLJS.Wa,$CLJS.hf.g($CLJS.iF,a)))}],null)],null));var zka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uN],null);
$CLJS.X(zR,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mk,new $CLJS.h(null,1,[$CLJS.yt,"Distinct, non-empty sequence of Field clauses"],null),pM(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yj,new $CLJS.h(null,1,[$CLJS.Zn,1],null),$CLJS.YS],null))],null));
$CLJS.X(rQ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Fs,new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TQ,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$U],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pP,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),bV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kG,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yj,new $CLJS.h(null,1,[$CLJS.Zn,1],null),$CLJS.kT],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jG,new $CLJS.h(null,1,
[$CLJS.Ts,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yj,new $CLJS.h(null,1,[$CLJS.Zn,1],null),$CLJS.YS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kQ,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ds,zS,$CLJS.CU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eF,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),cV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$F,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.bU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wS,new $CLJS.h(null,
1,[$CLJS.Ts,!0],null),DS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$R,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),pM(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yj,new $CLJS.h(null,1,[$CLJS.Zn,1],null),nka],null))],null),new $CLJS.P(null,3,5,$CLJS.Q,[vQ,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,2,5,$CLJS.Q,[vQ,CS],null),new $CLJS.P(null,2,5,$CLJS.Q,[eia,CS],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ON,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),zka],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bP,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yj,aV],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.yt,"Query must specify either `:source-table` or `:source-query`, but not both."],null),function(a){return $CLJS.E.g(1,$CLJS.D($CLJS.em(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TQ,$CLJS.pP],null))))}],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,
new $CLJS.h(null,1,[$CLJS.yt,"Fields specified in `:breakout` should not be specified in `:fields`; this is implied."],null),function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.jG);b=$CLJS.J.g(b,$CLJS.eF);return $CLJS.td($CLJS.ou.g($CLJS.fh(a),$CLJS.fh(b)))}],null)],null));
$CLJS.iV=$CLJS.Ig([$CLJS.PG,$CLJS.MP,oO,$N,cP,nS,JO,$CLJS.mS,$CLJS.$t,IO,YM,fR,cS,$CLJS.nN,ZR,lO,$CLJS.hP,GO,$CLJS.Yi,$CLJS.FO,GM,$CLJS.KN,$CLJS.EO,HP,$CLJS.Os,$CLJS.DB,AO],[new $CLJS.h(null,1,[TO,new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.PG,null,$CLJS.MP,null,$CLJS.$t,null,$CLJS.Os,null,$CLJS.DB,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.lj,$CLJS.MP,TO,new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.PG,null,$CLJS.MP,null,$CLJS.mS,null,$CLJS.hP,null,$CLJS.Yi,null],null),null)],null),new $CLJS.h(null,
3,[$CLJS.lj,$CLJS.Xj,$CLJS.HE,oR,TO,new $CLJS.ah(null,new $CLJS.h(null,1,[oO,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.lj,rS,$CLJS.HE,oR,TO,new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.PG,null,$N,null,$CLJS.$t,null,$CLJS.Yi,null,GM,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.lj,$CLJS.Xj,$CLJS.HE,bN,TO,new $CLJS.ah(null,new $CLJS.h(null,1,[cP,null],null),null)],null),new $CLJS.h(null,1,[TO,new $CLJS.ah(null,new $CLJS.h(null,1,[nS,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.lj,
rS,$CLJS.HE,vja,TO,new $CLJS.ah(null,new $CLJS.h(null,1,[JO,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.lj,$CLJS.MP,TO,new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.mS,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.lj,rS,TO,new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.PG,null,$N,null,$CLJS.$t,null,$CLJS.Yi,null,GM,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.lj,rS,$CLJS.HE,bN,TO,new $CLJS.ah(null,new $CLJS.h(null,1,[IO,null],null),null)],null),new $CLJS.h(null,1,[TO,new $CLJS.ah(null,
new $CLJS.h(null,1,[YM,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.lj,$CLJS.Xj,$CLJS.HE,oR,TO,new $CLJS.ah(null,new $CLJS.h(null,8,[$CLJS.PG,null,nS,null,YM,null,fR,null,GO,null,$CLJS.Yi,null,GM,null,$CLJS.DB,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.lj,$CLJS.Xj,$CLJS.HE,bN,TO,new $CLJS.ah(null,new $CLJS.h(null,1,[cS,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.lj,$CLJS.MP,TO,new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.mS,null,$CLJS.nN,null],null),null)],null),new $CLJS.h(null,
3,[$CLJS.lj,$CLJS.Xj,$CLJS.HE,bN,TO,new $CLJS.ah(null,new $CLJS.h(null,1,[ZR,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.lj,$CLJS.Xj,$CLJS.HE,bN,TO,new $CLJS.ah(null,new $CLJS.h(null,1,[lO,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.lj,$CLJS.MP,TO,new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.PG,null,$CLJS.MP,null,$CLJS.mS,null,$CLJS.hP,null,$CLJS.Yi,null],null),null)],null),new $CLJS.h(null,1,[TO,new $CLJS.ah(null,new $CLJS.h(null,1,[GO,null],null),null)],null),new $CLJS.h(null,1,[TO,
new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.Yi,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.lj,$CLJS.MP,TO,new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.mS,null,$CLJS.FO,null],null),null)],null),new $CLJS.h(null,1,[TO,new $CLJS.ah(null,new $CLJS.h(null,1,[GM,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.lj,$CLJS.MP,TO,new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.mS,null,$CLJS.KN,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.lj,$CLJS.MP,TO,new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.mS,
null,$CLJS.EO,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.lj,rS,$CLJS.HE,oR,TO,new $CLJS.ah(null,new $CLJS.h(null,1,[HP,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.lj,$CLJS.Os,TO,new $CLJS.ah(null,new $CLJS.h(null,3,[$CLJS.PG,null,$CLJS.Yi,null,$CLJS.Os,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.lj,$CLJS.Xj,TO,new $CLJS.ah(null,new $CLJS.h(null,8,[$CLJS.PG,null,nS,null,YM,null,fR,null,GO,null,$CLJS.Yi,null,GM,null,$CLJS.DB,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.lj,
rS,$CLJS.HE,bN,TO,new $CLJS.ah(null,new $CLJS.h(null,1,[AO,null],null),null)],null)]);$CLJS.X(BR,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lk,new $CLJS.h(null,1,[$CLJS.yt,"valid parameter type"],null)],null),$CLJS.Xg($CLJS.iV)));$CLJS.X(jN,$CLJS.eg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lk,new $CLJS.h(null,1,[$CLJS.yt,"valid template tag widget type"],null),$CLJS.mA],null),$CLJS.Xg($CLJS.iV)));
$CLJS.X(OQ,nM(Wha,$CLJS.H(["tag-name",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ys,zS,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yi,zS],null)],null)],null)])));var jV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,OQ],null);$CLJS.X(JM,nM($CLJS.PF,$CLJS.H(["target",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ys,$CLJS.YS,jV],null)])));var kV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JM],null);$CLJS.X(EN,nM(DO,$CLJS.H(["target",jV])));
var lV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,EN],null),Aka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ys,$CLJS.YS,oM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof kV?new $CLJS.Gc(function(){return kV},$CLJS.od(Uia,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[$CLJS.od($Q,new $CLJS.h(null,
1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),zQ,"metabase/mbql/schema.cljc",21,$CLJS.PF,1,1612,1612,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.PF,$CLJS.GJ,$CLJS.U($CLJS.Oj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ys,QN,SO],null))],null),$CLJS.Kc,"Schema for a valid dimension clause.",$CLJS.n(kV)?kV.H:null])):null));return $CLJS.n(a)?a:zQ}(),kV],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=tP.h($CLJS.pd("undefined"!==typeof $CLJS.qM&&
"undefined"!==typeof rM&&"undefined"!==typeof sM&&"undefined"!==typeof lV?new $CLJS.Gc(function(){return lV},$CLJS.od(jja,new $CLJS.h(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,tP,$CLJS.Si,$CLJS.mj,$CLJS.zk,pN,$CLJS.ti,$CLJS.ik,$CLJS.ok],[$CLJS.od($Q,new $CLJS.h(null,1,[$CLJS.ik,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sS,"metabase/mbql/schema.cljc",20,DO,1,1615,1615,new $CLJS.P(null,3,5,$CLJS.Q,[DO,
$CLJS.GJ,$CLJS.U($CLJS.Oj,SO)],null),$CLJS.Kc,"Schema for a valid variable clause.",$CLJS.n(lV)?lV.H:null])):null));return $CLJS.n(a)?a:sS}(),lV],null)]))],null),jM=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qN],null);
$CLJS.X(qN,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,function(a){return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Fs,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.yt,"Query must specify either `:native` or `:query`, but not both."],null),$CLJS.qE($CLJS.HA($CLJS.uQ,$CLJS.SF),$CLJS.Te($CLJS.qE($CLJS.uQ,$CLJS.SF)))],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.yt,"Native queries must specify `:native`; MBQL queries must specify `:query`."],null),function(b){var c=
$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.uQ);var d=$CLJS.J.g(c,$CLJS.SF);c=$CLJS.J.g(c,$CLJS.lj);c=c instanceof $CLJS.M?c.T:null;switch(c){case "native":return b;case "query":return d;default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}}],null)],null)}(new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eQ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ys,new $CLJS.h(null,1,[$CLJS.yt,"valid Database ID"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.oL],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,$CLJS.iL],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lk,$CLJS.SF,$CLJS.uQ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uQ,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),tka],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SF,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),ZU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FN,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yj,new $CLJS.P(null,
8,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj,qka],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Yi,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),zS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.GJ,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),Aka],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cj,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.Ps],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),zS],null),new $CLJS.P(null,3,5,$CLJS.Q,[bia,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),
zS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ci,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.Ps],null)],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mL,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,3,5,$CLJS.Q,[Uja,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),MS],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Tia,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,3,5,$CLJS.Q,[zP,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),DS],null),new $CLJS.P(null,3,5,$CLJS.Q,[iN,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),DS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.yt,"max-results-bare-rows must be less or equal to than max-results"],null),function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,zP);b=$CLJS.J.g(b,iN);return $CLJS.Va($CLJS.n(a)?b:a)?!0:a>=b}],null)],
null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Jia,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,3,5,$CLJS.Q,[Eja,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.Os],null),new $CLJS.P(null,3,5,$CLJS.Q,[Yha,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.Os],null),new $CLJS.P(null,3,5,$CLJS.Q,[Hha,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.Os],null),new $CLJS.P(null,3,5,$CLJS.Q,[dia,new $CLJS.h(null,1,[$CLJS.Ts,
!0],null),$CLJS.Os],null),new $CLJS.P(null,3,5,$CLJS.Q,[wja,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,$CLJS.Os],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Jha,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,$CLJS.Os],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Kja,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,$CLJS.Os],null)],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Oy,new $CLJS.h(null,
1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AN,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,18,5,$CLJS.Q,[$CLJS.lk,ija,fja,$CLJS.wO,hia,dka,kja,zia,Via,ria,Zha,via,tja,Hia,Tha,iia,cka,$ja],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[zja,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,CS],null)],null),new $CLJS.P(null,3,5,
$CLJS.Q,[Eia,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,CS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nR,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,eka],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Vja,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,zS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Gia,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,CS],null)],null),
new $CLJS.P(null,3,5,$CLJS.Q,[Tja,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ds,$CLJS.Ps,$CLJS.Ps],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Aia,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,CS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zO,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yj,new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.Ds,$CLJS.Ps,$CLJS.Ps],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YK,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,$CLJS.Ps],null)],null)],null)],null)],null)],null)),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.yt,"`:source-metadata` should be added in the same level as `:source-query` (i.e., the 'inner' MBQL query.)"],null),$CLJS.Te($CLJS.bP)],null)],null));var mV=$CLJS.iM(jM);
(function(){var a=Cha();return function(b){if($CLJS.n(mV.h?mV.h(b):mV.call(null,b)))return b;b=a.h?a.h(b):a.call(null,b);var c=$CLJS.fM(b);throw $CLJS.hi($CLJS.sE("Invalid query: {0}",$CLJS.H([$CLJS.Mh.l($CLJS.H([c]))])),new $CLJS.h(null,2,[$CLJS.Ky,c,Oia,b],null));}})();