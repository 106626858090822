var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./medley.core.js");require("./metabase.lib.convert.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.expression.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.util.js");require("./metabase.mbql.util.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var D1;$CLJS.z1=function(a,b){return $CLJS.D(a)<=$CLJS.D(b)&&$CLJS.Qe(function(c){return $CLJS.Id(b,c)},a)};$CLJS.A1=function(a,b){return $CLJS.OA($CLJS.Te(a),b)};$CLJS.B1=function(a){var b;a:for(b=a;;)if($CLJS.xd(b))b=$CLJS.Yi.h(b);else{b=$CLJS.Fd(b)?b:null;break a}if($CLJS.n(b))return b;throw Error($CLJS.sE("Not something with an ID: {0}",$CLJS.H([$CLJS.Mh.l($CLJS.H([a]))])));};
$CLJS.C1=function(a){if(null!=a&&null!=a.sg)a=a.sg(a);else{var b=$CLJS.C1[$CLJS.wa(null==a?null:a)];if(null!=b)a=b.h?b.h(a):b.call(null,a);else if(b=$CLJS.C1._,null!=b)a=b.h?b.h(a):b.call(null,a);else throw $CLJS.bb("MetadataProvider.database",a);}return a};D1=function(a,b){if(null!=a&&null!=a.tg)a=a.tg(a,b);else{var c=D1[$CLJS.wa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=D1._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.bb("MetadataProvider.field",a);}return a};
$CLJS.E1=function(a,b){return D1($CLJS.b0(a),b)};$CLJS.y1.m(null,$CLJS.rW,function(a){return $CLJS.x1(a,new $CLJS.h(null,2,[$CLJS.lj,$CLJS.zh,$CLJS.zL,$CLJS.Ve($CLJS.Sk,$CLJS.y1)],null))});$CLJS.O0.m(null,$CLJS.rW,function(){throw $CLJS.hi("You can't calculate a metadata map for a query! Use lib.metadata.calculation/returned-columns-method instead.",$CLJS.N);});$CLJS.Q0.m(null,$CLJS.rW,function(a,b,c,d){return $CLJS.R0.v(a,b,$CLJS.PW(c,b),d)});$CLJS.I0.m(null,$CLJS.rW,function(a,b,c,d){return $CLJS.i0.v(a,b,$CLJS.PW(c,b),d)});
$CLJS.F1=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.gj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("metabase.lib.query","can-run-method"),function(f){return $CLJS.fu.h($CLJS.PW(f,0))},e,a,b,c,d)}();$CLJS.F1.m(null,$CLJS.ci,function(){return!0});
$CLJS.G1=function(){function a(d,e,f){return new $CLJS.h(null,4,[$CLJS.fu,$CLJS.rW,$CLJS.tE,$CLJS.b0(e),$CLJS.eQ,d,$CLJS.zL,f],null)}function b(d,e){var f=$CLJS.Yi.h($CLJS.C1($CLJS.b0(d)));return c.j?c.j(f,d,e):c.call(null,f,d,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.H1=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.gj,$CLJS.oC],null),$CLJS.gj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("metabase.lib.query","query-method"),function(f,k){return $CLJS.mC(k)},e,a,b,c,d)}();$CLJS.H1.m(null,$CLJS.vB,function(a,b){b=$CLJS.hZ.h(b);return $CLJS.G1.g(a,$CLJS.zL.h(b))});
$CLJS.H1.m(null,$CLJS.rW,function(a,b){var c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.dZ);var d=$CLJS.b0(a),e=$CLJS.Ik.g($CLJS.R.j(c,$CLJS.tE,d),$CLJS.dZ);a=$CLJS.zL.h(e);return $CLJS.n(b)?$CLJS.R.j(e,$CLJS.zL,$CLJS.eg.j($CLJS.Cf,$CLJS.hf.h(function(f){var k=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return function u(m,t){try{if($CLJS.zd(t)&&3===$CLJS.D(t))try{var v=$CLJS.F(t,0);if($CLJS.he(v,$CLJS.vG))try{var x=$CLJS.F(t,1);if($CLJS.n($CLJS.Te($CLJS.HA($CLJS.zB,$CLJS.Ei))(x)))try{var z=$CLJS.F(t,2);if($CLJS.n($CLJS.qE($CLJS.Bl,
$CLJS.Ul)(z))){var C=$CLJS.F(t,2),G=$CLJS.F(t,1),K=$CLJS.em($CLJS.E1(d,C),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zB,$CLJS.Ei],null));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vG,$CLJS.hl.l($CLJS.H([K,G])),C],null)}throw $CLJS.Y;}catch(ha){if(ha instanceof Error){var S=ha;if(S===$CLJS.Y)throw $CLJS.Y;throw S;}throw ha;}else throw $CLJS.Y;}catch(ha){if(ha instanceof Error){S=ha;if(S===$CLJS.Y)throw $CLJS.Y;throw S;}throw ha;}else throw $CLJS.Y;}catch(ha){if(ha instanceof Error)if(S=ha,S===$CLJS.Y)try{if(v=
$CLJS.F(t,0),$CLJS.he(v,$CLJS.wB))try{x=$CLJS.F(t,1);if($CLJS.n($CLJS.Te($CLJS.HA($CLJS.zB,$CLJS.Ei))(x))){G=$CLJS.F(t,1);var V=$CLJS.F(t,2);K=function(){try{return $CLJS.A1(function(ra){return $CLJS.E.g($CLJS.zj,ra)},$CLJS.em($CLJS.hd($CLJS.Qna.j(e,k,V)),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zB,$CLJS.Ei],null)))}catch(ra){return null}}();return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wB,$CLJS.hl.l($CLJS.H([K,G])),V],null)}throw $CLJS.Y;}catch(ra){if(ra instanceof Error){var Z=ra;if(Z===$CLJS.Y)throw $CLJS.Y;
throw Z;}throw ra;}else throw $CLJS.Y;}catch(ra){if(ra instanceof Error){Z=ra;if(Z===$CLJS.Y)throw $CLJS.Y;throw Z;}throw ra;}else throw S;else throw ha;}else throw $CLJS.Y;}catch(ha){if(ha instanceof Error){S=ha;if(S===$CLJS.Y)return $CLJS.hX(u,m,t);throw S;}throw ha;}}($CLJS.Cf,f)}),$CLJS.dm($CLJS.Ss,a))):e});$CLJS.H1.m(null,$CLJS.dL,function(a,b){return $CLJS.G1.g(a,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.fu,$CLJS.eW,$CLJS.pP,$CLJS.B1(b)],null)],null))});
$CLJS.H1.m(null,$CLJS.rL,function(a,b){return $CLJS.G1.g(a,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.fu,$CLJS.eW,$CLJS.YV,$CLJS.B1(b)],null)],null))});