var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.metadata.protocols.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.util.malli.registry.js");
'use strict';var EZ,Ima,Jma,Kma,Lma,Mma,KZ,Nma,Oma,Pma,MZ,Qma,Rma,Sma,RZ,SZ,Tma,Uma,Vma,$Z,Wma,Xma,Yma,c_,Zma,$ma,ana,bna;EZ=function(){};$CLJS.FZ=function(a){return null!=a?$CLJS.Bc===a.ki?!0:a.Wc?!1:$CLJS.$a(EZ,a):$CLJS.$a(EZ,a)};Ima=new $CLJS.r(null,"metabase.lib.metadata.protocols","metabase.lib.metadata.protocols",128660233,null);$CLJS.GZ=new $CLJS.M("lib","source-column-alias","lib/source-column-alias",1494821386);
Jma=new $CLJS.M("metabase.lib.schema.metadata","database","metabase.lib.schema.metadata/database",270611124);Kma=new $CLJS.M("lib","persisted-info","lib/persisted-info",-118569625);$CLJS.HZ=new $CLJS.M("source","previous-stage","source/previous-stage",1962610878);$CLJS.IZ=new $CLJS.M(null,"database-id","database-id",1883826326);Lma=new $CLJS.r("metabase.lib.metadata.protocols","metadata-provider?","metabase.lib.metadata.protocols/metadata-provider?",-472923865,null);
$CLJS.JZ=new $CLJS.M("source","table-defaults","source/table-defaults",909975194);Mma=new $CLJS.M(null,"human-readable-values","human-readable-values",-624842907);KZ=new $CLJS.M("metabase.lib.schema.metadata","column.remapping.internal","metabase.lib.schema.metadata/column.remapping.internal",-285151559);Nma=new $CLJS.M("metabase.lib.schema.metadata","metadata-providerable","metabase.lib.schema.metadata/metadata-providerable",1520216538);Oma=new $CLJS.M(null,"dbms-version","dbms-version",-69238036);
Pma=new $CLJS.M(null,"field-name","field-name",1300687948);$CLJS.LZ=new $CLJS.M(null,"dataset","dataset",1159262238);MZ=new $CLJS.M("metabase.lib.schema.metadata","persisted-info","metabase.lib.schema.metadata/persisted-info",1457716669);Qma=new $CLJS.M(null,"field-definitions","field-definitions",1650155116);$CLJS.NZ=new $CLJS.M("source","expressions","source/expressions",-458367840);$CLJS.OZ=new $CLJS.M(null,"definition","definition",-1198729982);Rma=new $CLJS.M(null,"state","state",-1988618099);
$CLJS.PZ=new $CLJS.M("source","implicitly-joinable","source/implicitly-joinable",-729101197);Sma=new $CLJS.M(null,"is-audit","is-audit",327058420);$CLJS.QZ=new $CLJS.M("source","joins","source/joins",1225821486);RZ=new $CLJS.M("metabase.lib.schema.metadata","column-source","metabase.lib.schema.metadata/column-source",1071627828);SZ=new $CLJS.M("metabase.lib.schema.metadata","metadata-provider","metabase.lib.schema.metadata/metadata-provider",-1514728827);
$CLJS.TZ=new $CLJS.M("lib","desired-column-alias","lib/desired-column-alias",-1998967402);Tma=new $CLJS.M("lib","external-remap","lib/external-remap",543358316);$CLJS.UZ=new $CLJS.M(null,"selected?","selected?",-742502788);Uma=new $CLJS.M("metabase.lib.schema.metadata","segment","metabase.lib.schema.metadata/segment",-393440267);Vma=new $CLJS.M("metabase.lib.schema.metadata","metric","metabase.lib.schema.metadata/metric",1984974682);$CLJS.VZ=new $CLJS.M(null,"table-name","table-name",-2117866341);
$CLJS.WZ=new $CLJS.M("source","card","source/card",-139977973);$CLJS.XZ=new $CLJS.M(null,"active","active",1895962068);$CLJS.YZ=new $CLJS.M(null,"dataset-query","dataset-query",1851066427);$CLJS.ZZ=new $CLJS.M(null,"result-metadata","result-metadata",2017934672);$Z=new $CLJS.M("metabase.lib.schema.metadata","column.remapping.external","metabase.lib.schema.metadata/column.remapping.external",304054297);Wma=new $CLJS.M(null,"details","details",1956795411);
$CLJS.a_=new $CLJS.M("source","fields","source/fields",-649667981);Xma=new $CLJS.M("metabase.lib.schema.metadata","card","metabase.lib.schema.metadata/card",147726027);$CLJS.b_=new $CLJS.M("source","native","source/native",-1444604147);Yma=new $CLJS.M("metabase.lib.schema.metadata","table","metabase.lib.schema.metadata/table",-2128230385);c_=new $CLJS.M("metabase.lib.schema.metadata","persisted-info.definition","metabase.lib.schema.metadata/persisted-info.definition",496848961);
$CLJS.d_=new $CLJS.M("lib","card-id","lib/card-id",-1770167062);Zma=new $CLJS.M("metadata.column.remapping","internal","metadata.column.remapping/internal",-1077920983);$ma=new $CLJS.r(null,"metadata-provider?","metadata-provider?",1669871524,null);ana=new $CLJS.M("lib","internal-remap","lib/internal-remap",-220033801);bna=new $CLJS.M("metadata.column.remapping","external","metadata.column.remapping/external",-1813287755);$CLJS.e_=new $CLJS.M(null,"source-alias","source-alias",1652088724);
$CLJS.f_=new $CLJS.M(null,"engine","engine",1459054265);$CLJS.X(RZ,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.lk,$CLJS.WZ,$CLJS.b_,$CLJS.HZ,$CLJS.JZ,$CLJS.a_,$CLJS.sL,$CLJS.nL,$CLJS.QZ,$CLJS.NZ,$CLJS.PZ],null));$CLJS.X($Z,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tk,bna],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yi,$CLJS.fL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.EE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gL,$CLJS.NE],null)],null));
$CLJS.X(KZ,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tk,Zma],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yi,$CLJS.fL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.EE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yj,$CLJS.Ps],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[Mma,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yj,$CLJS.Ps],null)],null)],null));
$CLJS.X($CLJS.eL,new $CLJS.P(null,19,5,$CLJS.Q,[$CLJS.gk,new $CLJS.h(null,1,[$CLJS.yt,"Valid column metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tk,$CLJS.ZK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.Xj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zB,$CLJS.OE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Yi,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.NE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zE,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.xs,$CLJS.Xj],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ei,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,$CLJS.OE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kL,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,$CLJS.NE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jL,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,$CLJS.NE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.e_,
new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,$CLJS.EE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DE,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,$CLJS.EE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$K,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RZ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.d_,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,$CLJS.hL],
null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.GZ,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,$CLJS.EE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TZ,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xj,new $CLJS.h(null,2,[$CLJS.Zn,1,$CLJS.hk,60],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.UZ,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.Os],null),new $CLJS.P(null,3,5,$CLJS.Q,
[Tma,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$Z],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[ana,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,KZ],null)],null)],null)],null));
$CLJS.X(c_,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.VZ,$CLJS.EE],null),new $CLJS.P(null,2,5,$CLJS.Q,[Qma,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,2,5,$CLJS.Q,[Pma,$CLJS.EE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zB,$CLJS.OE],null)],null)],null)],null)],null)],null));
$CLJS.X(MZ,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.XZ,$CLJS.Os],null),new $CLJS.P(null,2,5,$CLJS.Q,[Rma,$CLJS.EE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.VZ,$CLJS.EE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.OZ,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,c_],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YK,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,
$CLJS.EE],null)],null)],null));
$CLJS.X(Xma,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.gk,new $CLJS.h(null,1,[$CLJS.yt,"Valid Card metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tk,$CLJS.rL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yi,$CLJS.hL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.EE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.IZ,$CLJS.iL],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YZ,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.gk],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZZ,
new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yj,$CLJS.gk],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LZ,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.Os],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lL,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,$CLJS.bL],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Kma,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,MZ],null)],null)],null)],null));
$CLJS.X(Uma,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.gk,new $CLJS.h(null,1,[$CLJS.yt,"Valid Segment metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tk,$CLJS.aL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yi,$CLJS.wE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.EE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lL,$CLJS.bL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.OZ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,$CLJS.gk],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.St,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,$CLJS.EE],null)],null)],null));
$CLJS.X(Vma,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.gk,new $CLJS.h(null,1,[$CLJS.yt,"Valid Metric metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tk,$CLJS.cL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yi,$CLJS.uE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.EE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lL,$CLJS.bL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.OZ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,$CLJS.gk],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.St,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,$CLJS.EE],null)],null)],null));
$CLJS.X(Yma,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.gk,new $CLJS.h(null,1,[$CLJS.yt,"Valid Table metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tk,$CLJS.dL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yi,$CLJS.bL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.EE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zE,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,$CLJS.EE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mk,new $CLJS.h(null,
1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,$CLJS.EE],null)],null)],null));
$CLJS.X(Jma,new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.gk,new $CLJS.h(null,1,[$CLJS.yt,"Valid Database metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tk,$CLJS.pL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yi,$CLJS.iL],null),new $CLJS.P(null,3,5,$CLJS.Q,[Oma,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,$CLJS.gk],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Wma,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.gk],null),new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.f_,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.Wi],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nA,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zi,$CLJS.Wi],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Sma,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.Os],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mL,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,$CLJS.gk],null)],null)],null));
$CLJS.X(SZ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.yt,"Valid MetadataProvider"],null),new $CLJS.Gc(function(){return $CLJS.FZ},Lma,$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,$CLJS.Si,$CLJS.mj,$CLJS.zk,$CLJS.ti,$CLJS.ik,$CLJS.ok],[Ima,$ma,"metabase/lib/metadata/protocols.cljc",25,1,78,78,$CLJS.U(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.ar],null)),"Whether `x` is a valid [[MetadataProvider]].",$CLJS.n($CLJS.FZ)?$CLJS.FZ.H:null]))],null));
$CLJS.X(Nma,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SZ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gk,new $CLJS.h(null,1,[$CLJS.yt,"map with a MetadataProvider in the key :lib/metadata (i.e. a query)"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SZ],null)],null)],null)],null));