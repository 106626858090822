var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.data.js");require("./clojure.set.js");require("./clojure.string.js");require("./malli.core.js");require("./malli.error.js");require("./medley.core.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.util.js");require("./metabase.mbql.normalize.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var NY,Wla,PY,Xla,Yla,Zla,RY,ama,bma,cma,dma,UY,VY,WY,XY,ZY,fma,$Y,aZ,gma,hma,ima,TY,$la,bZ,cZ,ema,eZ;NY=function(a,b){var c=$CLJS.y(b);for(a=$CLJS.y($CLJS.bf(a,b));;)if(a)c=$CLJS.B(c),a=$CLJS.B(a);else return c};
Wla=function(){return function(){function a(k,l,m){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj.j?$CLJS.lj.j(k,l,m):$CLJS.lj.call(null,k,l,m),$CLJS.nl.j?$CLJS.nl.j(k,l,m):$CLJS.nl.call(null,k,l,m)],null)}function b(k,l){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj.g?$CLJS.lj.g(k,l):$CLJS.lj.call(null,k,l),$CLJS.nl.g?$CLJS.nl.g(k,l):$CLJS.nl.call(null,k,l)],null)}function c(k){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj.h?$CLJS.lj.h(k):$CLJS.lj.call(null,k),$CLJS.nl.h?$CLJS.nl.h(k):$CLJS.nl.call(null,
k)],null)}function d(){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj.o?$CLJS.lj.o():$CLJS.lj.call(null),$CLJS.nl.o?$CLJS.nl.o():$CLJS.nl.call(null)],null)}var e=null,f=function(){function k(m,t,u,v){var x=null;if(3<arguments.length){x=0;for(var z=Array(arguments.length-3);x<z.length;)z[x]=arguments[x+3],++x;x=new $CLJS.w(z,0,null)}return l.call(this,m,t,u,x)}function l(m,t,u,v){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Se.N($CLJS.lj,m,t,u,v),$CLJS.Se.N($CLJS.nl,m,t,u,v)],null)}k.A=3;k.B=function(m){var t=
$CLJS.A(m);m=$CLJS.B(m);var u=$CLJS.A(m);m=$CLJS.B(m);var v=$CLJS.A(m);m=$CLJS.Lc(m);return l(t,u,v,m)};k.l=l;return k}();e=function(k,l,m,t){switch(arguments.length){case 0:return d.call(this);case 1:return c.call(this,k);case 2:return b.call(this,k,l);case 3:return a.call(this,k,l,m);default:var u=null;if(3<arguments.length){u=0;for(var v=Array(arguments.length-3);u<v.length;)v[u]=arguments[u+3],++u;u=new $CLJS.w(v,0,null)}return f.l(k,l,m,u)}throw Error("Invalid arity: "+arguments.length);};e.A=
3;e.B=f.B;e.o=d;e.h=c;e.g=b;e.j=a;e.l=f.l;return e}()};
$CLJS.OY=function(a){return function(b){var c=$CLJS.$e(-1);return function(){function d(l,m){var t=c.Dd(null,c.Qb(null)+1);m=a.g?a.g(t,m):a.call(null,t,m);return null==m?l:b.g?b.g(l,m):b.call(null,l,m)}function e(l){return b.h?b.h(l):b.call(null,l)}function f(){return b.o?b.o():b.call(null)}var k=null;k=function(l,m){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,m)}throw Error("Invalid arity: "+arguments.length);};k.o=f;k.h=e;k.g=d;return k}()}};
PY=function(a){return $CLJS.aM($CLJS.vW)(a,$CLJS.Cf,$CLJS.Cf)};Xla=function(a,b,c){return $CLJS.eb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return null==e?d:$CLJS.R.j(d,f,e)},null==b?a:$CLJS.R.j(a,$CLJS.kG,b),$CLJS.ot(2,2,c))};Yla=function(a,b){var c=$CLJS.fm(a,$CLJS.RQ);if($CLJS.n(c)){var d=$CLJS.R.j;c=$CLJS.Fb(c);b=$CLJS.It.g?$CLJS.It.g(c,b):$CLJS.It.call(null,c,b);a=d.call($CLJS.R,a,$CLJS.RQ,b)}return a};Zla=function(a){return $CLJS.gf.g($CLJS.WV(a),$CLJS.XV(a))};
$CLJS.QY=function(a,b){return $CLJS.HW(a)&&$CLJS.E.g($CLJS.A(a),b)};RY=function(a,b,c){var d=new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.Ws,null,$CLJS.tl,null],null),null),e=$CLJS.n(d.h?d.h(b):d.call(null,b))?$CLJS.Du(2,c):$CLJS.Du(1,c);b=$CLJS.n(d.h?d.h(b):d.call(null,b))?NY(2,c):NY(1,c);var f=$CLJS.I(b,0,null);return $CLJS.y(e)?$CLJS.FX.j(a,e,function(k){var l=$CLJS.R.j(k,f,null);return $CLJS.zd(k)?$CLJS.eg.j($CLJS.Cf,$CLJS.Rk.h($CLJS.Al),l):$CLJS.xd(k)?$CLJS.OA($CLJS.Wa,l):l}):$CLJS.Ik.g(a,f)};
ama=function(a){return $CLJS.eb(function(b,c){var d=$CLJS.I(c,0,null);$CLJS.I(c,1,null);return RY(b,$la,d)},a,Zla(a))};
bma=function(a){for(var b=0;;){var c=$CLJS.F($CLJS.zL.h(a),b);a:{var d=c;for(var e=$CLJS.Cf;;){var f=$CLJS.A($CLJS.hf.g(Wla(),$CLJS.lf($CLJS.Qk.j(SY,$CLJS.A,$CLJS.nl),$CLJS.$s.h(PY(d)))));if($CLJS.n(f)){var k=f;f=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);var l=RY(d,f,k),m=$CLJS.RA($CLJS.My);if($CLJS.n($CLJS.QA("metabase.lib.convert",m))){var t=$CLJS.IB.l($CLJS.H([$CLJS.Hz,$CLJS.Mh.l($CLJS.H([k]))])),u=$CLJS.IB,v=u.l,x=$CLJS.Mh,z=x.l;var C=f;C=$CLJS.n(C)?C:$CLJS.fM(PY(d));$CLJS.PA("metabase.lib.convert",
m,$CLJS.EW("Clean: Removing bad clause in %s due to error %s:\n%s",$CLJS.H([t,v.call(u,$CLJS.H([$CLJS.Hz,z.call(x,$CLJS.H([C]))])),$CLJS.IB.l($CLJS.H([$CLJS.wz,$CLJS.FW($CLJS.A($CLJS.ku(d,l)))]))])),null)}if($CLJS.E.g(l,d))break a;d=l;e=$CLJS.be.g(e,new $CLJS.P(null,2,5,$CLJS.Q,[f,k],null))}else break a}}d=ama(d);if($CLJS.E.g(c,d)){if($CLJS.E.g(b,$CLJS.D($CLJS.zL.h(a))-1))return a;b+=1}else a=$CLJS.Tk.N(a,$CLJS.zL,$CLJS.R,b,d)}};
cma=function(a){var b=$CLJS.SW();return $CLJS.Sk.g(function(c){return $CLJS.E.g($CLJS.iF.h(c),"__join")?$CLJS.Tk.j(c,$CLJS.iF,b):c},a)};dma=function(a){return"string"===typeof $CLJS.pP.h(a)?$CLJS.Ik.g($CLJS.R.j(a,$CLJS.YV,$CLJS.QW($CLJS.pP.h(a))),$CLJS.pP):a};UY=function(a,b){var c=$CLJS.J.j(a,b,TY);if($CLJS.E.g(c,TY))throw $CLJS.hi(["Unable to find ",$CLJS.Mh.l($CLJS.H([b]))," in map."].join(""),new $CLJS.h(null,2,[ema,a,$CLJS.cx,b],null));return c};
VY=function(){return $CLJS.Rk.h(function(a){var b=$CLJS.I(a,0,null);$CLJS.I(a,1,null);(a=$CLJS.me(b))?(b=$CLJS.ie(b),b=$CLJS.n(b)?$CLJS.E.g(b,"lib")||0==b.lastIndexOf("metabase.lib.",0):null):b=a;return b})};WY=function(a){return $CLJS.eg.j($CLJS.N,VY(),a)};XY=function(a){return $CLJS.Fe($CLJS.eg.j($CLJS.N,$CLJS.Qk.g(VY(),$CLJS.Rk.h(function(b){var c=$CLJS.I(b,0,null);$CLJS.I(b,1,null);return $CLJS.E.g(c,$CLJS.Ei)})),a))};
ZY=function(a){var b=$CLJS.y(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);var d=$CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),$CLJS.hf.h($CLJS.YY),c);a=$CLJS.E.g(a,$CLJS.eG)&&$CLJS.B(c)?$CLJS.be.g($CLJS.sd(d),new $CLJS.h(null,1,[$CLJS.ci,$CLJS.rd(d)],null)):d;b=$CLJS.Fe(XY(b));return $CLJS.n(b)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FM,a,b],null):a};fma=function(a){return $CLJS.eg.j($CLJS.Cf,$CLJS.Qk.g($CLJS.hf.h(function(b){return $CLJS.JA(b,$CLJS.NB)}),$CLJS.hf.h($CLJS.YY)),$CLJS.MW.h(a))};
$Y=function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.zL);a=$CLJS.A($CLJS.eb(function(b,c){var d=$CLJS.I(b,0,null),e=$CLJS.I(b,1,null);b=$CLJS.Q;var f=$CLJS.YY.h(c);d=$CLJS.n(d)?$CLJS.R.j(f,$CLJS.TQ,d):f;d=$CLJS.n(e)?$CLJS.R.j(d,$CLJS.bP,fma(e)):d;return new $CLJS.P(null,2,5,b,[d,$CLJS.yV.h(c)],null)},null,a));return $CLJS.n($CLJS.uQ.h(a))?$CLJS.JV(a,new $CLJS.h(null,1,[$CLJS.uQ,$CLJS.SF],null)):a};
aZ=function(a,b,c){var d=$CLJS.E.g($CLJS.D($CLJS.J.g(a,b)),1)?$CLJS.dX(a,b,$CLJS.Qk.g($CLJS.YY,$CLJS.A)):a;a=1<$CLJS.D($CLJS.J.g(a,b))?$CLJS.dX(d,b,function(e){return $CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Fs],null),$CLJS.hf.h($CLJS.YY),e)}):d;return $CLJS.JV(a,$CLJS.Ee([b,c]))};gma=function(a){var b=$CLJS.YV.h(a);return $CLJS.n(b)?$CLJS.R.j($CLJS.Ik.g(a,$CLJS.YV),$CLJS.pP,["card__",$CLJS.p.h(b)].join("")):a};
hma=new $CLJS.M(null,"legacy-index-\x3epMBQL-uuid","legacy-index-\x3epMBQL-uuid",-192973336);ima=new $CLJS.M(null,"legacy-ref","legacy-ref",1686404700);TY=new $CLJS.M("metabase.lib.convert","not-found","metabase.lib.convert/not-found",967996550);$la=new $CLJS.M("metabase.lib.schema","invalid-ref","metabase.lib.schema/invalid-ref",-2010445475);bZ=new $CLJS.M("metabase.lib.convert","aggregation-or-expression","metabase.lib.convert/aggregation-or-expression",1924029672);
cZ=new $CLJS.M("metabase.lib.convert","expression","metabase.lib.convert/expression",1648102782);$CLJS.dZ=new $CLJS.M("lib.convert","converted?","lib.convert/converted?",1937433683);ema=new $CLJS.M(null,"m","m",1632677161);eZ=new $CLJS.M("metabase.lib.convert","aggregation","metabase.lib.convert/aggregation",108290790);var SY,jma,kma,lma,mma,nma,oma,pma,qma,rma,sma;$CLJS.fZ=$CLJS.N;$CLJS.gZ=$CLJS.N;SY=new $CLJS.ah(null,new $CLJS.h(null,7,[$CLJS.sW,null,$CLJS.eF,null,$CLJS.ON,null,$CLJS.kQ,null,$CLJS.jG,null,$CLJS.$R,null,$CLJS.kG,null],null),null);jma=$CLJS.Xe($CLJS.N);kma=$CLJS.Xe($CLJS.N);lma=$CLJS.Xe($CLJS.N);mma=$CLJS.Xe($CLJS.N);nma=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.gj,$CLJS.oC],null),$CLJS.gj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));
$CLJS.hZ=new $CLJS.di($CLJS.Fh.g("metabase.lib.convert","-\x3epMBQL"),$CLJS.mC,nma,jma,kma,lma,mma);$CLJS.hZ.m(null,$CLJS.ci,function(a){if($CLJS.zd(a)&&$CLJS.A(a)instanceof $CLJS.M){var b=$CLJS.rd(a);var c=$CLJS.xd(b);a=c?$CLJS.sd(a):a;var d=$CLJS.y(a);a=$CLJS.A(d);d=$CLJS.B(d);b=$CLJS.zW($CLJS.eg.j(new $CLJS.P(null,2,5,$CLJS.Q,[a,c?b:$CLJS.N],null),$CLJS.hf.h($CLJS.hZ),d))}else b=a;return b});$CLJS.hZ.m(null,$CLJS.rW,function(a){return a});
$CLJS.hZ.m(null,$CLJS.eW,function(a){var b=$CLJS.hZ.h($CLJS.kG.h(a)),c=$CLJS.Fe($CLJS.Sk.g(function(t){var u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);return $CLJS.JW($CLJS.hZ.h(t),u)},$CLJS.kQ.h(a))),d=$CLJS.eg.j($CLJS.N,$CLJS.gm(function(t,u){$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=$CLJS.O(u);u=$CLJS.J.g(u,$CLJS.LE);return new $CLJS.P(null,2,5,$CLJS.Q,[t,u],null)}),b),e=$CLJS.eg.j($CLJS.N,$CLJS.gm(function(t,u){$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=$CLJS.O(u);u=$CLJS.J.g(u,$CLJS.LE);return new $CLJS.P(null,
2,5,$CLJS.Q,[u,t],null)}),b),f=$CLJS.gZ,k=$CLJS.fZ;$CLJS.gZ=d;$CLJS.fZ=e;try{var l=Xla(dma(a),b,$CLJS.H([$CLJS.kQ,c])),m=$CLJS.eb(function(t,u){return $CLJS.Va($CLJS.J.g(t,u))?t:$CLJS.Tk.j(t,u,$CLJS.hZ)},l,$CLJS.Jk.l(SY,$CLJS.kG,$CLJS.H([$CLJS.kQ])));return $CLJS.n($CLJS.ON.h(m))?$CLJS.Tk.j(m,$CLJS.ON,cma):m}finally{$CLJS.fZ=k,$CLJS.gZ=f}});$CLJS.hZ.m(null,$CLJS.lW,function(a){return Yla(a,function(b){return $CLJS.dX(b,$CLJS.PF,$CLJS.hZ)})});
$CLJS.hZ.m(null,$CLJS.uL,function(a){a=$CLJS.Tk.j($CLJS.Tk.j(a,$CLJS.CL,$CLJS.hZ),$CLJS.zL,$CLJS.hZ);var b=$CLJS.n($CLJS.eF.h(a))?$CLJS.Tk.j(a,$CLJS.eF,function(c){return $CLJS.Ml(c)?$CLJS.Sk.g($CLJS.hZ,c):$CLJS.zh.h(c)}):a;return $CLJS.Va($CLJS.iF.h(a))?$CLJS.R.j(b,$CLJS.iF,"__join"):b});$CLJS.hZ.m(null,$CLJS.tB,function(a){return $CLJS.Sk.g($CLJS.hZ,a)});
$CLJS.hZ.m(null,$CLJS.vB,function(a){return $CLJS.n($CLJS.lj.h(a))?bma($CLJS.R.j($CLJS.Tk.j($CLJS.NW(a),$CLJS.zL,function(b){return $CLJS.Sk.g($CLJS.hZ,b)}),$CLJS.dZ,!0)):$CLJS.It(a,$CLJS.hZ)});$CLJS.hZ.m(null,$CLJS.vG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.xd(b)?new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null):new $CLJS.P(null,2,5,$CLJS.Q,[b,a],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.zW(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vG,a,b],null))});
$CLJS.hZ.m(null,$CLJS.Cj,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.JV(a,new $CLJS.h(null,3,[$CLJS.nD,$CLJS.zB,$CLJS.OO,$CLJS.Tj,$CLJS.hS,$CLJS.GE],null));var c=$CLJS.R.j;var d=$CLJS.Ei.h(a);$CLJS.n(d)||(d=$CLJS.zB.h(a),d=$CLJS.n(d)?d:$CLJS.$E(b));a=c.call($CLJS.R,a,$CLJS.Ei,d);return $CLJS.zW(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cj,a,b],null))});
$CLJS.hZ.m(null,$CLJS.eG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.ci.h(c);b=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eG,$CLJS.Ik.g(c,$CLJS.ci),$CLJS.Sk.g($CLJS.hZ,b)],null);b=$CLJS.zW(b);return null!=a?$CLJS.be.g(b,$CLJS.hZ.h(a)):b});$CLJS.hZ.m(null,$CLJS.wB,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.zW(new $CLJS.P(null,3,5,$CLJS.Q,[b,a,c],null))});
$CLJS.hZ.m(null,$CLJS.kG,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.zW(new $CLJS.P(null,3,5,$CLJS.Q,[b,a,UY($CLJS.gZ,c)],null))});$CLJS.hZ.m(null,$CLJS.FM,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=$CLJS.hZ.h(b);var c=$CLJS.y(b);b=$CLJS.A(c);var d=$CLJS.B(c);c=$CLJS.A(d);d=$CLJS.B(d);return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.hl.l($CLJS.H([c,a]))],null),d)});oma=$CLJS.Xe($CLJS.N);pma=$CLJS.Xe($CLJS.N);
qma=$CLJS.Xe($CLJS.N);rma=$CLJS.Xe($CLJS.N);sma=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.gj,$CLJS.oC],null),$CLJS.gj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));$CLJS.YY=new $CLJS.di($CLJS.Fh.g("metabase.lib.convert","-\x3elegacy-MBQL"),$CLJS.mC,sma,oma,pma,qma,rma);
$CLJS.YY.m(null,$CLJS.ci,function(a){if($CLJS.zd(a)&&$CLJS.A(a)instanceof $CLJS.M){a=$CLJS.y(a);var b=$CLJS.A(a),c=$CLJS.B(a);a=$CLJS.A(c);var d=$CLJS.B(c);if($CLJS.xd(a)){c=$CLJS.eg.g;b=new $CLJS.P(null,1,5,$CLJS.Q,[b],null);var e=$CLJS.gf.g;d=$CLJS.hf.g($CLJS.YY,d);a=XY(a);a=c.call($CLJS.eg,b,e.call($CLJS.gf,d,$CLJS.n(a)?new $CLJS.P(null,1,5,$CLJS.Q,[a],null):null))}else a=$CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[b],null),$CLJS.hf.g($CLJS.YY,$CLJS.ee(a,d)))}else a=$CLJS.xd(a)?$CLJS.It(WY(a),$CLJS.YY):
a;return a});for(var iZ=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[eZ,cZ],null)),jZ=null,kZ=0,lZ=0;;)if(lZ<kZ){var tma=jZ.X(null,lZ);$CLJS.DF(tma,bZ);lZ+=1}else{var mZ=$CLJS.y(iZ);if(mZ){var nZ=mZ;if($CLJS.Ad(nZ)){var oZ=$CLJS.lc(nZ),uma=$CLJS.mc(nZ),vma=oZ,wma=$CLJS.D(oZ);iZ=uma;jZ=vma;kZ=wma}else{var xma=$CLJS.A(nZ);$CLJS.DF(xma,bZ);iZ=$CLJS.B(nZ);jZ=null;kZ=0}lZ=0}else break}
for(var pZ=$CLJS.y(new $CLJS.P(null,12,5,$CLJS.Q,[$CLJS.Zx,$CLJS.pG,$CLJS.EG,$CLJS.aG,$CLJS.hk,$CLJS.JG,$CLJS.Zn,$CLJS.gG,$CLJS.CG,$CLJS.LG,$CLJS.IG,$CLJS.QG],null)),qZ=null,rZ=0,sZ=0;;)if(sZ<rZ){var yma=qZ.X(null,sZ);$CLJS.DF(yma,eZ);sZ+=1}else{var tZ=$CLJS.y(pZ);if(tZ){var uZ=tZ;if($CLJS.Ad(uZ)){var vZ=$CLJS.lc(uZ),zma=$CLJS.mc(uZ),Ama=vZ,Bma=$CLJS.D(vZ);pZ=zma;qZ=Ama;rZ=Bma}else{var Cma=$CLJS.A(uZ);$CLJS.DF(Cma,eZ);pZ=$CLJS.B(uZ);qZ=null;rZ=0}sZ=0}else break}
for(var wZ=$CLJS.y($CLJS.yf([$CLJS.ds,$CLJS.rt,$CLJS.es,$CLJS.RG,$CLJS.eG,$CLJS.yG,$CLJS.WF,$CLJS.TG,$CLJS.NF,$CLJS.iG,$CLJS.RF,$CLJS.tG,$CLJS.AG,$CLJS.TF,$CLJS.lJ,$CLJS.nJ,$CLJS.Ny,$CLJS.hJ,$CLJS.pJ,$CLJS.bJ,$CLJS.tJ,$CLJS.gJ,$CLJS.$I,$CLJS.sJ,$CLJS.AJ,$CLJS.wJ,$CLJS.fJ,$CLJS.DJ,$CLJS.vJ,$CLJS.BJ,$CLJS.VF,$CLJS.OF,$CLJS.UG,$CLJS.lI,$CLJS.xG,$CLJS.Xx,$CLJS.MG,$CLJS.OG,$CLJS.VG,$CLJS.Lt,$CLJS.Rt],!0)),xZ=null,yZ=0,zZ=0;;)if(zZ<yZ){var Dma=xZ.X(null,zZ);$CLJS.DF(Dma,cZ);zZ+=1}else{var AZ=$CLJS.y(wZ);
if(AZ){var BZ=AZ;if($CLJS.Ad(BZ)){var CZ=$CLJS.lc(BZ),Ema=$CLJS.mc(BZ),Fma=CZ,Gma=$CLJS.D(CZ);wZ=Ema;xZ=Fma;yZ=Gma}else{var Hma=$CLJS.A(BZ);$CLJS.DF(Hma,cZ);wZ=$CLJS.B(BZ);xZ=null;yZ=0}zZ=0}else break}$CLJS.YY.m(null,bZ,function(a){return ZY(a)});$CLJS.YY.m(null,$CLJS.vB,function(a){return $CLJS.eg.j($CLJS.N,$CLJS.Qk.g(VY(),$CLJS.hf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[c,$CLJS.YY.h(b)],null)})),a)});
$CLJS.YY.m(null,$CLJS.kG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);if($CLJS.xd(b))try{var d=XY(b),e=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kG,UY($CLJS.fZ,c)],null);return $CLJS.n(d)?$CLJS.be.g(e,d):e}catch(f){throw b=f,c=$CLJS.DV(b),c=$CLJS.fa.g?$CLJS.fa.g("Error converting aggregation reference to pMBQL: %s",c):$CLJS.fa.call(null,"Error converting aggregation reference to pMBQL: %s",c),$CLJS.gi(c,new $CLJS.h(null,1,[$CLJS.W,a],null),b);}else return a});
$CLJS.YY.m(null,$CLJS.tB,function(a){return $CLJS.Sk.g($CLJS.YY,a)});$CLJS.YY.m(null,$CLJS.vG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=null==b||$CLJS.xd(b)?new $CLJS.P(null,2,5,$CLJS.Q,[b,a],null):new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vG,$CLJS.YY.h(a),XY(b)],null)});
$CLJS.YY.m(null,$CLJS.Cj,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=XY($CLJS.JV(b,new $CLJS.h(null,3,[$CLJS.zB,$CLJS.nD,$CLJS.Tj,$CLJS.OO,$CLJS.GE,$CLJS.hS],null)));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cj,a,b],null)});
$CLJS.YY.m(null,$CLJS.uL,function(a){var b=WY(a);a=0==$CLJS.iF.h(a).lastIndexOf("__join",0)?$CLJS.Ik.g(b,$CLJS.iF):b;return $CLJS.hl.l($CLJS.H([$CLJS.It($CLJS.Ik.l(a,$CLJS.zL,$CLJS.H([$CLJS.CL])),$CLJS.YY),aZ($CLJS.em(a,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.CL],null)),$CLJS.CL,$CLJS.bS),$Y(a)]))});
$CLJS.YY.m(null,$CLJS.eW,function(a){var b=$CLJS.kG.h(a),c=$CLJS.eg.j($CLJS.N,$CLJS.gm(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.LE);return new $CLJS.P(null,2,5,$CLJS.Q,[f,k],null)}),b);b=$CLJS.eg.j($CLJS.N,$CLJS.gm(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.LE);return new $CLJS.P(null,2,5,$CLJS.Q,[k,f],null)}),b);var d=$CLJS.gZ,e=$CLJS.fZ;$CLJS.gZ=c;$CLJS.fZ=b;try{return $CLJS.eb(function(f,k){return $CLJS.dX(f,
k,$CLJS.YY)},aZ($CLJS.dX($CLJS.dX(gma(WY(a)),$CLJS.kG,function(f){return $CLJS.Sk.g(ZY,f)}),$CLJS.kQ,function(f){return $CLJS.eg.g($CLJS.N,function(){return function m(l){return new $CLJS.ne(null,function(){for(;;){var t=$CLJS.y(l);if(t){if($CLJS.Ad(t)){var u=$CLJS.lc(t),v=$CLJS.D(u),x=$CLJS.qe(v);a:for(var z=0;;)if(z<v){var C=$CLJS.kd(u,z),G=$CLJS.YY.h(C);C=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.IW(C),$CLJS.E.g($CLJS.Cj,$CLJS.A(G))?$CLJS.hd(G):G],null);x.add(C);z+=1}else{u=!0;break a}return u?$CLJS.te($CLJS.ve(x),
m($CLJS.mc(t))):$CLJS.te($CLJS.ve(x),null)}x=$CLJS.A(t);u=$CLJS.YY.h(x);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.IW(x),$CLJS.E.g($CLJS.Cj,$CLJS.A(u))?$CLJS.hd(u):u],null),m($CLJS.Lc(t)))}return null}},null,null)}(f)}())}),$CLJS.sW,$CLJS.$F),$CLJS.Jk.l(SY,$CLJS.kG,$CLJS.H([$CLJS.sW,$CLJS.kQ])))}finally{$CLJS.fZ=e,$CLJS.gZ=d}});$CLJS.YY.m(null,$CLJS.lW,function(a){return $CLJS.It(WY(a),$CLJS.YY)});
$CLJS.YY.m(null,$CLJS.rW,function(a){try{var b=WY(a),c=$CLJS.FN.h(b),d=$Y(b),e=$CLJS.E.g($CLJS.fu.h($CLJS.id($CLJS.zL.h(a))),$CLJS.lW)?$CLJS.uQ:$CLJS.SF;return $CLJS.hl.l($CLJS.H([$CLJS.Ik.l(b,$CLJS.zL,$CLJS.H([$CLJS.FN,$CLJS.dZ])),function(){var k=$CLJS.Ee([$CLJS.lj,e,e,d]);return $CLJS.y(c)?$CLJS.R.j(k,$CLJS.FN,c):k}()]))}catch(k){var f=k;throw $CLJS.gi(function(){var l=$CLJS.DV(f);return $CLJS.fa.g?$CLJS.fa.g("Error converting MLv2 query to legacy query: %s",l):$CLJS.fa.call(null,"Error converting MLv2 query to legacy query: %s",
l)}(),new $CLJS.h(null,1,[$CLJS.SF,a],null),f);}});
$CLJS.DZ=function(){function a(d,e,f){f=$CLJS.MY(null,$CLJS.Uz(f,$CLJS.H([$CLJS.yi,!0])));var k=$CLJS.PW(d,e);k=$CLJS.O(k);var l=$CLJS.J.g(k,$CLJS.kG);k=$CLJS.eg.j($CLJS.N,$CLJS.gm(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.LE);return new $CLJS.P(null,2,5,$CLJS.Q,[v,x],null)}),l);l=$CLJS.eg.j($CLJS.N,$CLJS.gm(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.LE);return new $CLJS.P(null,2,5,$CLJS.Q,[x,v],null)}),l);var m=
$CLJS.gZ,t=$CLJS.fZ;$CLJS.gZ=k;$CLJS.fZ=l;try{try{return $CLJS.hZ.h(f)}catch(v){var u=v;throw $CLJS.gi(function(){var x=$CLJS.DV(u);return $CLJS.fa.g?$CLJS.fa.g("Error converting legacy ref to pMBQL: %s",x):$CLJS.fa.call(null,"Error converting legacy ref to pMBQL: %s",x)}(),new $CLJS.h(null,4,[$CLJS.SF,d,$CLJS.OL,e,ima,f,hma,$CLJS.gZ],null),u);}}finally{$CLJS.fZ=t,$CLJS.gZ=m}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();